import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { SharedModule } from 'app/shared/shared.module';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { StaffRoomRoutingModule } from './staff-room-routing.module';
import { StaffRoomComponent } from './staff-room.component';
import { StaffRoomService } from './staff-room.service';
import { TopicDetailsComponent } from './topic-details/topic-details.component';
import { TopicListViewComponent } from './topic-details/topic-list-view/topic-list-view.component';
import { TopicTileViewComponent } from './topic-details/topic-tile-view/topic-tile-view.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    StaffRoomRoutingModule,
    AngularEditorModule,
    NgxDocViewerModule,
    VgControlsModule,
    VgCoreModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgxMaterialTimepickerModule,
  ],
  declarations:[
    StaffRoomComponent,
    TopicDetailsComponent,
    TopicListViewComponent,
    TopicTileViewComponent,
  ],
  providers: [StaffRoomService]
})

export class StaffRoomModule { }
