import { AfterViewInit, Component, Input, OnInit, ViewChild, ElementRef, HostListener, Directive  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
 
import { AppComponent } from 'app/app.component';
import { AlertPopup, config, HttpLoader, ResolveEmit, path } from 'app/app.config';
import { PlayerService } from 'app/components/media-player/media-player-service';
import { SecureFileService } from 'app/secure-image/secure-image-service';
import { DataSharingService } from 'app/shared/data-sharing.service';
import { SocketService } from 'app/socket/socket.service';
import { DatePipe } from '@angular/common';
import { saveAs as importedSaveAs } from 'file-saver';
import { ChatService } from 'app/components/chat-list/chat.service';
import { viewerType } from 'ngx-doc-viewer/document-viewer.component';
import { ELibraryService } from '../../e-library.service';
import { ELibraryComponent } from '../../e-library.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileValidationService } from 'app/shared/file-validation.service';

import * as moment from "moment";
import { map } from 'rxjs/operators';
import { ConfirmationDialogService } from 'app/confirmation-dialog/confirmation-dialog.service';




// @Directive()
@Component({
  selector: 'app-e-library-list',
  templateUrl: './e-library-list.component.html',
  styleUrls: []
})
export class ELibraryListComponent implements OnInit, AfterViewInit {
  @Input() classFileList;
  @Input() editedAssessmentData;
  @Input() totalUserCount;
  @Input() maxLength: number = 400;
  @Input() maxHeight = "4rem";
  // @Input() mediaContent: any;
  @ViewChild("scrollframe") scrollFrame: ElementRef;
  @ViewChild("topicFileInput") public topicFileInput: ElementRef;
  @ViewChild("durationInput") durationInput: ElementRef;
  @ViewChild("surveyCheck") surveyCheck : ElementRef;

  @HostListener("document:click", ["$event"]) onDocumentClick(event) {
    this.show = false;
  }
  clickit($event, ind) {
    $event.stopPropagation();
    this.dropdownIndex = ind;
    this.show = !this.show;
  }

  show = false;

  mode = 'surveyQuiz';

  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  OFFSET = config.DEFAULT_OFFSET;
  RECORD_LIMIT = config.DEFAULT_RECORD_LIMIT;
  group_id: any;
  assRadioSel: any;
  radioSelected: string;
  assementOptions: any = [];
  assetFormValid = false;
  surveySelectOption = [];
  pollSelectOption = [];

  editedData = {};
  edditedAssesment = [];
  studentResult = [];
  surveyReports = [];
  pollReports = [];
  answers = [];
  uploadAttachFile: boolean = true;
  option: any = [];
  totalMarks = 0;
  overallmarks: number;
  percentage: number;
  maxMarks: number = 0;
  editedMessageId: number;
  public showRadioBtnFlg = false;
  selectedOption = [];
  loggedUserId: number;
  quizData: any;
  isHidden = false;
  assessmentSubmit = false;
  surveySubmit = false;
  pollBtnSubmit = false;

  mediaContent: any = null;
  inputSrc: string;
  currentTrack: any;
  playingMedia: string;
  totalRowCount: number;
  showPaginateSpinner = false;

  isUserRole: any;
  dropdownIndex: any;
  activeModalSelectFile = [];
  mesgIds = "";
  selectedReadFile = "";
  showFileLoadSpinner = false;
  showBtnSpinner = false;
  is_question_found = false;
  is_survey_found = false;
  is_polls_found = false;
  isChecked = false;
  surveycompleted='';
  pollCompleted='';


  viewControl = {
    hideExistingFileView: true,
    showHideEditFileUpload: true,
    showAssessmentMarks: true,
  };

  /** View Variables */
  domElementControl = {
    showCreateNewQuestions: false,
    showCreateNewSurQuestion: false,
    showCreateNewPolQuestion: false,
  };

  public subscribers: any = {};

  /***Video title, shared by and created details */
  msgTitle: any;
  shared_by: any;
  created_at: any;
  currentVideoId: any;
  thumbUrl: string;

  mediaData: any;

  currentIndex = 0;
  currentVideo = "";
  arrayData: any;
  nextDisabled = false;
  prevDisabled = true;
  showHiddenAssessmentModal = [];
  showHideSurveyModal = [];
  showHidePollModal = [];
  getAssessmentData: any[] = [];
  getAssessmentResult = [];
  getTestScore = [];
  questionCounter = 0; //Tracks question number
  totalUsersCount = 0;

  pager = {
    index: 0,
    size: 1,
    count: 1
  }

  /** Chat File Size limit */
  chatFileSizeLimit: number = config.MAX_ATTACH_FILE_SIZE;
  chatFileExt = config.ATTACHMENT_EXT;

  upLoadedFileList: any[] = [];
  uploadWatermarkLogo: any[] = [];
  fileDuration: string = "";
  selectedRadio = true;
  getElibraryFileList: any[] = [];
  fileUploadingInProgress = false;
  topicFileExtension = config.TOPIC_EXT;
  topicFileSize = config.TOPIC_FILE_SIZE;
  preUploadedFile: any;
  preUploadedWatermarkFile: any;
  preProfileUrl = path.PRE_PROFILE_URL;
  profileUrl = path.PROFILE_URL;

  file_download = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];

  publish = [
    { title: "Yes", value: true },
    { title: "No", value: false },
  ];

  radius: number = 125;
  semicircle: boolean = false;

  assessmentForm: FormGroup;
  editQuizForm: FormGroup;
  surveyQuizForm: FormGroup;
  pollQuizForm: FormGroup;

  uploadFileIcons = [
    { name: "pdf", icons: "../../../../assets/images/file_types/pdf.png" },
    { name: "doc", icons: "../../../../assets/images/file_types/doc.png" },
    { name: "docx", icons: "../../../../assets/images/file_types/docx.png" },
    { name: "txt", icons: "../../../../assets/images/file_types/txt.png" },
    { name: "ppt", icons: "../../../../assets/images/file_types/ppt.png" },
    { name: "pptx", icons: "../../../../assets/images/file_types/ppt.png" },
    { name: "pptm", icons: "../../../../assets/images/file_types/ppt.png" },
    { name: "mp3", icons: "../../../../assets/images/file_types/mp3.png" },
    { name: "mp4", icons: "../../../../assets/images/file_types/mp4.png" },
    { name: "wav", icons: "../../../../assets/images/file_types/mp4.png" },
    { name: "avi", icons: "../../../../assets/images/file_types/mp4.png" },
    { name: "png", icons: "../../../../assets/images/file_types/png.png" },
    { name: "jpeg", icons: "../../../../assets/images/file_types/jpg.png" },
    { name: "jpg", icons: "../../../../assets/images/file_types/jpg.png" },
    { name: "flv", icons: "../../../../assets/images/file_types/mp4.png" },
    { name: "html", icons: "../../../../assets/images/file_types/html.png" },
    { name: "psd", icons: "../../../../assets/images/file_types/psd.png" },
    { name: "json", icons: "../../../../assets/images/file_types/json.png" },
    { name: "xlsx", icons: "../../../../assets/images/file_types/xlsx.png" },
    { name: "xls", icons: "../../../../assets/images/file_types/xls.png" },
    { name: "css", icons: "../../../../assets/images/file_types/css.png" },
    { name: "zip", icons: "../../../../assets/images/file_types/zip.png" },
    { name: "sql", icons: "../../../../assets/images/file_types/zip.png" },
  ];

  constructor(
    private httpLoader: HttpLoader,
    private alert: AlertPopup,
    private appcomponent: AppComponent,
    private playerService: PlayerService,
    private router: Router,
    private secureFileService: SecureFileService,
    private confirmDialogService:ConfirmationDialogService,
    private socketService: SocketService,
    private dataSharingService: DataSharingService,
    private chatService: ChatService,
    private route: ActivatedRoute,
    // private elibraryService: ELibraryService,
    // private elibraryComponent: ELibraryComponent,
    private elibraryComponent: ELibraryComponent,
    private elibraryService: ELibraryService,
    private fb: FormBuilder,
    private fileValidationService: FileValidationService
  ) {
    this.assessmentForm = fb.group({
      is_correct_answer: [''],
      ovarallmark: [""],
    });

    this.surveyQuizForm = fb.group({
      isAnswered: ['']
    });

    this.pollQuizForm = fb.group({});

    this.editQuizForm = fb.group({
      message: ['', Validators.required],
      description: [''],
      duration: [''],
      price:[''],
      discount: [''],
      water_mark_title:[''],
      is_download:[''],
      assessment: fb.array([]),
      survey: fb.array([]),
      polls: fb.array([])
    });
  }


  // focusHandler = (e) => {
  //   e.preventDefault();
  //   this.updateMessageRead(this.classFileList);
  // };


  ngOnInit(): void {
    this.isUserRole = window.localStorage.getItem("user_role");
    this.isUserRole = parseInt(this.isUserRole.replace(/\"/g, ""));

    this.currentVideo = this.classFileList[this.currentIndex];

    this.route.paramMap.subscribe((x) => {
      this.group_id = x.get("id");
    });

    // window.removeEventListener('focus', this.focusHandler, false);
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.subscribers.media = this.elibraryService.getMediaContent().subscribe(
      (response) => {
        if (this.mediaContent && response) {
          if (this.mediaContent.file_path !== response.file_path) {
            this.mediaContent = response;

            this.msgTitle = response.message;
            this.shared_by = response.shared_by;
            this.created_at = response.created_at;
            this.currentVideoId = response.message_id;

            // console.log(this.currentVideoId);
          }
        } else {
          this.mediaContent = response;
        }
      },
      (error) => console.log(error)
    );
  }

  fetchElibraryVideoList() {
    const listLength = this.classFileList.length;
    if (this.totalRowCount > listLength && this.showPaginateSpinner) {
      this.OFFSET += 1;
      this.showPaginateSpinner = true;
    }
  }

  secureFileVideo(messageId, fileData) {
    try {
      // console.log(fileData.file_type);

      this.elibraryService.clear();
      if (fileData.file_type === "video" || fileData.file_type === "audio") {
        if (fileData) {
          this.viewControl.hideExistingFileView = false;
          this.elibraryComponent.viewLibraryList = true;

          this.currentVideoId = messageId;
          this.msgTitle = fileData.message;
          this.shared_by = fileData.shared_by;
          this.created_at = fileData.created_at;
          // this.currentVideoId = fileData.message_id;

          const payLoad = {
            file_path: fileData.download_url,
            file_type: fileData.file_type,
            message: fileData.message,
            shared_by: fileData.shared_by,
            created_at: fileData.created_at,
            message_id: fileData.message_id,
            thumb_url: fileData.thumb_url ? fileData.thumb_url : null,
            view_Url: fileData.view_Url ? fileData.view_Url : null,
          };

          this.elibraryService.emitMediaContent(payLoad);
          this.playingMedia = { ...fileData }.download_url;
        }
      } else {
        if (fileData.file_type === "image") {
          this.viewControl.hideExistingFileView = false;
          this.elibraryComponent.viewLibraryList = true;

          this.currentVideoId = messageId;
          this.msgTitle = fileData.message;
          this.shared_by = fileData.shared_by;
          this.created_at = fileData.created_at;

          const payLoad = {
            file_path: fileData.download_url,
            file_type: fileData.file_type,
            message: fileData.message,
            shared_by: fileData.shared_by,
            created_at: fileData.created_at,
            message_id: fileData.message_id,
            file_ext: fileData.file_ext,
            thumb_url: fileData.thumb_url ? fileData.thumb_url : null,
            view_Url: fileData.view_Url ? fileData.view_Url : null,
          };

          // console.log(payLoad);

          this.elibraryService.emitMediaContent(payLoad);
        }
        // this.playingMedia = {...fileData}.download_url;
      }

      if(this.isUserRole!=1){
        const message = {
          // from_user_id: 291,
          group_id: this.group_id,
          message_id: messageId,
          // user_id: 239
        }

        this.chatService.updateMessageRead(message).subscribe((res:any) => {
          console.log(res);

        })
      }

    } catch (error) {
      console.log(error);
    }
  }

  secureFileRead(messageId, fileData) {
    try {
      console.log(fileData.file_type);

      this.elibraryService.clear();
      if (fileData.file_type === "audio") {
        if (fileData) {
          this.viewControl.hideExistingFileView = false;
          this.elibraryComponent.viewLibraryList = true;

          this.currentVideoId = messageId;
          this.msgTitle = fileData.message;
          this.shared_by = fileData.shared_by;
          this.created_at = fileData.created_at;

          console.log(fileData);

          const payLoad = {
            file_path: fileData.download_url,
            file_type: fileData.file_type,
            message: fileData.message,
            shared_by: fileData.shared_by,
            created_at: fileData.created_at,
            message_id: fileData.message_id,
            thumb_url: fileData.thumb_url ? fileData.thumb_url : null,
            view_Url: fileData.view_Url ? fileData.view_Url : null,
          };

          this.elibraryService.emitMediaContent(payLoad);
          this.playingMedia = { ...fileData }.download_url;
        }
      } else {
        if (fileData) {
          this.showFileLoadSpinner = true;
          // this.activeModalSelectFile[messageId] = true;
          this.mesgIds = messageId;
          const url = fileData.view_Url;

          this.viewControl.hideExistingFileView = false;
          this.elibraryComponent.viewLibraryList = true;

          this.currentVideoId = messageId;
          this.msgTitle = fileData.message;
          this.shared_by = fileData.shared_by;
          this.created_at = fileData.created_at;

          const payLoad = {
            file_path: fileData.download_url,
            file_type: fileData.file_type,
            message: fileData.message,
            shared_by: fileData.shared_by,
            created_at: fileData.created_at,
            message_id: fileData.message_id,
            file_ext: fileData.file_ext,
            thumb_url: fileData.thumb_url ? fileData.thumb_url : null,
            view_Url: fileData.view_Url ? fileData.view_Url : null,
          };

          this.elibraryService.emitMediaContent(payLoad);
        }
      }

      if(this.isUserRole!=1){
        const message = {
          // from_user_id: 291,
          group_id: this.group_id,
          message_id: messageId,
          // user_id: 239
        }

        this.chatService.updateMessageRead(message).subscribe((res:any) => {
          console.log(res);

        })
      }

    } catch (error) {
      console.log(error);
    }
  }

  secureFileDownLoad(fileData: any) {
    try {
      const url = fileData.download_url;
      if (url) {
        fileData.startFileDownload = true;
        this.secureFileService.secureFileDownLoad(url).subscribe(
          (blob) => {
            fileData.startFileDownload = false;
            importedSaveAs(blob, fileData.file_id);
          },
          (error) => {
            fileData.startFileDownload = false;
            console.log(error);
          }
        );
      }
    } catch (error) {
      fileData.startFileDownload = false;
      console.log(error);
    }
  }

  secureFileViewed(messageId, fileData) {
    try {
      this.showFileLoadSpinner = true;
      this.activeModalSelectFile[messageId] = true;
      this.mesgIds = messageId;
      const url = fileData.view_Url;
      console.log(url);

      if (url) {
        // this.showFileLoadSpinner = false;
        fileData.startFileDownload = false;
        this.secureFileService.secureReadfile(url).subscribe((blob) => {
          this.showFileLoadSpinner = false;
          this.selectedReadFile = url;
        });
      }
    } catch (error) {
      this.showFileLoadSpinner = true;
      console.log(error);
    }
  }

  deleteSecureFile(messageId) {
    try {
      this.confirmDialogService.openConfirmDialog('',{
        title:'Confirmation',
        message:'Do you want to delete file?',
        btnOkText:'DELETE',
        btnCancelText:'NO',
        onClose: (data:any) => {
          if(data){
            this.chatService.deleteMessage(messageId).subscribe(
              (response) => {
                this.httpLoader.hideLoader();
                if (response["success"] === false) {
                  this.alert.showAlertMessage(response["message"]);
                }
                this.alert.showAlertMessage(response);
                let position = this.classFileList.findIndex(
                  (x) => x.message_id === messageId
                );

                if (~position) {
                  this.classFileList.splice(position, 1);
                }
              },
              (error) => {
                this.httpLoader.hideLoader();
                this.alert.showAlertMessage(error);
              }
            );
          }
        }	  
    });
     /* this._confirmation
        .create("Confirmation", "Are you sure want to delete File?", {
          confirmText: "DELETE",
        })
        .subscribe((ans: ResolveEmit) => {
          if (ans.resolved) {
            // const payLoad = {
            //   message_id : fileId,
            // }
            this.chatService.deleteMessage(messageId).subscribe(
              (response) => {
                this.httpLoader.hideLoader();
                if (response["success"] === false) {
                  this.alert.showAlertMessage(response["message"]);
                }
                this.alert.showAlertMessage(response);
                let position = this.classFileList.findIndex(
                  (x) => x.message_id === messageId
                );

                if (~position) {
                  this.classFileList.splice(position, 1);
                }
              },
              (error) => {
                this.httpLoader.hideLoader();
                this.alert.showAlertMessage(error);
              }
            );
          }
        }); */

    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  startPlayListVdo($index, fileData) {
    try {
      this.currentIndex = $index;
      this.currentVideo = fileData;
      this.prevDisabled = false;
      this.nextDisabled = false;

      if (this.currentIndex === 0) {
        this.prevDisabled = true;
      } else if (this.currentIndex === this.classFileList.length - 1) {
        this.nextDisabled = true;
      }

      // console.log(fileData);

      if (fileData.file_type === "video" || fileData.file_type === "audio") {
        const payLoad = {
          file_path: fileData.download_url,
          file_type: fileData.file_type,
          message: fileData.message,
          shared_by: fileData.shared_by,
          created_at: fileData.created_at,
          message_id: fileData.message_id,
          file_ext: fileData.file_ext,
          thumb_url: fileData.thumb_url ? fileData.thumb_url : null,
          view_Url: fileData.view_Url ? fileData.view_Url : null,
        };

        this.mediaData = payLoad;
      } else if (fileData.file_type === "image") {
        this.mediaData = fileData;
      } else {
        this.mediaData = fileData;
      }

      // console.log(this.mediaData);

      this.elibraryService.emitMediaContent(this.mediaData);
    } catch (error) {
      console.log(error);
    }
  }

  videoPlayerInit(event) {
    const fileSrc = this.mediaContent ? this.mediaContent.file_path : null;
    this.inputSrc = fileSrc.replace("securefileDownload", "secureReadfile");
  }

  getPreVideo() {
    this.currentIndex--;

    this.nextDisabled = false;
    if (this.currentIndex === 0) {
      this.prevDisabled = true;
    }

    this.currentVideo = this.classFileList[this.currentIndex];

    const payLoad = {
      file_path: this.currentVideo["download_url"]
        ? this.currentVideo["download_url"]
        : null,
      file_type: this.currentVideo["file_type"],
      shared_by: this.currentVideo["shared_by"],
      message: this.currentVideo["message"],
      message_id: this.currentVideo["message_id"],
      created_at: this.currentVideo["created_at"],
      file_ext: this.currentVideo["file_ext"],
      thumb_url: this.currentVideo["thumb_url"]
        ? this.currentVideo["thumb_url"]
        : null,
      view_Url: this.currentVideo["view_Url"]
        ? this.currentVideo["view_Url"]
        : null,
    };

    this.elibraryService.emitMediaContent(payLoad);
  }

  getNextVideo() {
    this.currentIndex++;
    this.prevDisabled = false;

    if (this.currentIndex === this.classFileList.length - 1) {
      // this.currentIndex = 0;
      this.nextDisabled = true;
    }

    // console.log(this.nextDisabled);

    this.currentVideo = this.classFileList[this.currentIndex];
    // this.enableButtons();
    const payLoad = {
      file_path: this.currentVideo["download_url"]
        ? this.currentVideo["download_url"]
        : null,
      file_type: this.currentVideo["file_type"],
      shared_by: this.currentVideo["shared_by"],
      message: this.currentVideo["message"],
      message_id: this.currentVideo["message_id"],
      created_at: this.currentVideo["created_at"],
      file_ext: this.currentVideo["file_ext"],
      thumb_url: this.currentVideo["thumb_url"]
        ? this.currentVideo["thumb_url"]
        : null,
      view_Url: this.currentVideo["view_Url"]
        ? this.currentVideo["view_Url"]
        : null,
    };

    this.elibraryService.emitMediaContent(payLoad);
  }

  disabledVideoPlay() {
    this.viewControl.hideExistingFileView = true;
    this.elibraryComponent.viewLibraryList = false;
  }

  hideReadFile(messageId) {
    this.activeModalSelectFile[messageId] = false;
  }

  onFileChange(event) {
    const files = event.target.files;

    const timeStamp = event.timeStamp;
    let duration = moment.duration(timeStamp, "seconds");

    let time = "";
    let hours = duration.hours();
    if (hours > 0) {
      time = hours + ":";
    }
    time = time + duration.minutes() + ":" + duration.seconds();

    this.fileDuration = time;

    if (files && files.length > 0) {
      const formFields = {
        fieldName: "staff_topic",
        activity: "topic",
        storeType: "temp",
        // group_id: parseInt(this.group_id)
      };
      this.uploadAttachFile = true;
      this.upLoadedFileList.push(files[0]);
      this.fileValidationService
        .fileValidation(files, this.topicFileExtension, this.topicFileSize)
        .then((validResponse) => {
          this.appcomponent
            .fileUploadWithProgess(formFields, this.upLoadedFileList)
            .then((fileUpLoadResponse: any) => {
              for (const key in fileUpLoadResponse) {
                if (fileUpLoadResponse[key]) {
                  this.preUploadedFile = fileUpLoadResponse[key];
                }
              }
              this.upLoadedFileList = [];
              this.topicFileInput.nativeElement.value = "";
            })
            .catch((error) => {
              this.upLoadedFileList = [];
              this.topicFileInput.nativeElement.value = "";
              this.alert.showAlertMessage(error.error);
            });
        })
        .catch((err) => {
          this.topicFileInput.nativeElement.value = "";
          this.upLoadedFileList = [];
          err.map((li) => this.alert.showAlertMessage(li));
        });
    }
  }

  removeUploadFile(data, file_id) {
    try {
      this.removeFileFromList(data);
      // this.upLoadedFileList = [];
      this.preUploadedFile = null;
      // this.fileDuration = null;
      this.durationInput.nativeElement.value = "";
      this.uploadAttachFile = false;
    } catch (error) {
      console.log(error);
    }
  }

  removeFileFromList(val) {
    const data = val[0];
    if (this.upLoadedFileList.length > 0) {
      const newFileList = this.upLoadedFileList;
      const currentFiles = newFileList.filter(
        (fl) => fl.uniqId !== data.uniqId
      );
      const paramId = +this.route.snapshot.paramMap.get("id");
      let fileEl = null;
      const existFileList = this.appcomponent.selectedFilesList;
      fileEl = existFileList.find((el) => el.group_id === paramId);
      if (fileEl) {
        fileEl.files = currentFiles;
      }
      this.upLoadedFileList = currentFiles;
      this.appcomponent.selectedFilesList = existFileList;
    }
  }

  hideEditModal() {
    this.viewControl.showHideEditFileUpload = true;
    this.elibraryComponent.viewLibraryList = false;
  }

  openTab(evt, tabName) {
    var i, x, tablinks;
    x = document.getElementsByClassName("content-tab");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tab");
    for (i = 0; i < x.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" is-active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " is-active";
  }

  getQuizeDetails(message_id) {
    try {
      const payLoad = {
        group_id: parseInt(this.group_id),
        file_id: message_id,
      };

      this.viewControl.showHideEditFileUpload = false;
      this.elibraryComponent.viewLibraryList = true;

      this.chatService.editUploadFileList(payLoad).subscribe(
        (data: any) => {
          // console.log(data.record.message_id);
          this.editedData = data.record;
          this.editedMessageId = data.record ? data.record.message_id : 0;

          let assData = this.editedData;

          assData["assessment"].forEach((ass) => {
            const question: FormGroup = this.newAssQuestion();

            this.assQuestion().push(question);

            ass.answer.forEach((opt) => {
              const answer = this.newAssAnswers();
              (question.get("answer") as FormArray).push(answer);
            });
          });

          assData["survey"].forEach((sur) => {
            const question: FormGroup = this.newAssQuestion();

            this.surveyQuestions().push(question);

            sur.answer.forEach((opt) => {
              const answer = this.newAssAnswers();
              (question.get("answer") as FormArray).push(answer);
            });
          });

          assData["polls"].forEach((pol) => {
            const question: FormGroup = this.newAssQuestion();

            this.pollQuestions().push(question);

            pol.answer.forEach((opt) => {
              const answer = this.newAssAnswers();
              (question.get("answer") as FormArray).push(answer);
            });
          });

          this.editQuizForm.patchValue({
            message: data.record.message,
            description: data.record.description,
            duration: data.record.duration,
            price: data.record.price,
            discount: data.record.discount,
            is_download: data.record.is_download,
            water_mark_title: data.record.water_mark_title,
            assessment: data.record.assessment,
            survey: data.record.survey,
            polls: data.record.polls,
          });

          let index = 0; // or 1 or 2
          // this.clearFormArry();

          if (data.record.assessment.length !== 0) {
            this.domElementControl.showCreateNewQuestions = true;
            this.is_question_found = true;
          }

          if (data.record.survey.length !== 0) {
            this.domElementControl.showCreateNewSurQuestion = true;
            this.is_survey_found = true;
          }

          if (data.record.polls.length !== 0) {
            this.domElementControl.showCreateNewPolQuestion = true;
            this.is_polls_found = true;
          }

        },
        (error) => {
          this.httpLoader.hideLoader();
          console.log(error);
        }
      );
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }


  /***Assessment Test Quiz */

  onClickYourFirstQuestion() {
    this.is_question_found = true;
    this.domElementControl.showCreateNewQuestions = true;
    this.onCreateAssQuestion();
  }

  assQuestion(): FormArray {
    return this.editQuizForm.get("assessment") as FormArray;
  }

  newAssQuestion(): FormGroup {
    return this.fb.group({
      question: ["", Validators.required],
      question_id: [""],
      mark: [""],
      answer: this.fb.array([]),
      question_type: ["question"],
    });
  }

  onCreateAssQuestion() {
    this.assQuestion().push(this.newAssQuestion());
  }

  assQuestAnswers(questIndex: number): FormArray {
    return this.assQuestion().at(questIndex).get("answer") as FormArray;
  }

  newAssAnswers(): FormGroup {
    return this.fb.group({
      answer: ["", Validators.required],
      answer_id: [""],
      is_correct_answer: [""],
    });
  }

  onCreateAssOption(questIndex: number) {
    this.assQuestAnswers(questIndex).push(this.newAssAnswers());
  }

  onCheckedAnswer(evetn, choices, questIndex, optionIndex) {
    let control = <FormArray>this.editQuizForm.controls.assessment;
    let answer = control.value[questIndex].answer;
    for (let i = 0; i < answer.length; i++) {
      // const element = answer[i];
      if (i === optionIndex) {
        answer[i].is_correct_answer = true;
      } else {
        answer[i].is_correct_answer = false;
      }
      // this.isChecked = event.target.checked;
    }
  }

  removeAssQuestion(questIndex: number) {
    this.assQuestion().removeAt(questIndex);
  }

  removeAssOptions(questIndex: number, optionIndex: number) {
    this.assQuestAnswers(questIndex).removeAt(optionIndex);
  }

  loadAssessmentQuiz(message_id, question_type){
    try {

      this.mesgIds = message_id;

      if(question_type==='question'){
        this.showHiddenAssessmentModal[message_id] = true;

        if (this.isUserRole === 1) {
          const questionType = 'question';
          this.chatService
            .getStudentResult(message_id, questionType)
            .subscribe((response) => {
              this.studentResult = response["assessment"];
              this.overallmarks = response["overallmark"];
            });
        } else {
          const payLoad = {
            group_id: parseInt(this.group_id),
            message_id: message_id,
            question_type: question_type
          };

          this.chatService
            .getQuestions(payLoad)
            .subscribe((response) => {
              let assmentCompleted =
                response["assessment"]["is_questionnaire_completed"];

              // console.log(assmentCompleted);
              if (assmentCompleted === true) {
                this.viewControl.showAssessmentMarks = false;
                this.percentage = parseInt(response["assessment"]["percentage"]);
                this.overallmarks = response["overallmark"];
                this.alert.showAlertMessage(response);
              } else {
                this.getAssessmentData = response["assessment"];
                this.pager.count = this.getAssessmentData.length;

                this.viewControl.showAssessmentMarks = true;
                this.calculateTotalMarks(this.getAssessmentData);
              }
              // this.getAssessmentData = response['assessment'];
            });
        }

      }else if(question_type==='survey'){
        this.showHideSurveyModal[message_id] = true;

        if (this.isUserRole===1) {

          this.chatService.getSurveyPollResult(message_id, question_type).subscribe(
            (data:any) => {
              this.surveyReports = data.assessment;
              this.pager.count = this.surveyReports.length;
            })
        } else {
          const payLoad = {
            group_id: parseInt(this.group_id),
            message_id: message_id,
            question_type: question_type
          };

          this.chatService
            .getQuestions(payLoad)
            .subscribe((response) => {
              let assmentCompleted =
                response["assessment"]["is_questionnaire_completed"];

              // console.log(assmentCompleted);
              if (assmentCompleted === true) {
                this.viewControl.showAssessmentMarks = false;
                this.percentage = parseInt(response["assessment"]["percentage"]);
                this.overallmarks = response["overallmark"];
                // const message = response;
                // this.surveycompleted === message;

                // console.log(this.surveycompleted);

              } else {
                this.getAssessmentData = response["assessment"];
                this.pager.count = this.getAssessmentData.length;

                this.viewControl.showAssessmentMarks = true;
                this.calculateTotalMarks(this.getAssessmentData);
              }
              // this.getAssessmentData = response['assessment'];
            });
        }
      }else if(question_type==='polls'){
        this.showHidePollModal[message_id] = true;

        if (this.isUserRole === 1) {
          const questionType = 'polls';
          this.chatService
            .getSurveyPollResult(message_id, questionType)
            .subscribe((data:any) => {
              this.pollReports = data.assessment;
              this.pager.count = this.pollReports.length;
            });
        } else {
          const payLoad = {
            group_id: parseInt(this.group_id),
            message_id: message_id,
            question_type: question_type
          };

          this.chatService
            .getQuestions(payLoad)
            .subscribe((response) => {
              let assmentCompleted =
                response["assessment"]["is_questionnaire_completed"];

              // console.log(assmentCompleted);
              if (assmentCompleted === true) {
                this.viewControl.showAssessmentMarks = false;
                this.percentage = parseInt(response["assessment"]["percentage"]);
                this.overallmarks = response["overallmark"];
                this.alert.showAlertMessage(response);
              } else {
                this.getAssessmentData = response["assessment"];
                this.pager.count = this.getAssessmentData.length;

                this.viewControl.showAssessmentMarks = true;
                this.calculateTotalMarks(this.getAssessmentData);
              }
              // this.getAssessmentData = response['assessment'];
            });
        }
      }




    } catch (error) {
      console.log(error);
    }
  }

  calculateTotalMarks(getMarks) {
    for (let i = 0; i < getMarks.length; i++) {
      this.maxMarks += Number(getMarks[i]["mark"]);

    }
    return this.maxMarks;
  }

  onSelectedAssRadio(data, option) {

    let isCorrectAnswer:boolean = false;

    if(data.answer_id===option.answer_id){
      this.totalMarks += parseInt(data.mark);
      isCorrectAnswer = true;
    }


   this.answers = this.answers.filter((x) => x.question_id !== data.question_id)


    this.answers.push({
      question_id : data.question_id,
      answer_id: option.answer_id,
      is_correct_answer: isCorrectAnswer
    })

    if(this.getAssessmentData.length === this.answers.length){
      this.assessmentSubmit = true;
    }

  }


  hideModalAssessment(message_id) {
    this.showHiddenAssessmentModal[message_id] = false;
  }

  hideAssessmentModal(message_id) {
    try {
      if (this.isUserRole !== 1) {
        this.confirmDialogService.openConfirmDialog('',{
          title:'Confirmation',
          message:'Are you sure you want to cancel? If you cancel, you have to retake the test from the beginning',
          btnOkText:'YES',
          btnCancelText:'NO',
          onClose: (data:any) => {
            if(data){
              this.showHiddenAssessmentModal[message_id] = false;
              this.assessmentSubmit = false;
              this.answers = [];
              this.assessmentForm.reset();
            }
          }	  
      });	
       /* this._confirmation
          .create(
            "Confirmation",
            "Are you sure you want to cancel ? If you cancel, you have to retake the test from the beginning",
            { confirmText: "Yes, Cancel" }
          )
          .subscribe((ans: ResolveEmit) => {
            if (ans.resolved) {
              this.showHiddenAssessmentModal[message_id] = false;
              this.assessmentSubmit = false;
              this.answers = [];
              this.assessmentForm.reset();
            }
          });*/
      }
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }




  /***Survey Quiz */

  addfirstSurQuestion() {
    this.domElementControl.showCreateNewSurQuestion = true;
    this.is_survey_found = true;
    this.onCreateSurveyQuestion();
  }

  surveyQuestions(): FormArray {
    return this.editQuizForm.get("survey") as FormArray;
  }

  newSurveyQuestion(): FormGroup {
    return this.fb.group({
      question_id: [""],
      question: ["", Validators.required],
      answer: this.fb.array([]),
      question_type: ["survey"],
    });
  }

  onCreateSurveyQuestion() {
    this.surveyQuestions().push(this.newSurveyQuestion());
  }

  onRemoveSurveyQuestion(questIndex) {
    this.surveyQuestions().removeAt(questIndex);
  }

  surveyOptions(questIndex: number): FormArray {
    return this.surveyQuestions().at(questIndex).get("answer") as FormArray;
  }

  newSurveyOptions(): FormGroup {
    return this.fb.group({
      answer_id: [""],
      answer: ["", Validators.required],
    });
  }

  oncreateSurveyOptions(questIndex: number) {
    this.surveyOptions(questIndex).push(this.newSurveyOptions());
  }

  removeSurveyOptions(questIndex, choiceIndex) {
    this.surveyOptions(questIndex).removeAt(choiceIndex);
  }



  get filteredQuestions(){
    return (this.getAssessmentData) ?
      this.getAssessmentData.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  get filterReportSurvey(){

    return (this.surveyReports) ?
      this.surveyReports.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  isAnswered(question) {
    return question.options.find(x => x.selected) ? 'Answered' : 'Not Answered';
  };

  isCorrect(question) {
    return question.options.every(x => x.selected === x.isAnswer) ? 'correct' : 'wrong';
  };

  onSelectedSurRadio(data, option){
    if(data.question_type ==='survey'){
      data.answer.forEach((x) => {
        if(x.answer_id !== option.answer_id) x.selected = false;
      });
    }

    this.answers = this.answers.filter((x) => x.question_id !== data.question_id)


    this.answers.push({
      question_id : data.question_id,
      answer_id: option.answer_id,
    })


    if(this.getAssessmentData.length === this.answers.length){
      this.surveySubmit = true;
    }
  }


  getPreviewNext(index: number){
    if(index >= 0 && index < this.pager.count){
      this.pager.index = index;
      this.mode = 'surveyQuiz';
    }
  }


  hideSurveyQuizModal(message_id){

    try {
      if (this.isUserRole !== 1) {
        this.confirmDialogService.openConfirmDialog('',{
          title:'Confirmation',
          message:'Are you sure you want to cancel? If you cancel, you have to retake the survey from the beginning',
          btnOkText:'YES',
          btnCancelText:'NO',
          onClose: (data:any) => {
            if(data){
              this.showHideSurveyModal[message_id] = false;
              this.surveySubmit = false;
              this.answers = [];
            }
          }	  
      });
        /*this._confirmation
          .create(
            "Confirmation",
            "Are you sure you want to cancel ? If you cancel, you have to retake the survey from the beginning",
            { confirmText: "Yes, Cancel" }
          )
          .subscribe((ans: ResolveEmit) => {
            if (ans.resolved) {
              this.showHideSurveyModal[message_id] = false;
              this.surveySubmit = false;
              this.answers = [];
            }
          }); */
      }
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  hideSurverModal(message_id){
    this.showHideSurveyModal[message_id] = false;
  }

  /***Polls Quiz */


  onClickyourpollfirstquest() {
    this.is_polls_found = true;
    this.domElementControl.showCreateNewPolQuestion = true;
    this.onCreatePollQuestion();
  }

  pollQuestions(): FormArray {
    return this.editQuizForm.get("polls") as FormArray;
  }

  newPollQuestion(): FormGroup {
    return this.fb.group({
      question_id: [""],
      question: ["", Validators.required],
      answer: this.fb.array([]),
      question_type: ["polls"],
    });
  }

  onCreatePollQuestion() {
    this.pollQuestions().push(this.newPollQuestion());
  }

  pollOptions(questIndex: number): FormArray {
    return this.pollQuestions().at(questIndex).get("answer") as FormArray;
  }

  newPollOptions(): FormGroup {
    return this.fb.group({
      answer_id: [""],
      answer: ["", Validators.required],
    });
  }

  onCreatePollOptions(questIndex: number) {
    this.pollOptions(questIndex).push(this.newPollOptions());
  }

  removePollQuestion(index) {
    this.pollQuestions().removeAt(index);
  }

  onRemovePollOptions(questIndex, choiceIndex) {
    this.pollOptions(questIndex).removeAt(choiceIndex);
  }


  get pollfilterQuestions(){
    return (this.getAssessmentData) ?
      this.getAssessmentData.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  get pollsfilterReports(){
    return (this.pollReports) ?
      this.pollReports.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }



  getPollNextPrev(index: number){
    if(index >= 0 && index < this.pager.count){
      this.pager.index = index;
      // this.mode = 'surveyQuiz';
    }

  }

  onSelectedPollsRadio(data, option){
    if(data.question_type ==='polls'){
      data.answer.forEach((x) => {
        if(x.answer_id !== option.answer_id) x.selected = false;
      });
    }


    this.answers = this.answers.filter((x) => x.question_id !== data.question_id)


    this.answers.push({
      question_id : data.question_id,
      answer_id: option.answer_id,
    })


    if(this.getAssessmentData.length === this.answers.length){
      this.surveySubmit = true;
    }
  }

  hidePollActiveModal(message_id){
    try {
      if (this.isUserRole !== 1) {
        this.confirmDialogService.openConfirmDialog('',{
          title:'Confirmation',
          message:'Are you sure you want to cancel ? If you cancel, you have to retake the poll from the beginning',
          btnOkText:'YES',
          btnCancelText:'NO',
          onClose: (data:any) => {
            if(data){
              this.showHidePollModal[message_id] = false;
              this.answers = [];
              this.pollBtnSubmit = false;
            }
          }	  
      });
       /* this._confirmation
          .create(
            "Confirmation",
            "Are you sure you want to cancel ? If you cancel, you have to retake the poll from the beginning",
            { confirmText: "Yes, Cancel" }
          )
          .subscribe((ans: ResolveEmit) => {
            if (ans.resolved) {
              this.showHidePollModal[message_id] = false;
              this.answers = [];
              this.pollBtnSubmit = false;
            }
          }); */
      }
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }

  }

  hidePollModal(message_id){
    this.showHidePollModal[message_id] = false;
  }


  submitAssessmentQuiz(data, message_id, question_type) {
    try {

      const payLoad = {
        file_id: message_id,
        total_mark: this.totalMarks,
        answered: this.answers,
        question_type: question_type
      };

      this.showBtnSpinner = true;
      this.assessmentForm.reset();

      this.chatService.submitAssessment(payLoad).subscribe((response) => {
        this.httpLoader.hideLoader();
        this.showBtnSpinner = false;
        this.viewControl.showAssessmentMarks = false;
        this.showHideSurveyModal[message_id] = false;
        this.showHidePollModal[message_id] = false;

         this.alert.showAlertMessage(response);
        this.percentage = response["assessment"]["percentage"];
      });

    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }


  updateFileData(payLoad) {
    try {
      this.showBtnSpinner = true;
      this.httpLoader.hideLoader();

      if (this.uploadAttachFile) {
        payLoad["file_data"] = this.preUploadedFile;
      }

      if (payLoad["assessment"].length > 0) {
        this.is_question_found = true;
      }

      if (payLoad["survey"].length > 0) {
        this.is_survey_found = true;
      }

      if (payLoad["polls"].length > 0) {
        this.is_polls_found = true;
      }

      payLoad["group_id"] = parseInt(this.group_id);
      payLoad["message_id"] = this.editedMessageId;
      payLoad["is_question_found"] = this.is_question_found;
      payLoad["is_survey_found"] = this.is_survey_found;
      payLoad["is_polls_found"] = this.is_polls_found;

      console.log(payLoad);

      this.chatService.updateUploadFileData(payLoad).subscribe(
        (response) => {
          const updateLibrary = response["record"];
          const library = [...this.classFileList];

          library.unshift(updateLibrary);
          this.classFileList = library;
          this.showBtnSpinner = false;
          this.httpLoader.hideLoader();
          this.viewControl.showHideEditFileUpload = true;
          this.elibraryComponent.viewLibraryList = false;
          this.alert.showAlertMessage(response);
          this.clearFormArray();
        },
        (err) => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(err.error);
        }
      );
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

    /**
     * After open the user message update message delivered and read
     * @param messageList
     */
    // updateMessageRead(messageList) {
    //   const logUserId = this.loggedUserId;
    //   if (messageList && messageList.length > 0) {
    //       const filterMsgList = _.filter(messageList, function (eachMsg) {
    //           return (eachMsg.from_user_id !== logUserId);
    //       });
    //       const payLoad = _.pick(_.last(filterMsgList), 'group_id', 'message_id', 'from_user_id', 'to_user_id', 'user_id', 'is_read');
    //       if (payLoad.message_id && !payLoad.is_read) {
    //           this.chatService.updateMessageRead(payLoad).subscribe(
    //               response => {
    //                   if (response && response['user_id'] !== 0) {
    //                       this.elibraryComponent.elibraryList.find((user): any => {
    //                           if (response['user_id'] === user.user_id) {
    //                               return user.unread_msg_count = 0;
    //                           }
    //                       });
    //                   }
    //                   if (response && response['group_id'] !== 0) {
    //                       this.elibraryComponent.elibraryList.find((user): any => {
    //                           if (response['group_id'] === user.group_id) {
    //                               return user.unread_msg_count = 0;
    //                           }
    //                       });
    //                   }
    //               },
    //               error => { console.log(error) }
    //           )
    //       }
    //   }
    // };

  clearFormArray() {
    this.assQuestion().clear();
    this.surveyQuestions().clear();
    this.pollQuestions().clear();
  }


  getOverlayStyle() {
    const isSemi = this.semicircle;
    const transform = (isSemi ? "" : "translateY(-50%) ") + "translateX(-50%)";

    return {
      top: isSemi ? "auto" : "50%",
      bottom: isSemi ? "5%" : "auto",
      left: "50%",
      transform,
      "font-size": this.radius / 3.5 + "px",
    };
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.inputSrc = null;

    // window.removeEventListener('focus', this.focusHandler, false);
  }

}
