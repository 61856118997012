import { Component, HostListener, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../menu-options/menu.serive';
import { SettingsService } from '../settings/settings.service';
import { DataSharingService } from 'app/shared/data-sharing.service';
import { ChatService } from '../chat-list/chat.service';
import { HeaderService } from '../header/header.service';
import { AlertPopup, HttpLoader, MENU_ICON, path } from 'app/app.config';
import { PlayerService } from '../media-player/media-player-service';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  @Input() menuList: any[];
  @Input() setting: any;
  // @Input() isUserLoggedIn: boolean;
  @Input() currentUser: any;

  isToken: any;
  isUserRole: any;

  mediaContent: any = null;
  subscribers: any = {};

  menuIcon: any = MENU_ICON;

  activeUserChannelList : any[] = [];
  activeChannel = false;
  singleSelect: any;
  companySearch:any;
  searchInList = new Subject<any>();
  isBelowIpadSize:boolean=false;
  profileUrl = path.PROFILE_URL;
  companyUrl = path.COMPANY_URL;
  userDetail:any;
  @HostListener('window:resize', ['$event'])
	onResize(event) {
    this.isBelowIpadSize = window.innerWidth <= 768; // Update screen size on resize
	  
	}


  ShowDropdown:boolean=false;


  constructor(
    private router: Router,
    private menuService: MenuService,
    private settingsService: SettingsService,
    private dataSharingService: DataSharingService,
    private chatService: ChatService,
    private headerService: HeaderService,
    private loader: HttpLoader,
    private alert: AlertPopup,
    private playerService: PlayerService,
    @Inject(DOCUMENT) private document:Document
    // private elibraryService: ELibraryService
    ) {

  }

  ngOnInit() {
   // this.isUserLoggedIn = true;
 
    this.isToken = localStorage.getItem('token');

    if(this.isToken){
      this.isUserRole = localStorage.getItem('user_role');
      this.isUserRole = parseInt(this.isUserRole.replace(/\"/g, ""));
    }
      
    this.isBelowIpadSize = window.innerWidth <= 640;

    this.router.events.subscribe(
      (event:any)=> {
      let sidebar = this.document.querySelector('.sidebar');
     
       setTimeout(()=>{
        if(sidebar&& sidebar.classList.contains('show')){
          sidebar.classList.remove('show');
        }
       
        let ngtooltip = this.document.querySelector('.ng-tooltip');
        if(ngtooltip && ngtooltip.classList.contains('ng-tooltip-show')){
          ngtooltip.classList.remove('ng-tooltip-show');
        }
        
       },300);
   
      });
  }



  ngAfterViewInit() {
    this.subscribers.notifyCount = this.dataSharingService.getNotifyCount().subscribe(menuObj => {

     // console.log(this.menuList)

      const actualMenuList = [...this.menuList];
      const newMenuObj = actualMenuList.find(ml => ml.menu_name === menuObj.menu);

      if (menuObj.count) {
        newMenuObj.notify_count = menuObj.count;
        return;
      }

      if (newMenuObj.notify_count) {
        newMenuObj.notify_count += 1;
      } else {
        newMenuObj.notify_count = 0;
        newMenuObj.notify_count += 1;
      }
      this.menuList = actualMenuList;
    })


    this.subscribers.media = this.playerService.getMediaContent().subscribe(response => {
      if (this.mediaContent && response) {
        if (this.mediaContent.file_path !== response.file_path) {
          this.mediaContent = response;
        }
      } else {
        this.mediaContent = response;
      }
    }, error => console.log(error))


    this.settingsService.getUserSettings().subscribe((res:any)=>{
            this.userDetail=res['userDetail'];
    });

  }

  urlFormation(menuName) {
    if (menuName) {
      return menuName.replace(/_/g, '');
    }
  };


  /**
 * Chang the hide sidemenu style
 * @param value
 */
  hideSideMenu() {
    try {
      const payLaod = {
        visible_sidebar: !this.setting.visible_sidebar
      };
      const settingObj = { ...this.setting }
      settingObj.visible_sidebar = !this.setting.visible_sidebar;
      this.setting = settingObj;
      this.settingsService.updateUserSettings(payLaod).subscribe(
        response => {
        },
        error => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }

    if(this.isBelowIpadSize){
      let sidebar = this.document.querySelector('.sidebar');
      sidebar.classList.toggle('show');
    }

  };

  removeNotifyCount(menuObj) {
    menuObj.notify_count = 0;
  };


  ngOnDestroy(): void {
    this.subscribers.notifyCount.unsubscribe();
    this.subscribers.media.unsubscribe();
    this.activeChannel = false;
  }

  showSearchBox(toggle){
    console.log(toggle);

  }

  displayUserFirstLetter(userName) {
    if (userName) {
      return userName.charAt(0).toUpperCase();
    } else {
      return;
    }
  }

  showuserMenu(){
    this.ShowDropdown = !this.ShowDropdown;
  }

  toggleSideMenu(){
    this.setting.visible_sidebar = !this.setting.visible_sidebar;
  }

  logout() {
    const payload = {
      user_id: this.currentUser.user_id,
      status_id: 0
    }

    this.headerService.updateStatus(payload).subscribe(res => {
      window.localStorage.clear();
      this.router.navigate(['/login']);
    });
  }

}
