import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from '@angular/common/http';
import { config } from './../../app.config';

@Injectable()

export class AttendanceService {

  /** specifying Base URL.*/
  public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;


  constructor(){}




}
