import { Component, OnInit, HostBinding, Input, AfterContentChecked, ViewChild, ElementRef, Directive  } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import { UserService } from '../user.service';
import { config, path, AlertPopup, HttpLoader, BASE_URI } from '../../../../app.config';
import { HttpEventType, HttpResponse, HttpClient, HttpRequest } from '@angular/common/http';

import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'underscore';
import { AppComponent } from 'app/app.component';
 
import { NewUserComponent } from '../new-user/new-user.component';
import { saveAs as importedSaveAs } from 'file-saver';


@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnInit, AfterContentChecked {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('excelFile') excelFile: ElementRef;
  public viewActionBtn = true;
  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  profileUrl = path.PROFILE_URL;
  sortBy = 'time';
  search: string;
  listOfUsers: any[] = [];
  totalRowCount: number;
  showBtnSpinner = false;
  public showSearchSec = true;
  public viewSecondPanel = false;
  viewUserList = false;

  isActiveGroupForm = false;
  newClassRoom : FormGroup;
  welcomeWindowName: string;
  showWelcomeWindow = true;
  showMenuView = false;
  loggedUserDeatails: any;
  is_tour_completed = false;
  activeUrl:any;


  showPaginateLoader = false;

  viewControl = {
    changedSearchText: false
  };
  searchInList = new Subject<any>();

  /** User Data With excel file */
  // hideFormBtn: boolean;
  // showFormView: boolean;
  excelFileList: any;
  excelFileOpt: any = {
    percent: 0,
    showProgress: false
  };

  userCreationViewCtrl = {
    addUserBtn: false,
    showForm: true,
    showTable: false,
    showFileChoosen: false,
    startUpload: false
  };

  excelUserList: any = [];
  bulkUserFileName: string;

  // fileLoadedPercentage: number;
  uploadSpinner = false;
  uploaderResponse: any;

   /** specifying Base URL.*/
   public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${
    config.VERSION
    }/users`;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private httpLoader: HttpLoader,
    private alert: AlertPopup,
    private formBuilder: FormBuilder,
    private appComponent: AppComponent,
   
    private http: HttpClient
    // private newUserComponent: NewUserComponent
    ) {

      this.newClassRoom = formBuilder.group({
        class_name: [null, Validators.compose([Validators.required])]
      });


    this.searchInList
      .debounceTime(500)
      .subscribe(event => {
        this.search = event.target.value;
        if (!this.search) {
          return;
        }
        this.viewControl.changedSearchText = true;
        this.getUserListSortAndSearch(0);
      });
  }


  ngOnInit() {
    this.welcomeWindow('welcome');
    this.getuserList();

    this.route.url.subscribe(() => {
      this.activeUrl = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.url[0].path : false;
      this.viewUserList = false;
      this.viewSecondPanel = false;
    });
  };

  ngAfterContentChecked() {

    if (_.isEqual(this.loggedUserDeatails, this.appComponent.userDetail)) {
      return;
    }

    if (_.isObject(this.appComponent.userDetail)) {
      this.loggedUserDeatails = this.appComponent.userDetail;
      this.is_tour_completed = !this.loggedUserDeatails.is_tour_completed
    }
  };


  getuserList() {
    try {
      const payLoad = {
        offset: 0,
        limit: this.RECORD_LIMIT
      };
      this.httpLoader.showLoader();
      this.userService.getUserList(payLoad).subscribe(
        lists => {
          this.httpLoader.hideLoader();
          this.listOfUsers = lists['userList'];
          this.totalRowCount = lists['TotalRowCount'];
        },
        error => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        }
      )
    } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
    }
  };

  showSearchbox(toggle) {
    this.showSearchSec = !this.showSearchSec;
    // if (toggle === 'close' && this.search) {
    if (this.viewControl.changedSearchText) {
      try {
        this.search = '';
        const offset = 0;
        this.viewControl.changedSearchText = false;
        this.getUserListSortAndSearch(offset);
      } catch (err) {
        this.httpLoader.hideLoader();
      }
    }
  };

  getUserListSortAndSearch(pageNo) {
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
        this.httpLoader.showLoader();
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        sort: this.sortBy,
        search: this.search || ''
      };
      this.userService.getSortAndSearch(payLoad).subscribe(
        userList => {
          if (offset === 0) {
            this.httpLoader.hideLoader();
            this.listOfUsers = userList['userList'];
            this.totalRowCount = userList['TotalRowCount'];
          } else if (offset > 0) {
            this.listOfUsers = this.listOfUsers.concat(userList['userList']);
          }
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.httpLoader.hideLoader();
        }
      )
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  };

  /**
  * Pagination called while scroll down
  */
  // onScrollDown() {
  //   if (this.listOfUsers.length < this.totalRowCount) {
  //     this.OFFSET += 1;
  //     return this.getUserListSortAndSearch(this.OFFSET);
  //   } else {
  //     return Observable.empty<Response>();
  //   }
  // };


  onScrollDown() {
    if (this.listOfUsers.length < this.totalRowCount && !this.showPaginateLoader) {
      this.OFFSET += 1;
      const payLoad = {
        offset: this.OFFSET,
        limit: this.RECORD_LIMIT,
        sort: this.sortBy,
        search: this.search || ''
      };

      this.showPaginateLoader = true;
      this.userService.getSortAndSearch(payLoad).subscribe(
        response => {
          this.listOfUsers = this.listOfUsers.concat(response['userList']);
          this.showPaginateLoader = false;
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.showPaginateLoader = false;
        });
    }
  };

  createGroupName(){
    this.isActiveGroupForm =! this.isActiveGroupForm
  }

  showCreateStudent(){
    this.viewActionBtn = true;
  }

  showGroupName(){

  }

  hideSecondPanel(){
    this.viewSecondPanel = !this.viewSecondPanel;
  }

  hideGroupWindow(){
    this.isActiveGroupForm = false;
  }

  createNewGroup(formVal){
    try {
      const payLoad = {
        "class_name": formVal['class_name']
      };

      this.userService.addNewClass(payLoad).subscribe(response => {
        this.alert.showAlertMessage(response);
        this.isActiveGroupForm = false;
        this.newClassRoom.reset();

      }, error => {
        this.alert.showAlertMessage(error.error);
      })
    } catch (error) {
      console.log(error);

    }
  }

  /** Active Teacher help Desk */
  welcomeWindow(name) {

    if (name) {
      this.showWelcomeWindow = true;
      this.welcomeWindowName = name;
    } else {
      this.showWelcomeWindow = false;
    }
  }

  showOnboarding() {
   // this.hintService.initialize({ elementsDisabled: true });
    this.showWelcomeWindow = false;
  }


  /**
   * Send mail to all listed and active user
   */
  sendMailtoUser(usersList) {
    const userIds = [];
    for (let index = 0; index < usersList.length; index++) {
      userIds.push(usersList[index]['user_id']);

    }

    const payLoad = {
      include_all: true,
      excludeAddress: [],
      domain: '',
      activeUserIds : userIds
    };
    this.showBtnSpinner = true

    this.userService.emitEmailToAllUsers(payLoad).subscribe(
      response => {
        this.showBtnSpinner = false;
        this.alert.showAlertMessage(response);
      },
      error => {
        this.showBtnSpinner = false;
        this.alert.showAlertMessage(error.error);
      }
    )
  };

  /**
   * @description Upload excewl file with user details and Parse it back
   * @author  Baskaran R
   * @created Date 21-12-2018
   *
   */

  excelFileUploadSectionHandler() {
    this.userCreationViewCtrl.addUserBtn = true;
    this.userCreationViewCtrl.showForm = false;
    this.userCreationViewCtrl.showFileChoosen = true;
    this.userCreationViewCtrl.showTable = false;
    this.excelUserList = [];
  }

  onCancelFileUploadSection() {
    this.excelFileList = null;
    this.userCreationViewCtrl.addUserBtn = false;
    this.userCreationViewCtrl.showForm = true;
    this.userCreationViewCtrl.showFileChoosen = false;
    this.userCreationViewCtrl.showTable = false;
    // this.showFormView = true;
    // this.hideFormBtn = false;
  }

  onExcelFileChoosen(event) {
    const files = event.target.files[0];
    if (!_.isObject(files)) {
      return;
    }
    this.excelFileList = files;
    // this.excelFile.nativeElement.value = '';
  }

  onClearExcelFileChoosen() {
    this.excelFileList = null;
    this.excelFile.nativeElement.value = null;
  }

  onUploadFileProcess() {
    const URL = `${BASE_URI.URL}/users/xls-file-parser`;
    const formData: FormData = new FormData();
    const file = this.excelFileList;
    formData.append('file', file);

    const req = new HttpRequest('POST', URL, formData, {
      reportProgress: true
    });
    this.excelFileOpt.showProgress = true;
    // this.excelFileOpt.showProgress = true;
    this.http.request(req).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.excelFileOpt.percent = Math.round(
            (100 * event.loaded) / event.total
          );
        }

        if (event.type === HttpEventType.Response) {
          this.excelFileOpt.showProgress = false;
          this.excelFileOpt.percent = 0;
          this.excelFileList = null;

          this.userCreationViewCtrl.addUserBtn = false;
          this.userCreationViewCtrl.showForm = false;
          this.userCreationViewCtrl.showFileChoosen = false;
          this.userCreationViewCtrl.showTable = true;

          const eventBody = event.body['userList'];
          // this.alert.showAlertMessage(event.body);
          this.excelUserList = eventBody;
          this.bulkUserFileName = event.body['fileName'];
        }
      },
      error => {
        this.excelFileOpt.showProgress = false;
        this.excelFileOpt.percent = 0;
        this.alert.showAlertMessage(error.error);
      }
    );
  }

  cancelUserList() {
    this.excelFileUploadSectionHandler();
  }

  removeUserFromListHandler(user) {
    if (user) {
      const userListCopy = [...this.excelUserList];
      const filteredUserList = userListCopy.filter(
        el => el.email_id !== user.email_id
      );
      this.excelUserList = filteredUserList;

      if (this.excelUserList.length === 0) {
        this.excelFileUploadSectionHandler();
      }
    }
  }

  createBulkUser() {
    const payLoad = this.excelUserList;
    if (!_.isArray(payLoad)) {
      return;
    }
    this.userCreationViewCtrl.startUpload = true;
    this.userService.bulkUserCreate(payLoad).subscribe(
      response => {
        const userList = [
          ...this.listOfUsers,
          ...response['createdResult']
        ];
        this.userCreationViewCtrl.startUpload = false;
        this.listOfUsers = userList;
        this.alert.showAlertMessage(response);
        this.excelUserList = [];
        this.onCancelFileUploadSection();
        setTimeout(function(){
          window.location.reload();
        },3000);
      },
      error => {
        this.userCreationViewCtrl.startUpload = false;
        this.alert.showAlertMessage(error.error);
      }
    );
  }

  getBulkUserTemplate() {
    try {
      this.userService.getBulkUserTemplate().subscribe( blob =>  {
          importedSaveAs(blob, 'Bulk-Users-Upload-Template.xls')
        },
        error => console.log(error)
        );
    } catch (e) {
      console.log(e);
    }
  }
}