import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpHeaders, HttpParams  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'app/app.config';
import { LoaderService } from 'app/loader/loader.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaffRoomService {

  baseUrl = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;

  private topicInsert = new Subject<any>();

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService
  ) { }


  private showLoader():void {
    this.loaderService.showLoader();
  }

  private hideLoader():void {
    this.loaderService.hideLoader();
  }

  emitNewTopicCreation(){
    return this.topicInsert.asObservable();
  }

  getStaffRoomList(payLoad){
    const Params = this.preHttpParams(payLoad);
    return this.http.get(`${this.baseUrl}/staffroom/roomLists`, {params: Params});
  }

  getClassList(payLoad){
    const Params = this.preHttpParams(payLoad);
    return this.http.get(`${this.baseUrl}/classList`, {params: Params});
  }

  deleteRoom(payLoad){
    return this.http.post(`${this.baseUrl}/staffroom/deletedRoom`, payLoad);
  }

  createNewRoom(payLoad){
    this.showLoader();
    return this.http.post(`${this.baseUrl}/staffroom/createroom`, payLoad);
  }

  getRoomDetails(roomId: number){
    this.showLoader();
    return this.http.get(`${this.baseUrl}/staffroom/currentRoom/${roomId}`);
  }

  // getTopicList(payLoad){
  //   const Params = this.preHttpParams(payLoad);
  //   return this.http.get(`${this.baseUrl}/staffroom/topicLists`, {params: Params});
  // }

  updateRoom(payLoad){
    this.showLoader();
    return this.http.post(`${this.baseUrl}/staffroom/editRoom`, payLoad);
  }

  //Topic

  createTopic(payLoad){
    this.showLoader();
    return this.http.post(`${this.baseUrl}/staffroom/createtopic`, payLoad);
  }

  //FeedBack
  createFeedBack(payLoad){
    this.showLoader();
    return this.http.post(`${this.baseUrl}/studyroom/feedback`, payLoad);
  }

  getRoomTopic(id){
    this.showLoader();
    return this.http.get(`${this.baseUrl}/staffroom/topicLists/${id}`);
  }

  deletedTopic(payLoad){
    this.showLoader();
    return this.http.post(`${this.baseUrl}/staffroom/deletedTopic`, payLoad);
  }

  publishedTopic(payLoad){
    this.showLoader();
    return this.http.post(`${this.baseUrl}/staffroom/publishTopic`, payLoad);
  }

  getStudyRoomTopic(){
    return this.http.get(`${this.baseUrl}/studyroom/topics`);
  }

  getStudentList(id){
    return this.http.get(`${this.baseUrl}/staffroom/reviewAssignment/${id}`);
  }

  getCurrentTopic(id){
    return this.http.get(`${this.baseUrl}/staffroom/currentTopic/${id}`);
  }

  updateTopicData(topicId, payLoad){
    return this.http.put(`${this.baseUrl}/topics/${topicId}`, payLoad);
  }

  preHttpParams(payLoad) {
    let Params = new HttpParams();
    for (const key in payLoad) {
        if (key && (payLoad[key] || payLoad[key] === 0)) {
            Params = Params.append(key, payLoad[key]);
        }
    }
    return Params;
};
}
