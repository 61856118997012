import { Pipe, PipeTransform } from '@angular/core';
import linkifyStr from 'linkifyjs/string';

import { EmojiEvent } from 'lib/emoji/public_api';

@Pipe({ name: 'linkify' })
export class LinkifyPipe implements PipeTransform {

  urls: any = /(\b(https?|http|ftp|ftps|Https|rtsp|Rtsp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim; // Find/Replace URL's in text
	hashtags: any = /(^|\s)(#[a-z\d][\w-]*)/ig; // Find/Replace #hashtags in text
	mentions: any = /(^|\s)(@[a-z\d][\w-]*)/ig; // Find/Replace @Handle/Mentions in text
  emails: any = /(\S+@\S+\.\S+)/gim; // Find/Replace email addresses in text
  boldtags: any = /\*\*(.*?)\*\*/gm; //Find/Replace text bold
  escapedText: any = /(\<([^\>]+)\>)/gi;
  // emojiText: any = /&zwj;/g;

    transform(str: string): string {
        // if (str) {
        //     return str ? linkifyStr(str, { target: '_blank' }) : str;
        // } else {
        //   console.log(str);

        //     return str;
        // }

        return this.linkify(str);
    }

    private linkify(plainText): string{

      // Find/Replace URL's in text
    if (plainText.match(this.urls)) {
          plainText = plainText.replace(this.urls, function replacer($1, $2, $3) {
              let url: any = $1;
              let urlClean: any = url.replace("" + $3 + "://", "");

              return "<a href=\"" + url + "\" target=\"_blank\">" + urlClean + "</a>";
          });
      }


      // Find/Replace @Handle/Mentions in text
    if (plainText.match(this.hashtags)) {
      plainText = plainText.replace(this.hashtags, "<a href=\"/search/hashtag/$2\" class=\"hashtag-link\">$1$2</a>");
    }

    // Find/Replace #hashtags in text
    if (plainText.match(this.mentions)) {
      plainText = plainText.replace(this.mentions, "<a href=\"/search/handle/$2\" class=\"handle-link\">$1$2</a>");
    }

    // Find/Replace email addresses in text
    if (plainText.match(this.emails)) {
      plainText = plainText.replace(this.emails, "<a href=\"mailto:$1\">$1</a>");
    }

    if (plainText.match(this.boldtags)){
      plainText = plainText.replace(this.boldtags, "<strong class=\"msgHighlight\">$1</strong>");
    }

    return plainText;
    }
}
