import { AfterContentInit, Component, ContentChildren, Directive, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { TabComponent } from './tab/tab.component';
import { fromEvent } from 'rxjs';
import { AssistBotService } from 'app/components/ai-assist/assist-bot.service';
 

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})

export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;
  @ViewChild('tooltip',null) tooltip: ElementRef;
  @Output() closeTabPanel= new EventEmitter();
  showTabConent:boolean=true;
  constructor(private assistBotService:AssistBotService ) {
  
  }

  ngOnInit(){
    this.assistBotService.tabPanelBheaviour.subscribe((res:any)=>{
         console.log("tab",res)
         if(res){
          this.showTabConent = res['actionShow'];
         }
    });
    this.showTabConent = true;
  }

  ngAfterViewInit(){
   // if(window.innerWidth < 767){
     
   // }
  }
  ngAfterContentInit() {
    
    const activeTabs = this.tabs.filter(tab => tab.active);

    if (activeTabs.length === 0) {
    // this.selectTab(this.tabs.first);
    }

    
  }

  selectTab(tab: TabComponent) {
    this.showTabConent = true;
    this.tabs.toArray().forEach(tab => (tab.active = false));
    tab.active = true;
 
     
  }

  // expandPanel(){
  //   this.showTabConent = !this.showTabConent;
  // }

   
}
