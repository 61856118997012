import { Component, OnInit, Input, Directive  } from '@angular/core';
import { DatePipe } from '@angular/common';
import { config, path, AlertPopup } from './../../../../../app.config';
import { NotificationService } from './../../../notification.service';


// @Directive()
@Component({
    selector: 'app-sub-comment',
    templateUrl: './sub-comment.component.html',
    styleUrls: []
})
export class SubCommentComponent implements OnInit {
    @Input() postSubComment: any;
    RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
    OFFSET: number = config.DEFAULT_OFFSET;

    totalRowCount: number;
    profileUrl = path.PROFILE_URL;
    showPaginateSpinner = false;
    constructor(
        private notificationService: NotificationService,
        private alert: AlertPopup
    ) { }

    ngOnInit() {
        this.loadMoreSubComments(this.postSubComment);
    };


    loadMoreSubComments(postSubComment) {
        try {
            if (postSubComment) {
                this.totalRowCount = postSubComment.subCommentsCount;
                const listLength = postSubComment.subCommentList.length;
                if (this.totalRowCount > listLength && !this.showPaginateSpinner) {
                    this.showPaginateSpinner = true;
                    const payLoad = {
                        comment_id: postSubComment.comment_id,
                        offset: this.OFFSET,
                        limit: this.RECORD_LIMIT
                    };
                    this.notificationService.getAllSubComment(payLoad).subscribe(
                        response => {
                            if (this.OFFSET === 0) {
                                postSubComment.subCommentList = response['subCommentList'];
                            } else if (this.OFFSET > 0) {
                                postSubComment.subCommentList = [...postSubComment.subCommentList.concat(response['subCommentList'])];
                            }
                            this.showPaginateSpinner = false;
                            this.OFFSET += 1;
                        },
                        error => {
                            this.showPaginateSpinner = false;
                            this.alert.showAlertMessage(error.error);
                        }
                    )
                }
            }
        } catch (err) {
            console.log(err);
        }
    };
}
