import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild, Directive  } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Router, Params, ParamMap } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { AlertPopup, config, HttpLoader, path } from 'app/app.config';
import { ValidationService } from 'app/components/form-validation/validation.service';
import { FileValidationService } from 'app/shared/file-validation.service';
import { StudyRoomComponent } from '../study-room.component';
import { StudyRoomService } from '../study-room.service';
import { DatePipe } from '@angular/common';
import { SocketService } from 'app/socket/socket.service';
import { Observable } from 'rxjs/Observable';
import * as _ from 'underscore';
import 'rxjs/add/operator/switchMap';


// @Directive()
@Component({
  selector: 'app-studyroom-details',
  templateUrl: './studyroom-details.component.html',
  styleUrls: []
})
export class StudyroomDetailsComponent implements OnInit, AfterViewInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('fileInput') private fileInput: ElementRef;


  Record_Limit: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET :number = config.DEFAULT_OFFSET;

  domElementControl = {
    showAssignedTopicRoom : false
  }

  _domParams = {
    hideFilePreview : false
  }

  selectedRoomTopic = {};
  public search : string;
  totalRowCount: number;
  roomId : number;
  showPaginationSpinner = false;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  showPaginateSpinner = false;
  getStudyRoomData :any[] = [];
  AlertMessage = "";

  private subscribers:any = {};

  constructor(
    private route : ActivatedRoute,
    private router: Router,
    private studyRoomService: StudyRoomService,
    private httpLoader : HttpLoader,
    private alert: AlertPopup,
    private appComponent: AppComponent,
    private formBuilder: FormBuilder,
    private validateService: ValidationService,
    private studyRoomComponent: StudyRoomComponent,
    private fileValidationService: FileValidationService,
    private socketService: SocketService
  ) { }

  ngOnInit(): void {
    this.getOneStudyRoomDetails();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }

  closeActiveUrl(){
    this.studyRoomComponent.viewStudyRoomList = false;
    this.router.navigate(['/main/studyroom']);
  }

  getOneStudyRoomDetails(){
    try {
      this.subscribers.studyRoomList = this.route.paramMap
          .switchMap((params: ParamMap) =>
              this.studyRoomService.getStudyRoomTopic(+params.get('id'))).subscribe(
          data => {
              if(data['success']==false){

                // this.AlertMessage = data;
                // this.alert.showAlertMessage(data);
              }
              this.OFFSET = config.DEFAULT_OFFSET;
              this.selectedRoomTopic = data['groupDetails'];
              this.roomId = data['groupDetails']['chat_group_id'];
              this.getStudyRoomData = data['result'];
              // this.groupId = data['group_detail']['chat_group_id'];
              this.totalRowCount = data['TotalRowCount'];
              this.httpLoader.hideLoader();
              // this.updateNewSubPostReadStatus(this.getRoomTopicList);
          },
          error => {
            this.router.navigate([`/main/studyroom`]);
            this.httpLoader.hideLoader();
            this.alert.showAlertMessage(error.error);
          }
          )

    } catch (err) {
        this.httpLoader.hideLoader();
    }
  }

  hideShowStudyRoom(){
    this.studyRoomComponent.viewStudyRoomList = !this.studyRoomComponent.viewStudyRoomList;
  }

  fetchNextTopic(){

  }


  // ngOnDestroy(){

  // }

}
