import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-listdialog',
  templateUrl: './listdialog.component.html',
  styleUrls: ['./listdialog.component.scss']
})
export class ListdialogComponent {
  @Input() title: string="Generate Document";
  @Input() message: string="";
  @Input() btnOkText: string="Save";
  @Input() btnCancelText: string="Cancel";
  @Output() onClose:EventEmitter<any> = new EventEmitter<any>();

  filetype:any;
  constructor( ) { }

  ngOnInit() {
    
  }
  close(data?: any): void {
    this.onClose.emit(data);
  }

  Confirm(){
    if(this.filetype){
      this.onClose.emit(this.filetype);
    }
   
  }

  Cancel(){
    this.onClose.emit(false);
  }

  handleChange(data:any){
    this.filetype=data;
  
  }
}
