import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../../app.config';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';


@Injectable()
export class HeaderService {

    BASE_URL_STATIC = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/`;
    BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/users/`;

    private _changeProfile = new Subject();
    profileIconChange$ = this._changeProfile.asObservable();

    private _changeCompanyLogo = new Subject();
    companyLogoChange$ = this._changeCompanyLogo.asObservable();

    constructor(private http: HttpClient) { }

    getCurrentUserDetail() {
        return this.http.get(`${this.BASE_URL}currentUser`);
    }

    getUserStatus() {
        return this.http.get(`${this.BASE_URL_STATIC}status`);
    }

    userProfileChanged(profile) {
        this._changeProfile.next(profile);
    };

    instituteLogoChanged(institute){
      this._changeCompanyLogo.next(institute);
    }

    updateStatus(payload) {
        return this.http.post(`${this.BASE_URL}status`, payload);
    }

    getChannelList(){
      return this.http.get(`${this.BASE_URL}channelList`);
    }

    changeChannel(payLoad){
      // const params = this.preHttpParams(payLoad);
      return this.http.post(`${this.BASE_URL}changeChannel`, payLoad);
    }
}
