import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {
  //@HostBinding('class.active') isActive = false;
  constructor(private elemRef:ElementRef) { }

  @HostListener('click') onDropdownClick(){
    if(this.elemRef.nativeElement.parentNode.lastChild.className==""){
      this.elemRef.nativeElement.parentNode.lastChild.className='active'; 
    }else{
      this.elemRef.nativeElement.parentNode.lastChild.className=''; 
    }
      
  }     
  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    if(target.offsetParent.className !=="menu-list"){
      this.elemRef.nativeElement.parentNode.lastChild.className=''; 
    }
    
  }


}
