import { AfterViewInit, Component, ElementRef, HostBinding, OnInit, ViewChild, Directive  } from '@angular/core';
import { ActivatedRoute, ParamMap, Router, Params } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { AlertPopup, config, HttpLoader, path, ResolveEmit } from 'app/app.config';
import { StaffRoomService } from 'app/components/staff-room/staff-room.service';
import { ELibraryComponent } from '../e-library.component';
import { DatePipe } from '@angular/common';
import { ELibraryService } from '../e-library.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChatService } from 'app/components/chat-list/chat.service';
import { HttpEventType, HttpResponse, HttpClient, HttpRequest } from '@angular/common/http';
import { FileValidationService } from 'app/shared/file-validation.service';
import { Subject, Observable, fromEvent, Subscription } from 'rxjs';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/debounceTime';
import * as _ from 'underscore';
import { DataSharingService } from 'app/shared/data-sharing.service';
import { GroupService } from 'app/components/userandgroup/group/group.service';
import { ValidationService } from 'app/components/form-validation/validation.service';
 
import { UserService } from 'app/components/userandgroup/user/user.service';
import { ConfirmationDialogService } from 'app/confirmation-dialog/confirmation-dialog.service';

// @Directive()
@Component({
  selector: 'app-e-library-details',
  templateUrl: './e-library-details.component.html',
  styleUrls: ["./e-library-details.component.scss"]
})
export class ELibraryDetailsComponent implements OnInit, AfterViewInit {

  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('elibraryFileInput') elibraryFileInput: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;

  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  totalFileRowCount = 0;

  domElementControl = {
    showCreateElibrary: false
  }

  activeUrl :string;
  selectedClass = {};
  selectedDataClass = {};
  getElibraryFileList: any[] = [];
  selectedFileList:any[] = [];
  listOfUsers: any[] = [];
  getSearchItem:any = [];
  getLessonCount = {};
  getReadUserCount = 0;
  // search: string;
  public search: string = null;
  totalRowCount:number;
  showPaginationSpinner = false;
  showBtnSpinner = false;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  groupId: number;
  editedGroupId: number;
  libraryForm: FormGroup;
  activeListView = false;
  activeTileView = true;
  activeDashboard = false;
  activeFileUpload = false;
  activeFileUploadHeader = false;
  showPaginateSpinner = false;
  isUserRole:any ="";
  getUserCount=[];
  public showSearchBar = false;
  searchText: string;
  searchUser: string;
  updatedGroupList: any = {};
  selectedIdList: number[] = [];
  toggleModalSection = false;



    /** Chat File Size limit */
    chatFileSizeLimit: number = config.MAX_ATTACH_FILE_SIZE;
    chatFileExt = config.ATTACHMENT_EXT;

  /** File upload Variables */
  upLoadedFileList: any[] = [];
  fileUploadingInProgress = false;
  topicFileExtension = config.TOPIC_EXT;
  topicFileSize = config.TOPIC_FILE_SIZE;
  preUploadedFile:any;
  preProfileUrl = path.PRE_PROFILE_URL;
  profileUrl = path.PROFILE_URL;
  profileExtension = config.PROFILE_EXT;
  profileSize = config.PROFILE_SIZE;
  groupUserList: any =[];

  /** Chat File Size limit */
  attachedFileSizeLimit: number = config.MAX_ATTACH_FILE_SIZE;
  attachFileExt = config.ATTACHMENT_EXT;

  viewControl: any = {
    changedSearchText : false,
    editClassesDetails: false
  }

  public subscribers: any = {};
  _domParams = {
    hideFilePreview : false
  }
  group_id: any="";
  totalUserCount = 0;
  responsiveTab:boolean = false;
  newGroupForm: FormGroup;


  uploadFileIcons = [
    {name: "pdf", icons : '../../../../assets/images/file_types/pdf.png'},
    {name: "doc",icons : '../../../../assets/images/file_types/doc.png'},
    {name: "docx",icons : '../../../../assets/images/file_types/docx.png'},
    {name: "txt",icons : '../../../../assets/images/file_types/txt.png'},
    {name: "ppt",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: "pptx",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: "pptm",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: 'mp3',icons : '../../../../assets/images/file_types/mp3.png'},
    {name: 'mp4',icons : '../../../../assets/images/file_types/mp4.png'},
    {name: 'wav',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'avi',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'png',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'jpeg',icons : '../../../../assets/images/file_types/jpg.png'},
    {name: 'jpg',icons : '../../../../assets/images/file_types/jpg.png'},
    {name: 'flv',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'html',icons : '../../../../assets/images/file_types/html.png'},
    {name: 'psd',icons : '../../../../assets/images/file_types/psd.png'},
    {name: 'json',icons : '../../../../assets/images/file_types/json.png'},
    {name: 'xlsx',icons : '../../../../assets/images/file_types/xlsx.png'},
    {name: 'xls',icons : '../../../../assets/images/file_types/xls.png'},
    {name: 'css',icons : '../../../../assets/images/file_types/css.png'},
    {name: 'zip ',icons : '../../../../assets/images/file_types/zip.png'}
  ];

  searchSubject = new Subject <any>();
  searchInList = new Subject <any>();
  userList: any[] = [];

  fileFilter:any="all";
  tempgetElibraryFileList:any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private staffRoomService: StaffRoomService,
    private elibraryComponent: ELibraryComponent,
    private alert: AlertPopup,
    private httpLoader : HttpLoader,
    private appComponent: AppComponent,
    private elibraryService:ELibraryService,
    private fb: FormBuilder,
    private chatService: ChatService,
    private fileValidationService: FileValidationService,
    private dataSharingService: DataSharingService,
    private groupService: GroupService,
     private confirmDialogService:ConfirmationDialogService,
    private userService: UserService
  ) {
    this.libraryForm = fb.group({
      title: [null, Validators.compose([Validators.required])],
      description:[null],
    });

    this.newGroupForm = fb.group({
      chat_group_name: [null, Validators.compose([Validators.required, ValidationService.noWhitespaceValidator])],
      // is_private: [null, Validators.required],
      chat_description: [null],
      userIdList: []
    });

    this.searchSubject
      .debounceTime(500)
      .subscribe(event => {
        this.viewControl.changedSearchText = true;
        const searchText = event.target.value;
        this.searchFileList(searchText);
      });

      this.searchInList
      .debounceTime(500)
      .subscribe(event => {
        this.searchUser = event.target.value;
        this.viewControl.changedSearchText = true;
        this.userSearchAndPagination(0);
      });

  }

  ngOnInit(): void {

    try {
      this.httpLoader.showLoader();
      this.route.paramMap
        .switchMap((params: ParamMap) => this.groupService.getClassRoomDetail(+params.get('id')))
        .subscribe(groupList => {
          this.httpLoader.hideLoader();
          this.editedGroupId = groupList['group_detail'].chat_group_id;
          this.updatedGroupList = groupList['group_detail'];
          this.userList = groupList['groupMemberList'];
          this.userSearchAndPagination(0);
          this.viewControl.editClassesDetails=false;
          this.activeDashboard = false;
          this.activeTileView = true;
          for (const key in this.newGroupForm.value) {
            if (key) {
              const newObj = {};
              newObj[key] = groupList['group_detail'][key];
              this.newGroupForm.patchValue(newObj);
            }
          }
        },
        error => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        });
    } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
    }

    this.isUserRole = window.localStorage.getItem('user_role');
    this.isUserRole = parseInt(this.isUserRole.replace(/\"/g, ""));
    // this.getOneElibraryClass();
    this.route.paramMap.subscribe(x => {
      this.group_id = x.get('id');
      const payLoad = {
        offset: this.OFFSET,
        limit: this.RECORD_LIMIT,
        groupId: this.group_id
      }
      this.getMessageAttchmentList(payLoad);
      this.activeFileUploadHeader = false;
    });

    this.getFileDetailsList(this.search);

    this.elibraryService.elibraryBS.subscribe((res:any)=>{
         if(res['action']=="showeditlibrary"){
          this.editClassesLesson();
        }
    });
  }

  ngAfterViewInit(): void{

  }

  onfileFilterSelected(event:any){
   console.log("this.getElibraryFileList",this.getElibraryFileList);
    let selectedValue = event.target.value;
   if(selectedValue=="llm"){
       this.getElibraryFileList = this.tempgetElibraryFileList.filter((list:any)=>list['is_llm_training']==true);
    }else if(selectedValue=="non-llm"){
      this.getElibraryFileList = this.tempgetElibraryFileList.filter((list:any)=>list['is_llm_training']==false || list['is_llm_training']==null);
    }else{
      this.getElibraryFileList = this.tempgetElibraryFileList;
    }
  }

  editClassesLesson(){
    this.viewControl.editClassesDetails = true;
    this.activeFileUploadHeader = true;
    this.route.paramMap.subscribe(x => {
      this.group_id = x.get('id');
      const payLoad = {
        offset: this.OFFSET,
        limit: this.RECORD_LIMIT,
        groupId: this.group_id
      }

      this.chatService.getMessageAttachments(payLoad).subscribe(
        response => {
          this.selectedDataClass = response['groupDetail'];
        });
    });
  }

  onFileChange(event) {
    const files = event.target.files;
    const groupId = this.editedGroupId;
    if (files && files.length > 0) {
      const formFields = {
        fieldName: 'group_profile',
        activity: 'group_icon_update',
        chat_group_id: groupId
      };

      this.selectedFileList.push(files[0]);
      this.fileValidationService.fileValidation(files, this.profileExtension, this.profileSize).then(validResponse => {
        this.appComponent.fileUploadWithProgess(formFields, this.selectedFileList).then((fileUpLoadResponse: any) => {
          for (const key in fileUpLoadResponse) {
            if (fileUpLoadResponse[key]) {
              this.updatedGroupList.chat_group_icon = fileUpLoadResponse[key]['groupProfile']['chat_group_icon'];
              this.elibraryComponent.listOfGroups.find(group => {
                if (group.group_id === groupId) {
                  return group.group_icon = fileUpLoadResponse[key]['groupProfile']['chat_group_icon'];
                }
              });
            }
          }
          this.selectedFileList = [];
          this.fileInput.nativeElement.value = '';
          this.alert.showAlertMessage(fileUpLoadResponse['message']);
        }).catch(error => {
          this.selectedFileList = [];
          this.fileInput.nativeElement.value = '';
          this.alert.showAlertMessage(error.error);
        })
      }).catch(err => {
        this.fileInput.nativeElement.value = '';
        this.selectedFileList = [];
        err.map(li => this.alert.showAlertMessage(li));
      });
    }
  };

  userSearchAndPagination(pageNo, groupId = this.editedGroupId){
    try {

      if (pageNo === 0) {
        this.OFFSET = pageNo;
        this.httpLoader.showLoader();
      }

      if (!this.searchUser) {
        this.viewControl.changedSearchText = false;
      }

      const offset = this.OFFSET;
      const payLoad = {
        groupId: groupId,
        offset: offset,
        limit: this.RECORD_LIMIT,
        // sort: '',
        search: this.searchUser || ''
      };


      this.userService.getSelectedUserList(payLoad).subscribe(
        (userList) => {
          this.showPaginateSpinner = false;
          if (offset === 0) {
            this.listOfUsers = userList['memberList'];
            this.totalRowCount = userList['TotalRowCount'];
            this.httpLoader.hideLoader();
          } else if (offset > 0) {
            this.listOfUsers = [...this.listOfUsers.concat(userList['memberList'])];
          }
        },
        error => {
          this.httpLoader.hideLoader();
          this.showPaginateSpinner = false;
          this.alert.showAlertMessage(error.error);
        });
    } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
    }
  }

  clickedSearchIcon(event: Event) {
    this.showSearchBar = !this.showSearchBar;
    console.log(this.showSearchBar);
  }

  hideElibaryClass(){
    this.elibraryComponent.viewLibraryList =! this.elibraryComponent.viewLibraryList;
  }

  onScrollDown() {
    // if ((this.getElibraryFileList.length < this.totalFileRowCount)) {
      if ((this.getElibraryFileList.length < this.totalFileRowCount) && !this.showPaginateSpinner) {
      this.showPaginateSpinner = true;
      this.OFFSET += 1;
      const payLoad = {
        offset: this.OFFSET,
        limit: this.RECORD_LIMIT,
        groupId: this.group_id
      }
      this.getMessageAttchmentList(payLoad);
    }
    // }

    if (this.listOfUsers.length < this.totalRowCount && !this.showPaginateSpinner) {
      this.OFFSET += 1;
      this.showPaginateSpinner = true;
      this.userSearchAndPagination(this.OFFSET);
    }
};

closeActiveClass(){
  this.elibraryComponent.viewLibraryList=false;
  this.router.navigate(['/main/elibraryandcourse/e-library']);
}

  getMessageAttchmentList (payLoad) {

    this.chatService.getMessageAttachments(payLoad).subscribe(
      response => {
        if (this.OFFSET === 0) {

          this.getElibraryFileList = response['recordList'];

          this.dataSharingService.emitLessonsRecords(response);

          this.getLessonCount = response['recordList'].length;
          this.getUserCount = response['TotalUserCount'];
          var userRead = response['recordList']['read_user_ids'];
        } else {
          this.getElibraryFileList = this.getElibraryFileList.concat(response['recordList']);
        }

        let group_admin = window.localStorage.getItem('user_id');
        this.responsiveTab = true;
        const chatUserList = response['groupUserDetail'].filter((x) => x !== parseInt(group_admin));
        this.selectedDataClass = response['groupDetail'];
        this.selectedClass = response['TotalUserCount'];
        this.groupUserList = response['groupUserDetail'];
        this.totalFileRowCount = response['TotalRowCount'];
        this.getLessonCount = response['TotalRowCount'];
        this.getSearchItem = response['recordList'];
        // response['totalUserCount'] = chatUserList.length;
        this.dataSharingService.emitElibaryDashboardCount(response);
        this.tempgetElibraryFileList = this.getElibraryFileList;
        this.showPaginateSpinner = false;
      },
      error => {
        this.router.navigate([`/main/elibraryandcourse/e-library`]);
        this.alert.showAlertMessage(error.error);
        this.showPaginateSpinner = false;
      }
    )
  }

  searchFileList(search){
    this.getFileDetailsList(search);
  }

  getFileDetailsList(search='', pageNo=0){
    try {
      if(pageNo === 0){
        this.OFFSET = 0;
        this.httpLoader.hideLoader();
      }

      // if(search!=null){
        const offset = this.OFFSET;
        const payLoad = {
          offset: offset,
          limit: this.RECORD_LIMIT,
          search: search || '',
          groupId: this.group_id
        }

        this.chatService.getAttachmentsFileSearch(payLoad).subscribe(list => {
          if(offset === 0){
            this.httpLoader.hideLoader();
            this.getElibraryFileList = list['recordList'];
          }else{
            this.getElibraryFileList = [...this.getElibraryFileList.concat(list['recordList'])];
          }


          this.selectedDataClass = list['groupDetail'];
          this.selectedClass = list['TotalUserCount'];
          this.groupUserList = list['groupUserDetail'];
          this.getLessonCount = list['TotalRowCount'];
          this.totalFileRowCount = list['TotalRowCount'];
          this.getSearchItem = list['recordList'];

          this.dataSharingService.emitElibaryDashboardCount(list);
          this.showPaginateSpinner = false;

        }, error => {
          this.showPaginateSpinner = false;
          this.httpLoader.hideLoader();
        })
      // }

    } catch (error) {
      console.log(error.error);
      this.showPaginateSpinner = false;
    }
  }

  clearSearchFileList(){
    this.search = null;
    if (!this.viewControl.changedSearchText) {
      return;
    }
    this.viewControl.changedSearchText = false;
    this.getFileDetailsList(this.search);

  }

  getSearchFileList(){

  }

  addNewbook(){
    // this.domElementControl.showCreateElibrary =! this.domElementControl.showCreateElibrary;
    this.activeFileUpload = true;
    this.activeFileUploadHeader = true;
  }

  hideElibaryModal(data){
    this.removeFileFromList(data)
    this.domElementControl.showCreateElibrary = false;
    this.libraryForm.reset();
    // this.upLoadedFileList = [];
    this.preUploadedFile = null;
  }

  showHideModal(viewModal){
    if(viewModal==='tile'){
      this.activeTileView = true;
      this.activeListView = false;
      this.activeDashboard = false;
      this.activeFileUpload = false;
      this.activeFileUploadHeader = false;
      this.elibraryComponent.viewLibraryList = false;
      this.viewControl.editClassesDetails = false;
    }else if(viewModal==='list'){
      this.activeListView = true;
      this.activeTileView = false;
      this.activeDashboard = false;
      this.activeFileUpload = false;
      this.activeFileUploadHeader = false;
      this.elibraryComponent.viewLibraryList = false;
      this.viewControl.editClassesDetails = false;
    }else if(viewModal==='upload'){
      this.activeFileUpload = true;
      this.activeFileUploadHeader = true;
      this.activeTileView = false;
      this.activeListView = false;
      this.activeDashboard = false;
      this.elibraryComponent.viewLibraryList = true;
      this.viewControl.editClassesDetails = false;
    }else if(viewModal==='dashboard'){
      this.activeDashboard = true;
      this.activeTileView = false;
      this.activeListView = false;
      this.activeFileUpload = false;
      this.activeFileUploadHeader = false;
      this.elibraryComponent.viewLibraryList = false;
      this.viewControl.editClassesDetails = false;
    }else{
      this.activeTileView = true;
      this.elibraryComponent.viewLibraryList = false;
      this.viewControl.editClassesDetails = false;
    }
  }


  disabledDashboard(){
    this.activeDashboard = false;
    this.activeTileView = true;
  }

  createLibrary(formVal){
    this.sendMessageFile(formVal);
  }


removeUploadFile(data){
  try {
    this.removeFileFromList(data)
    // this.upLoadedFileList = [];
    this.preUploadedFile = null;
  } catch (error) {
    console.log(error);
  }
}

  async fileValidationHanlder(files) {
    try {
        const isValidate = await this.fileValidationService.fileValidation(files, this.chatFileExt, this.chatFileSizeLimit);
        return files;
    } catch (err) {
        err.map(li => {
            const errMsg = {
                message: li
            };
            this.alert.showAlertMessage(errMsg);
        });
        // this.fileInput.nativeElement.value = '';
        return false;
    }
};

async chooseUploadFile(event) {
  const files = event.target.files;
  const fileValid = await this.fileValidationHanlder(files);
  if (fileValid) {
      this.concatFileIntoArray(files);
  }
  // this.concatFileIntoArray(files);
  // this.fileInput.nativeElement.value = '';
};

concatFileIntoArray(files) {
  if (files && files.length > 0) {
      if (this.upLoadedFileList.length < 1) {
          const fileObject = {
              user_id: null,
              group_id: null,
              files: []
          };
          const paramId = +this.route.snapshot.paramMap.get('id');
          let fileEl = null;
          let existFileList = null;
          for (let i = 0; i < files.length; i++) {
              const splitArr = files[i].name.split('.');
              files[i].mimeType = splitArr[splitArr.length - 1].toLowerCase();
              files[i].uniqId = Date.now();
              existFileList = [...this.appComponent.selectedFilesList];
                  fileEl = existFileList.find(el => el.group_id === paramId);
                  if (fileEl) {
                      fileEl.files.unshift(files[i]);
                      this.upLoadedFileList = fileEl.files;
                  } else {
                    console.log(paramId);

                      fileObject.group_id = paramId;
                      fileObject.files.push(files[i]);
                      this.upLoadedFileList = fileObject.files;
                      existFileList.unshift(fileObject);
                  }
          }
          this.appComponent.selectedFilesList = existFileList;
      } else {
          this.alert.showAlertMessage({
              message: 'File seletion limit reached.'
          });
      }
  }
};



    /** Remove files from selected list */
    removeFileFromList(val) {
      const data = val[0]
      if(this.upLoadedFileList.length > 0) {
      const newFileList = this.upLoadedFileList;
      const currentFiles = newFileList.filter(fl => fl.uniqId !== data.uniqId);
      const paramId = +this.route.snapshot.paramMap.get('id');
      let fileEl = null;
      const existFileList = this.appComponent.selectedFilesList;
      fileEl = existFileList.find(el => el.group_id === paramId);
      if (fileEl) {
        fileEl.files = currentFiles;
      }
      this.upLoadedFileList = currentFiles;
      this.appComponent.selectedFilesList = existFileList;
    }
  };

sendMessageFile(data) {
  if (this.upLoadedFileList && this.upLoadedFileList.length > 0 && !this.fileUploadingInProgress) {
      const formFields: any = {
          fieldName: null,
          activity: null,
          to_user_id: null,
          group_id: null,
          message: null
      };

      this.httpLoader.showLoader();
      this.showBtnSpinner = true;

      const existFileList = [...this.appComponent.selectedFilesList];
      let fileIndex = null;
      formFields.message = data.title;
      formFields.fieldName = 'group_gallery';
      formFields.activity = 'groupChatMessage';
      formFields.group_id = this.group_id;
      formFields.chat_user_list = this.selectedClass['chat_group_id'];
      fileIndex = existFileList.findIndex(el => el.group_id === this.group_id);
          if (fileIndex >= 0) {
              existFileList.splice(fileIndex, 1);
          }
      // this.textMessage = null;
      this.appComponent.selectedFilesList = existFileList;
      this.fileUploadingInProgress = true;
      this.appComponent.fileUploadWithProgess(formFields, this.upLoadedFileList).then((fileList: any): void => {

          if (this.upLoadedFileList.length === 0) {
              this.fileUploadingInProgress = false;
          }


          const newData = {
            created_at: fileList[0].day,
            download_url: fileList[0].download_file_path,
            file_ext: fileList[0].file_ext,
            file_id: fileList[0].file_id,
            file_path: 'temp',
            file_size: fileList[0].file_size,
            file_type: fileList[0].file_type,
            message: fileList[0].message,
            message_id: fileList[0].message_id,
            message_type: fileList[0].message_type,
            shared_by: fileList[0].first_name,
            thumb_Url: fileList[0].thumb_file_path
          }
          const copyList = [...this.getElibraryFileList];
          const mergeData = [newData, ...copyList];
          this.getElibraryFileList = mergeData;
          this.httpLoader.hideLoader();
          this.showBtnSpinner = false;
          this.libraryForm.reset();
          this.domElementControl.showCreateElibrary = false;

      }).catch(error => {
          this.httpLoader.hideLoader();
          this.showBtnSpinner = false;
          this.fileUploadingInProgress = false;
          this.alert.showAlertMessage(error.error);
      });
  }
};

showSearchbox(toggle){
  console.log(toggle);
  try {

    if (this.viewControl.changedSearchText) {
      try {
        this.searchUser = '';
        const offset = 0;
        this.viewControl.changedSearchText = false;
        this.userSearchAndPagination(offset);
      } catch (err) {
        this.httpLoader.hideLoader();
      }
    }
  } catch (err) {
    this.httpLoader.hideLoader();
  }
}

/**
    * Add user selected user id in the userListId array
    * @param userId
    */
 selectUserId(index, userId) {
  this.listOfUsers[index].is_selected = !this.listOfUsers[index].is_selected;
  if (this.listOfUsers[index].is_aleady_selected && this.listOfUsers[index].is_selected) {
    this.listOfUsers[index].is_changed = false;
  } else {
    this.listOfUsers[index].is_changed = true;
  }

  if (this.selectedIdList.indexOf(userId) > -1 && !this.listOfUsers[index].is_selected) {
    this.selectedIdList = _.without(this.selectedIdList, userId);
    return;
  } else if (this.listOfUsers[index].is_selected && !this.listOfUsers[index].is_aleady_selected) {
    this.selectedIdList.push(userId);
    return;
  }
};


closeClassesDetails(){
  this.viewControl.editClassesDetails = false;
  this.activeFileUploadHeader = false;
}

hideFileUploadModal(){
  this.activeFileUpload = false;
  this.activeFileUploadHeader = false;
  this.activeTileView = true;
  this.elibraryComponent.viewLibraryList = false;
  // this.activeTileView = !this.activeTileView;
}


openAddUserSection(){
  this.toggleModalSection = !this.toggleModalSection;
  const userList = _.findWhere(this.listOfUsers, { is_changed: true });
  if (userList) {
    this.newGroupForm.markAsDirty();
  }
}

clearAddUserSection(){
  const userList = _.findWhere(this.listOfUsers, { is_changed: true });
    if (userList) {
      this.confirmDialogService.openConfirmDialog('',{
        title:'Confirmation',
        message:'Do you want to clear the modification?',
        btnOkText:'YES',
        btnCancelText:'NO',
        onClose: (data:any) => {
          if(data){
            this.selectedIdList = [];
            this.userSearchAndPagination(0);
          } else {
            return true;
          }
        }  
      });	

     /*
      this._confirmation.create('Confirmation', 'Do you want to clear the modification?')
        .subscribe((ans: ResolveEmit) => {
          if (ans.resolved) {
            this.selectedIdList = [];
            this.userSearchAndPagination(0);
          } else {
            return true;
          }
        }); */
    }
}

closeAddUserSection(){
  this.toggleModalSection = false;
    const userList = _.findWhere(this.listOfUsers, { is_changed: true });
    if (userList) {
      this.selectedIdList = [];
      this.userSearchAndPagination(0);
    } else {
      this.selectedIdList = [];
    }
}



updateGroupDetail(formVal) {
  const userList = _.where(this.listOfUsers, { is_changed: true });
//if (userList && userList.length > 0) {
  const userIdList = userList.map(user => {
    return Object.assign({},
      {
        user_id: user.user_id,
        is_selected: user.is_selected
      });
  });

  formVal.userIdList = userIdList;

  let payLoad = {};
  const groupId = this.editedGroupId;
  payLoad = {
    id: groupId,
    data: formVal
  }

  this.groupService.updateGroupDetail(payLoad).subscribe(
    group => {
      this.selectedIdList = [];

      this.updatedGroupList = group['groupDetail'];
      this.elibraryComponent.getElibrary(0);

      this.selectedDataClass = group['groupDetail'];

      console.log(group);

      if (group['groupMemberList']!='') {

        this.userList.push(...group['groupMemberList']);
        // this.userList.concat([group['groupMemberList']]);
      }

      this.newGroupForm.markAsPristine();
      const pageNo = 0;
      var groupId = 0;
      this.userSearchAndPagination(pageNo, groupId = this.editedGroupId);
      this.alert.showAlertMessage(group);
      this.viewControl.editClassesDetails=false;
    },
    error => {
      this.alert.showAlertMessage(error.error);
    });
    this.activeFileUploadHeader = false;
  // }
}


removeGroupDetail = function () {
  try {
    const id = this.editedGroupId;
    this.confirmDialogService.openConfirmDialog('',{
      title:'Confirmation',
      message:'Are you sure want to delete this policy library?',
      btnOkText:'YES',
      btnCancelText:'NO',
      onClose: (data:any) => {
        if(data){
          this.loader.showLoader();
          const index = _.findIndex(this.elibraryComponent.listOfGroups, { group_id: id });
          this.groupService.deleteSelectedGroup(id).subscribe(
            response => {
              this.loader.hideLoader();
              this.groupComponent.listOfGroups.splice(index, 1);
              this.router.navigate(['/main/elibraryandcourse/e-library']);
              this.elibraryComponent.selectedDataClass = true;
            },
            error => {
              this.loader.hideLoader();
              this.alert.showAlertMessage(error.error);
            }
          )
        }
      }	  
    });	
   /*
    this._confirmation.create('Confirmation', 'Are you sure want to delete this classroom?', { confirmText: 'YES' })
      .subscribe((ans: ResolveEmit) => {
        if (ans.resolved) {


          this.loader.showLoader();
          const index = _.findIndex(this.elibraryComponent.listOfGroups, { group_id: id });
          this.groupService.deleteSelectedGroup(id).subscribe(
            response => {
              this.loader.hideLoader();
              this.groupComponent.listOfGroups.splice(index, 1);
              this.router.navigate(['/elibraryandcourse/e-library']);
              this.elibraryComponent.selectedDataClass = true;
            },
            error => {
              this.loader.hideLoader();
              this.alert.showAlertMessage(error.error);
            }
          )
        }
      }); */
  } catch (err) {
    this.loader.hideLoader();
    console.log(err);
  }
};

deleteGroupUser(student_id){
  try {

   const group_id = this.editedGroupId;

   if(this.userList.length > 1){
    this.confirmDialogService.openConfirmDialog('',{
      title:'Confirmation',
      message:'Are you sure, you want to remove the user from this lesson?',
      btnOkText:'YES',
      btnCancelText:'NO',
      onClose: (data:any) => {
        if(data){
          this.httpLoader.showLoader();
          this.groupService.deleteGroupUsers(group_id, student_id).subscribe(response=>{
            this.httpLoader.hideLoader();
            this.alert.showAlertMessage(response);
            this.selectedIdList = [];
            const pageNo = 0;
            var groupId = 0;
            this.userSearchAndPagination(pageNo, groupId = this.editedGroupId);
            this.elibraryComponent.getElibrary(0);
            const userId = response['result']['user_id'];
            let position = this.userList.findIndex(x => x.user_id===userId);
 
                if(~position){
                  this.userList.splice(position, 1);
                }
            // this.groupComponent.listOfGroups.splice(index, 1)
          }, error => {
            this.httpLoader.hideLoader();
            this.alert.showAlertMessage(error.error);
          });
        }
      }	  
  });

    /* this._confirmation.create('Confirmation', 'Are you sure, you want to remove the student from this class?', {confirmText: 'Yes, Remove'}).subscribe((ans: ResolveEmit) => {
       if(ans.resolved){
         this.httpLoader.showLoader();
         this.groupService.deleteGroupUsers(group_id, student_id).subscribe(response=>{
           this.httpLoader.hideLoader();
           this.alert.showAlertMessage(response);
           this.selectedIdList = [];
           const pageNo = 0;
           var groupId = 0;
           this.userSearchAndPagination(pageNo, groupId = this.editedGroupId);
           this.elibraryComponent.getElibrary(0);
           const userId = response['result']['user_id'];
           let position = this.userList.findIndex(x => x.user_id===userId);

               if(~position){
                 this.userList.splice(position, 1);
               }
           // this.groupComponent.listOfGroups.splice(index, 1)
         }, error => {
           this.httpLoader.hideLoader();
           this.alert.showAlertMessage(error.error);
         })
       }
     }) */

   }else{

     this.alert.showAlertMessage({
       message: 'Cannot delete all the Users, At least one User needed in the Lesson'
     });
     return false;

   }
  } catch (error) {
   this.httpLoader.hideLoader();
   console.log(error);
  }
 }


ngOnDestroy(): void {
  //Called once, before the instance is destroyed.
  //Add 'implements OnDestroy' to the class.
  this.searchSubject.unsubscribe();
  this.searchInList.unsubscribe();
}


}
