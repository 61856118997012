import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Subject } from 'rxjs/Subject';
import { config } from 'app/app.config';
import { BehaviorSubject } from 'rxjs';

export class Media {
  file_path: string;
  file_type: number;
}


@Injectable({
  providedIn: 'root'
})
export class ELibraryService {

  baseUrl = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;

  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  private mediaContent = new Subject<Media>();
  elibraryBS:BehaviorSubject<any> = new BehaviorSubject({});
  constructor(
    private http: HttpClient
  ) { }

  getMediaContent(): Observable<any> {
    return this.mediaContent.asObservable();
  }

  emitMediaContent(mediaFields: any) {
    setTimeout(() => {
        this.mediaContent.next(<Media>mediaFields);
    }, 300);
};

  clear(){
    this.mediaContent.next();
  }

  updateMessageRead(message: any) {
    return this.http.post(`${this.baseUrl}/chats/updateMessageRead`, message);
  };


  getIndividualStAssessment(fileId, userId){
    return this.http.get(`${this.baseUrl}/studentAssessment/${fileId}/${userId}`);
  }

  getSudentsAssessmentResult(payLoad){
    return this.http.get(`${this.baseUrl}/studentQuestionandAnswer/${payLoad.fileId}/${payLoad.userId}/${payLoad.answerId}`);
  }

  getStudentDashboard(id){
    return this.http.get(`${this.baseUrl}/student/dashboard/${id}`);
  }

  postTeacherRemark(payLoad){
    return this.http.post(`${this.baseUrl}/teacher/remark`, payLoad);
  }

  submitInteractiveQuiz(payload){
    return this.http.post(`${this.baseUrl}/submitVideoQuiz`, payload);
  }


  preHttpParams(payLoad) {
    let Params = new HttpParams();
    for (const key in payLoad) {
        if (key && (payLoad[key] || payLoad[key] === 0)) {
            Params = Params.append(key, payLoad[key]);
        }
    }
    return Params;
  }

  uploadLLMPdf(payLoad){
     return this.http.post("https://document.canstringai.com:8443/upload_document/receive_document",payLoad);
  }

  getSummarize(extractText){
    let payload = {
      "text":extractText,
      "task":"summarization"
      };
    return this.http.post("https://document.canstringai.com:8443/ca-llm/multiple_task",payload);
  }

  uploadSummaryPdf(payload){
    return this.http.post("https://gpt.canstringai.com/pdf/upload-pdf",payload);
  }

}
