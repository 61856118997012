import { AppComponent } from './../../app.component';
import { AlertPopup, HttpLoader } from './../../app.config';
import { Component, Input, OnInit, Directive  } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { config } from '../../app.config';
import { ValidationService } from '../form-validation/validation.service';
import { UserService } from './../userandgroup/user/user.service';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';
import { DataSharingService } from 'app/shared/data-sharing.service';
import { PasswordResetService } from '../password-reset/password-reset.service';

// @Directive()
@Component({
  selector: 'app-verify',
  templateUrl: './verification.component.html',
  styleUrls: []
})

export class VerificationComponent implements OnInit {
  verifyForm: FormGroup;
  showVerificationPin = false;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alert: AlertPopup,
    private httpLoader: HttpLoader,
    private app: AppComponent,
    private dataSharingService: DataSharingService,
    private passwordResetService: PasswordResetService,
    private userService: UserService) {
    this.verifyForm = formBuilder.group({
      verify_otp: ['', Validators.compose([Validators.required])]
    });
  }

  ngOnInit() { };

  returnToLogin() {
    this.router.navigate(['/login']);
  };

  public isNumberOnly(evt:any){
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)){
      // this.discountValidate = true;
      this.alert.showAlertMessage({message: 'Please enter 6 digit OTP number only.'});
        // this.priceErrMsg = 'Please enter discount number only. For example 535';
        return false;
    }else{
      // this.discountValidate = false;
      return;
    }
  }

  isvalidateOtp(event){
    let otp = event.target.value;
    if(otp.length == 6){
          let button = document.querySelector('.disabledBtn');
          button.removeAttribute('disabled');
    }else{
      let button = document.querySelector('.disabledBtn');
          button.setAttribute('disabled', 'disabled');
    }
  }

  verifyAcc(formVal) {

    try {
      this.userService.verify_otp(formVal).subscribe((response:any) => {

        this.passwordResetService.getUserEmailId(response.user['email_id']);
        window.sessionStorage.setItem('email_id' , response.user['email_id']);
        this.router.navigate(['/password_reset'])
        // window.localStorage.user_id = JSON.stringify(response['user_id']);
        // window.localStorage.user_role = JSON.stringify(parseInt(response['user_role']));
        // window.localStorage.token = JSON.stringify(response['token']);

        // const isActiveUserRole = parseInt(response['user_role']);

        // if(isActiveUserRole===1){
        //   this.router.navigate(['/userandgroup/user']);
        // }else{
        //   this.router.navigate(['/studyroom']);
        // }
        // // this.router.navigate(['/chats']);

        // this.app.getUserStatusData();
        // this.app.getCurrentUserData();

      }, error => {
        this.alert.showAlertMessage(error.error);
      });
    } catch (err) {

    }
  };



}

