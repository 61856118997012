import { Directive, ElementRef, Input, Renderer2, AfterViewInit } from '@angular/core';

// @Directive()
@Directive({
  selector: '[inputFocus]'
})

export class FocusDirective implements AfterViewInit {
  constructor(private el: ElementRef, private rd: Renderer2) {
  }

  ngAfterViewInit() {
    this.el.nativeElement.focus()
  }

}
