import { Component, ElementRef, ViewChild, OnInit, HostBinding, Input, Directive  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { HttpEventType, HttpResponse, HttpClient, HttpRequest } from '@angular/common/http';
import * as _ from 'underscore';
import { ValidationService } from '../form-validation/validation.service';
import { config, path, UPLOAD_URI, AlertPopup, HttpLoader } from './../../app.config';
import { UtilityService } from './../../utility.service';
import { FileValidationService } from './../../shared/file-validation.service';
import { BASE_URI } from './../../app.config';

import { saveAs as importedSaveAs } from 'file-saver';
import { AppComponent } from 'app/app.component';
import { AttendanceService } from './attendance.service';
import { ChatService } from '../chat-list/chat.service';
import { StaffRoomService } from '../staff-room/staff-room.service';
import { UserService } from '../userandgroup/user/user.service';



// @Directive()
@Component({
  selector: 'attendance',
  templateUrl: './attendance.component.html',
  styleUrls: []
})
export class AttendanceComponent implements OnInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('excelFile') excelFile: ElementRef;
  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  newUserForm: FormGroup;
  searchText: string;
  roleList: any[] = [];
  groupList: any[] = [];
  totalRowCount: number;
  groupIdList: number[] = [];
  selectedFile: any;
  sort:any;
  isUserRole:any;

  /** User Profile icon handler variables */
  selectedFileList: any[] = [];
  profileExtension = config.PROFILE_EXT;
  profileSize = config.PROFILE_SIZE;

  /** User Data With excel file */
  // hideFormBtn: boolean;
  // showFormView: boolean;
  excelFileList: any;
  excelFileOpt: any = {
    percent: 0,
    showProgress: false
  };

  userCreationViewCtrl = {
    addUserBtn: false,
    showForm: true,
    showTable: false,
    showFileChoosen: false,
    startUpload: false
  };

  excelUserList: any = [];
  userList:any[]=[];
  bulkUserFileName: string;

  // fileLoadedPercentage: number;
  uploadSpinner = false;
  uploaderResponse: any;
  preUploadedFile: any;

  toggleModalSection = false;
  showPaginateLoader = false;
  // modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  // modalScrollThrottle = config.SCROLL_THROTTLE;

  /** specifying Base URL.*/
  public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${
  config.VERSION
  }/users`;
  profileUrl = path.PROFILE_URL;
  preProfileUrl = path.PRE_PROFILE_URL;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private attendanceService: AttendanceService,
    private httpLoader: HttpLoader,
    private router: Router,
    private route: ActivatedRoute,
    private appComponent: AppComponent,
    private utilityService: UtilityService,
    private alert: AlertPopup,
    private fileValidationService: FileValidationService,
    private chatService: ChatService,
    private staffroomService: StaffRoomService,
    private userService: UserService
  ) {
    this.newUserForm = fb.group({
      first_name: [''],
      last_name: [''],
      user_role: ['', Validators.required],
      phone_no: [null],
      email_id: [
        '',
        Validators.compose([
          Validators.required,
          ValidationService.emailValidator
        ])
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          ValidationService.passwordValidator
        ])
      ],
      gender: [''],
      user_address: [''],
      user_city: [''],
      user_state: [''],
      user_zip: [null],
      user_notes: [''],
      user_id: [''],
      user_icon: ['']
    });
  }

  ngOnInit() {
    this.getDefaultLookup();
    var Role = window.localStorage.getItem('user_role');
    this.isUserRole = parseInt(Role.replace(/\"/g, ""));

    this.getAttendance();

  }

  onFileChange(event) {

  }

  getAttendance(search='',pageNo=0){
    try {
      if (pageNo===0) {
        this.OFFSET = 0;
        this.httpLoader.showLoader();
      }
      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: search || '',
      }

      this.staffroomService.getClassList(payLoad).subscribe((response:any)=>{
        if (offset===0) {
          this.httpLoader.hideLoader();
          this.groupList = response.chatList;
        }else if (offset > 0) {
          this.showPaginateLoader = false;
          this.groupList = {...this.groupList.concat(response.chatList)};
        }
      })
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  onSelectedClass(evt){
    var groupId = evt.target.value;
    this.userList=[];
    this.userService.getActiveUserList(groupId).subscribe((response:any)=>{
      console.log(response);
      this.userList = response.userList;

    })

  }

  /**
   * Remove the Selected File based on the index position
   * @param index
   */
  removeSelectedFile() {
    this.selectedFileList = [];
    this.preUploadedFile = null;
    // this.fileLoadedPercentage = 0;
  }

  /**
   * Creating a new user
   * @param user
   */
  addNewUser(user) {

  }

  /**
   * Add Group Section in modal window
   */
  addGroupSection() {
    this.toggleModalSection = !this.toggleModalSection;
    this.groupSearchAndPagination(0);
  }

  /**
   * Clear Group Id list Selection
   */
  clearGroupSection() {

  }

  /**
   * Add group Ids in groupIdList Array
   * @param groupId
   */
  selectGroup(groupId) {

  }

  getDefaultLookup() {

  }

  /**
   *
   * @param searchText
   */
  groupSearchAndPagination(pageNo) {

  }

  /**
   * Pagination called while scroll down
   */
  onScrollDown() {

  }

  /**
   * @description Upload excewl file with user details and Parse it back
   * @author  Baskaran R
   * @created Date 21-12-2018
   *
   */

  excelFileUploadSectionHandler() {

  }

  onCancelFileUploadSection() {

  }

  onExcelFileChoosen(event) {

  }

  onClearExcelFileChoosen() {
    this.excelFileList = null;
    this.excelFile.nativeElement.value = null;
  }

  onUploadFileProcess() {

  }

  cancelUserList() {
    this.excelFileUploadSectionHandler();
  }

  removeUserFromListHandler(user) {

  }

  createBulkUser() {

  }

  getBulkUserTemplate() {

  }
}
