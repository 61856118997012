import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { config } from 'app/app.config';

@Injectable()


export class HomeworkService {

  public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`

  constructor(
    private http: HttpClient
  ){}

  getActiveUsers(payLoad){
    return this.http.get(`${this.BASE_URL}/chats/users?offset=${payLoad.offset}&limit=${payLoad.limit}&search=${payLoad.search}`);
  }

  //Create pin task
  createPinTask(payLoad){
    return this.http.post(`${this.BASE_URL}/pintask/createPin`, payLoad);
  }

  updateTaskStatus(payLoad){
    return this.http.post(`${this.BASE_URL}/pintask/updatePin`, payLoad);
  }

  getPinTask(id){
    return this.http.get(`${this.BASE_URL}/pintask/`+id);
  }

  getPinTaskLists(payLoad) {
    const Params = this.preHttpParams(payLoad);
    return this.http.get(`${this.BASE_URL}/pintask/taskList`, {params: Params});
  }

  getGroupTaskLists(payLoad){
    const Params = this.preHttpParams(payLoad);
    return this.http.get(`${this.BASE_URL}/pintask/groupTaskList`, {params: Params});
  }

  getGroupTaskView(payLoad){
    const Params = this.preHttpParams(payLoad);
    return this.http.get(`${this.BASE_URL}/groupPinDetails`, {params: Params});
  }

  getOverallTaskLists (payLoad){
    const Params = this.preHttpParams(payLoad);
    return this.http.get(`${this.BASE_URL}/pintask/userTaskList`, {params: Params});
  }

  preHttpParams(payLoad) {
      let Params = new HttpParams();
      for (const key in payLoad) {
          if (key && (payLoad[key] || payLoad[key] === 0)) {
              Params = Params.append(key, payLoad[key]);
          }
      }
      return Params;
  };
}
