import { Component, ElementRef, ViewChild, OnInit, HostBinding, Input, AfterViewInit, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { HttpEventType, HttpResponse, HttpClient, HttpRequest } from '@angular/common/http';
import * as _ from 'underscore';
import { UserService } from '../user.service';
import { AppComponent } from './../../../../app.component';
// import { UserComponent } from '../user.component';
import { ValidationService } from '../../../form-validation/validation.service';
import { config, path, UPLOAD_URI, AlertPopup, HttpLoader } from './../../../../app.config';
import { GroupService } from './../../group/group.service';
import { UtilityService } from './../../../../utility.service';
import { FileValidationService } from './../../../../shared/file-validation.service';
import { BASE_URI } from '../../../../app.config';

import { saveAs as importedSaveAs } from 'file-saver';
import { UserComponent } from '../user.component';
import { fromEvent, Observable, Subscription } from 'rxjs';


// @Directive()
@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.scss']
})
export class NewUserComponent implements OnInit, AfterViewInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('excelFile') excelFile: ElementRef;
  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  newUserForm: FormGroup;
  searchText: string;
  roleList: any[] = [];
  groupList: any[] = [];
  totalRowCount: number;
  groupIdList: number[] = [];
  selectedFile: any;
  classList: any[] = [];
  defaultSelectRole = 6;
  lastname = false;

  /** User Profile icon handler variables */
  selectedFileList: any[] = [];
  profileExtension = config.PROFILE_EXT;
  profileSize = config.PROFILE_SIZE;

  /** User Data With excel file */
  // hideFormBtn: boolean;
  // showFormView: boolean;
  excelFileList: any;
  excelFileOpt: any = {
    percent: 0,
    showProgress: false
  };

  userCreationViewCtrl = {
    addUserBtn: false,
    showForm: true,
    showTable: false,
    showFileChoosen: false,
    startUpload: false
  };

  excelUserList: any = [];
  bulkUserFileName: string;

  // fileLoadedPercentage: number;
  uploadSpinner = false;
  uploaderResponse: any;
  preUploadedFile: any;

  toggleModalSection = false;
  showPaginateLoader = false;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;

  /** specifying Base URL.*/
  public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${
  config.VERSION
  }/users`;
  profileUrl = path.PROFILE_URL;
  preProfileUrl = path.PRE_PROFILE_URL;
  genderArrayList = [
    {name: 'Male', value: 'M'},
    {name: 'Female',value: 'F'},
    // {name: 'Other',value: 'O'}
  ];

  defaultGender = 'M';

  isMobile: boolean = false;
  width:number = window.innerWidth;
  height:number = window.innerHeight;
  mobileWidth:number  = 768;


  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription;

  cuntryCode:number;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private userService: UserService,
    private httpLoader: HttpLoader,
    private router: Router,
    private route: ActivatedRoute,
    private appComponent: AppComponent,
    private groupService: GroupService,
    private utilityService: UtilityService,
    private alert: AlertPopup,
    private fileValidationService: FileValidationService,
    public userComponent : UserComponent
  ) {
    this.newUserForm = fb.group({
      first_name: ['', Validators.compose([Validators.required, ValidationService.nameValidator])],
      last_name: ['', Validators.compose([Validators.required, ValidationService.noWhitespaceValidator, ValidationService.namePattern])],
      user_role: ['', Validators.required],
      phone_no: ['', Validators.compose([
        Validators.required,
        ValidationService.phoneNoValidate,
        ValidationService.noWhitespaceValidator
      ])],
      email_id: [
        '',
        Validators.compose([
          Validators.required,
          ValidationService.emailValidator,
          ValidationService.noWhitespaceValidator
        ])
      ],
      // password: [
      //   '',
      //   Validators.compose([
      //     Validators.required,
      //     ValidationService.passwordValidator,
      //     ValidationService.noWhitespaceValidator
      //   ])
      // ],
      // gender: [''],
      user_address: [''],
      user_city: [''],
      user_state: [''],
      user_zip: [''],
      user_notes: [''],
      user_id: [''],
      user_icon: [''],
      roll_number: [''],
      reg_number:[''],
      aadhar_number:[''],
      pan_number:[''],
      emis_number:[''],
      class_group:[''],
      other_ids:['']
    });
  }

  ngOnInit() {
    this.getDefaultLookup();
    this.userComponent.viewActionBtn = false;

    if(this.width <= this.mobileWidth){
      this.userComponent.viewUserList = true;
    }else{
      this.userComponent.viewUserList = false;
    }

    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      let windowWith = window.innerWidth;
      if(windowWith <= this.mobileWidth){
        this.userComponent.viewUserList = true;
      }else{
        this.userComponent.viewUserList = false;
      }
    })
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  keyDownHandler(event) {
    if (event.keyCode === 32 && event.target.selectionStart === 0) {
      return false;
    }
  }

  lastnameValidation(evt){
    let letters = /^[A-Za-z]+$/;
    if(evt.target.value.match(letters)){
      this.lastname = false;
      return true;
    }else{
      this.lastname = true;
      return false;
    }
  }

  onFileChange(event) {
    const files = event.target.files;
    if (files && files.length > 0) {
      const formFields = {
        fieldName: 'user_profile',
        activity: 'user_profile_add',
        storeType: 'temp'
      };

      this.selectedFileList.push(files[0]);
      this.fileValidationService
        .fileValidation(files, this.profileExtension, this.profileSize)
        .then(validResponse => {
          this.appComponent
            .fileUploadWithProgess(formFields, this.selectedFileList)
            .then((fileUpLoadResponse: any) => {
              for (const key in fileUpLoadResponse) {
                if (fileUpLoadResponse[key]) {
                  this.preUploadedFile = fileUpLoadResponse[key];
                }
              }
              this.selectedFileList = [];
              this.fileInput.nativeElement.value = '';
            })
            .catch(error => {
              this.selectedFileList = [];
              this.fileInput.nativeElement.value = '';
              this.alert.showAlertMessage(error.error);
            });
        })
        .catch(err => {
          this.fileInput.nativeElement.value = '';
          this.selectedFileList = [];
          err.map(li => this.alert.showAlertMessage(li));
        });
    }
  }

  /**
   * Remove the Selected File based on the index position
   * @param index
   */
  removeSelectedFile() {
    this.selectedFileList = [];
    this.preUploadedFile = null;
    // this.fileLoadedPercentage = 0;
  }

  /**
   * Creating a new user
   * @param user
   */
  addNewUser(user) {
    try {
      this.httpLoader.showLoader();
      user.groupIdList = this.groupIdList;
      user.fileData = this.preUploadedFile;

      user.role_name = this.roleList.find(el=>el.role_id==user.user_role).role_name;

      this.userService.addNewUser(user).subscribe(
        data => {
          const userId = data['createdUser'] ? data['createdUser'].user_id : 0;
          if (userId) {
            this.userComponent.listOfUsers.unshift(data['createdUser']);
            this.selectedFileList = [];
            this.preUploadedFile = null;
            this.groupIdList = [];
            this.httpLoader.hideLoader();
            this.alert.showAlertMessage(data);
          }
          this.userComponent.getUserListSortAndSearch(0);
          this.newUserForm.reset();
          // this.newUserForm.controls.gender.patchValue('M');
          this.newUserForm.controls.user_role.patchValue('6');
        },
        error => {
          this.selectedFileList = [];
          this.preUploadedFile = null;
          this.alert.showAlertMessage(error.error);
          this.httpLoader.hideLoader();
        }
      );
    } catch (err) {
      this.selectedFileList = [];
      this.preUploadedFile = null;
      this.httpLoader.hideLoader();
      console.log(err);
    }
  }

  /**
   * Add Group Section in modal window
   */
  addGroupSection() {
    this.toggleModalSection = !this.toggleModalSection;
    this.groupSearchAndPagination(0);
  }

  /**
   * Clear Group Id list Selection
   */
  clearGroupSection() {
    if (this.groupIdList && this.groupIdList.length > 0) {
      const confirmation = confirm('Do you want to cancel selected groups ?');
      if (confirmation === true) {
        this.groupIdList = [];
        this.addGroupSection();
      } else {
        return true;
      }
    } else {
      this.addGroupSection();
    }
  }

  /**
   * Add group Ids in groupIdList Array
   * @param groupId
   */
  selectGroup(groupId) {
    if (this.groupIdList.indexOf(groupId) > -1) {
      this.groupIdList = _.without(this.groupIdList, groupId);
      return;
    } else {
      this.groupIdList.push(groupId);
      return;
    }
  }

  getDefaultLookup() {
    try {
      this.httpLoader.showLoader();
      this.userService.getDefaultLookup().subscribe(
        data => {
          this.httpLoader.hideLoader();
          this.roleList = data['roleList'];
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.httpLoader.hideLoader();
        }
      );
    } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
    }
  }

  /**
   *
   * @param searchText
   */
  groupSearchAndPagination(pageNo) {
    try {
      this.httpLoader.showLoader();
      if (pageNo === 0) {
        this.OFFSET = pageNo;
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        sort: '',
        search: this.searchText || ''
      };
      this.groupService.getSortAndSearch(payLoad).subscribe(
        groupList => {
          this.httpLoader.hideLoader();
          if (offset === 0) {
            this.groupList = groupList['groupList'];
            this.totalRowCount = groupList['TotalRowCount'];
          } else if (offset > 0) {
            this.groupList = this.groupList.concat(groupList['groupList']);
            this.showPaginateLoader = false;
          }
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.showPaginateLoader = false;
          this.httpLoader.hideLoader();
        }
      );
    } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
    }
  }

  /**
   * Pagination called while scroll down
   */
  onScrollDown() {
    if (
      this.groupList.length < this.totalRowCount &&
      !this.showPaginateLoader
    ) {
      this.OFFSET += 1;
      this.showPaginateLoader = true;
      this.groupSearchAndPagination(this.OFFSET);
    }
  }

  /**
   * @description Upload excewl file with user details and Parse it back
   * @author  Baskaran R
   * @created Date 21-12-2018
   *
   */

  excelFileUploadSectionHandler() {
    this.userCreationViewCtrl.addUserBtn = true;
    this.userCreationViewCtrl.showForm = false;
    this.userCreationViewCtrl.showFileChoosen = true;
    this.userCreationViewCtrl.showTable = false;
    this.excelUserList = [];
  }

  onCancelFileUploadSection() {
    this.excelFileList = null;
    this.userCreationViewCtrl.addUserBtn = false;
    this.userCreationViewCtrl.showForm = true;
    this.userCreationViewCtrl.showFileChoosen = false;
    this.userCreationViewCtrl.showTable = false;
    // this.showFormView = true;
    // this.hideFormBtn = false;
  }

  createNewStudent(){

  }

  onExcelFileChoosen(event) {
    const files = event.target.files[0];
    if (!_.isObject(files)) {
      return;
    }
    this.excelFileList = files;
    // this.excelFile.nativeElement.value = '';
  }

  onClearExcelFileChoosen() {
    this.excelFileList = null;
    this.excelFile.nativeElement.value = null;
  }

  onUploadFileProcess() {
    const URL = `${BASE_URI.URL}/users/xls-file-parser`;
    const formData: FormData = new FormData();
    const file = this.excelFileList;
    formData.append('file', file);

    const req = new HttpRequest('POST', URL, formData, {
      reportProgress: true
    });
    this.excelFileOpt.showProgress = true;
    // this.excelFileOpt.showProgress = true;
    this.http.request(req).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.excelFileOpt.percent = Math.round(
            (100 * event.loaded) / event.total
          );
        }

        if (event.type === HttpEventType.Response) {
          this.excelFileOpt.showProgress = false;
          this.excelFileOpt.percent = 0;
          this.excelFileList = null;

          this.userCreationViewCtrl.addUserBtn = false;
          this.userCreationViewCtrl.showForm = false;
          this.userCreationViewCtrl.showFileChoosen = false;
          this.userCreationViewCtrl.showTable = true;

          const eventBody = event.body['userList'];
          // this.alert.showAlertMessage(event.body);
          this.excelUserList = eventBody;
          this.bulkUserFileName = event.body['fileName'];
        }
      },
      error => {
        this.excelFileOpt.showProgress = false;
        this.excelFileOpt.percent = 0;
        this.alert.showAlertMessage(error.error);
      }
    );
  }

  cancelUserList() {
    this.excelFileUploadSectionHandler();
  }

  removeUserFromListHandler(user) {
    if (user) {
      const userListCopy = [...this.excelUserList];
      const filteredUserList = userListCopy.filter(
        el => el.email_id !== user.email_id
      );
      this.excelUserList = filteredUserList;

      if (this.excelUserList.length === 0) {
        this.excelFileUploadSectionHandler();
      }
    }
  }

  createBulkUser() {
    const payLoad = this.excelUserList;
    if (!_.isArray(payLoad)) {
      return;
    }
    this.userCreationViewCtrl.startUpload = true;
    this.userService.bulkUserCreate(payLoad).subscribe(
      response => {
        const userList = [
          ...this.userComponent.listOfUsers,
          ...response['createdResult']
        ];
        this.userCreationViewCtrl.startUpload = false;
        this.userComponent.listOfUsers = userList;
        this.alert.showAlertMessage(response);
        this.excelUserList = [];
        this.onCancelFileUploadSection();
        setTimeout(function(){
          window.location.reload();
        },3000);
      },
      error => {
        this.userCreationViewCtrl.startUpload = false;
        this.alert.showAlertMessage(error.error);
      }
    );
  }

  showHideUserList(){
    this.userComponent.viewUserList =! this.userComponent.viewUserList;
  }

  cancelRegister(){
    this.userComponent.viewActionBtn = true;
  }

  getBulkUserTemplate() {
    try {
      this.userService.getBulkUserTemplate().subscribe( blob =>  {
          importedSaveAs(blob, 'Bulk-Users-Upload-Template.xls')
        },
        error => console.log(error)
        );
    } catch (e) {
      console.log(e);
    }
  }

  getPhoneNumber($event){}
  telInputObject(obj){
    console.log(obj.s.dialCode);
    this.cuntryCode = obj.s.dialCode;

    obj.setCountry('us');
  }


  onCountryChange($event){
    console.log($event);
  }

}
