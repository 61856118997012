import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appToggleIcon]'
})
export class ToggleIconDirective implements AfterViewInit {

  @Output() stateChange:EventEmitter<any> = new EventEmitter<any>();
  @Input() defaultState:string='off';
   isActive:boolean=false;
  constructor(private elemRef:ElementRef,private renderer: Renderer2) { }

  @HostListener('click') toggleClick(){
    if(this.elemRef.nativeElement.className.includes("is-active")){
      this.renderer.removeClass(this.elemRef.nativeElement,"is-active");
      this.isActive=false;
     }else{
      this.renderer.addClass(this.elemRef.nativeElement,"is-active");
      this.isActive=true;
     }
   this.stateChange.emit(this.isActive);
     
  }

  ngAfterViewInit(){
   if(this.defaultState=='off'){
    this.renderer.removeClass(this.elemRef.nativeElement,"is-active");
   }else{
    this.renderer.addClass(this.elemRef.nativeElement,"is-active");
    
   }
  }

}
