import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { StudyRoomRoutingModule } from './study-room-routing.module';
import { StudyRoomComponent } from './study-room.component';
import { StudyRoomService } from './study-room.service';
import { StudyroomDetailsComponent } from './studyroom-details/studyroom-details.component';
import { StudyroomListComponent } from './studyroom-details/studyroom-list/studyroom-list.component';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    StudyRoomRoutingModule,
    VgControlsModule,
    VgCoreModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgxDocViewerModule
  ],
  declarations: [
    StudyRoomComponent,
    StudyroomDetailsComponent,
    StudyroomListComponent,
  ],
  providers: [StudyRoomService]
})

export class StudyRoomModule {}
