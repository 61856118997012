import { Component, OnInit, Input, Output, Directive } from '@angular/core';
import { SearchService } from './search.service';
import { Router } from '@angular/router';
import { config, HttpLoader, AlertPopup } from 'app/app.config';
import { Subject } from 'rxjs';
// import { error } from 'console';
import { AppComponent } from 'app/app.component';
import { EventEmitter } from 'protractor';

// @Directive()
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {

  @Input() chatMsgList;
  @Output() getSearchhighlight;

  RECORD_LIMIT:number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;

  public search: string = null;
  allChatsTotalCount: number;
  tabName: string;
  modelScrollDistance = config.DEFAULT_SCROLL_DIST;
  modelScrollThrottle = config.SCROLL_THROTTLE;
  commonSearch =<any>[];


  viewControl: any = {
    changedSearchText : false
  }

  globalSearchSubject = new Subject<any>();
  searchBoxFocused:boolean = false;
  searchString:boolean = false;

  constructor(
    private searchService: SearchService,
    private router: Router,
    private httpLoader: HttpLoader,
    private app: AppComponent,
    private alert: AlertPopup
  ) {
    this.globalSearchSubject.debounceTime(500).subscribe(event => {
      this.viewControl.changedSearchText = true;
      let searchText = event.target.value;
      this.globalSearchList(searchText, this.tabName);
    })
  }

  ngOnInit(): void {
  }

  globalSearchList(search, tabName){
    this.getGlobalSearchList(search);
  }

  getGlobalSearchList(search = "", pageNo = 0){
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
        this.httpLoader.hideLoader();
        this.searchBoxFocused = false;
        this.searchString = false;
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: search || ''
      };
      if(search !=''){
        this.searchBoxFocused = true;
        this.searchString = true;
      }

      this.searchService.getAllChatSearchList(payLoad).subscribe(
        item => {
          if (offset === 0) {
            this.httpLoader.hideLoader();
          }else{
            console.log(item);
            this.commonSearch.push(item);
           console.log(this.commonSearch);
            // this.globalSearch = item;
          }
        },
        error => {
          this.alert.showAlertMessage(error.error);
        }
      );
    } catch (error) {
      this.httpLoader.hideLoader();
    }
  }

  getFilterSearchWord(data){
    try {
      const offset = this.OFFSET;

      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: data || ''
      };

      this.searchService.getFilterElibrarySection(payLoad).subscribe(response => {
        console.log(response);
      })

    } catch (error) {

    }
  }

  handleSearchClose(){
    this.search =null;

    if(!this.viewControl.changedSearchText){
      return;
    }

    this.viewControl.changedSearchText = false;
    this.searchBoxFocused = false;
    this.searchString = false;
    this.getGlobalSearchList(this.search);
  }
}
