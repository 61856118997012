import { Component, OnInit, HostBinding, Input, Directive  } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GroupService } from './group.service';
import { config, path, AlertPopup, HttpLoader } from './../../../app.config';

import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';


// @Directive()
@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: []
})
export class GroupComponent implements OnInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  viewClassRoom = true;
  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  profileUrl = path.PROFILE_URL;
  sortBy = 'title';
  sort:any;
  search: string;
  listOfGroups= [];
  totalRowCount: number;
  showSearchSec = true;
  public viewSecondPanel = false;
  // viewClassRoom = true;

  showPaginateSpinner = false;
  // modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  // modalScrollThrottle = config.SCROLL_THROTTLE;

  viewControl = {
    changedSearchText: false
  };
  searchInList = new Subject<any>();

  constructor(
    private groupService: GroupService,
    private loader: HttpLoader,
    private alert: AlertPopup) {
    this.groupService.emitNewGroupCreation().subscribe((group) => {
      this.listOfGroups.unshift(group);
    });

    this.searchInList
      .debounceTime(500)
      .subscribe(event => {
        this.search = event.target.value;
        if (!this.search) {
          return;
        }
        this.viewControl.changedSearchText = true;
        this.groupListSortAndSearch(0);
        // this.getgroupListData(0);
      });
  };

  ngOnInit() {
    // this.getgroupListData(0);
    this.groupListSortAndSearch(0);
  };


  // getgroupListData(pageNo) {
  //   // try {
  //   //   this.loader.showLoader();
  //   //   this.groupService.getClassRoomList().subscribe(
  //   //     (lists) => {
  //   //       this.loader.hideLoader();
  //   //       this.listOfGroups = lists['result'];
  //   //       this.totalRowCount = lists['TotalUserCount'];
  //   //     },
  //   //     error => {
  //   //       this.loader.hideLoader();
  //   //       this.alert.showAlertMessage(error.error);
  //   //     });
  //   // } catch (err) {
  //   //   this.loader.hideLoader();
  //   //   console.error(err);
  //   // }

  //     try {
  //     if (pageNo === 0) {
  //       this.OFFSET = 0;
  //       this.loader.showLoader();
  //     }

  //     const offset = this.OFFSET;
  //     const payLoad = {
  //       offset: this.OFFSET,
  //       limit: this.RECORD_LIMIT,
  //       sort: this.sortBy || '',
  //       search: this.search || ''
  //     };

  //     console.log(payLoad);

  //     this.groupService.getClassRoomList(payLoad).subscribe(
  //       (groupList) => {
  //         if (offset === 0) {
  //           this.loader.hideLoader();
  //           this.listOfGroups = groupList['result'];
  //           this.totalRowCount = groupList['TotalRowCount'];
  //         } else if (offset > 0) {
  //           // groupList['groupList'].map(group => this.listOfGroups.push(group));
  //           this.listOfGroups = this.listOfGroups.concat(groupList['result']);
  //           this.showPaginateSpinner = false;
  //         }
  //       },
  //       error => {
  //         this.loader.hideLoader();
  //         this.showPaginateSpinner = false;
  //         this.alert.showAlertMessage(error.error);
  //       });
  //   } catch (err) {
  //     this.loader.hideLoader();
  //   }
  // };



  groupListSortAndSearch(pageNo) {
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
        this.loader.showLoader();
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: this.OFFSET,
        limit: this.RECORD_LIMIT,
        sort: this.sortBy || '',
        search: this.search || ''
      };

      this.groupService.getClassRoomList(payLoad).subscribe(
        (groupList) => {
          if (offset === 0) {
            this.loader.hideLoader();
            this.listOfGroups = groupList['chatList'];

             this.totalRowCount = 5;

          } else if (offset > 0) {
            this.listOfGroups = this.listOfGroups.concat(groupList['chatList']);
            this.showPaginateSpinner = false;
          }
        },
        error => {
          this.loader.hideLoader();
          this.showPaginateSpinner = false;
          this.alert.showAlertMessage(error.error);
        });
    } catch (err) {
      this.loader.hideLoader();
    }
  };

  showSearchbox(toggle) {
    try {
      this.showSearchSec = !this.showSearchSec;
      if (this.viewControl.changedSearchText) {
        try {
          this.search = '';
          const offset = 0;
          this.viewControl.changedSearchText = false;
          this.groupListSortAndSearch(offset);
        } catch (err) {
          this.loader.hideLoader();
        }
      }
    } catch (err) {
      this.loader.hideLoader();
    }
  };

  showCreateStudent(){
    this.viewClassRoom = true;
  }

  hideSecondPanel(){
    this.viewSecondPanel =!this.viewSecondPanel;
  }

  /**
 * Pagination called while scroll down
 */
  onScrollDown() {
    if (this.listOfGroups.length < this.totalRowCount && !this.showPaginateSpinner) {
      this.OFFSET += 1;
      this.showPaginateSpinner = true;
      this.groupListSortAndSearch(this.OFFSET);
    }
  };

  showCreateClass(){
    this.viewClassRoom = true;
  }

}
