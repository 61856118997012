import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HomeComponent } from '../../components/home/home.component';
import { SettingsComponent } from './settings.component';
import { AuthGuardService as AuthGuard } from './../../auth-guard.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: '', component: SettingsComponent, canActivate: [AuthGuard] }
    ])
  ],
  declarations: [],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
