import { Injectable } from '@angular/core';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { config } from '../../app.config';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Subject } from 'rxjs/Subject'


@Injectable()
export class ChatService {

    baseUrl = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;

    private botViewToggle = new Subject<any>();
    private dataObs$ = new Subject();

    constructor(private http: HttpClient) { }

    getData() {
        return this.dataObs$;
    }

    updateData(data: boolean) {
        this.dataObs$.next(data);
    }

    openBotView() {
        this.botViewToggle.next();
    };

    getBotViewToggle(): Observable<any> {
        return this.botViewToggle.asObservable();
    };


    getChatList(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.baseUrl}/chats`, { params: Params });
    };

    getAllChatList(payLoad) {
        // getAllChatList(search = '') {
        // return this.http.get(`${this.baseUrl}/chats/users?search=${search}`);
        return this.http.get(`${this.baseUrl}/chats/users?offset=${payLoad.offset}&limit=${payLoad.limit}&search=${payLoad.search}`);
    };


    frequentChatList(payLoad) {
        return this.http.get(`${this.baseUrl}/chats/frequentChatList?offset=${payLoad.offset}&limit=${payLoad.limit}&search=${payLoad.search}`);
    };

    createChat(to_user_id) {
        return this.http.post(`${this.baseUrl}/chats/createChat`, to_user_id);
    };

    sendMessage(message) {
        return this.http.post(`${this.baseUrl}/chats/messages`, message);
    };

    // getMessageList(user_id, payLoad) {
    getMessageList(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.baseUrl}/chats/messages`, { params: Params });
        // return this.http.get(`${this.baseUrl}/chats/messages?userId=${user_id}&sort='date`);
    };

    getMessagePaginationList(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.baseUrl}/chats/messagesPagination`, { params: Params });
    };

    groupMessagePagination(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.baseUrl}/chats/groupMessagePagination`, { params: Params });
    };

    createGroup(chat_group_name) {
        return this.http.post(`${this.baseUrl}/chats/createChatGroup`, chat_group_name);
    };

    createGroupChat(group_id) {
        return this.http.post(`${this.baseUrl}/chats/createGroupChat`, group_id);
    };

    sendGroupMessage(message) {
        return this.http.post(`${this.baseUrl}/chats/groupMessages`, message);
    };

    getGroupMessageList(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.baseUrl}/chats/groupMessages`, { params: Params });
    };

    updateChatGroup(payLoad) {
        return this.http.put(`${this.baseUrl}/chats/chatGroup`, payLoad);
    };

    getGroupMemberList(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.baseUrl}/chats/groupMemberList`, { params: Params });
    };

    getNonGroupMemberList(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.baseUrl}/chats/getNonGroupMemberList`, { params: Params });
    };

    getMessageAttachments(payLoad) {
      const Params = this.preHttpParams(payLoad);
      return this.http.get(`${this.baseUrl}/chats/messageAttachments`, { params: Params });
    };

    getAttachmentsFileSearch(payLoad) {
      return this.http.get(`${this.baseUrl}/chats/messageAttachments?offset=${payLoad.offset}&limit=${payLoad.limit}&groupId=${payLoad.groupId}&search=${payLoad.search}`)
    };

    getMessageLinks(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.baseUrl}/chats/messageLinks`, { params: Params });
    };

    addGroupMembers(payLoad) {
        return this.http.post(`${this.baseUrl}/chats/addGroupMembers`, payLoad);
    };

    removeGroupMembers(groupId) {
        return this.http.put(`${this.baseUrl}/chats/removeGroupMembers`, groupId);
    };

    deleteChatGroupIcon(groupId) {
        return this.http.delete(`${this.baseUrl}/chats/deleteChatGroupIcon?chat_group_id=${groupId}`);
    };

    removeChat(chatId: number) {
        return this.http.put(`${this.baseUrl}/chats/removeChat/${chatId}`, '');
    };

    updateMessageRead(message: any) {
        return this.http.post(`${this.baseUrl}/chats/updateMessageRead`, message);
    };

    deleteMessage(messageId){
      // console.log(messageId);

      return this.http.delete(`${this.baseUrl}/chats/messages/${messageId}`);
    }

    getQuestions(payLoad){
      return this.http.get(`${this.baseUrl}/getQuestions/${payLoad.group_id}/${payLoad.message_id}?question_type=${payLoad.question_type}`);
    }

    getStudentTestMark(payLoad){
      return this.http.get(`${this.baseUrl}/getStudentTestMarks/${payLoad.group_id}/${payLoad.message_id}?question_type=${payLoad.question_type}`);
    }

    editUploadFileList(payLoad){
      return this.http.get(`${this.baseUrl}/currentQuestion/${payLoad.file_id}`);
    }

    getLoggedUserNotes(file_id){
      return this.http.get(`${this.baseUrl}/userNotes/${file_id}`);
    }

    updateUserNotes(payLoad, file_id){
      return this.http.patch(`${this.baseUrl}/userNotes/${file_id}`, payLoad);
    }

    submitUserNotes(payLoad, file_id){
      return this.http.post(`${this.baseUrl}/userNotes/${file_id}`, payLoad);
    }

    getUserNotesDownload(file_id){
      return this.http.get(`${this.baseUrl}/userNotes/download/${file_id}`);
    }



    submitAssessment(payLoad){
      return this.http.post(`${this.baseUrl}/submitAssessment`, payLoad);
    }

    updateUploadFileData(payLoad){
      return this.http.patch(`${this.baseUrl}/question`, payLoad);
    }

    getStudentResult(message_id, question_type){
      return this.http.get(`${this.baseUrl}/studentAssessment/${message_id}?question_type=${question_type}`)
    }

    getSurveyPollResult(message_id, question_type){
      return this.http.get(`${this.baseUrl}/studentSurveyandPolls/${message_id}?question_type=${question_type}`)
    }

    deleteassessmentQuest(payLoad){
      return this.http.delete(`${this.baseUrl}/question/${payLoad.message_id}/${payLoad.question_id}`);
    }

    deleteQuestionAnswer(payLoad){
      return this.http.delete(`${this.baseUrl}/answer/${payLoad.question_id}/${payLoad.answer_id}`);
    }



    preHttpParams(payLoad) {
        let Params = new HttpParams();
        for (const key in payLoad) {
            if (key && (payLoad[key] || payLoad[key] === 0)) {
                Params = Params.append(key, payLoad[key]);
            }
        }
        return Params;
    };
    createMeeting(data){
        return this.http.post(`${this.baseUrl}/chats/meeting`, data);
    };

    acceptedCall(data){
        return this.http.post(`${this.baseUrl}/chats/meeting/accepted`, data);
    }

    declinedCall(data) {
        console.log(data)
        return this.http.post(`${this.baseUrl}/chats/meeting/declined`, data);
    };
}
