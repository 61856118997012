import { Component, OnInit, HostBinding, Directive } from '@angular/core';

// @Directive()
@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html'
})
export class ConnectionsComponent implements OnInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  welcomeWindowName: string;

  showWelcomeWindow = true;
  showMenuView = false;
  constructor() { }



  ngOnInit() {
    // this.welcomeWindow('welcome')
  }

  welcomeWindow(name) {
    if (name) {
      this.showWelcomeWindow = true;
      this.welcomeWindowName = name;
    } else {
      this.showWelcomeWindow = false;
    }
  }

  nextView(res) {
    this.showMenuView = true;
  }

}
