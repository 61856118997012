import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';

import { SettingsRoutingModule } from './settings.routing';
import { ValidationService } from '../form-validation/validation.service';
import { SettingsComponent } from './settings.component';
import { SettingsService } from './settings.service';

import {Ng2TelInputModule} from 'ng2-tel-input';

import { ThemeService } from './theme.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SettingsRoutingModule,
    Ng2TelInputModule
  ],
  declarations: [
    SettingsComponent
  ],
  providers: [ValidationService, SettingsService,ThemeService]
})
export class SettingsModule { }
