import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
 
import { LayoutComponent } from "./layout.component";
import { AiAssistModule } from "../../components/ai-assist/ai-assist.module";
import { AuthGuardService as AuthGuard } from './../../auth-guard.service';
import { SidebarComponent } from "app/components/sidebar/sidebar.component";
import { ElibraryandcourseModule } from "../../components/elibraryandcourse/elibraryandcourse.module";
import { ChatListModule } from "app/components/chat-list/chat-list.module";
import { NotificationModule } from "../../components/notification/notification.module";
import { PageHeaderComponent } from "app/components/page-header/page-header.component";
import { VideoQuizModule } from "../../components/video-quiz/video-quiz.module";
 

export const routes:Routes = [
    {
        path:"main",
        component:LayoutComponent,
        canActivate: [AuthGuard], 
        children:[
        {
         path:"aiassist",
         loadChildren:()=>import('../../components/ai-assist/ai-assist.module').then(m=>m.AiAssistModule)
         },
         {
            path:"elibraryandcourse",
            loadChildren:()=>import('../../components/elibraryandcourse/elibraryandcourse.module').then(m=>m.ElibraryandcourseModule)
         },
          {
            path:"userandgroup",
            loadChildren:()=>import('../../components/userandgroup/userandgroup.module').then(m=>m.UserandgroupModule)
          },
          {
            path:"chats",
            loadChildren:()=>import('../../components/chat-list/chat-list.module').then(m=>m.ChatListModule)
          },
          {
            path:"channels",
            loadChildren:()=>import('../../components/notification/notification.module').then(m=>m.NotificationModule)
          },
          {
            path:"videoquiz",
            loadChildren:()=>import('../../components/video-quiz/video-quiz.module').then(m=>m.VideoQuizModule)
          },
          {
            path:"settings",
            loadChildren:()=>import('../../components/settings/settings.module').then(m=>m.SettingsModule)
          } ,
          {
            path:"feedback",
            loadChildren:()=>import('../../components/feedback/feedback.module').then(m=>m.FeedbackModule)
          },{
            path:"subscription",
            loadChildren:()=>import('../../components/subscription/subscription.module').then(m=>m.SubscriptionModule)
          }
        ]
       
    }
]
@NgModule({
    imports: [
      CommonModule,
      AiAssistModule,
      ElibraryandcourseModule,
      ChatListModule,
      NotificationModule,
      VideoQuizModule,
      RouterModule.forChild(routes)
    ],
    declarations: [
     LayoutComponent,
     SidebarComponent
     
    ],
    exports: [RouterModule],
    schemas:[CUSTOM_ELEMENTS_SCHEMA] 
  })
export class LayoutModule { }