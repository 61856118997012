import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeago'
})
export class TimeagoPipe implements PipeTransform {
  transform(value, omitSuffix?: boolean): string {
    // const res = moment(value).startOf('hour').fromNow();
    const now = moment();
    const res = moment(value).startOf('hour').fromNow()
    return res;
  }

}
