import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot.component';
import { ForgotRoutingModule } from './forgot-routing';
import { ForgotPasswordService } from './forgot.password.service';
import { SharedModule } from '../../shared/shared.module';



@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ForgotRoutingModule,
    SharedModule
  ],
  declarations: [
    ForgotPasswordComponent
  ],
  providers: [ForgotPasswordService]
})
export class ForgotModule { }
