export const THEMES = {
    default: {
      primaryColor: "#1571b9",
      primarylightColor: "#d2daf3",
      menuSelectColor:'#eceffe',
      bodyBgColor:'#f1f1f1',
      whiteColor:'#ffffff',
      secondaryHoverbg:'#f5f5f5',
      popupwindowheadbg:'#2e77ae'
    },
    steelblue:{
      primaryColor:'#1571b9',
      primarylightColor: "#b5dcff",
      menuSelectColor:'#ebf5ff',
      bodyBgColor:'#f1f1f1',
      whiteColor:'#ffffff',
      secondaryHoverbg:'#ebf5ff',
      popupwindowheadbg:'#2e77ae'
    },
    green: {
      primaryColor: "#2ecc71",
      primarylightColor: "#c4f2d7",
      menuSelectColor:'#defac8',
      bodyBgColor:'#f1f1f1',
      whiteColor:'#ffffff',
      secondaryHoverbg:'#defac8',
      popupwindowheadbg:'#defac8'
    },
    blue: {
      primaryColor: "#3498db",
      primarylightColor: "#87ccfa",
      menuSelectColor:'#bbe2fc',
      bodyBgColor:'#f1f1f1',
      whiteColor:'#ffffff',
      secondaryHoverbg:'#bbe2fc',
      popupwindowheadbg:'#bbe2fc'
    },
    orange: {
      primaryColor: "#f1c40f",
      primarylightColor: "#fff68b",
      menuSelectColor:'#fff6c1',
      bodyBgColor:'#f1f1f1',
      whiteColor:'#ffffff',
      secondaryHoverbg:'#f5f5f5',
      popupwindowheadbg:'#fff68b'
    },
    black: {
      primaryColor: "#000000",
      primarylightColor: "#a3a3a3",
      menuSelectColor:'#ebebeb',
      bodyBgColor:'#f1f1f1',
      whiteColor:'#ffffff',
      secondaryHoverbg:'#f5f5f5',
      popupwindowheadbg:'#6666'
    }
  
  };
  