import { PushNotificationsService } from './../../push-notification/push-notification-service';
import { Component, OnInit, HostBinding, AfterViewInit, AfterContentChecked, OnDestroy, Directive, Inject  } from '@angular/core';
import * as _ from 'underscore';
import { ActivatedRoute, NavigationEnd, Router,Event } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
// import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import { ChatService } from './chat.service';
import { SocketService } from './../../socket/socket.service';
import { DataSharingService } from './../../shared/data-sharing.service';
import { config, path, HttpLoader, AlertPopup } from '../../app.config';
// import { HintService } from 'angular-custom-tour';
import { AppComponent } from '../../app.component';


import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import { DOCUMENT } from '@angular/common';




// @Directive()
@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ["./chat-list.component.scss"]
})
export class ChatListComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @HostBinding('style.flex-direction') direction='column';
  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;

  search: string="";
  searchUser: string;
  sortBy = 'name';
  listSortBy = 'updatedAt';
  viewChatList = false;
  chatList: any[] = [];
  activeUserChannelList : any[] = [];
  totalRowCount: number;
  groupChatList: any[] = [];
  profileUrl = path.PROFILE_URL;
  public chatSubscribers: any = {};
  activeUrl: any;
  showSearchSec = true;
  showPaginateSpinner = false;
  welcomeWindowName: string;
  showWelcomeWindow = true;
  showMenuView = false;
  loggedUserDeatails: any;
  is_tour_completed = false;
  is_switch_user = false;
  isActiveGroupForm = false;
  allUserTotalCount: number;
  showStudentList = true;
  showClassRoomList = false;

  viewControl = {
    changedSearchText: false
  };
  searchInList = new Subject<any>();
  searchSubject = new Subject<any>();
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  userList: any[] = [];
  userIdList: number[] = [];

  public userSubscribers: any = {};

  singleSelect: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private chatService: ChatService,
    private socketService: SocketService,
    private loader: HttpLoader,
    private alert: AlertPopup,
    private appComponent: AppComponent,
    
    private dataSharingService: DataSharingService,

    private _notificationService: PushNotificationsService,
    @Inject(DOCUMENT) private document:Document) {

    this.searchInList
      .debounceTime(500)
      .subscribe(event => {
        this.search = event.target.value;
        if (!this.search) {
          return;
        }
        this.viewControl.changedSearchText = true;
        this.getChatList(0);
      });

      this.searchSubject.debounceTime(500).subscribe(
        event => {
          this.viewControl.changedSearchText = true;
          const searchText = event.target.value;
          this.searchUserList(searchText);
        }
      )
  }



  ngOnInit() {
    this.welcomeWindow('welcome');
    this.getChatList(0);
    // this.socketService.initiateSocket();
    this.route.url.subscribe(() => {
      this.activeUrl = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.url[0].path : false;
      console.log("activeUrl",this.activeUrl);
      this.viewChatList = false;
   });

    this.getAllChatList(this.searchUser);
  
  }


  ngAfterViewInit() {
    /** subscribe user online staus */

    this.chatSubscribers.newChatSub = this.dataSharingService.getNewChatsNotify().subscribe(newChat => {
      if (newChat) {
        const existingChatList = [...this.chatList];
        existingChatList.unshift(newChat);
        this.chatList = existingChatList;
        this.totalRowCount += 1;
      }
    });

    this.chatSubscribers.statusUpdate = this.dataSharingService.getUserstatusUpdate().subscribe((status) => {
      const copyUserList = [...this.chatList];
      const findIndex = copyUserList.findIndex(el => el.chat_type === 'user' && el.user_id === status.user_id);
      if (findIndex >= 0) {
        const copyObj = copyUserList[findIndex];
        copyObj.status_name = status.status_name;
        copyObj.user_status = status.status_id;
        copyUserList[findIndex] = copyObj;
      }
      this.chatList = copyUserList;
    });


    this.chatSubscribers.userStatusSub = this.dataSharingService.getUserStatusNotify().subscribe(userStatus => {
      const copyUserList = [...this.chatList];
      const findIndex = copyUserList.findIndex(el => el.chat_type === 'user' && el.user_id === userStatus.id);
      if (findIndex >= 0) {
        const copyObj = copyUserList[findIndex];
        copyObj.is_online = !userStatus.userDisconnected;
        copyObj.last_seen = userStatus.t;
        copyObj.status_name = userStatus.status_name;
        copyObj.user_status = userStatus.user_status;
        copyUserList[findIndex] = copyObj;
      }
      this.chatList = copyUserList;
    });




    this.chatSubscribers.userMessageSub = this.dataSharingService.getUserMessage().subscribe(newMessage => {
      if (newMessage.message) {
        if (!this.activeUrl || this.activeUrl === 'newchat') {
          this.incrementMessageCount(newMessage.message);
        }
      }
    });

    /* Subscribe new group message from socket emit */
    this.chatSubscribers.groupMessageSub = this.dataSharingService.getGroupMessage().subscribe(newMessage => {
      const response = newMessage.message;
      if (response) {
        if (!this.activeUrl || this.activeUrl === 'newchat') {
          if (response && response.chat_type === 'group') {
            this.incrementMessageCount(response);
          }
        }
      }
    });

    this.chatSubscribers.userTypingUpdate = this.dataSharingService.getTypingStatusNotify().subscribe(status => {
      if (status) {
        this.addMessageTyping(status);
      }
    });

    /* Active user company name */
    // this.chatService.getChannelList().subscribe(channelList => {
    //   if(channelList){
    //     // console.log(channelList['result'][0]['id']);

    //     this.singleSelect = channelList['result'][0]['id'];
    //     this.activeUserChannelList = channelList['result'];
    //   }
    // })

    this.userSubscribers.userStatusSub = this.dataSharingService.getUserStatusNotify().subscribe(
      userStatus => {
        const copyUserList = [...this.userList];
        const findIndex = copyUserList.findIndex(el => el.user_id ===userStatus.id);
        if(findIndex >=0){
          const copyObj = copyUserList[findIndex];
          copyObj.is_online= !userStatus.userDisconnected;
          copyObj.last_seen= userStatus.t;
          copyObj.status_name = userStatus.status_name;
          copyObj.user_status = userStatus.user_status;
          copyUserList[findIndex] = copyObj;
        }

        this.userList = copyUserList;
      }
    )

  };

  fetchNextUserList(){

  }



  selectedUser(userId) {
    try {
      if (userId) {
        if (this.userIdList.indexOf(userId) > -1) {
          this.userIdList = _.without(this.userIdList, userId);
        } else {
          this.userIdList.push(userId);
        }
      }

    } catch (err) {
      console.log(err);
    }
  };

  ngAfterContentChecked() {

    if (_.isEqual(this.loggedUserDeatails, this.appComponent.userDetail)) {
      return;
    }

    if (_.isObject(this.appComponent.userDetail)) {
      this.loggedUserDeatails = this.appComponent.userDetail;
      this.is_tour_completed = !this.loggedUserDeatails.is_tour_completed
    }
  };

  welcomeWindow(name) {
    if (name) {
      this.showWelcomeWindow = true;
      this.welcomeWindowName = name;
    } else {
      this.showWelcomeWindow = false;
    }
  }

  showOnboarding() {
    // this.hintService.initialize({ elementsDisabled: true });
    this.showWelcomeWindow = false;
  }

  swichUser(data) {
    // this.is_switch_user = false;
    this.chatService.updateData(data);
    // console.log(data);
  }

  /** User to increment message count */
  incrementMessageCount(response) {
    if (response && response.chat_type === 'user') {
      const copyUserList = [...this.chatList];
      const findIndex = copyUserList.findIndex(el => el.chat_type === 'user' && el.user_id === response.from_user_id);
      const copyObj = copyUserList[findIndex];
      if (findIndex >= 0) {
        copyObj.last_message = response.message;
        if (copyObj.unread_msg_count >= 0) {
          copyObj.unread_msg_count += 1;
        } else {
          copyObj.unread_msg_count = 1;
        }
        copyUserList[findIndex] = copyObj;
      } else {
        copyUserList[0] = copyObj;
      }
      this.chatList = copyUserList;
    }

    if (response && response.chat_type === 'group') {
      const copyUserList = [...this.chatList];
      const findIndex = copyUserList.findIndex(el => el.chat_type === 'group' && el.group_id === response.group_id);
      const copyObj = copyUserList[findIndex];

      if (findIndex >= 0) {
        copyObj.last_message = response.message;
        if (copyObj.unread_msg_count >= 0) {
          copyObj.unread_msg_count += 1;
        } else {
          copyObj.unread_msg_count = 0;
        }
        copyUserList[findIndex] = copyObj;
      } else {
        copyUserList[0] = copyObj;
      }
      this.chatList = copyUserList;
    }

  };

  /** User to increment message count */
  addMessageTyping(response) {
    if (response) {
      const copyOfChatList = [...this.chatList];
      copyOfChatList.find(user => {
        if ((user.user_id === response.from_user_id && !response.group_id) || (user.group_id === response.group_id && response.from_user_id)) {
          user.isTyping = response.isTyping;
          user.is_typing_msg = response.is_typing_msg;
          return user;
        }
      });
      this.chatList = copyOfChatList;
    }
  };

  /** User to increment message count */
  updateLastMessageinChat(response, Id) {
    if (response && response.chat_type === 'user') {
      const copyUserList = [...this.chatList];
      const findIndex = copyUserList.findIndex(el => el.chat_type === 'user' && el.user_id === Id);
      const copyObj = copyUserList[findIndex];
      copyObj.file_id = response.file_id;
      copyObj.last_message = response.message;
      copyObj.updatedAt = moment().format();
      copyUserList[findIndex] = copyObj;
      this.chatList = copyUserList;
    }
    if (response && response.chat_type === 'group') {
      const copyUserList = [...this.chatList];
      const findIndex = copyUserList.findIndex(el => el.chat_type === 'group' && el.group_id === Id);
      const copyObj = copyUserList[findIndex];
      copyObj.file_id = response.file_id;
      copyObj.last_message = response.message;
      copyObj.updatedAt = moment().format();
      copyUserList[findIndex] = copyObj;
      this.chatList = copyUserList;
    }
  };


  /** emit Update Delivery once received new message */
  emitUpdateDelivery(response) {
    let updateDelivery = {};
    updateDelivery = _.pick(response, 'message_id', 'chat_type', 'from_user_id', 'to_user_id');
    this.socketService.updateDeliveredMessage(updateDelivery);
  };

  getChatList(pageNo) {
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
        this.loader.showLoader();
      }
      this.listSortBy = (this.sortBy === 'time') ? 'updatedAt' : 'full_name';
      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: this.search || '',
        sort: this.sortBy
      };
      this.chatService.getChatList(payLoad).subscribe(
        response => {
          if (offset === 0) {
            this.loader.hideLoader();
            this.chatList = response['chatlist']
          } else if (offset > 0) {
            this.showPaginateSpinner = false;
            this.chatList = this.chatList.concat(response['chatlist']);
          }
          this.totalRowCount = response['TotalRowCount'];
        },
        (error) => {
          this.alert.showAlertMessage(error.error);
          this.showPaginateSpinner = false;
          this.loader.hideLoader();
        }
      )
    } catch (err) {
      this.loader.hideLoader();
    }
  };

  showSearchbox(toggle) {
    this.showSearchSec = !this.showSearchSec;
    if (this.viewControl.changedSearchText) {
      try {
        this.search = '';
        const offset = 0;
        this.viewControl.changedSearchText = false;
        this.getChatList(offset);
      } catch (err) {
        this.loader.hideLoader();
      }
    }
  };

  /** Pagination called while scroll down */
  onScrollDown() {
    const listLength = this.chatList.length;
    if (this.totalRowCount > listLength && !this.showPaginateSpinner) {
      this.OFFSET += 1;
      this.showPaginateSpinner = true;
      this.getChatList(this.OFFSET);
    }
  };


  removeChat(chatId, index) {
    try {
      this.loader.showLoader();
      const currentChat = { ...this.chatList[index] };
      let firstRoutePath = null;
      if (this.route.snapshot && this.route.snapshot.firstChild) {
        firstRoutePath = this.route.snapshot.firstChild.url[0].path;
      }
      let routeParamId = null
      if (this.route.firstChild) {
        routeParamId = +this.route.firstChild.snapshot.params['id'];
      }
      this.chatService.removeChat(chatId).subscribe(
        response => {
          this.chatList.splice(index, 1);
          this.loader.hideLoader();
          if (firstRoutePath === 'singleuser' && currentChat.user_id === routeParamId) {
            this.router.navigate(['/main/chats']);
          }
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.loader.hideLoader();
        }
      )
    } catch (err) {
      this.loader.hideLoader();
      console.log(err);
    }
  };

  createGroupName(){
    this.isActiveGroupForm =! this.isActiveGroupForm
  }

  hideGroupWindow(){
    this.isActiveGroupForm = false;
  }

  createNewGroup(){

  }

  activeStudentList(){

  }

  activeClassList(){

  }

  clearSearchUserList(){
    this.searchUser = null;
    if(!this.viewControl.changedSearchText){
      return;
    }
    this.viewControl.changedSearchText = false;
    this.getAllChatList(this.searchUser);
  }

  searchUserList(searchUser){
    this.getAllChatList(searchUser);
  }

  getAllChatList(searchUser = '', pageNo = 0){
    try {
      if (pageNo===0) {
        this.OFFSET = 0;
        this.loader.hideLoader();
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset:offset,
        limit: this.RECORD_LIMIT,
        search: searchUser || ''
      };

      this.chatService.getAllChatList(payLoad).subscribe(
        list => {
          if(offset === 0){
            this.loader.hideLoader();
            this.userList = list['userList'];
          }else{
            this.userList = [...this.userList.concat(list['userList'])];
          }
          this.allUserTotalCount = list['TotalRowCount'];
          this.showPaginateSpinner = false;
        },
        err => {
          this.alert.showAlertMessage(err.error);
          this.showPaginateSpinner = false;
          this.loader.hideLoader();
        }
      )
    } catch (error) {
      this.loader.hideLoader();
    }
  }

  /**
   * NgFor track by function
   * @param index
   * @param item
   */
  trackByFn(index, item) {
    return item.chat_id;
  }

  ngOnDestroy() {
    this.searchInList.unsubscribe();
    if (this.chatSubscribers) {

      if (_.isObject(this.chatSubscribers.userStatusSub)) {
        this.chatSubscribers.userStatusSub.unsubscribe();
      }

      if (_.isObject(this.chatSubscribers.newChatSub)) {
        this.chatSubscribers.newChatSub.unsubscribe();
      }

      if (_.isObject(this.chatSubscribers.userMessageSub)) {
        this.chatSubscribers.userMessageSub.unsubscribe();
      }

      if (_.isObject(this.chatSubscribers.groupMessageSub)) {
        this.chatSubscribers.groupMessageSub.unsubscribe();
      }

      if (_.isObject(this.chatSubscribers.statusUpdate)) {
        this.chatSubscribers.statusUpdate.unsubscribe();
      }
    }
  };

  // selectCompany($event){
  //   const payLoad = {
  //     "company_id" : $event
  //   }
  //   this.loader.showLoader();
  //   this.chatService.changeChannel(payLoad).subscribe((response) => {
  //     if(response){
  //       console.log(response);
  //       window.localStorage.token = JSON.stringify(response['token']);
  //       this.ngOnInit();
  //       this.loader.hideLoader();
  //     }
  //   })
  // }


}
