import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HttpModule } from '@angular/http';

import { LoginComponent } from '../../components/login/login.component';
import { LoginService } from './login.service';
import { LoginRoutingModule } from './login-routing.module';
import { LoaderService } from './../../loader/loader.service';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoginRoutingModule,
    SharedModule
    // HttpModule
  ],
  declarations: [
    LoginComponent
  ],
  providers: [LoginService, LoaderService]
})
export class LoginModule { }
