import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  constructor( @Inject(DOCUMENT) private document:Document,){
    
  }

  toggleSideMenu(){
    let sidebar = this.document.querySelector('.sidebar');
       sidebar.classList.toggle('show');
   }
}
