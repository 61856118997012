import { Component, OnInit, HostBinding, Input, AfterContentChecked, ViewChild, ElementRef, Directive, Renderer2  } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import { UserService } from './user.service';
import { config, path, AlertPopup, HttpLoader, BASE_URI } from './../../../app.config';
import { HttpEventType, HttpResponse, HttpClient, HttpRequest } from '@angular/common/http';

import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'underscore';
import { AppComponent } from 'app/app.component';
 
import { NewUserComponent } from './new-user/new-user.component';
import { saveAs as importedSaveAs } from 'file-saver';

import { ConfirmationDialogService } from 'app/confirmation-dialog/confirmation-dialog.service';
import { ValidationService } from 'app/components/form-validation/validation.service';
import { FileValidationService } from 'app/shared/file-validation.service';
import { GroupService } from '../group/group.service';
import { ChatService } from 'app/components/chat-list/chat.service';




// @Directive()
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, AfterContentChecked {
 @HostBinding('class.flex-grid') leftBarClass = true;
 @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('excelFile') excelFile: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('input1') inputEl: ElementRef;
  public viewActionBtn = true;
  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  profileUrl = path.PROFILE_URL;
  sortBy = 'name';
  search: string;
  listOfUsers: any[] = [];
  totalRowCount: number;
  showBtnSpinner = false;
  public showSearchSec = true;
  public viewSecondPanel = false;
  viewUserList = false;

  isActiveGroupForm = false;
  newClassRoom : FormGroup;
  welcomeWindowName: string;
  showWelcomeWindow = true;
  showMenuView = false;
  loggedUserDeatails: any;
  is_tour_completed = false;
  activeUrl:any;


  showPaginateLoader = false;

  viewControl = {
    changedSearchText: false
  };
  searchInList = new Subject<any>();

  /** User Data With excel file */
  // hideFormBtn: boolean;
  // showFormView: boolean;
  excelFileList: any;
  excelFileOpt: any = {
    percent: 0,
    showProgress: false
  };

  userCreationViewCtrl = {
    addUserBtn: false,
    showForm: true,
    showTable: false,
    showFileChoosen: false,
    startUpload: false
  };

  excelUserList: any = [];
  bulkUserFileName: string;

  // fileLoadedPercentage: number;
  uploadSpinner = false;
  uploaderResponse: any;

   /** specifying Base URL.*/
   public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${
    config.VERSION
    }/users`;

    //user
    newUserForm: FormGroup=new FormGroup({});
    roleList: any[] = [];
    user_role:any;
    showPopModal:boolean=false;
    editedUserId:any;
    selectedFileList: any[] = [];
    profileExtension = config.PROFILE_EXT;
    profileSize = config.PROFILE_SIZE;
    preProfileUrl = path.PRE_PROFILE_URL;
    updatedUserDetail: any = {};
    selectedTableName:string;
    cuntryCode:number;

    page: number = 0;
    //Group
    newGroupForm:FormGroup = new FormGroup({});
    listOfGroups:any[]= [];
    selectedIdList: number[] = [];
    userList: any[] = [];
    userIdList: number[] = [];
    user_Ids: number[] = [];
    editedGroupId: any=null;
    groupMemberList:any[] = [];
    updatedGroupList: any = {};
    toggleModalSection:boolean = false;
  


  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private httpLoader: HttpLoader,
    private alert: AlertPopup,
    private formBuilder: FormBuilder,
    private appComponent: AppComponent,
    private confirmDialogService:ConfirmationDialogService,
    private http: HttpClient,
    private fb:FormBuilder,
    private fileValidationService: FileValidationService,
    private groupService: GroupService,
    private chatService: ChatService,
    private renderer:Renderer2
    // private newUserComponent: NewUserComponent
    ) {

      this.newClassRoom = formBuilder.group({
        class_name: [null, Validators.compose([Validators.required])]
      });


    this.searchInList
      .debounceTime(500)
      .subscribe(event => {
        this.search = event.target.value;
        // if (!this.search) {
        //   return;
        // }
        this.viewControl.changedSearchText = true;
        if(this.selectedTableName=="user"){
          this.getUserListSortAndSearch(0);
        }else{
          this.groupListSortAndSearch(0);
        }
        
      });
  }


  ngOnInit() {
   // this.welcomeWindow('welcome');
    this.selectedTableName = "user";
   
   //this.getuserList();
    // this.route.url.subscribe(() => {
    //  this.activeUrl = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.url[0].path : false;
    //   this.viewUserList = false;
    //   this.viewSecondPanel = false;
    // });

    this.loadUserForm();
    this.getDefaultLookup() ;
    this.getUserListSortAndSearch(0);
  }

  loadUserForm(){
    this.newUserForm = this.fb.group({
      first_name: ['', Validators.compose([Validators.required, ValidationService.nameValidator])],
      last_name: ['',Validators.compose([Validators.required, ValidationService.noWhitespaceValidator, ValidationService.namePattern])],
      user_role: ['', Validators.required],
      phone_no: ['', Validators.compose([Validators.required, ValidationService.phoneNoValidate, ValidationService.noWhitespaceValidator])],
      email_id: ['', Validators.compose([Validators.required, ValidationService.emailValidator])],
      // gender: [''],
      user_address: [''],
      user_city: [''],
      user_state: [''],
      user_zip: [''],
      user_notes: [''],
      roll_number:[''],
      reg_number:[''],
      aadhar_number:[''],
      pan_number:[''],
      emis_number:[''],
      other_ids:['']
    });

    this.newGroupForm = this.fb.group({
      chat_group_name: ['', Validators.compose([Validators.required, ValidationService.noWhitespaceValidator])],
       chat_description: [''],
       
    });
  }

  ngAfterContentChecked() {

    // if (_.isEqual(this.loggedUserDeatails, this.appComponent.userDetail)) {
    //   return;
    // }

    // if (_.isObject(this.appComponent.userDetail)) {
    //   this.loggedUserDeatails = this.appComponent.userDetail;
    //   this.is_tour_completed = !this.loggedUserDeatails.is_tour_completed
    // }
  }

  
  getDefaultLookup() {
    try {
      this.httpLoader.showLoader();
      this.userService.getDefaultLookup().subscribe(
        data => {
          this.httpLoader.hideLoader();
          this.roleList = data['roleList'];
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.httpLoader.hideLoader();
        }
      );
    } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
    }
  }


  getuserList() {
    try {
      const payLoad = {
        offset: 0,
        limit: this.RECORD_LIMIT
      };
      this.httpLoader.showLoader();
      this.userService.getUserList(payLoad).subscribe(
        lists => {
          
          this.httpLoader.hideLoader();
          this.listOfUsers = lists['userList'];
         this.totalRowCount = lists['TotalRowCount'];
         
        },
        error => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        }
      )
    } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
    }
  };

  showSearchbox(toggle) {
    this.showSearchSec = !this.showSearchSec;
    // if (toggle === 'close' && this.search) {
    if (this.viewControl.changedSearchText) {
      try {
        this.search = '';
        const offset = 0;
        this.viewControl.changedSearchText = false;
        this.getUserListSortAndSearch(offset);
      } catch (err) {
        this.httpLoader.hideLoader();
      }
    }
  };

  getUserListSortAndSearch(pageNo) {
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
        this.httpLoader.showLoader();
      }else{
        this.OFFSET = pageNo;
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        sort: this.sortBy,
        search: this.search || ''
      };
      this.userService.getSortAndSearch(payLoad).subscribe(
        userList => {
         // if (offset === 0) {
            this.httpLoader.hideLoader();
            this.listOfUsers = userList['userList'];
            this.totalRowCount = userList['TotalRowCount'];
          //} 
          // else if (offset > 0) {
          //   this.listOfUsers = this.listOfUsers.concat(userList['userList']);
          // }
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.httpLoader.hideLoader();
        }
      )
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  };

  /**
  * Pagination called while scroll down
  */
  // onScrollDown() {
  //   if (this.listOfUsers.length < this.totalRowCount) {
  //     this.OFFSET += 1;
  //     return this.getUserListSortAndSearch(this.OFFSET);
  //   } else {
  //     return Observable.empty<Response>();
  //   }
  // };


  onScrollDown() {
    if (this.listOfUsers.length < this.totalRowCount && !this.showPaginateLoader) {
      this.OFFSET += 1;
      const payLoad = {
        offset: this.OFFSET,
        limit: this.RECORD_LIMIT,
        sort: this.sortBy,
        search: this.search || ''
      };

      this.showPaginateLoader = true;
      this.userService.getSortAndSearch(payLoad).subscribe(
        response => {
          this.listOfUsers = this.listOfUsers.concat(response['userList']);
          this.showPaginateLoader = false;
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.showPaginateLoader = false;
        });
    }
  }

  createGroupName(){
    this.isActiveGroupForm =! this.isActiveGroupForm
  }

  showCreateStudent(){
    this.viewActionBtn = true;
  }

  showGroupName(){

  }

  hideSecondPanel(){
    this.viewSecondPanel = !this.viewSecondPanel;
  }

  hideGroupWindow(){
    this.isActiveGroupForm = false;
  }

  createNewGroup(formVal){
    try {
      const payLoad = {
        "class_name": formVal['class_name']
      };

      this.userService.addNewClass(payLoad).subscribe(response => {
        this.alert.showAlertMessage(response);
        this.isActiveGroupForm = false;
        this.newClassRoom.reset();

      }, error => {
        this.alert.showAlertMessage(error.error);
      })
    } catch (error) {
      console.log(error);

    }
  }

  /** Active Teacher help Desk */
  welcomeWindow(name) {

    if (name) {
      this.showWelcomeWindow = true;
      this.welcomeWindowName = name;
    } else {
      this.showWelcomeWindow = false;
    }
  }

  showOnboarding() {
   // this.hintService.initialize({ elementsDisabled: true });
    this.showWelcomeWindow = false;
  }


  /**
   * Send mail to all listed and active user
   */
  sendMailtoUser(usersList) {
    const userIds = [];
    for (let index = 0; index < usersList.length; index++) {
      userIds.push(usersList[index]['user_id']);

    }

    const payLoad = {
      include_all: true,
      excludeAddress: [],
      domain: '',
      activeUserIds : userIds
    };
    this.showBtnSpinner = true

    this.userService.emitEmailToAllUsers(payLoad).subscribe(
      response => {
        this.showBtnSpinner = false;
        this.alert.showAlertMessage(response);
      },
      error => {
        this.showBtnSpinner = false;
        this.alert.showAlertMessage(error.error);
      }
    )
  };

  /**
   * @description Upload excewl file with user details and Parse it back
   * @author  Baskaran R
   * @created Date 21-12-2018
   *
   */

  excelFileUploadSectionHandler() {
    this.userCreationViewCtrl.addUserBtn = true;
    this.userCreationViewCtrl.showForm = false;
    this.userCreationViewCtrl.showFileChoosen = true;
    this.userCreationViewCtrl.showTable = false;
    this.excelUserList = [];
  }

  onCancelFileUploadSection() {
    this.excelFileList = null;
    this.userCreationViewCtrl.addUserBtn = false;
    this.userCreationViewCtrl.showForm = true;
    this.userCreationViewCtrl.showFileChoosen = false;
    this.userCreationViewCtrl.showTable = false;
    this.selectedTableName="user";
    // this.showFormView = true;
    // this.hideFormBtn = false;
  }

  onExcelFileChoosen(event) {
    const files = event.target.files[0];
    if (!_.isObject(files)) {
      return;
    }
    this.excelFileList = files;
    // this.excelFile.nativeElement.value = '';
  }

  onClearExcelFileChoosen() {
    this.excelFileList = null;
    this.excelFile.nativeElement.value = null;
  }

  onUploadFileProcess() {
    const URL = `${BASE_URI.URL}/users/xls-file-parser`;
    const formData: FormData = new FormData();
    const file = this.excelFileList;
    formData.append('file', file);

    const req = new HttpRequest('POST', URL, formData, {
      reportProgress: true
    });
    this.excelFileOpt.showProgress = true;
    // this.excelFileOpt.showProgress = true;
    this.http.request(req).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.excelFileOpt.percent = Math.round(
            (100 * event.loaded) / event.total
          );
        }

        if (event.type === HttpEventType.Response) {
          this.excelFileOpt.showProgress = false;
          this.excelFileOpt.percent = 0;
          this.excelFileList = null;

          this.userCreationViewCtrl.addUserBtn = false;
          this.userCreationViewCtrl.showForm = false;
          this.userCreationViewCtrl.showFileChoosen = false;
          this.userCreationViewCtrl.showTable = true;

          const eventBody = event.body['userList'];
          // this.alert.showAlertMessage(event.body);
          this.excelUserList = eventBody;
          this.bulkUserFileName = event.body['fileName'];
        }
      },
      error => {
        this.excelFileOpt.showProgress = false;
        this.excelFileOpt.percent = 0;
        this.alert.showAlertMessage(error.error);
      }
    );
  }

  cancelUserList() {
    this.excelFileUploadSectionHandler();
  }

  removeUserFromListHandler(user) {
    if (user) {
      const userListCopy = [...this.excelUserList];
      const filteredUserList = userListCopy.filter(
        el => el.email_id !== user.email_id
      );
      this.excelUserList = filteredUserList;

      if (this.excelUserList.length === 0) {
        this.excelFileUploadSectionHandler();
      }
    }
  }

  createBulkUser() {
    const payLoad = this.excelUserList;
    if (!_.isArray(payLoad)) {
      return;
    }
    this.userCreationViewCtrl.startUpload = true;
    this.userService.bulkUserCreate(payLoad).subscribe(
      response => {
        const userList = [
          ...this.listOfUsers,
          ...response['createdResult']
        ];
       // this.userCreationViewCtrl.startUpload = false;
        //this.listOfUsers = userList;
        this.alert.showAlertMessage(response);
        this.excelUserList = [];
        this.onCancelFileUploadSection();
        this.getUserListSortAndSearch(0);
        this.selectedTableName="user";
        setTimeout(function(){
          window.location.reload();
        },3000);
      },
      error => {
        this.userCreationViewCtrl.startUpload = false;
        this.alert.showAlertMessage(error.error);
      }
    );
  }

  getBulkUserTemplate() {
    try {
      this.userService.getBulkUserTemplate().subscribe( blob =>  {
          importedSaveAs(blob, 'Bulk-Users-Upload-Template.xls')
        },
        error => console.log(error)
        );
    } catch (e) {
      console.log(e);
    }
  }


  changeUserStatus(event:any,user:any){
     const confirmMessage = user.acc_active ? 'deactivate' : 'activate';
 
    this.confirmDialogService.openConfirmDialog('',{
      title:'Confirmation',
      message:`Are you sure want to ${confirmMessage} user?`,
      btnOkText:'YES',
      btnCancelText:'NO',
      onClose: (data:any) => {
          if(data){
          const id = user['user_id'];
          const index = _.findIndex(this.listOfUsers, { user_id: id });
          this.userService.activateAndDeactiveUser(id).subscribe(
              response => {
                if(response['success']==true){
                  this.httpLoader.hideLoader();
                  this.alert.showAlertMessage(response);
                 // this.acc_active = response['acc_active'];
                  const userList = [...this.listOfUsers];
                 // this.getUserListSortAndSearch(0);
                  const userObj = userList.find(el => el.user_id === id);
                  // userObj.acc_active = response['acc_active'];
                  userList[index] = userObj;
                  this.listOfUsers = userList;
                  // window.location.reload();
                }else{
                  this.httpLoader.hideLoader();
                  this.alert.showAlertMessage(response);
                 // this.acc_active = response['acc_active'];
                  const userList = [...this.listOfUsers];
                  const userObj = userList.find(el => el.user_id === id);
                  userObj.acc_active = response['acc_active'];
                  userList[index] = userObj;
                  this.listOfUsers = userList;
                }
              },
              error => {
                console.log(error);

                  this.httpLoader.hideLoader();
                  this.alert.showAlertMessage(error.error);
              })
          }else{
           this.getuserList();
           
           this.httpLoader.hideLoader();
          }
      }	  
  });
  }


  deleteUser(user:any){
     try {
            this.confirmDialogService.openConfirmDialog('',{
                title:'Confirmation',
                message:'Are you sure want to delete user?',
                btnOkText:'Yes',
                btnCancelText:'No',
                data: {},
                onClose: (data:any) => {
                  if(data){
                    this.httpLoader.showLoader();
                        const id = user['user_id'];
                        const index = _.findIndex(this.listOfUsers, { user_id: id });
                        this.userService.deleteUserDetail(id).subscribe(
                            response => {
                                this.httpLoader.hideLoader();
                                this.getUserListSortAndSearch(0);
                                this.alert.showAlertMessage(response);
                            },
                            error => {
                                this.httpLoader.hideLoader();
                                this.alert.showAlertMessage(error.error);
                            }
                        )
                  }else{
                  
                    this.httpLoader.hideLoader();
                  }
                } 
              });
          
        } catch (err) {
            this.httpLoader.hideLoader();
        }
  }

  editUser(user:any){
    this.editedUserId = user['user_id'];
    this.userService.getUserDetail(user['user_id'])
                .subscribe(user => {
                    this.httpLoader.hideLoader();
                    this.roleList = user['roleList'];
                    this.user_role = parseInt(user['userDetail']['user_role']);
                  //  this.editedUserId = user['userDetail'] ? user['userDetail'].user_id : null;
                    if (user['userDetail']) {
                        this.updatedUserDetail = {
                            first_name: user['userDetail'].first_name,
                            user_icon: user['userDetail'].user_icon
                        }

                        // this.acc_active = user['userDetail'].acc_active;
                        // this.isEmailVerfied = user['userDetail'].is_email_verified;
                 
                          this.newUserForm.patchValue(user['userDetail']);
                    }
                    this.showPopModal = true;
                   
                },
                error => {
                    this.httpLoader.hideLoader();
                    console.log(error);
                })
  }


  updateUserDetail(userVal){
    const id = this.editedUserId;
    if(id==null){
      try {
        this.httpLoader.showLoader();
        // user.groupIdList = this.groupIdList;
        // user.fileData = this.preUploadedFile;
  
        userVal.role_name = this.roleList.find(el=>el.role_id==userVal.user_role).role_name;
  
        this.userService.addNewUser(userVal).subscribe(
          data => {
              const userId = data['createdUser'] ? data['createdUser'].user_id : 0;
            if (userId) {
              this.listOfUsers.unshift(data['createdUser']);
              this.selectedFileList = [];
             // this.preUploadedFile = null;
              //this.groupIdList = [];
              this.httpLoader.hideLoader();
              this.alert.showAlertMessage(data);
            }
            this.getUserListSortAndSearch(0);
            this.newUserForm.reset();
            this.editedUserId = null;
            this.showPopModal = !this.showPopModal;
            // this.newUserForm.controls.gender.patchValue('M');
           // this.newUserForm.controls.user_role.patchValue('6');
          },
          error => {
             this.selectedFileList = [];
           // this.preUploadedFile = null;
           this.editedUserId = null;
            this.alert.showAlertMessage(error.error);
            this.httpLoader.hideLoader();
          }
        )
      } catch (err) {

        this.selectedFileList = [];
        //this.preUploadedFile = null;
        this.httpLoader.hideLoader();
        console.log(err);
      }
    }else{

    
    this.userService.updateUserDetail(id, userVal).subscribe(
      (user) => {
          this.newUserForm.setValue(user['updatedUser']);
          this.httpLoader.hideLoader();
           this.newUserForm.markAsPristine();
         // this.getUserListSortAndSearch(0);
          this.alert.showAlertMessage(user);
          this.showPopModal = !this.showPopModal;
      },
      error => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
      });
    }
  }



  moveUserlist(position:any) {
    if (this.listOfUsers.length  && !this.showPaginateLoader) {
      if(position=="next"){
        this.OFFSET += 1;
      }else{
        this.OFFSET -=1;
      }
     
      
      const payLoad = {
        offset: this.OFFSET,
        limit: this.RECORD_LIMIT,
        sort: this.sortBy,
        search: this.search || ''
      };
      if(this.OFFSET >= 0){
      this.showPaginateLoader = true;
      this.userService.getSortAndSearch(payLoad).subscribe(
        response => {
          
          if(response['userList'].length > 0){
            this.listOfUsers = this.listOfUsers.concat(response['userList']);
           
          }
          this.totalRowCount = response['TotalRowCount'];
          
          this.showPaginateLoader = false;
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.showPaginateLoader = false;
        });
      }
    }
  }


  onFileChange(event) {
    const files = event.target.files;

    if(this.selectedTableName=="user"){
      const selectedUserId = this.editedUserId;
      if (files && files.length > 0) {
        let formFields = {};
        if(this.editedUserId==null){
           formFields = {
            fieldName: 'user_profile',
            activity: 'user_profile_add',
            storeType: 'temp'
          }
        }else{
          formFields = {
              fieldName: 'user_profile',
              activity: 'user_profile_update',
              user_id: selectedUserId
          }
        }  
  
  
  
          this.selectedFileList.push(files[0]);
          this.fileValidationService.fileValidation(files, this.profileExtension, this.profileSize).then(validResponse => {
              this.appComponent.fileUploadWithProgess(formFields, this.selectedFileList).then((fileUpLoadResponse: any) => {
                 for (const key in fileUpLoadResponse) {
                      if (fileUpLoadResponse[key]) {
                        if(this.editedUserId==null){
                          this.updatedUserDetail.user_icon = fileUpLoadResponse[key]['file_path'];
                        }else{
                          this.updatedUserDetail.user_icon = fileUpLoadResponse[key]['user_icon'];
                        }
  
                          
                          // this.listOfUsers.find(user => {
                          //     if (user.user_id === selectedUserId) {
  
                          //         return user.user_icon = fileUpLoadResponse[key]['user_icon'];
                          //     }
                          // });
                      }
                      const successMsg = fileUpLoadResponse[key]['message'];
                      this.alert.showAlertMessage(successMsg);
                  }
                  this.selectedFileList = [];
                  this.fileInput.nativeElement.value = '';
              }).catch(error => {
                  this.selectedFileList = [];
                  this.fileInput.nativeElement.value = '';
                  this.alert.showAlertMessage(error.error);
              })
          }).catch(err => {
              this.fileInput.nativeElement.value = '';
              this.selectedFileList = [];
              err.map(li => this.alert.showAlertMessage(li));
          });
      }
    }else{
      const groupId = this.editedGroupId;
      if (files && files.length > 0) {
        let formFields = {}

        if(groupId==null){
          formFields = {
            fieldName: 'user_profile',
            activity: 'user_profile_add',
            storeType: 'temp'
          }
        }else{
          formFields = {
            fieldName: 'group_profile',
            activity: 'group_icon_update',
            chat_group_id: groupId
          }
        }
          
      this.selectedFileList.push(files[0]);
        this.fileValidationService.fileValidation(files, this.profileExtension, this.profileSize).then(validResponse => {
          this.appComponent.fileUploadWithProgess(formFields, this.selectedFileList).then((fileUpLoadResponse: any) => {
            for (const key in fileUpLoadResponse) {
              if (fileUpLoadResponse[key]) {
                this.updatedGroupList.chat_group_icon = fileUpLoadResponse[key]['groupProfile']['chat_group_icon'];
                this.listOfGroups.find(group => {
                  if (group.group_id === groupId) {
                    return group.group_icon = fileUpLoadResponse[key]['groupProfile']['chat_group_icon'];
                  }
                });
              }
            }
            this.selectedFileList = [];
            this.fileInput.nativeElement.value = '';
            this.alert.showAlertMessage(fileUpLoadResponse['message']);
          }).catch(error => {
            this.selectedFileList = [];
            this.fileInput.nativeElement.value = '';
            this.alert.showAlertMessage(error.error);
          })
        }).catch(err => {
          this.fileInput.nativeElement.value = '';
          this.selectedFileList = [];
          err.map(li => this.alert.showAlertMessage(li));
        });
      }
    }
   
}

deleteUserProfile() {
  try {
      this.httpLoader.showLoader();
      const id = this.editedUserId;
      if (id) {
          this.userService.deleteUserProfile(id).subscribe(
              response => {
                  this.httpLoader.hideLoader();
                  this.alert.showAlertMessage(response);
                  this.updatedUserDetail.user_icon = response['user_icon'];
                  this.listOfUsers.find(user => {
                      if (user.user_id === id) {
                          return user.user_icon = response['user_icon'];
                      }
                  });
              },
              error => {
                  this.httpLoader.hideLoader();
                  this.alert.showAlertMessage(error.error);
              }
          )
      }
  } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
  }
}


deleteGroupProfile() {
  try {
    const group_id = this.editedGroupId;
    if (group_id) {
      this.httpLoader.showLoader();
      this.groupService.deleteGroupProfile(group_id).subscribe(
        response => {
          this.httpLoader.hideLoader();
          this.updatedGroupList.chat_group_icon = response['groupProfile']['chat_group_icon'];
          this.listOfGroups.find(group => {
            if (group.group_id === group_id) {
              return group.group_icon = response['groupProfile']['chat_group_icon'];
            }
          });


        },
        error => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        });
    }
  } catch (err) {
    this.httpLoader.hideLoader();
    console.log(err);
  }
}

clearAddUserSection() {
  const userList = _.findWhere(this.listOfUsers, { is_changed: true });
  if (userList) {
    this.confirmDialogService.openConfirmDialog('',{
      title:'Confirmation',
      message:'Do you want to clear the modification?',
      btnOkText:'YES',
      btnCancelText:'NO',
      onClose: (data:any) => {
        if(data){
          this.selectedIdList = [];
         // this.userSearchAndPagination(0);
        } else {
          return true;
        }
      }	  
  });	
     
  }
}

closeAddUserSection() {
  this.toggleModalSection = false;
  const userList = _.findWhere(this.listOfUsers, { is_changed: true });

  if (userList) {
    this.selectedIdList = [];
   // this.userSearchAndPagination(0);
  } else {
    this.selectedIdList = [];

  }
}

createNew(){
  this.updatedUserDetail = {};
  this.newUserForm = new FormGroup({});
  this.loadUserForm();
  this.editedUserId = null;
  this.editedGroupId = null;
  this.groupMemberList=[];
  this.showPopModal = !this.showPopModal;
}

SelectTable(table:string){
  this.selectedTableName = table;
  this.search="";
  this.page = 0;
  if(this.selectedTableName=="group"){
    this.groupListSortAndSearch(0);
    this.getuserList();
  }

  if(this.selectedTableName=="user"){
    this.getUserListSortAndSearch(0);
  }
  if(this.selectedTableName=="userbulkupload"){
    this.excelFileUploadSectionHandler();
  }
}




groupListSortAndSearch(pageNo) {
  try {
    if (pageNo === 0) {
      this.OFFSET = 0;
      this.httpLoader.showLoader();
    }else{
      this.OFFSET = pageNo;
    }

    const offset = this.OFFSET;
    const payLoad = {
      offset: this.OFFSET,
      limit: this.RECORD_LIMIT,
      sort: this.sortBy || '',
      search: this.search || ''
    };

    this.groupService.getClassRoomList(payLoad).subscribe(
      (groupList) => {
          this.httpLoader.hideLoader();
          if(groupList.hasOwnProperty('chatList')){
            this.listOfGroups = groupList['chatList'];
            this.totalRowCount = groupList['TotalRowCount'];
          }else{
            this.listOfGroups = groupList['result'];
            this.totalRowCount = groupList['TotalRowCount'];
          }
       
      },
      error => {
        this.httpLoader.hideLoader();
        //this.showPaginateSpinner = false;
        this.alert.showAlertMessage(error.error);
      });
  } catch (err) {
    this.httpLoader.hideLoader();
  }
}

pageChanged(event:any){
   this.page = event;
  if(this.selectedTableName=="user"){
   this.getUserListSortAndSearch(this.page -1);
  }else if(this.selectedTableName=="group"){
    this.groupListSortAndSearch(this.page - 1);
  }

}

addNewClassRoom(group){
  if(this.groupMemberList.length==0){
    this.alert.showAlertMessage({"message":"Please add atleast one user in this lesson"});
 }else{
      try {

          this.userIdList = _.uniq(this.groupMemberList);

          this.httpLoader.showLoader();
          let ids = this.groupMemberList.map(gp=>{
            if(gp.hasOwnProperty("user_id")){
              return gp['user_id']
            }
           })
        
        group.user_Ids = ids;

        var group_name = group.chat_group_name;
        group_name = group_name.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
        group.chat_group_name = group_name.trim();

        this.chatService.createGroup(group).subscribe(
          (data)=>{
              this.httpLoader.hideLoader();
              this.alert.showAlertMessage(data);
              this.newGroupForm.reset();

              let userIdList = {...data['createdGroup']};
              userIdList.TotalUserCount = this.userIdList.length;

              var classCount = this.listOfGroups;

              if (classCount===undefined) {
                this.listOfGroups = [userIdList];

              }else{
                this.listOfGroups.unshift(userIdList);
              }

              this.userIdList = [];
              this.user_Ids = [];
              this.selectedFileList = [];
              this.showPopModal = !this.showPopModal;
          },
          error =>{
            this.alert.showAlertMessage({message: error.error['message']} );
            this.selectedFileList = [];
            this.httpLoader.hideLoader();
          }
        )

      } catch (error) {
        this.selectedFileList = [];
        this.httpLoader.hideLoader();
        console.log(error);

      }
  }
 }

 updateGroupDetail(formVal) {
  const userList = _.where(this.listOfUsers, { is_changed: true });
//if (userList && userList.length > 0) {
  const userIdList = userList.map(user => {
    return Object.assign({},
      {
        user_id: user.user_id,
        is_selected: user.is_selected
      });
  });

  formVal.userIdList = userIdList;

  let payLoad = {};
  const groupId = this.editedGroupId;
  payLoad = {
    id: groupId,
    data: formVal
  }

  this.groupService.updateGroupDetail(payLoad).subscribe(
    group => {
      this.selectedIdList = [];

      this.updatedGroupList = group['groupDetail'];
      this.groupListSortAndSearch(0);

      if (group['groupMemberList']!='') {

        this.userList.push(...group['groupMemberList']);
        // this.userList.concat([group['groupMemberList']]);
      }

      this.newGroupForm.markAsPristine();
      const pageNo = 0;
      var groupId = 0;
     // this.userSearchAndPagination(pageNo, groupId = this.editedGroupId);
      this.alert.showAlertMessage(group);
    },
    error => {
      this.alert.showAlertMessage(error.error);
    });
  // }
}


 selectUserId(index, user) {

  this.listOfUsers[index].is_selected = !this.listOfUsers[index].is_selected;
  if (this.listOfUsers[index].is_aleady_selected && this.listOfUsers[index].is_selected) {
    this.listOfUsers[index].is_changed = false;
  } else {
    this.listOfUsers[index].is_changed = true;
  }

  if (this.selectedIdList.indexOf(user.user_id) > -1 && !this.listOfUsers[index].is_selected) {
    this.selectedIdList = _.without(this.selectedIdList, user.user_id);
    return;
  } else if (this.listOfUsers[index].is_selected && !this.listOfUsers[index].is_aleady_selected) {
    // this.selectedIdList.push(user);
    return;
  }
}

openAddUserSection() {
  this.toggleModalSection = !this.toggleModalSection;
  
 var selectUserList = _.where(this.listOfUsers, { is_changed: true });

  var userIdList = selectUserList.map(user => {
     if (user.is_selected===true) {
      return Object.assign({},
        {
          user_id: user.user_id,
          is_selected: user.is_selected,
          full_name: user.first_name,
          user_icon: user.user_icon,
          is_online:user.is_online,
          master_user_id:user.master_user_id 
        });
    }
  });

   userIdList = userIdList.filter(function( x ) {
    return x !== undefined;
    });

  if (selectUserList) {
    this.userList = [this.userList[0], ...userIdList];
    this.newGroupForm.markAsDirty();
  }  
   let ids = this.groupMemberList.map((group)=> group['user_id']);
    this.listOfUsers = this.listOfUsers.map((user,index)=>
      {
         if(ids.indexOf(user['user_id']) != -1){
          return Object.assign({},
            {
              user_id: user.user_id,
              is_selected:true,
              first_name: user.first_name,
              last_name:user.last_name,
              user_icon: user.user_icon,
              userType:'exist'
             });
         }else{
          return Object.assign({},
            {
              user_id: user.user_id,
              is_selected:false,
              first_name: user.first_name,
              last_name:user.last_name,
              user_icon: user.user_icon
             });
         }
       
        });
     
}  

AddUsertoGroup(){
 let selectedUser = this.listOfUsers.filter(user=>user['is_selected']==true ).map((user:any)=>{
  console.log("users",user);
     return Object.assign({},
     {
       user_id: user.user_id,
       is_selected: user.is_selected,
       full_name: user.first_name,
       user_icon: user.user_icon,
       is_online:user.is_online,
       master_user_id:user.master_user_id 
     });
  });
   this.groupMemberList = selectedUser;
   this.groupMemberList = _.uniq(this.groupMemberList);
   this.toggleModalSection = !this.toggleModalSection;
 
}

editGroup(group:any){
  this.editedGroupId = group['group_id'];
  this.groupService.getClassRoomDetail(group['group_id'])
        .subscribe(groupList => {
          this.httpLoader.hideLoader();
          this.editedGroupId = groupList['group_detail'].chat_group_id;
           this.updatedGroupList = groupList['group_detail'];
           this.groupMemberList = groupList['groupMemberList'];

         // this.getuserList();
          for (const key in this.newGroupForm.value) {
            if (key) {
              const newObj = {};
              newObj[key] = groupList['group_detail'][key];
              this.newGroupForm.patchValue(newObj);
            }
          }
          this.showPopModal = !this.showPopModal;
        },
        error => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        });
}

deleteGroup(group){
  try {
    const id = group['group_id'];
    this.confirmDialogService.openConfirmDialog('',{
      title:'Confirmation',
      message:'Are you sure want to delete this policy library?',
      btnOkText:'YES',
      btnCancelText:'NO',
      onClose: (data:any) => {
        if(data){
          this.httpLoader.showLoader();
          const index = _.findIndex(this.listOfGroups, { group_id: id });
          this.groupService.deleteSelectedGroup(id).subscribe(
            response => {
              this.httpLoader.hideLoader();
              if(response['success']){
                this.listOfGroups.splice(index, 1);
                this.alert.showAlertMessage(response['message']);
              }
             
             },
            error => {
              this.httpLoader.hideLoader();
              this.alert.showAlertMessage(error.error);
            }
          )
        }
      }	  
    });
 
  } catch (err) {
    this.httpLoader.hideLoader();
    console.log(err);
  }

}

deleteGroupUser(user_id){
  try {

   const group_id = this.editedGroupId;

   if(this.groupMemberList.length > 1){
     this.confirmDialogService.openConfirmDialog('',{
       title:'Confirmation',
       message:'Are you sure, you want to remove the user from this lesson?',
       btnOkText:'YES',
       btnCancelText:'NO',
       onClose: (data:any) => {
         if(data){
           this.httpLoader.showLoader();
        
           let position = this.groupMemberList.findIndex(x => x.user_id==user_id);
       
          if(position != -1){
             this.groupMemberList.splice(position, 1);
           }
         this.groupService.deleteGroupUsers(group_id, user_id).subscribe(response=>{
           this.httpLoader.hideLoader();
           this.alert.showAlertMessage(response);
           this.selectedIdList = [];
           const pageNo = 0;
           var groupId = 0;
          // this.userSearchAndPagination(pageNo, groupId = this.editedGroupId);
           this.groupListSortAndSearch(0);
          //  const userId = response['result']['user_id'];
          //  let position = this.groupMemberList.findIndex(x => x.user_id===userId);

          //      if(position){
          //        this.groupMemberList.splice(position, 1);
          //      }
           // this.groupComponent.listOfGroups.splice(index, 1)
         }, error => {
           this.httpLoader.hideLoader();
           this.alert.showAlertMessage(error.error);
         });
         }
       }	  
   });
    

   }else{

     this.alert.showAlertMessage({
       message: 'Cannot delete all the user, At least one user needed in the Group/Lesson'
     });
     return false;

   }
  } catch (error) {
   this.httpLoader.hideLoader();
   console.log(error);
  }
 }
 telInputObject(obj){
  console.log(obj.s.dialCode);
  this.cuntryCode = obj.s.dialCode;
  obj.setCountry('us');
}



 

  keyDownHandler(event:any) {
    if (event.keyCode === 32 && event.target.selectionStart === 0) {
      return false;
    }
  }


}

