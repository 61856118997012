import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild, Directive } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router, Params, ParamMap } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { AlertPopup, config, HttpLoader, path } from 'app/app.config';
import { SocketService } from 'app/socket/socket.service';
import { StaffRoomComponent } from '../staff-room.component';
import { StaffRoomService } from '../staff-room.service';
import { Observable } from 'rxjs/Observable';
import * as _ from 'underscore';
import 'rxjs/add/operator/switchMap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'app/components/form-validation/validation.service';
import 'rxjs/add/operator/switchMap';
import { FileValidationService } from 'app/shared/file-validation.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// @Directive()
@Component({
  selector: 'app-topic-details',
  templateUrl: './topic-details.component.html',
  styleUrls: []
})
export class TopicDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('topicFileInput') private topicFileInput: ElementRef;
  @Output() public groupId: number;

  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;

  domElementControl = {
    showCreateNewRoom : false
  }
  activeListView = true;
  activeTileView = false;
  showBtnSpinner = false;
  assignmentActiveModal = false;
  topicDetailsList: any[] = [];
  getRoomTopicList: any = [];
  selectedRoom = {};
  public search: string;
  totalRowCount:number;
  editRoomDetails: any = null;
  roomId: number;
  topicPostName: string;
  showPaginateSpinner = false;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;


  newTopicForm: FormGroup;

  /** File upload Variables */
  upLoadedFileList: any[] = [];
  fileUploadingInProgress = false;
  topicFileExtension = config.TOPIC_EXT;
  topicFileSize = config.TOPIC_FILE_SIZE;
  preUploadedFile:any;
  preProfileUrl = path.PRE_PROFILE_URL;
  profileUrl = path.PROFILE_URL;
   /** Chat File Size limit */
   chatFileSizeLimit: number = config.MAX_ATTACH_FILE_SIZE;
   chatFileExt = config.TOPIC_EXT;
  uploadAttachFile = true;

  /** Chat File Size limit */
  attachedFileSizeLimit: number = config.MAX_ATTACH_FILE_SIZE;
  attachFileExt = config.TOPIC_EXT;

  private subscribers:any = {};
  public currentTopic;

  _domParams = {
    // showUserList: false,
    hideFilePreview: false,
};

defaultChecked = 'T';
selectedDateLabel="Session Date";
selectedTimeLabel="Session Time";
// totalRowCount = 0;

uploadFileIcons = [
    {name: "pdf", icons : '../../../../assets/images/file_types/pdf.png'},
    {name: "doc",icons : '../../../../assets/images/file_types/doc.png'},
    {name: "docx",icons : '../../../../assets/images/file_types/docx.png'},
    {name: "txt",icons : '../../../../assets/images/file_types/txt.png'},
    {name: "ppt",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: "pptx",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: "pptm",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: 'mp3',icons : '../../../../assets/images/file_types/mp3.png'},
    {name: 'mp4',icons : '../../../../assets/images/file_types/mp4.png'},
    {name: 'wav',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'avi',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'png',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'jpeg',icons : '../../../../assets/images/file_types/jpg.png'},
    {name: 'jpg',icons : '../../../../assets/images/file_types/jpg.png'},
    {name: 'html',icons : '../../../../assets/images/file_types/html.png'},
    {name: 'psd',icons : '../../../../assets/images/file_types/psd.png'},
    {name: 'json',icons : '../../../../assets/images/file_types/json.png'},
    {name: 'xlsx',icons : '../../../../assets/images/file_types/xlsx.png'},
    {name: 'xls',icons : '../../../../assets/images/file_types/xls.png'},
    {name: 'css',icons : '../../../../assets/images/file_types/css.png'},
    {name: 'zip',icons : '../../../../assets/images/file_types/zip.png'},
    {name: 'xml',icons : '../../../../assets/images/file_types/xml.png'}
];

topicType = [
  {name: 'Topic', value: 'T'},
  {name: 'Assignment',value: 'A'},
  // {name: 'Online Test',value: 'O'}
];


editorConfig: AngularEditorConfig = {
 editable : true,
 spellcheck: true,
 height: 'auto',
 minHeight: '0',
//  maxHeight: '95px',
 width: 'auto',
 minWidth: '0',
 translate: 'yes',
 enableToolbar: true,
 showToolbar: true,
 placeholder: 'Enter Topic / Assignment here....',
 defaultParagraphSeparator: '',
 defaultFontName: '',
 defaultFontSize: '',
 fonts: [
   {class: 'arial', name: 'Arial'},
   {class: 'times-new-roman', name: 'Times New Roman'},
   {class: 'calibri', name: 'Calibri'},
   {class: 'comic-sans-ms', name: 'Comic Sans MS'}
  ],
  uploadWithCredentials: false,
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    [
      'undo',
      'redo',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      // 'justifyLeft',
      // 'justifyCenter',
      // 'justifyRight',
      // 'justifyFull',
      // 'indent',
      // 'outdent',
      // 'insertUnorderedList',
      // 'insertOrderedList',
      'heading',
      // 'fontName'
    ],
    [
      // 'fontSize',
      'textColor',
      'backgroundColor',
      'customClasses',
      'link',
      'unlink',
      'insertImage',
      'insertVideo',
      'insertHorizontalRule',
      'removeFormat',
      // 'toggleEditorMode'
    ]
  ]
}
schedule_date: Date;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private staffRoomService: StaffRoomService,
    private staffRoomComponent: StaffRoomComponent,
    private httpLoader: HttpLoader,
    private socketService: SocketService,
    private alert: AlertPopup,
    private appComponent: AppComponent,
    private formBuilder: FormBuilder,
    private validateService: ValidationService,
    private fileValidationService: FileValidationService,
    // private angularEditorConfig: AngularEditorConfig
  ) {
    this.newTopicForm = formBuilder.group({
      topic: [null, Validators.compose([Validators.required])],
      notes: [null],
      schedule_date: [new Date().toISOString().split('T')[0]],
      schedule_time: [new Date().toLocaleTimeString('en-US', { hour12: false,
        hour: "numeric",
        minute: "numeric"})],
      staff_type: [null]
    })

    this.schedule_date = new Date();

  }



  ngOnInit(): void {
    this.getOneRoomDetails();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }

  editRoomName(){
    this.editRoomDetails = {...this.selectedRoom };
  }

  selectedType(index){
    if(index==1){
      this.selectedDateLabel = 'Submission Date';
      this.selectedTimeLabel = 'Submission Time';
    }else if(index==2){
      this.selectedDateLabel = 'Test of Date';
    }else{
      this.selectedDateLabel = 'Session Date';
      this.selectedTimeLabel = 'Session Time';
    }
  }

  isRestrictDate(evt:any){
    evt.preventDetault();
  }

  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }

  getCurrentTime():string {
    return new Date().toLocaleTimeString('en-US', { hour12: false,
      hour: "numeric",
      minute: "numeric"})
  }

  closeRightSidebarMobile(){
    this.staffRoomComponent.viewStaffRoomList = false;
    this.router.navigate(['/main/staffroom']);
  }

  updateRoomName(data){
    if (data && data.room_title) {
      try {
        const payLoad = {
          room_title: data.room_title,
          room_id: data.room_id
        }
        this.staffRoomService.updateRoom(payLoad).subscribe(
          response => {
            this.selectedRoom = { ...this.editRoomDetails};
            const roomList = [...this.staffRoomComponent.roomsList];
            roomList.map((el) => {
              if (el.room_id === this.editRoomDetails.room_id) {
                el.room_title = this.editRoomDetails.room_title;
              }
            });
            this.staffRoomComponent.roomsList = roomList;
            this.editRoomDetails = null;
            this.httpLoader.hideLoader();
            this.alert.showAlertMessage(response);
          },
          error => {
            this.httpLoader.hideLoader();
            this.alert.showAlertMessage(error);
          }
        )
      } catch (error) {
        console.log(error);

      }
    }
  }

  getOneRoomDetails(){
    try {
      this.subscribers.staffRoomList = this.route.paramMap
          .switchMap((params: ParamMap) =>
              this.staffRoomService.getRoomTopic(+params.get('id'))).subscribe(
          data => {
              // if(data['success']==false){
              //   this.alert.showAlertMessage(data);
              // }
              this.OFFSET = config.DEFAULT_OFFSET;
              this.selectedRoom = data['group_detail'];
              this.roomId = data['group_detail']['chat_group_id'];
              this.groupId = data['group_detail']['chat_group_id'];
              this.getRoomTopicList = data['result'];
              this.totalRowCount = data['TotalRowCount'];
              this.httpLoader.hideLoader();
              this.updateNewSubPostReadStatus(this.getRoomTopicList);
          },
          error => {
              this.router.navigate([`/main/staffroom`]);
              this.httpLoader.hideLoader();
              this.alert.showAlertMessage(error.error);

          }
          )

    } catch (err) {
        this.httpLoader.hideLoader();
    }
  }

  updateNewSubPostReadStatus(classRoomList){

  }

  openCreateNewTopic(){
    this.domElementControl.showCreateNewRoom = !this.domElementControl.showCreateNewRoom;
  }

  openCreateAssign(){
    this.assignmentActiveModal =! this.assignmentActiveModal;
  }


  async fileValidationHanlder(files) {
    try {
        const isValidate = await this.fileValidationService.fileValidation(files, this.attachFileExt, this.attachedFileSizeLimit);
        return files;
    } catch (err) {
        err.map(li => {
            const errMsg = {
                message: li
            };
            // this.alert.showAlertMessage(errMsg);
            this.alert.showAlertMessage({message: 'The file is too large and cannot be uploaded. Please reduce the size of the file and try again (max 1 GB)'});
            let button = document.querySelector(".hideBtnUpload");
            button.setAttribute('disabled', 'disabled');
        });
        return false;
    }
  };

  async onChooseFile(event){
    const files = event.target.files;

    console.log(files);

    const fileValid = await this.fileValidationHanlder(files);
    if (fileValid) {
      this.concatFileIntoArray(files);
    }
  }


  /**Select And Drag & Drop selected file form change event */
  concatFileIntoArray(files) {

    if (files && files.length > 0) {

      let button = document.querySelector(".hideBtnUpload");
      button.setAttribute('disabled','disabled');

      if(this.upLoadedFileList.length < 1){
        const formFields = {
          fieldName: 'staff_topic',
          activity: 'topic',
          // storeType: 'temp'
        };

        let fileEl = null;
        let existFileList = null;
        this.uploadAttachFile = true;
        this.fileUploadingInProgress = true;
        for (let i = 0; i < files.length; i++) {
          const splitArr = files[i].name.split('.');

          files[i].mimeType = splitArr[splitArr.length - 1].toLowerCase();
          files[i].uniqId = Date.now();
          existFileList = [...this.appComponent.selectedFilesList];

          if(fileEl){
            fileEl.files.unshift(files[i]);
            this.upLoadedFileList = fileEl.files;
            // if(files[i].mimeType==='pdf' || files[i].mimeType ==='docx' || files[i].mimeType==='doc' || files[i].mimeType==='ppt' || files[i].mimeType ==='pptx' || files[i].mimeType==='pptm' || files[i].mimeType==='txt' || files[i].mimeType==='xml'){
            //   this.editQuizForm.patchValue({
            //     duration: ''
            //   });
            // }
          }else{
            this.upLoadedFileList.push(files[0]);
            this.fileValidationService
          .fileValidation(files, this.chatFileExt, this.chatFileSizeLimit)
          .then((validResponse) => {
            this.appComponent
              .fileUploadWithProgess(formFields, this.upLoadedFileList)
              .then((fileUpLoadResponse: any) => {
                for (const key in fileUpLoadResponse) {
                  if (fileUpLoadResponse[key]) {
                    this.preUploadedFile = fileUpLoadResponse[key];
                  }
                }
                this.upLoadedFileList = [];
                let button = document.querySelector(".hideBtnUpload");
                    button.removeAttribute('disabled');
                this.fileUploadingInProgress = false;
              })
              .catch((error) => {
                this.upLoadedFileList = [];
                // this.topicFileInput.nativeElement.value = "";
                this.alert.showAlertMessage(error.error);
              });
          })
          .catch((err) => {
            this.topicFileInput.nativeElement.value = "";
            this.upLoadedFileList = [];
            err.map((li) => this.alert.showAlertMessage(li));
          });
          }
        }
      }else{
        this.alert.showAlertMessage({
          message: 'File seletion limit reached.'
        });
      }
    }
};

removeUploadFile(){
  try {
    this.upLoadedFileList = [];
    this.preUploadedFile = null;
  } catch (error) {
    console.log(error);
  }
}

  calcTextLength(text: string){
    if(text){
      return text.length > 0 ? text.length + 10: text.length;
    }
    return 50;
  }

  fetchNextTopicList() : void{
    const listLength = this.topicDetailsList.length;
    if(this.totalRowCount > listLength && !this.showPaginateSpinner){
      this.OFFSET +=1;
      this.showPaginateSpinner = true;
      const offset = this.OFFSET;
      const payLoad = {
        room_id: this.roomId,
        offset: offset,
        limit:this.RECORD_LIMIT,
        search: this.search || ''
      };
    }
  }

  hideTopicModal(){
    this.domElementControl.showCreateNewRoom = false;
    this.newTopicForm.reset();
    this.upLoadedFileList=[];
    this.preUploadedFile= null;
    this.showBtnSpinner = false;
    this.newTopicForm.controls.staff_type.patchValue('T');
    this.newTopicForm.controls.schedule_date.patchValue((new Date()).toISOString().substring(0,10));
  }

  removeSelectedFile(){
    this.upLoadedFileList = [];
    this.preUploadedFile = null;
  }


  createNewTopic(room_topic){
    if (room_topic) {

      this.upLoadedFileList[0];

      var topic_schedule = new Date(room_topic['schedule_date']);

      try {
        const payLoad = {
          topic: room_topic['topic'],
          notes: room_topic['notes'],
          shedule_date: topic_schedule,
          shedule_time: room_topic['schedule_time'],
          staff_type: room_topic['staff_type'],
          room_id: this.roomId,
          unique_id: Date.now(),
          file_data: this.preUploadedFile
        }

        this.showBtnSpinner = true;

        this.staffRoomService.createTopic(payLoad).subscribe(
          response => {
            this.showBtnSpinner = false;
            this.newTopicForm.reset();
            this.preUploadedFile = null;
            this.schedule_date = new Date();
            this.newTopicForm.controls.staff_type.patchValue('T');
            this.newTopicForm.controls.schedule_date.patchValue((new Date()).toISOString().substring(0,10));
            this.upLoadedFileList=[];
            this.removeSelectedFile();
            const createdTopic = response['result'];
            this.currentTopic = createdTopic['topic'];
            this.topicPostName = null;
            const topicList = [...this.getRoomTopicList];
            topicList.unshift(createdTopic);
            this.totalRowCount += 1;
            this.getRoomTopicList = topicList;
            this.domElementControl.showCreateNewRoom = false;
            this.httpLoader.hideLoader();
            this.alert.showAlertMessage(response);
          },
          error =>{
            // console.log(error.error.message);
            const message = error.error;
            this.httpLoader.hideLoader();
            this.alert.showAlertMessage(message);
            this.showBtnSpinner = false;
          }
        )

      } catch (error) {
        this.httpLoader.hideLoader();
        console.log(error);

      }
    }

  }

  cancelEditedRoom(){
    this.editRoomDetails = null;
  }

  hideRoomList(){
    this.staffRoomComponent.viewStaffRoomList = !this.staffRoomComponent.viewStaffRoomList;
  }

  showhideListView(){
    this.activeListView = true;
    this.activeTileView = false;
  }

  showhideTileView(){
    this.activeTileView = true;
    this.activeListView = false;
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }

}
