import { Component, OnInit, HostBinding, ViewChild, ElementRef, AfterViewInit, Directive  } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpEventType, HttpResponse, HttpClient, HttpRequest } from '@angular/common/http';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/of';
import { Observable } from 'rxjs/Observable';
import * as _ from 'underscore';
 

import { config, path, ResolveEmit, AlertPopup, HttpLoader } from './../../../../app.config';
import { AppComponent } from './../../../../app.component';
import { GroupService } from '../group.service';
import { GroupComponent } from '../group.component';
import { UserService } from '../../user/user.service';
import { UtilityService } from './../../../../utility.service';
import { FileValidationService } from './../../../../shared/file-validation.service';

import {Subject} from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import { ValidationService } from 'app/components/form-validation/validation.service';
import { ChatListComponent } from 'app/components/chat-list/chat-list.component';
import { DataSharingService } from 'app/shared/data-sharing.service';
import { SocketService } from 'app/socket/socket.service';

import { fromEvent, Subscription } from 'rxjs';
import { ConfirmationDialogService } from 'app/confirmation-dialog/confirmation-dialog.service';


// @Directive()
@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: []
})
export class GroupDetailComponent implements OnInit, AfterViewInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('fileInput') fileInput: ElementRef;

  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;

  newGroupForm: FormGroup;
  listOfUsers: any[] = [];
  totalRowCount: number;
  searchText: string;
  privacyList = [
    { name: 'Public', value: false },
    { name: 'Private', value: true }
  ];

  toggleModalSection = false;
  public viewSecondPanel: boolean;
  showSearchSec = true;

  /** User Profile icon handler variables */
  selectedFileList: any[] = [];
  profileExtension = config.PROFILE_EXT;
  profileSize = config.PROFILE_SIZE;


  selectedIdList: number[] = [];
  userIdList: any[] = [];

  userList: any[] = [];

  /**
   * Edited Group variables
   */
  updatedGroupList: any = {};
  selectedGroupDetail: any = {};
  editedGroupId: number;
  showPaginateSpinner = false;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;

  isMobile: boolean = false;
  width:number = window.innerWidth;
  height:number = window.innerHeight;
  mobileWidth:number  = 768;


  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription


  viewControl = {
    changedSearchText: false
    };
    searchInList = new Subject <any>();


  /** specifying Base URL.*/
  public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/groups`;
  profileUrl = path.PROFILE_URL;

  constructor(
    private fb: FormBuilder,
    private appComponent: AppComponent,
    private groupComponent: GroupComponent,
    // private chatListComponent: ChatListComponent,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private groupService: GroupService,
    private http: HttpClient,
    private utilityService: UtilityService,
   
    private confirmDialogService:ConfirmationDialogService,
    private loader: HttpLoader,
    private alert: AlertPopup,
    private dataSharingService: DataSharingService,
    private socketService: SocketService,
    private fileValidationService: FileValidationService) {
    this.newGroupForm = fb.group({
      chat_group_name: [null, Validators.compose([Validators.required, ValidationService.noWhitespaceValidator])],
      // is_private: [null, Validators.required],
      chat_description: [null],
      userIdList: []
    });

    this.searchInList
    .debounceTime(500)
    .subscribe(event => {
      this.searchText = event.target.value;
      this.viewControl.changedSearchText = true;
      this.userSearchAndPagination(0);
    });
  }


  ngOnInit() {
    try {
      this.loader.showLoader();
      this.route.paramMap
        .switchMap((params: ParamMap) => this.groupService.getClassRoomDetail(+params.get('id')))
        .subscribe(groupList => {
          this.loader.hideLoader();
          this.editedGroupId = groupList['group_detail'].chat_group_id;
          this.updatedGroupList = groupList['group_detail'];
          this.userList = groupList['groupMemberList'];

          this.userSearchAndPagination(0);
          for (const key in this.newGroupForm.value) {
            if (key) {
              const newObj = {};
              newObj[key] = groupList['group_detail'][key];
              this.newGroupForm.patchValue(newObj);
            }
          }
        },
        error => {
          this.loader.hideLoader();
          this.alert.showAlertMessage(error.error);
        });
    } catch (err) {
      this.loader.hideLoader();
      console.log(err);
    }

    if(this.width <= this.mobileWidth){
      this.groupComponent.viewSecondPanel = true;
    }else{
      this.groupComponent.viewSecondPanel = false;
    }

    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      let windowWith = window.innerWidth;
      if(windowWith <= this.mobileWidth){
        this.groupComponent.viewSecondPanel = true;
      }else{
        this.groupComponent.viewSecondPanel = false;
      }
    })

  };

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.groupComponent.viewClassRoom = false;
  }

  keyDownHandler(event) {
    if (event.keyCode === 32 && event.target.selectionStart === 0) {
      return false;
    }
  }

  moveCursor(event){
    console.log(event.target);

    event.target.setSelectionRange(0, 0);
  }



  /**
  *
  * @param searchText
  */
  userSearchAndPagination(pageNo, groupId = this.editedGroupId) {
    try {

      if (pageNo === 0) {
        this.OFFSET = pageNo;
        this.loader.showLoader();
      }

      if (!this.searchText) {
        this.viewControl.changedSearchText = false;
      }

      const offset = this.OFFSET;
      const payLoad = {
        groupId: groupId,
        offset: offset,
        limit: this.RECORD_LIMIT,
        // sort: '',
        search: this.searchText || ''
      };


      this.userService.getSelectedUserList(payLoad).subscribe(
        (userList) => {
          this.showPaginateSpinner = false;
          if (offset === 0) {
            this.listOfUsers = userList['memberList'];
            this.totalRowCount = userList['TotalRowCount'];
            this.loader.hideLoader();
          } else if (offset > 0) {
            this.listOfUsers = [...this.listOfUsers.concat(userList['memberList'])];
          }
        },
        error => {
          this.loader.hideLoader();
          this.showPaginateSpinner = false;
          this.alert.showAlertMessage(error.error);
        });
    } catch (err) {
      this.loader.hideLoader();
      console.log(err);
    }
  };

  showSearchbox(toggle){
    console.log(toggle);
    try {
      // this.showSearchSec = !this.showSearchSec;
      console.log(this.viewControl.changedSearchText);

      if (this.viewControl.changedSearchText) {
        try {
          this.searchText = '';
          const offset = 0;
          this.viewControl.changedSearchText = false;
          this.userSearchAndPagination(offset);
        } catch (err) {
          this.loader.hideLoader();
        }
      }
    } catch (err) {
      this.loader.hideLoader();
    }
  }

  /**
   * Pagination called while scroll down
   */
  onScrollDown() {
    if (this.listOfUsers.length < this.totalRowCount && !this.showPaginateSpinner) {
      this.OFFSET += 1;
      this.showPaginateSpinner = true;
      this.userSearchAndPagination(this.OFFSET);
    }
  };


  /**
  * Call this function on every file choose
  * @param event
  */
  onFileChange(event) {
    const files = event.target.files;
    const groupId = this.editedGroupId;
    if (files && files.length > 0) {
      const formFields = {
        fieldName: 'group_profile',
        activity: 'group_icon_update',
        chat_group_id: groupId
      };

      this.selectedFileList.push(files[0]);
      this.fileValidationService.fileValidation(files, this.profileExtension, this.profileSize).then(validResponse => {
        this.appComponent.fileUploadWithProgess(formFields, this.selectedFileList).then((fileUpLoadResponse: any) => {
          for (const key in fileUpLoadResponse) {
            if (fileUpLoadResponse[key]) {
              this.updatedGroupList.chat_group_icon = fileUpLoadResponse[key]['groupProfile']['chat_group_icon'];
              this.groupComponent.listOfGroups.find(group => {
                if (group.group_id === groupId) {
                  return group.group_icon = fileUpLoadResponse[key]['groupProfile']['chat_group_icon'];
                }
              });
            }
          }
          this.selectedFileList = [];
          this.fileInput.nativeElement.value = '';
          this.alert.showAlertMessage(fileUpLoadResponse['message']);
        }).catch(error => {
          this.selectedFileList = [];
          this.fileInput.nativeElement.value = '';
          this.alert.showAlertMessage(error.error);
        })
      }).catch(err => {
        this.fileInput.nativeElement.value = '';
        this.selectedFileList = [];
        err.map(li => this.alert.showAlertMessage(li));
      });
    }
  };



  /**
    * Add user selected user id in the userListId array
    * @param userId
    */
  selectUserId(index, user) {

    this.listOfUsers[index].is_selected = !this.listOfUsers[index].is_selected;
    if (this.listOfUsers[index].is_aleady_selected && this.listOfUsers[index].is_selected) {
      this.listOfUsers[index].is_changed = false;
    } else {
      this.listOfUsers[index].is_changed = true;
    }

    if (this.selectedIdList.indexOf(user.user_id) > -1 && !this.listOfUsers[index].is_selected) {
      this.selectedIdList = _.without(this.selectedIdList, user.user_id);
      return;
    } else if (this.listOfUsers[index].is_selected && !this.listOfUsers[index].is_aleady_selected) {
      // this.selectedIdList.push(user);
      return;
    }
  };

  /** Select User id from User Section in modal window */
  openAddUserSection() {
    this.toggleModalSection = !this.toggleModalSection;
    var selectUserList = _.where(this.listOfUsers, { is_changed: true });

    var userIdList = selectUserList.map(user => {

      if (user.is_selected===true) {
        return Object.assign({},
          {
            user_id: user.user_id,
            is_selected: user.is_selected,
            full_name: user.full_name,
            user_icon: user.user_icon,
            is_online:user.is_online,
            master_user_id:user.master_user_id
          });
      }
    });

     userIdList = userIdList.filter(function( x ) {
      return x !== undefined;
      });

    if (selectUserList) {
      this.userList = [this.userList[0], ...userIdList];
      this.newGroupForm.markAsDirty();
    }
  };

  closeAddUserSection() {
    this.toggleModalSection = false;
    const userList = _.findWhere(this.listOfUsers, { is_changed: true });

    if (userList) {
      this.selectedIdList = [];
      this.userSearchAndPagination(0);
    } else {
      this.selectedIdList = [];

    }
  };

  /** Clear user Id list Selection */
  clearAddUserSection() {
    const userList = _.findWhere(this.listOfUsers, { is_changed: true });
    if (userList) {
      this.confirmDialogService.openConfirmDialog('',{
        title:'Confirmation',
        message:'Do you want to clear the modification?',
        btnOkText:'YES',
        btnCancelText:'NO',
        onClose: (data:any) => {
          if(data){
            this.selectedIdList = [];
            this.userSearchAndPagination(0);
          } else {
            return true;
          }
        }	  
    });	
      /* this._confirmation.create('Confirmation', 'Do you want to clear the modification?')
        .subscribe((ans: ResolveEmit) => {
          if (ans.resolved) {
            this.selectedIdList = [];
            this.userSearchAndPagination(0);
          } else {
            return true;
          }
        }); */
    }
  };

  updateGroupDetail(formVal) {
    const userList = _.where(this.listOfUsers, { is_changed: true });
  //if (userList && userList.length > 0) {
    const userIdList = userList.map(user => {
      return Object.assign({},
        {
          user_id: user.user_id,
          is_selected: user.is_selected
        });
    });

    formVal.userIdList = userIdList;

    let payLoad = {};
    const groupId = this.editedGroupId;
    payLoad = {
      id: groupId,
      data: formVal
    }

    this.groupService.updateGroupDetail(payLoad).subscribe(
      group => {
        this.selectedIdList = [];

        this.updatedGroupList = group['groupDetail'];
        this.groupComponent.groupListSortAndSearch(0);

        if (group['groupMemberList']!='') {

          this.userList.push(...group['groupMemberList']);
          // this.userList.concat([group['groupMemberList']]);
        }

        this.newGroupForm.markAsPristine();
        const pageNo = 0;
        var groupId = 0;
        this.userSearchAndPagination(pageNo, groupId = this.editedGroupId);
        this.alert.showAlertMessage(group);
      },
      error => {
        this.alert.showAlertMessage(error.error);
      });
    // }
  };


  removeGroupDetail = function () {
    try {
      const id = this.editedGroupId;
      this.confirmDialogService.openConfirmDialog('',{
        title:'Confirmation',
        message:'Are you sure want to delete this policy library?',
        btnOkText:'YES',
        btnCancelText:'NO',
        onClose: (data:any) => {
          if(data){
            this.loader.showLoader();
            const index = _.findIndex(this.groupComponent.listOfGroups, { group_id: id });
            this.groupService.deleteSelectedGroup(id).subscribe(
              response => {
                this.loader.hideLoader();
                this.groupComponent.listOfGroups.splice(index, 1);
                this.router.navigate(['/main/userandgroup/group']);
                this.groupComponent.viewClassRoom = true;
              },
              error => {
                this.loader.hideLoader();
                this.alert.showAlertMessage(error.error);
              }
            )
          }
        }	  
    });
    /*  this._confirmation.create('Confirmation', 'Are you sure want to delete this classroom?', { confirmText: 'YES' })
        .subscribe((ans: ResolveEmit) => {
          if (ans.resolved) {


            this.loader.showLoader();
            const index = _.findIndex(this.groupComponent.listOfGroups, { group_id: id });
            this.groupService.deleteSelectedGroup(id).subscribe(
              response => {
                this.loader.hideLoader();
                this.groupComponent.listOfGroups.splice(index, 1);
                this.router.navigate(['/userandgroup/group']);
                this.groupComponent.viewClassRoom = true;
              },
              error => {
                this.loader.hideLoader();
                this.alert.showAlertMessage(error.error);
              }
            )
          }
        }); */
    } catch (err) {
      this.loader.hideLoader();
      console.log(err);
    }
  };

  deleteGroupUser(student_id){
   try {

    const group_id = this.editedGroupId;

    if(this.userList.length > 1){
      this.confirmDialogService.openConfirmDialog('',{
        title:'Confirmation',
        message:'Are you sure, you want to remove the user from this lesson?',
        btnOkText:'YES',
        btnCancelText:'NO',
        onClose: (data:any) => {
          if(data){
            this.loader.showLoader();
          this.groupService.deleteGroupUsers(group_id, student_id).subscribe(response=>{
            this.loader.hideLoader();
            this.alert.showAlertMessage(response);
            this.selectedIdList = [];
            const pageNo = 0;
            var groupId = 0;
            this.userSearchAndPagination(pageNo, groupId = this.editedGroupId);
            this.groupComponent.groupListSortAndSearch(0);
            const userId = response['result']['user_id'];
            let position = this.userList.findIndex(x => x.user_id===userId);

                if(~position){
                  this.userList.splice(position, 1);
                }
            // this.groupComponent.listOfGroups.splice(index, 1)
          }, error => {
            this.loader.hideLoader();
            this.alert.showAlertMessage(error.error);
          });
          }
        }	  
    });
      /*this._confirmation.create('Confirmation', 'Are you sure, you want to remove the student from this class?', {confirmText: 'Yes, Remove'}).subscribe((ans: ResolveEmit) => {
        if(ans.resolved){
          this.loader.showLoader();
          this.groupService.deleteGroupUsers(group_id, student_id).subscribe(response=>{
            this.loader.hideLoader();
            this.alert.showAlertMessage(response);
            this.selectedIdList = [];
            const pageNo = 0;
            var groupId = 0;
            this.userSearchAndPagination(pageNo, groupId = this.editedGroupId);
            this.groupComponent.groupListSortAndSearch(0);
            const userId = response['result']['user_id'];
            let position = this.userList.findIndex(x => x.user_id===userId);

                if(~position){
                  this.userList.splice(position, 1);
                }
            // this.groupComponent.listOfGroups.splice(index, 1)
          }, error => {
            this.loader.hideLoader();
            this.alert.showAlertMessage(error.error);
          })
        }
      }); */

    }else{

      this.alert.showAlertMessage({
        message: 'Cannot delete all the user, At least one user needed in the Group/Lesson'
      });
      return false;

    }
   } catch (error) {
    this.loader.hideLoader();
    console.log(error);
   }
  }

  hideSecondPanel() {
    this.groupComponent.viewSecondPanel = !this.groupComponent.viewSecondPanel;
  };

  hideEditGroup(){
    var selectUserList = _.where(this.listOfUsers, { is_changed: true });

    var userIdList = selectUserList.map(user => {

      if (user.is_selected===true) {
        return Object.assign({},
          {
            user_id: user.user_id,
            is_selected: user.is_selected,
            full_name: user.full_name,
            user_icon: user.user_icon,
            is_online:user.is_online,
            master_user_id:user.master_user_id
          });
      }
    });

     userIdList = userIdList.filter(function( x ) {
      return x !== undefined;
      });

    if (userIdList.length > 0) {
      var str ='';

      if (userIdList.length > 1) {
        str = 's'
      }

      this.confirmDialogService.openConfirmDialog('',{
        title:'Confirmation',
        message:'You are added '+userIdList.length+ ' student'+str+'. So you want to cancel these user?',
        btnOkText:'YES',
        btnCancelText:'NO',
        onClose: (data:any) => {
          if(data){
            this.selectedIdList = [];
            this.userList = [this.userList[0]];
            this.userSearchAndPagination(0);
          }else{
            this.alert.showAlertMessage({'message': 'Please click on below Update button added more user in this lesson.'});
          }
        }	  
    });

     /* this._confirmation.create('Confirmation', 'You are added '+userIdList.length+ ' student'+str+'. So you want to cancel these students?').subscribe((ans: ResolveEmit)=> {
        if (ans.resolved) {
          this.selectedIdList = [];
          this.userList = [this.userList[0]];
          this.userSearchAndPagination(0);
        }else{
          this.alert.showAlertMessage({'message': 'Please click on below Update button added more students in this class.'})
        }
      }); */
    }else{
      this.router.navigate(['/main/userandgroup/group']);
      this.groupComponent.viewClassRoom = true;
    }
  }

  fetchNextUserList(){

  }

  deleteGroupProfile() {
    try {
      const group_id = this.editedGroupId;
      if (group_id) {
        this.loader.showLoader();
        this.groupService.deleteGroupProfile(group_id).subscribe(
          response => {
            this.loader.hideLoader();
            this.updatedGroupList.chat_group_icon = response['groupProfile']['chat_group_icon'];
            this.groupComponent.listOfGroups.find(group => {
              if (group.group_id === group_id) {
                return group.group_icon = response['groupProfile']['chat_group_icon'];
              }
            });


          },
          error => {
            this.loader.hideLoader();
            this.alert.showAlertMessage(error.error);
          });
      }
    } catch (err) {
      this.loader.hideLoader();
      console.log(err);
    }
  };
};
