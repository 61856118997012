import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpHeaders} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HttpModule } from '@angular/http';

import { HelpComponent } from './help.component';
import { HelpService } from './help.service';
import { HelpRoutingModule } from './help-routing.module';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    HelpRoutingModule
    // HttpModule
  ],
  declarations: [
    HelpComponent
  ],
  providers: [HelpService]
})
export class HelpModule { }
