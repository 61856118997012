import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/startWith';

interface ScrollPosition {
    sH: number;
    sT: number;
    cH: number;
};

const DEFAULT_SCROLL_POSITION: ScrollPosition = {
    sH: 0,
    sT: 0,
    cH: 0
};

// @Directive()
@Directive({
    selector: '[appInfiniteScroller]'
})
export class InfiniteScrollerDirective implements AfterViewInit {

    private scrollEvent$;

    private userScrolledDown$;

    private requestStream$;

    private requestOnScroll$;

    @Input()
    scrollCallback;

    @Input()
    immediateCallback;

    @Input()
    scrollPosition;

    @Input()
    scrollPercent;

    @Input()
    scrollTopPercent;

    constructor(private elm: ElementRef) { }

    ngAfterViewInit() {

        this.registerScrollEvent();

        this.streamScrollEvents();

        this.requestCallbackOnScroll();

    }

    private registerScrollEvent() {

        this.scrollEvent$ = Observable.fromEvent(this.elm.nativeElement, 'scroll');

    }

    private streamScrollEvents() {
        this.userScrolledDown$ = this.scrollEvent$
            .map((e: any): ScrollPosition => ({
                sH: e.target.scrollHeight,
                sT: e.target.scrollTop,
                cH: e.target.clientHeight
            }))
            .pairwise()
            .filter(positions => {
                if (this.scrollPosition === 'down') {
                    return this.isUserScrollingDown(positions) && this.isScrollExpectedPercent(positions[1])
                }

                if (this.scrollPosition === 'up') {
                    return this.isUserScrollingUp(positions) && this.isScrollUpExpectedPercent(positions[1])
                }
            })
    }

    private requestCallbackOnScroll() {
        this.requestOnScroll$ = this.userScrolledDown$;

        if (this.immediateCallback) {
            this.requestOnScroll$ = this.requestOnScroll$
                .startWith([DEFAULT_SCROLL_POSITION, DEFAULT_SCROLL_POSITION]);
        }

        this.requestOnScroll$
            .exhaustMap(() => {
                return this.scrollCallback();
            })
            .subscribe((data) => {
                // console.log(data)
            }, (err) => {
                console.log(err)
            });

    }

    private isUserScrollingDown = (positions) => {
        return positions[0].sT < positions[1].sT;
    }

    private isUserScrollingUp = (positions) => {
        return positions[0].sT > positions[1].sT;
    }

    private isScrollExpectedPercent = (position) => {
        return ((position.sT + position.cH) / position.sH) > (this.scrollPercent / 100);
    }

    private isScrollUpExpectedPercent = (position) => {
        console.log(position)
        return (position.sT < this.scrollTopPercent);
    }
}
