import { PushNotificationsService } from './../../../push-notification/push-notification-service';
import { Component, OnInit, AfterContentChecked, AfterViewInit, HostBinding, HostListener, ElementRef, Output, ViewChild, OnDestroy, EventEmitter, Input, Directive  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import * as _ from 'underscore';
import * as moment from 'moment';
import { Observable } from 'rxjs/Rx';
import { Subscription } from 'rxjs/Subscription';
import { AppComponent } from './../../../app.component';
import { ChatListComponent } from '../chat-list.component';
import { ChatService } from '../chat.service';
import { SocketService } from './../../../socket/socket.service';
import { config, path, AlertPopup, AUTH_TOKEN } from './../../../app.config';
import { SettingsService } from './../../settings/settings.service';
import { DataSharingService } from './../../../shared/data-sharing.service';
import { FileValidationService } from './../../../shared/file-validation.service';
import { EmojiService } from 'lib/emoji/public_api';
// import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
//import {CaptureAudioService,CaptureScreenService} from '../../../shared/meeting-service';

// @Directive()
@Component({
    selector: 'app-single-chat',
    templateUrl: './chat-message.component.html',
    styleUrls: []
})
export class ChatMessageComponent implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy {

    @HostBinding('class.flex-grid') leftBarClass = true;
 
   // @HostBinding('style.height') flexheight = '100%';
    @HostBinding('style.width') flexwidth = '100%';
    @ViewChild('chatArea') private myScrollContainer: ElementRef;
    @ViewChild('fileInput') private fileInput: ElementRef;
    @ViewChild('messageInput') private messageInputEl: ElementRef;
    @ViewChild('msgFileInput') private msgFileInput: ElementRef;
    @ViewChild('localVideo') localVideo: ElementRef;
    @ViewChild('remoteVideo') remoteVideo: ElementRef;

    @Output() isActiveTabName :string;
    eventsSubject: Subject<void> = new Subject<void>();


    subscription: Subscription;
    RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
    OFFSET: number = config.DEFAULT_OFFSET;

    _domParams = {
        showUserList: false,
        hideFilePreview: false,
    };

    userSettings: any = {};
    getMessageList: any = [];
    totalRowCount: number;
    isUserActivated = true;
    selectedUserDetail: any = {};
    send_user_detail: any = {};
    is_group_chat = false;
    is_msg_view = false;
    selectedGroupDetail: any = {};
    textMessage: string;
    groupText: string;
    groupId: number;
    chatViewChange = true;
    editGroupDetails: any = null;
    activeUrl: any;
    chatId: number;
    loggedUserId: number;
    loggedUserRole:number;
    otherUserId: number;
    selectedGroupId: number;
    private subscribers: any = {};
    changedUserData: any;
    beforeUserDetails = false;
    audioCallStart = false;
    audioTabShow = false;
    meetingPanel = false;
    is_question_found = false;
    is_survey_found = false;
    is_polls_found = false;
    publish = false;
    download_link = false;
    fileDuration = '';
    price = 0;
    discount = 0;

    /** File upload Variables */
    upLoadedFileList: any[] = [];
    fileUploadingInProgress = false;

    /** Group Profile Update */
    profileExtension = config.PROFILE_EXT;
    profileSize = config.PROFILE_SIZE;
    groupIconFileList: any[] = [];

    /** Chat File Size limit */
    chatFileSizeLimit: number = config.MAX_ATTACH_FILE_SIZE;
    chatFileExt = config.ATTACHMENT_EXT;

    /** Infinite related variables */
    showPaginateSpinner = false;
    pagination: string;
    profileUrl = path.PROFILE_URL;
    SECURE_FILE_URI = path.NEW_PROFILE_URL;
    TOKEN = AUTH_TOKEN.TOKEN;
    TypeTimer;
    TypingInterval = 1000;
    width:number = window.innerWidth;
    height:number = window.innerHeight;
    mobileWidth:number  = 760;
    hidechatContent:boolean=false;

    constructor(
        private appComponent: AppComponent,
        private route: ActivatedRoute,
        private router: Router,
        private chatListComponent: ChatListComponent,
        private chatService: ChatService,
        private socketService: SocketService,
        private settingsService: SettingsService,
        private alert: AlertPopup,
        private dataSharingService: DataSharingService,
        private fileValidationService: FileValidationService,
        private _notificationService: PushNotificationsService,
        private emojiService: EmojiService
        // private audioService: CaptureAudioService,
        // private screenService: CaptureScreenService
        ) {

    }


    focusHandler = (e) => {
        e.preventDefault();
        this.updateMessageRead(this.getMessageList);
    };

    focusBlurHandler = (e) => {
        e.preventDefault();
    };

    clipboardPasteHandler = (event) => {
        const files = [];
        const items = (event['clipboardData'] || event['originalEvent'].clipboardData || window['clipboardData']).items;
        for (const key in items) {
            if (key) {
                const item = items[key];
                if (item.kind === 'file') {
                    const blob = item.getAsFile();
                    files.push(blob);
                    this.concatFileIntoArray(files);
                }
            }
        }
    };

    ngOnInit() {
        this.hidechatContent=false;
        try {
            this.chatService.getData().subscribe(data => {
                this.audioCallStart = false;
                this.beforeUserDetails = true;
                this.changedUserData = data;
                // this.getMessageList = [];
              });

            this.route.url.subscribe(() => {
                this.activeUrl = this.route.snapshot.url[0].path;
                this.getMessageList = [];
                this.otherUserId = null;
                if(this.width < this.mobileWidth){
                    this.chatListComponent.viewChatList=true;
                }
              
                // this.is_msg_view = false;
            });
            // let resData = this.route.snapshot.paramMap.get('data')
            // if(resData === 'ans'){
            //     this.audioCallStart = true;
            //     this.meetingPanel = true
            // }

            if (this.activeUrl && this.activeUrl === 'singleuser') {
                this.is_group_chat = false;
                // this.getMessageList = [];
                this.route.paramMap
                .switchMap((params: ParamMap) => this.chatService.getMessageList(
                    {
                        userId: +params.get('id'),
                        offset: config.DEFAULT_OFFSET,
                        limit: this.RECORD_LIMIT
                    }))
                    .subscribe(
                        responseList => {
                        this.beforeUserDetails = false;
                        this.is_msg_view = true;
                        // console.log('singleUser', responseList);
                        this.OFFSET = 0;
                        this.totalRowCount = responseList['TotalRowCount'];
                        // const concatArray = responseList['message'].concat(this.getMessageList);
                        // this.getMessageList = this.groupedCollection(concatArray);
                        const msgArr = this.groupedCollection(responseList['message']);
                        this.getMessageList = [...msgArr];
                        // console.log('getMessageList', this.getMessageList);
                        this.selectedUserDetail = responseList['user_detail'] || {};
                        this.isUserActivated = this.selectedUserDetail['acc_active'];
                        this.send_user_detail = responseList['from_user_detail'] || {};
                        this.is_group_chat = responseList['is_group_chat'];
                        this.chatId = responseList['chat_id'];
                        this.loggedUserId = responseList['loggedUserId'];
                        this.otherUserId = responseList['user_detail'].user_id;
                        this._domParams.showUserList = false;
                        this.getFilesFromAppComponent('singleuser', responseList['user_detail'].user_id);
                        this.updateMessageRead(this.getMessageList);
                        if(!this.audioCallStart){
                            this.scrollBottom();
                          this.messageInputEl.nativeElement.focus();
                        }
                    },
                    error => {
                        this.router.navigate([`/main/chats`]);
                        this.alert.showAlertMessage(error.error);
                    });
            } else if (this.activeUrl && this.activeUrl === 'groupuser') {
                this.is_msg_view = true;
                this.is_group_chat = true;
                this.groupId = +this.route.snapshot.paramMap.get('id');
                // this.getMessageList = [];
                this.route.paramMap
                    .switchMap((params: ParamMap) => this.chatService.getGroupMessageList({
                        groupId: +params.get('id'),
                        offset: config.DEFAULT_OFFSET,
                        limit: this.RECORD_LIMIT
                    }))
                    .subscribe(responseList => {
                        this.OFFSET = 0;
                        this.totalRowCount = responseList['TotalRowCount'];
                        // const concatArray = responseList['message'].concat(this.getMessageList);
                        // this.getMessageList = this.groupedCollection(concatArray);
                        this.getMessageList = this.groupedCollection(responseList['message']);
                        this.selectedGroupDetail = responseList['group_detail'];
                        this.send_user_detail = responseList['from_user_detail'] || {};
                        this.is_group_chat = responseList['is_group_chat'];
                        this.chatId = responseList['chat_id'];
                        this.selectedGroupId = responseList['groupId'];
                        this.loggedUserId = responseList['loggedUserId'];
                        this._domParams.showUserList = false;
                        this.getFilesFromAppComponent('groupuser', responseList['groupId']);
                        this.updateMessageRead(this.getMessageList);
                        this.scrollBottom();
                        this.messageInputEl.nativeElement.focus();
                    },
                    error => {
                        this.router.navigate([`/main/chats`]);
                        this.alert.showAlertMessage(error.error);
                    });
            }


            //Instant change Group Name using API & Socket

            this.dataSharingService.getChangeGroupName().subscribe((response) => {
              const actualGrpDeatail = { ...this.selectedGroupDetail };
               const chatArrList = [...this.chatListComponent.chatList];
              chatArrList.map((el) => {
                  if (el.group_id === actualGrpDeatail.chat_group_id) {
                      el.full_name = response.chat_group_name;
                  }
              });
              this.chatListComponent.chatList = chatArrList;
            })


        } catch (err) {
            console.log(err);
        }

        window.addEventListener('focus', this.focusHandler, false);
        window.addEventListener('blur', this.focusBlurHandler, false);
        window.addEventListener('paste', this.clipboardPasteHandler, false);




        // window.addEventListener('focus', (e) => {
        //     e.preventDefault();
        //     this.updateMessageRead(this.getMessageList);
        // }, false);

        // window.addEventListener('blur', (e) => {
        //     e.preventDefault();
        // }, false);

        // window.addEventListener('paste', (event) => {
        //     console.log('addEventListener - paste...');
        //     const files = [];
        //     const items = (event['clipboardData'] || event['originalEvent'].clipboardData || window['clipboardData']).items;
        //     for (const key in items) {
        //         if (key) {
        //             const item = items[key];
        //             if (item.kind === 'file') {
        //                 const blob = item.getAsFile();
        //                 files.push(blob);
        //                 this.concatFileIntoArray(files);
        //             }
        //         }
        //     }
        // }, false);
    };

    ngAfterViewInit() {
        try {
            this.subscribers.userMessage = this.dataSharingService.getUserMessage().subscribe(newMessage => {
                const response = newMessage.message;
                if (response) {
                    if (response && response.chat_type === 'user') {
                        if ((response && response.from_user_id) === +this.route.snapshot.paramMap.get('id')
                            && this.activeUrl === 'singleuser') {
                            this.addMessageToArray(response);
                        } else {
                            this.chatListComponent.incrementMessageCount(response);
                            // if (this.chatListComponent.viewChatList) {
                            //     const totalCount = this.getTotalUnReadMsgCount();
                            //     this.dataSharingService.emitNotifyCount({ menu: 'chats', count: totalCount });
                            // }
                        }
                    } else if (response && response.chat_type === 'group') {
                        this.chatListComponent.incrementMessageCount(response);
                    }
                    this.totalRowCount += 1;
                    this.updateChatRecordRecent(response.chat_id);
                    this.chatListComponent.updateLastMessageinChat(response, response.from_user_id);
                }
            });

            this.subscribers.dataAnswerd = this.dataSharingService.getDataAnswer().subscribe(res => {
                if(res.data === 'ans'){
                        this.audioCallStart = true;
                        this.meetingPanel = true
                     }
            })

            this.subscribers.groupMessage = this.dataSharingService.getGroupMessage().subscribe(newMessage => {
                const response = newMessage.message;
                if (response) {
                    if (response && response.chat_type === 'group') {
                        if ((response && response.group_id) === +this.route.snapshot.paramMap.get('id')
                            && this.activeUrl === 'groupuser') {
                            this.addMessageToArray(response);
                        } else {
                            this.chatListComponent.incrementMessageCount(response);
                            // if (this.chatListComponent.viewChatList) {
                            //     const totalCount = this.getTotalUnReadMsgCount();
                            //     this.dataSharingService.emitNotifyCount({ menu: 'chats', count: totalCount });
                            // }
                        }
                    } else if (response && response.chat_type === 'user') {
                        this.chatListComponent.incrementMessageCount(response);
                    }
                    this.totalRowCount += 1;
                    this.updateChatRecordRecent(response.chat_id);
                    this.chatListComponent.updateLastMessageinChat(response, response.group_id);
                }
            });

            this.subscribers.updateDelivery = this.dataSharingService.getMessageUpdateNotify().subscribe(deliveredMessage => {
                try {
                    if (deliveredMessage) {
                        setTimeout(() => {
                            this.getMessageList.map(message => {
                                if (!message.is_read) {
                                    return Object.assign(message, { is_delivered: deliveredMessage.is_delivered, is_read: deliveredMessage.is_read });
                                } else {
                                    return message;
                                }
                            });
                        }, 300)
                    }
                } catch (err) {
                    console.log(err);
                }
            });

            this.subscribers.readBulkMessage = this.dataSharingService.getReadMsgUpdateNotify().subscribe(readedMessage => {
                try {
                    if (readedMessage) {
                        this.getMessageList.map(message => {
                            if (!message.is_read) {
                                return Object.assign(message, { is_read: true });
                            } else {
                                return message;
                            }
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            });


            this.subscribers.groupUpdate = this.dataSharingService.getGroupUpdateNotify().subscribe(updatedGroup => {
                try {
                    if (updatedGroup) {
                        this.bindUpdatedGroupDetail(updatedGroup);
                    }
                } catch (err) {
                    console.log(err);
                }
            });

        } catch (err) {
            console.log(err);
        }
    };

    ngAfterContentChecked() {
        if (!_.isEqual(this.userSettings, this.appComponent.userSetting)) {
            this.userSettings = this.appComponent.userSetting;
        }
    };


    getTotalUnReadMsgCount() {
        let unReadMsgCount = 0;
        const chatListArray = [...this.chatListComponent.chatList];
        const totalCount = chatListArray.forEach(el => unReadMsgCount += el.unread_msg_count);
        return unReadMsgCount;
    }

    keyDownHandler(e){

      if(e.which === 32 && e.target.innerHTML==""){
        e.preventDefault();
      }

    }
    callAudioInput() {
        this.audioCallStart = true;

        this.meetingCreation();
    }
    async meetingCreation(){
       // const refId = new Date().valueOf();
        let to_user_id;
        if(this.otherUserId !== null) {
          to_user_id = this.otherUserId;
        } else {
            to_user_id = this.changedUserData.user_id;
        }
    const data = {
        to_user_id:to_user_id,
        // unique_ref_id: refId,
        from_user_id: this.send_user_detail.user_id,
        first_name: this.send_user_detail.first_name,
        last_name: this.send_user_detail.last_name,
        user_icon: this.send_user_detail.user_icon,
        calling:'true'
    };
     this.chatService.createMeeting(data).subscribe( response =>{
         if(response['success']){
            this.audioCallStart = true;
         }

     })
    }


    audioCallStop(event) {
        this.audioCallStart = event;
        this._domParams.showUserList = false;
        this.audioTabShow = false;
        let to_user_id;
        if(this.otherUserId !== null) {
          to_user_id = this.otherUserId;
        } else {
            to_user_id = this.changedUserData.user_id;
        }
        const data = {
            to_user_id:to_user_id,
            from_user_id: this.send_user_detail.user_id,
            first_name: this.send_user_detail.first_name,
            last_name: this.send_user_detail.last_name,
            user_icon: this.send_user_detail.user_icon,
            calling:'false'
        };

        // this.chatService.declinedCall(data)

    }



    keyUpEmit(chat_type, bool) {
        const user_name = this.send_user_detail.first_name + ' ' + this.send_user_detail.last_name;
        const payLoad: any = {
            isTyping: bool,
            is_typing_msg: `${user_name} is typing...`,
            from_user_id: this.loggedUserId,
            to_user_id: null,
            group_id: this.selectedGroupId
        }

        if (chat_type === 'user') {
            payLoad.to_user_id = [this.otherUserId]
        }

        if (chat_type === 'group') {
            payLoad.to_user_id = this.selectedGroupDetail.chat_user_list;
        }

        if (!this.textMessage) {
            payLoad.isTyping = false;
            payLoad.is_typing_msg = null;
        }

        if (bool && this.textMessage) {
            this.appComponent.socketTyping(payLoad);
        } else {
            this.appComponent.socketTyping(payLoad);
        }
    };

    getFilesFromAppComponent(chatType, id) {
        const findFileObj = null;
        let findFileIndex = null;
        const filesList = [...this.appComponent.selectedFilesList];
        if (chatType === 'singleuser') {
            findFileIndex = filesList.findIndex(el => el.user_id === id);
        } else if (chatType === 'groupuser') {
            findFileIndex = filesList.findIndex(el => el.group_id === id);
        }
        if (findFileIndex >= 0) {
            this.upLoadedFileList = filesList[findFileIndex].files;
        } else {
            this.upLoadedFileList = [];
        }
    };

    trackByFn(index, msg) {
        return index;
    };

    sendMessage(message) {

        try {
            if ((message && this.upLoadedFileList.length === 0) || (message && this.fileUploadingInProgress)) {
                if (this.textMessage.trim() !== '') {
                this.keyUpEmit('user', false);
                // let to_user_id = this.otherUserId;
                let to_user_id;
                if(this.otherUserId !== null) {
                    to_user_id = this.otherUserId;
                } else {
                    to_user_id = this.changedUserData.user_id;
                }

                let messageText = this.chatMessageCodeBlock(message);

                if(messageText==true){
                  let msg = message.slice(6);
                  message = '<pre><code>'+msg+'</code></pre>';
                }else{
                  message = message;
                }

                const refId = new Date().valueOf();
                const payLoad = {
                    to_user_id,
                    message,
                    unique_ref_id: refId
                };


                document.activeElement.innerHTML="";

                this.textMessage = null;

                this.messageFormation(payLoad);
                this.chatService.sendMessage(payLoad).subscribe(
                    (messageRes) => {
                        if (messageRes['createdMessage']) {
                            // this.addMessageToArray(messageRes['createdMessage']);
                            this.updateMessageToArray(messageRes['createdMessage']);
                            // this.textMessage = null;
                            this.totalRowCount += 1;
                            this.chatListComponent.updateLastMessageinChat(messageRes['createdMessage'], messageRes['createdMessage'].to_user_id);
                            this.updateChatRecordRecent(this.chatId);
                        }
                    },
                    error => {
                        this.alert.showAlertMessage(error.error);
                    }
                    )
                }
            } else {
                this.sendMessageFile('user');
            }
        } catch (err) {
            console.log(err);
        }
    };

    calcTextLength(text: string) {
        if (text) {
            return text.length > 0 ? text.length + 10 : text.length;
        }
        return 50;
    };

    editGroupName() {
        this.editGroupDetails = { ...this.selectedGroupDetail };
    }

    cancelEditedGroupName() {
        this.editGroupDetails = null;
    };

    sendGroupMessage(message) {
        try {
            if ((message && this.upLoadedFileList.length === 0) || (message && this.fileUploadingInProgress)) {
                this.keyUpEmit('group', false);
                const group_id = +this.route.snapshot.paramMap.get('id');

                let messageText = this.chatMessageCodeBlock(message);
                if(messageText==true){
                  let msg = message.slice(6);
                  message = '<pre><code>'+msg+'</code></pre>';
                }else{
                  message = message;
                }

                const refId = new Date().valueOf();
                const payLoad = {
                    group_id,
                    message,
                    unique_ref_id: refId
                };
                this.messageFormation(payLoad);
                document.activeElement.innerHTML="";
                this.textMessage = null;
                this.chatService.sendGroupMessage(payLoad).subscribe(
                    (messageRes) => {
                        if (messageRes['createdMessage']) {
                            // this.addMessageToArray(messageRes['createdMessage']);
                            this.updateMessageToArray(messageRes['createdMessage']);
                            this.textMessage = null;
                            this.totalRowCount += 1;
                            this.chatListComponent.updateLastMessageinChat(messageRes['createdMessage'], messageRes['createdMessage'].group_id);
                            this.updateChatRecordRecent(this.chatId);
                        }
                    },
                    error => {
                        this.alert.showAlertMessage(error.error);
                    }
                )
            } else {
                this.sendMessageFile('group');
            }
        } catch (err) {
            console.log(err);
        }
    };


    /** Pre Message formation to append UI */
    messageFormation(payLoad) {
        const tempMessage: any = {
            unique_ref_id: payLoad.unique_ref_id,
            message_id: null,
            message: payLoad.message,
            day: moment().format('MMM DD, YYYY'),
            from_user_id: this.send_user_detail.user_id,
            to_user_id: payLoad.to_user_id,
            group_id: payLoad.group_id,
            message_type: 'chat',
            file_type: null,
            user_id: this.send_user_detail.user_id,
            first_name: this.send_user_detail.first_name,
            last_name: this.send_user_detail.last_name,
            user_icon: this.send_user_detail.user_icon,
            is_delivered: false,
            is_read: false
        };
        this.addMessageToArray(tempMessage);
    };

    addMessageToArray(message) {
        if (message) {
            if (this.getMessageList) {
                const lastMessage = _.last(this.getMessageList);
                if (lastMessage && lastMessage.day === message.day) {
                    message.showDay = false;
                } else {
                    message.showDay = true;
                }
                this.getMessageList.push(message);
                this.scrollBottom();
                return;
            } else {
                this.getMessageList = [];
                this.getMessageList.push(message);
                this.scrollBottom();
                return;
            }
        }
    };

    /** Update Message sent information to append UI */
    updateMessageToArray(message) {
        if (message) {
            const messageList = this.getMessageList;
            const msgIndex = messageList.findIndex(msg => msg.unique_ref_id === message.unique_ref_id);
            if (msgIndex >= 0) {
                message.showDay = messageList[msgIndex].showDay;
                messageList[msgIndex] = message;
            }

            this.getMessageList = messageList;
        }
    };

    /**Message shift enter wrap */

    _wrapTextMessage(e){

      if(e.keyCode==13 && !e.shiftKey){
        e.preventDefault();
        document.execCommand('innerHTML', false, '<br/>');
        return false;
      }
    }

    cursorEndPosition(e){

    }



    chatMessageCodeBlock(message){
      let $input = message.substring(0, 6);

      if($input == '/code '){
        return true;
      }else{
        return false;
      }
    }

    //Paste plain html contenteditable
    handleTextPaste(e){

      let content;
        e.preventDefault();

        if( e.clipboardData ){
          content = e.clipboardData.getData('text/plain');
          document.execCommand('insertText', false, content);
          return false;
        }

        // else if( window.clipboardData ){
        //   content = window.clipboardData.getData('Text');
        //   if (window.getSelection)
        //     window.getSelection().getRangeAt(0).insertNode( document.createTextNode(content) );
        // }
    }


    /** Message with day Formation */

    groupedCollection(msgList) {
        if (msgList && msgList.length > 0) {
            const groupedCollection = msgList.reduce((previous, current) => {
                const keyObj: any = {};
                if (previous.day === current.day) {
                    previous.day = current.day;
                    current.showDay = false;
                } else {
                    previous.day = current.day;
                    current.showDay = true;
                }
                return previous;
            }, {});
            return msgList;
        } else {
            return [];
        }
    };


    updateChatRecordRecent(chatId) {
        this.chatListComponent.chatList.find((chat): any => {
            if (chatId === chat.chat_id) {
                chat.updatedAt = moment().format();
                return chat;
            }
        });
    };

    /**
     * After open the user message update message delivered and read
     * @param messageList
     */
    updateMessageRead(messageList) {
        const logUserId = this.loggedUserId;
        if (messageList && messageList.length > 0) {
            const filterMsgList = _.filter(messageList, function (eachMsg) {
                return (eachMsg.from_user_id !== logUserId);
            });
            const payLoad = _.pick(_.last(filterMsgList), 'group_id', 'message_id', 'from_user_id', 'to_user_id', 'user_id', 'is_read');
            if (payLoad.message_id && !payLoad.is_read) {
                this.chatService.updateMessageRead(payLoad).subscribe(
                    response => {
                        if (response && response['user_id'] !== 0) {
                            this.chatListComponent.chatList.find((user): any => {
                                if (response['user_id'] === user.user_id) {
                                    return user.unread_msg_count = 0;
                                }
                            });
                        }
                        if (response && response['group_id'] !== 0) {
                            this.chatListComponent.chatList.find((user): any => {
                                if (response['group_id'] === user.group_id) {
                                    return user.unread_msg_count = 0;
                                }
                            });
                        }
                    },
                    error => { console.log(error) }
                )
            }
        }
    };

    /** * @param response */
    emitReadUpdate(response) {
        let updateDelivery = {};
        updateDelivery = _.pick(response, 'message_id', 'chat_type', 'from_user_id', 'to_user_id');
        this.socketService.updateReadMessage(updateDelivery);
    };

    onScrollUp(event) {
        const listLength = this.getMessageList.length;

        if ((this.totalRowCount > listLength) && !this.showPaginateSpinner && this.otherUserId !== null) {
            this.showPaginateSpinner = true;
            this.OFFSET += 1;
            const offset = this.OFFSET;
            const to_user_id = this.otherUserId;
            // const to_group_id = this.selectedGroupId;
            const payLoad = {
                userId: to_user_id,
                // groupId: to_group_id,
                offset: offset,
                limit: this.RECORD_LIMIT,
            };
            let api = 'getMessagePaginationList';

            const beforeScrollHeight = this.myScrollContainer.nativeElement.scrollHeight;

            this.chatService[api](payLoad).subscribe(
                response => {
                    // console.log('onscroll', response);
                    this.showPaginateSpinner = false;
                    if (response['message'] && response['message'].length > 0) {
                        if(this.otherUserId === response['message'][0].from_user_id) {
                            const concatArray = response['message'].concat(this.getMessageList);
                            this.getMessageList = this.groupedCollection(concatArray);
                            this.keepScrollBarPosition(beforeScrollHeight);
                            // console.log('same id')
                        }
                        // this.getMessageList = response['message'].concat(this.getMessageList);

                    }
                },
                error => {
                    this.showPaginateSpinner = false;
                    this.alert.showAlertMessage(error.error);
                });
        } else if ((this.totalRowCount > listLength) && !this.showPaginateSpinner && this.activeUrl === 'groupuser') {
            this.showPaginateSpinner = true;
            this.OFFSET += 1;
            const offset = this.OFFSET;
            // const to_user_id = this.otherUserId;
            const to_group_id = this.selectedGroupId;
            const payLoad = {
                groupId: to_group_id,
                offset: offset,
                limit: this.RECORD_LIMIT,
            };
            let api = 'groupMessagePagination';

            const beforeScrollHeight = this.myScrollContainer.nativeElement.scrollHeight;
            console.log(beforeScrollHeight);

            this.chatService[api](payLoad).subscribe(
                response => {
                    // console.log('onscroll', response);
                    this.showPaginateSpinner = false;
                    if (response['message'] && response['message'].length > 0) {
                            const concatArray = response['message'].concat(this.getMessageList);
                            this.getMessageList = this.groupedCollection(concatArray);
                            this.keepScrollBarPosition(beforeScrollHeight);
                        }
                        // this.getMessageList = response['message'].concat(this.getMessageList);
                },
                error => {
                    this.showPaginateSpinner = false;
                    this.alert.showAlertMessage(error.error);
                });
        }
        // if ((this.totalRowCount > listLength) && !this.showPaginateSpinner) {
        //     if ((this.totalRowCount > listLength) && !this.showPaginateSpinner && this.otherUserId !== null) {
        //     this.showPaginateSpinner = true;
        //     this.OFFSET += 1;
        //     const offset = this.OFFSET;
        //     const to_user_id = this.otherUserId;
        //     const to_group_id = this.selectedGroupId;
        //     const payLoad = {
        //         userId: to_user_id,
        //         groupId: to_group_id,
        //         offset: offset,
        //         limit: this.RECORD_LIMIT,
        //     };
        //     let api: string;
        //     if (to_group_id) {
        //         api = 'groupMessagePagination';
        //     } else {
        //         api = 'getMessagePaginationList';
        //     }

        //     const beforeScrollHeight = this.myScrollContainer.nativeElement.scrollHeight;
        //     this.chatService[api](payLoad).subscribe(
        //         response => {
        //             // console.log('onscroll', response);
        //             this.showPaginateSpinner = false;
        //             if (response['message'] && response['message'].length > 0) {
        //                 if(this.otherUserId === response['message'][0].from_user_id && !to_group_id && this.activeUrl === 'singleuser') {
        //                     const concatArray = response['message'].concat(this.getMessageList);
        //                     this.getMessageList = this.groupedCollection(concatArray);
        //                     this.keepScrollBarPosition(beforeScrollHeight);
        //                     // console.log('same id')
        //                 } else if(to_group_id && this.activeUrl === 'groupuser') {
        //                     const concatArray = response['message'].concat(this.getMessageList);
        //                     this.getMessageList = this.groupedCollection(concatArray);
        //                     this.keepScrollBarPosition(beforeScrollHeight);
        //                 }
        //                 // this.getMessageList = response['message'].concat(this.getMessageList);

        //             }
        //         },
        //         error => {
        //             this.showPaginateSpinner = false;
        //             this.alert.showAlertMessage(error.error);
        //         });
        // } else if (this.activeUrl === 'groupuser') {

        // }
    };

    showAudioUserList() {
        this._domParams.showUserList = !this._domParams.showUserList;
        this.audioTabShow = true;
    }

    showGroupUserList() {
        this._domParams.showUserList = !this._domParams.showUserList;
    };

    showUserInformation(tabName){
      this._domParams.showUserList = true;
      this.hidechatContent=true;
      this.isActiveTabName = tabName;
      this.eventsSubject.next(tabName);
      // this._domParams.showUserList = !this._domParams.showUserList;
    }

    hideChatList() {
        this.chatListComponent.viewChatList = !this.chatListComponent.viewChatList;
     
    };

    scrollBottom() {
        try {
            setTimeout(() => {
                this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
            }, 75);

        } catch (err) {
            console.log(err);
        }
    };

    keepScrollBarPosition(beforeScrollHeight) {
        try {
            setTimeout(() => {
                this.myScrollContainer.nativeElement.scrollIntoView()
                // this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight - beforeScrollHeight;
            }, 200)
        } catch (err) {
            console.log(err);
        }
    };

    changeChatView() {
        this.chatViewChange = !this.chatViewChange
    };

    toggleFilePreview() {
        this._domParams.hideFilePreview = !this._domParams.hideFilePreview;
    };

    updateChatGroup(editedGroup) {
        try {
            const payLoad = {
                groupId: editedGroup.chat_group_id,
                chat_group_name: editedGroup.chat_group_name
            };
            this.chatService.updateChatGroup(payLoad).subscribe(
                response => {
                    this.bindUpdatedGroupDetail(response['updatedChatGroupDetail']);
                    this.editGroupDetails = null;
                    this.alert.showAlertMessage(response);
                },
                error => {
                    this.alert.showAlertMessage(error.error);
                }
            )

        } catch (err) {
            console.log(err);
        }
    };

    /** Update Group deatail internal function both API & socket */

    bindUpdatedGroupDetail(response) {
        if (response) {
            const actualGrpDeatail = { ...this.selectedGroupDetail };
            actualGrpDeatail.chat_group_name = response.chat_group_name;
            this.selectedGroupDetail = actualGrpDeatail;
            const chatArrList = [...this.chatListComponent.chatList];
            chatArrList.map((el) => {
                if (el.group_id === actualGrpDeatail.chat_group_id) {
                    el.full_name = response.chat_group_name;
                }
            });
            this.chatListComponent.chatList = chatArrList;
        }
    };

    updatedGroupIconChanges(response) {
        if (response) {
            const actualGrpDeatail = { ...this.selectedGroupDetail };
            actualGrpDeatail.chat_group_icon = response.chat_group_icon;
            this.selectedGroupDetail = actualGrpDeatail;
            const chatArrList = [...this.chatListComponent.chatList];
            chatArrList.map((el) => {
                if (el.group_id === actualGrpDeatail.chat_group_id) {
                    el.group_icon = response.chat_group_icon;
                }
            });
            this.chatListComponent.chatList = chatArrList;
        }
    };


    /************ Append Emoji text with actual message ***************/

    getPickedEmoji(emoji) {

        try {
            let typedText = null;
            if (this.textMessage) {
                typedText = `${this.textMessage}${emoji}`;
            } else {
                typedText = emoji;
            }

            document.getElementsByClassName('contenteditable')[0].innerHTML=typedText;

            const elm = document.getElementsByClassName('contenteditable')[0];
            this.setEndOfContenteditable(elm);

            this.textMessage = typedText;
            this.messageInputEl.nativeElement.focus();
        } catch (err) {
            console.log(err);
        }
    };

    setEndOfContenteditable(contentEditableElement)
    {
        var range,selection;
        if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
        {
            range = document.createRange();
            range.selectNodeContents(contentEditableElement);
            range.collapse(false);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        }

    }


    /** Update Group profile icon */

    changeGroupIcon(event, groupId) {
        const files = event.target.files;
        if (files && files.length > 0) {
            const formFields = {
                fieldName: 'group_profile',
                activity: 'group_icon_update',
                chat_group_id: groupId
            };

            this.groupIconFileList.push(files[0]);
            this.fileValidationService.fileValidation(files, this.profileExtension, this.profileSize).then(validResponse => {
                this.appComponent.fileUploadWithProgess(formFields, this.groupIconFileList).then((fileUpLoadResponse:any)=> {
                    for (const key in fileUpLoadResponse) {
                        if (fileUpLoadResponse[key]) {
                            this.updatedGroupIconChanges(fileUpLoadResponse[key]['groupProfile']);
                        }
                    }
                }).catch(error => {
                    this.alert.showAlertMessage(error.error);
                });
                this.fileInput.nativeElement.value = '';
            }).catch(err => {
                this.fileInput.nativeElement.value = '';
                this.groupIconFileList = [];
                err.map(li => this.alert.showAlertMessage(li));
            });
        }
    };


    /** Delete Chat Group Icon */
    deleteChatGroupIcon(groupId) {
        try {
            this.chatService.deleteChatGroupIcon(groupId).subscribe(
                response => {
                    this.updatedGroupIconChanges(response['groupProfile']);
                },
                error => {
                    this.alert.showAlertMessage(error.error);
                });
        } catch (err) {
            console.log(err);
        }
    };

    /** Select And Drag and Drop selected file from change event */

    async fileValidationHanlder(files) {
        try {
            const isValidate = await this.fileValidationService.fileValidation(files, this.chatFileExt, this.chatFileSizeLimit);
            return files;
        } catch (err) {
            err.map(li => {
                const errMsg = {
                    message: li
                };
                this.alert.showAlertMessage(errMsg);
            });
            this.msgFileInput.nativeElement.value = '';
            return false;
        }
    };

    async onDropHandler(event) {
        const files = event;
        const fileValid = await this.fileValidationHanlder(files);
        if (fileValid) {
            this.concatFileIntoArray(files);
        }
        // this.msgFileInput.nativeElement.value = '';
    };

    async chooseUploadFile(event) {
        const files = event.target.files;

        const vidData = files[0].type.split("/");


      if(vidData[0]==='video' || vidData[0]==='audio'){

        let video = document.createElement(vidData[0])
        const vid = await this.onloadVideo(files[0], video);

          var sec = vid['duration'];
          var h, m, s, result='';
          // HOURs
          h = Math.floor(sec/3600);
          sec -= h*3600;
          if(h){
              result = h<10 ? '0'+h+':' : h+':';
          }
          // MINUTEs
          m = Math.floor((sec % 3600) / 60);
          sec -= m*60;
          result += m<10 ? '0'+m+':' : m+':';
          // SECONDs
          s=Math.floor((sec % 3600) % 60);
          result += s<10 ? '0'+s : s;

        var videoDuration = result;

        this.fileDuration = videoDuration;
      }

        const fileValid = await this.fileValidationHanlder(files);
        if (fileValid) {
            this.concatFileIntoArray(files);
        }

        // this.concatFileIntoArray(files);
        // this.msgFileInput.nativeElement.value = '';
    };

    onloadVideo(files, video){
      return new Promise((resolve, reject) => {
       try {

           video.preload = 'metadata'

           video.onloadedmetadata = function () {
               resolve(this)
           }
           video.src = window.URL.createObjectURL(files)
       } catch (event) {
           reject(event)
       }
     });
   }

    concatFileIntoArray(files) {
        if (files && files.length > 0) {
            if (this.upLoadedFileList.length < 1) {
                const fileObject = {
                    user_id: null,
                    group_id: null,
                    files: []
                };
                const paramId = +this.route.snapshot.paramMap.get('id');
                this._domParams.hideFilePreview = true;
                let fileEl = null;
                let existFileList = null;
                for (let i = 0; i < files.length; i++) {
                    const splitArr = files[i].name.split('.');
                    files[i].mimeType = splitArr[splitArr.length - 1].toLowerCase();
                    files[i].uniqId = Date.now();
                    existFileList = [...this.appComponent.selectedFilesList];
                    if (this.activeUrl && this.activeUrl === 'singleuser') {
                        fileEl = existFileList.find(el => el.user_id === paramId);
                        if (fileEl) {
                            fileEl.files.unshift(files[i]);
                            this.upLoadedFileList = fileEl.files;
                        } else {
                            fileObject.user_id = paramId;
                            fileObject.files.push(files[i]);
                            this.upLoadedFileList = fileObject.files;
                            existFileList.unshift(fileObject);
                        }
                    } else if (this.activeUrl && this.activeUrl === 'groupuser') {
                        fileEl = existFileList.find(el => el.group_id === paramId);
                        if (fileEl) {
                            fileEl.files.unshift(files[i]);
                            this.upLoadedFileList = fileEl.files;
                        } else {
                            fileObject.group_id = paramId;
                            fileObject.files.push(files[i]);
                            this.upLoadedFileList = fileObject.files;
                            existFileList.unshift(fileObject);
                        }
                    }
                }

                this.appComponent.selectedFilesList = existFileList;
                this.messageInputEl.nativeElement.focus();
            } else {
                this.alert.showAlertMessage({
                    message: 'File seletion limit reached.'
                });
            }
        }
    };


    /** Remove files from selected list */
    removeFileFromList(val) {
        const newFileList = this.upLoadedFileList;
        const currentFiles = newFileList.filter(fl => fl.uniqId !== val.uniqId);
        const paramId = +this.route.snapshot.paramMap.get('id');
        let fileEl = null;
        const existFileList = this.appComponent.selectedFilesList;
        if (this.activeUrl && this.activeUrl === 'singleuser') {
            fileEl = existFileList.find(el => el.user_id === paramId);
            if (fileEl) {
                fileEl.files = currentFiles;
            }
        } else if (this.activeUrl && this.activeUrl === 'groupuser') {
            fileEl = existFileList.find(el => el.group_id === paramId);
            if (fileEl) {
                fileEl.files = currentFiles;
            }
        }
        this.upLoadedFileList = currentFiles;
        this.appComponent.selectedFilesList = existFileList;
    };

    sendMessageFile(msgType) {
        if (this.upLoadedFileList && this.upLoadedFileList.length > 0 && !this.fileUploadingInProgress) {
            const formFields: any = {
                fieldName: null,
                activity: null,
                to_user_id: null,
                group_id: null,
                message: null
            };

            const existFileList = [...this.appComponent.selectedFilesList];
            let fileIndex = null;
            formFields.message = this.textMessage?this.textMessage: '';
            if (msgType === 'user') {
                formFields.fieldName = 'user_gallery';
                formFields.activity = 'singleChatMessage';
                formFields.to_user_id = `${this.otherUserId}`;
                fileIndex = existFileList.findIndex(el => el.user_id === this.otherUserId);
                if (fileIndex >= 0) {
                    existFileList.splice(fileIndex, 1);
                }
            }

            if (msgType === 'group') {
                formFields.assessment = [];
                formFields.survey = [];
                formFields.polls = [];
                formFields.is_download = this.download_link;
                formFields.price = this.price;
                formFields.discount = this.discount;
                formFields.water_mark_title = '';
                formFields.description = '';
                formFields.duration = this.fileDuration;
                formFields.fieldName = 'group_gallery';
                formFields.activity = 'groupChatMessage';
                formFields.is_question_found = this.is_question_found;
                formFields.is_publish = this.publish;
                formFields.is_survey_found = this.is_survey_found;
                formFields.is_polls_found = this.is_polls_found;
                formFields.group_id = `${this.selectedGroupId}`;
                formFields.chat_user_list = this.selectedGroupDetail.chat_user_list;
                fileIndex = existFileList.findIndex(el => el.group_id === this.selectedGroupId);
                if (fileIndex >= 0) {
                    existFileList.splice(fileIndex, 1);
                }
            }
            document.activeElement.innerHTML="";
            this.textMessage = null;
            this.appComponent.selectedFilesList = existFileList;
            this.fileUploadingInProgress = true;
            this.appComponent.fileUploadWithProgess(formFields, this.upLoadedFileList).then((fileList: any): void => {
                for (const key in fileList) {
                    if (fileList[key]) {
                        this.addMessageToArray(fileList[key]);

                        if (this.otherUserId) {
                            this.chatListComponent.updateLastMessageinChat({
                                chat_type: 'user',
                                message: fileList[key].message,
                                file_id: fileList[key].file_id
                            }, this.otherUserId);
                        } else if (this.selectedGroupId) {
                            this.chatListComponent.updateLastMessageinChat({
                                chat_type: 'group',
                                message: fileList[key].message,
                                file_id: fileList[key].file_id
                            }, this.selectedGroupId);
                        }
                    }
                }

                if (this.upLoadedFileList.length === 0) {
                    this.fileUploadingInProgress = false;
                }

            }).catch(error => {
                this.fileUploadingInProgress = false;
                this.alert.showAlertMessage(error.error);
            });
        }
    };


    /** Toggle Chat bot view */
    toggleChatBotPanel() {
        this.chatService.openBotView();
    }

    hideSingleChat(){
        this.hidechatContent = true;
        this.chatListComponent.viewChatList = false;
        this.router.navigateByUrl("/main/chats");
    }

    ngOnDestroy() {
        this.upLoadedFileList = [];
        this.getMessageList = [];
        this.subscribers.userMessage.unsubscribe();
        this.subscribers.groupMessage.unsubscribe();
        this.subscribers.updateDelivery.unsubscribe();
        this.subscribers.readBulkMessage.unsubscribe();
        this.subscribers.dataAnswerd.unsubscribe();
       // this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} });

        window.removeEventListener('paste', this.clipboardPasteHandler, false);
        window.removeEventListener('focus', this.focusHandler, false);
        window.removeEventListener('blur', this.focusBlurHandler, false);
    };

}
