import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuizService } from './quiz.service';
import { QuizRoutingModule } from './quiz-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { VideoQuizComponent } from './video-quiz.component';
import { VideoQuizDetailsComponent } from './video-quiz-details/video-quiz-details.component';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuizRoutingModule,
    SharedModule,
    VgControlsModule,
    VgCoreModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
  declarations: [
    VideoQuizComponent,
    VideoQuizDetailsComponent
  ],
  providers:[
    QuizService
  ]
})
export class VideoQuizModule { }
