import { Component, OnInit, Input, Directive  } from '@angular/core';
// @Directive()
@Component({
    selector: 'pagination-loader',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationLoaderComponent implements OnInit {
    @Input() showFetchLoader: boolean;
    ngOnInit() {}
}
