import { Pipe, PipeTransform } from '@angular/core';

@Pipe ({
  name: 'decimalPoint'
})

export class DecimalPipe implements PipeTransform {

  transform(value: number): string {
    var num1 = Math.ceil(value * 10) / 10;
    return num1.toFixed(2);
  }

}
