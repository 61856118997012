import { Injectable } from '@angular/core';
import { AlertService } from './notification-alert/alert.service';
import { LoaderService } from './loader/loader.service';
import * as _ from 'underscore';
import { environment } from 'environments/environment';

export const config = {

    HOST: environment.host,
    SOCKET_HOST: environment.socket,
    PORT: environment.port,
    PROTOCOL: environment.protocol,

    DOMAIN_NAME: 'www.canstringbot.com',
    // DOMAIN_NAME: 'demo.canstrings.com',
    VERSION: 'v1',
    DEFAULT_RECORD_LIMIT:25,
    DEFAULT_OFFSET: 0,
    DEFAULT_SCROLL_DIST: 2,
    SCROLL_THROTTLE: 300,
    PROFILE_SIZE: 1024, // 1 GB
    PROFILE_EXT: 'PNG,JPEG,JPG',
    POST_FILE_EXT: 'PNG,JPEG,JPG,MP4',
    TOPIC_EXT:'PNG,JPEG,JPG,SVG,MP3,MP4,WAV,PDF,PPT,PPTX,PPTM,XML,XLS,XLSX,XLSM,XLW,DOC,DOCX,TXT',
    TOPIC_FILE_SIZE: 1024, // 1 GB
    ATTACHMENT_SIZE: 1024, // 1 GB
    ATTACHMENT_EXT: 'PNG,JPEG,JPG,SVG,MP3,MP4,WAV,AVI,FLV,JS,PDF,CSV,PPT,PPTX,PPTM,XML,PSD,XLS,XLSX,XLSM,XLW,SQL,JAR,CSS,HTML,HTM,PHP,ZIP,RAR,DOC,DOCX,TXT,DAT,RPTDESIGN,JSON',
    ATTACHMENT_LIMIT: 5,
    ASSIGN_FILE_EXT: 'PNG,JPEG,JPG,SVG,PDF,PPT,PPTX,PPTM,DOC,DOCX',
    ASSIGN_FILE_SIZE: 1024, // 1GB
    NOTIFICATION_TIMEOUT: 5000,
    MAX_ATTACH_FILE_SIZE: 1024, //1 GB
    ENCRYPTION: true,
    SECRETKEY: 'WIiOiIxMjM0NTY3ODkwIiwibmFtZ',
    TIMINIG: 10000,
    copyrights: new Date().getFullYear(),
    fileSupportFormat: 'png, jpeg, jpg, svg, mp3, mp4, wav, pdf, ppt, pptx, pptm, xml, xls, xlsx, xlw, doc, docx, txt'
};


///company/profile/:file_id

export const path = {
    PROFILE_URL: `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/users/profile/`,
    PRE_PROFILE_URL: `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/users/preProfile/`,
    NEW_PROFILE_URL: `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/users/readfile`,
    COMPANY_URL: `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/company/profile/`
};

export const BASE_URI = {
    URL: `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`
};

export const UPLOAD_URI = {
    URL: `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/users/fileUploadProgress`
};

export const AUTH_TOKEN = {
    TOKEN: localStorage.getItem('token')
};

export const COMPANY_ID = {
  Company_id : localStorage.getItem('company_id')
};

// export const MENU_ICON = {
//     chats: 'fa-commenting',
//     channels: 'fa-comments-o',
//     // bulletineBoard: 'fa-thumb-tack',
//     user_and_group: 'fa-users',
//     settings: 'fa-cog',
//     attendance: 'fa-calendar-times-o',
//     chatbot: 'fa-weixin',
//     home_work: 'fa-thumb-tack',
//     staff_room:'fa fa-joomla',
//     study_room: 'fa fa-book'
// };

export const MENU_ICON = {
  user_and_group: '../../../assets/images/usermanage.png',
  //user_and_group: '../../../assets/images/Stu_reg.png',
  chats: '../../../assets/images/conversation.png',
  channels: '../../../assets/images/channels.png',
  settings: '../../../assets/images/setting.png',
  attendance: '../../../assets/images/attendance.png',
  video_quiz: '../../../assets/images/video-quiz.png',
  // chatbot: 'fa-weixin',
  // home_work: 'fa-thumb-tack',
  staff_room:'../../../assets/images/staff-room.png',
  study_room: '../../../assets/images/study-room.png',
  virtual_lab: '../../../assets/images/virtual_lab.png',
  elibraryandcourse: '../../../assets/images/online-library.png',
  aiassist: '../../../assets/images/ai_assist.png',
  feedback:'../../../assets/images/feedback.png'
};

export interface ResolveEmit {
    resolved?: boolean;
    closedWithOutResolving?: string;
};


@Injectable()
export class AlertPopup {
    constructor(private alertService: AlertService, private loaderService: LoaderService) {
    }

    public showAlertMessage(response) {
        if (_.isObject(response)) {
            this.alertService.success(response['message']);
        }
    };
};

@Injectable()
export class HttpLoader {
    constructor(private loaderService: LoaderService) {
    }

    public showLoader(): void {
        this.loaderService.showLoader();
    }

    public hideLoader(): void {
        this.loaderService.hideLoader();
    }
};




