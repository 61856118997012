import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { HomeworkRoutingModule } from './homework.routing';
import { HomeworkComponent } from './homework.component';
import { ValidationService } from '../form-validation/validation.service';
import { HomeworkService } from './homework.service';

@NgModule({
  imports:[
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HomeworkRoutingModule
  ],
  declarations:[
    HomeworkComponent
  ],
  providers: [ValidationService, HomeworkService]
})

export class HomeworkModule { }
