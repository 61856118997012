import { Component, OnInit, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup,  Validators, FormBuilder } from '@angular/forms';
import { config } from '../../app.config';
import { LoginService } from './login.service';
import { AppComponent } from './../../app.component';
import { SocketService } from './../../socket/socket.service';
import { AlertPopup, HttpLoader } from './../../app.config';
import { ValidationService } from '../form-validation/validation.service';
import { DataSharingService } from 'app/shared/data-sharing.service';

// @Directive()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: []
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showBtnSpinner = false;
  showSpinner = false;
  loginErrMsg: string = "";
  copyRights: number = config.copyrights;
  show: boolean;
  isloginBtnShow = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private socketService: SocketService,
    private loader: HttpLoader,
    private alert: AlertPopup,
    private dataSharingService: DataSharingService,
    private app: AppComponent) {
    this.loginForm = formBuilder.group({
      email_id: ['', Validators.compose([Validators.required, ValidationService.emailValidator])],
      password: [null]
    });

    // this.loginForm = formBuilder.group({
    //   email_id: ['baskar@test.com', Validators.required],
    //   password: ['password123', Validators.required]
    // });

  }

  ngOnInit() {};

  showHidePassword(){
    this.show = !this.show;
  }


  clearLocalStorage() {
    window.localStorage.clear();
  };

  /**
   * User Authentication Email Verified Function
   * @param formValue
   */

  authenticateEmailVerify(formValue){
    try {
      this.showSpinner = true;
      this.loader.showLoader();
      this.loginService.authenticateEmail(formValue).subscribe((response:any)=> {
        this.loader.hideLoader();

        if (response.data['acc_active']===true) {
          this.showSpinner = false;
          this.isloginBtnShow = true;
          this.loginForm.get('password').setValidators([Validators.required, ValidationService.passwordValidate]);
          this.loginForm.controls['password'].enable();
        }
        if(response.data['acc_active']==false){
          this.showSpinner = false;
          this.loader.hideLoader();
          this.alert.showAlertMessage({'message':'Your account has been deactivated'});
        }
      }, error => {
        this.alert.showAlertMessage(error.error);
        this.showSpinner = false;
        this.loader.hideLoader();
      })
    } catch (error) {
      this.loader.hideLoader();
      this.showSpinner = false;
      console.log(error);
    }
  }

  /**
   * User Authentication Function
   * @param formValue
   */
  authenticateUser(formValue) {
    try {
      this.showBtnSpinner = true;
      this.isloginBtnShow = true;
      this.loader.showLoader();
      this.loginService.authenticateUser(formValue).subscribe(
        (user) => {
          this.loader.hideLoader();
          window.localStorage.user_id = JSON.stringify(user['user_id']);
          window.localStorage.user_role = JSON.stringify(user['user_role']);
          window.localStorage.token = JSON.stringify(user['token']);
          window.localStorage.company_id = JSON.stringify(user['company_id']);
          this.app.getCurrentUserData();
          this.app.getUserStatusData();
          // this.socketService.initiateSocket().then(connected => {
          //   this.app.observableRefresh();
          // }).catch(err => {
          //   console.log(err);
          // });
          this.showBtnSpinner = false;
          if(user['user_role']==1){
            this.router.navigate(['main/aiassist']);
           /// this.router.navigate(['/elibraryandcourse/e-library']);
          }else{
            this.router.navigate(['main/aiassist']);
           // this.router.navigate(['/elibraryandcourse/e-library']);
          }


        },
        (error) => {
          const errMsg = JSON.stringify(error.error['message']).replace(/['"]+/g, '');
          this.loginErrMsg = errMsg;
          // this.alert.showAlertMessage(error.error);
          this.showBtnSpinner = false;
          this.loader.hideLoader();
        }
      )
    } catch (err) {
      this.loader.hideLoader();
    }
    // this.hintService.initialize({elementsDisabled: false});
  };

  removeErrMsg(){
    this.loginErrMsg = "";
  }

  redirectToforgetpass() {
    let forgotMail = this.loginForm.get('email_id').value;

    this.dataSharingService.emitgetForgotMailId(forgotMail);
    window.sessionStorage.setItem('email_id' , forgotMail);

    this.router.navigate(['/reset_password']);
  };

  redirectTosignup() {
    this.router.navigate(['/sign_up']);
  };

}



