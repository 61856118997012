import { Component, OnInit, Input, OnDestroy, Directive  } from '@angular/core';

// @Directive()
@Component({
    selector: 'app-player',
    templateUrl: './media-player-component.html',
    styleUrls: ['./media-player-component.scss']
})

export class PlayerComponent implements OnInit, OnDestroy {

    @Input() mediaContent: any;
    inputSrc: string;
    currentTrack: any;

    constructor(
    ) {
    }

    ngOnInit() {
        const fileSrc = this.mediaContent ? this.mediaContent.file_path : null;
        this.inputSrc = fileSrc.replace('securefileDownload', 'secureReadfile');
    };

    closeMedia() {
        this.mediaContent = null;
    }

    ngOnDestroy(): void {
        this.inputSrc = null;
    }

}



