import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { VideoQuizComponent } from './video-quiz.component';
import { VideoQuizDetailsComponent } from './video-quiz-details/video-quiz-details.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {path: '', component: VideoQuizComponent, children:[
        {path: ':id', component: VideoQuizDetailsComponent}
      ]}
    ])
  ],
  exports:[RouterModule]
})
export class QuizRoutingModule { }
