// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// `ng build --env=stage` then `environment.stage.ts` will be used instead.
// `ng build --env=demo` then `environment.demo.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  host: 'gpt.canstringai.com',
  socket: 'gpt.canstringai.com',
  // host: 'matrix.canstringclass.com',
  // socket: 'matrix.canstringclass.com',
  port: '443',
  protocol:'https',
  // host: 'localhost',
  // socket: 'localhost',
  // port: '8080',
  // protocol:'http',
  // host: window["env"]["apiUrl"] || "localhost",
  // socket:window["env"]["apiUrl"] || "localhost",
  // debug: window["env"]["debug"] || false
  stripe:{
    publicKey:"pk_test_51PLHHNSDuguvJYJa9K8NaQ05NCohHREKIYV00wjG7xFfVYkbYAbajWRcHxmq5mkEJKCVcfBzRhm9RDiVInIm5W9D00psJWj24u"
  }
};
