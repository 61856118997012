import { Pipe, PipeTransform } from '@angular/core';

@Pipe ({
  name: 'highlight'
})

export class Highlight implements PipeTransform {
  transform(value: string, args: string): any {


    if (args && value) {
        let startIndex = value.toLowerCase().indexOf(args.toLowerCase());
        console.log(startIndex);
        if (startIndex != -1) {
            let endLength = args.length;
            let matchingString = value.substr(startIndex, endLength);
            return value.replace(matchingString, "<mark>" + matchingString + "</mark>");
        }

    }
    return value;
}
}
