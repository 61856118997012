import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionComponent } from './subscription.component';
import { AuthGuardService as AuthGuard } from './../../auth-guard.service';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: SubscriptionComponent,
        canActivate: [AuthGuard]
      }
    ])
  ],
  exports:[
    RouterModule
  ]
})
export class SubscriptionRoutingModule { }
