import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HttpModule } from '@angular/http';
import { SharedModule } from 'app/shared/shared.module';

import { UserComponent } from './user/user.component';
import { GroupComponent } from './group/group.component';
import { NewUserComponent } from './user/new-user/new-user.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { NewGroupComponent } from './group/new-group/new-group.component';
import { GroupDetailComponent } from './group/group-detail/group-detail.component';
import { UserService } from './user/user.service';
import { GroupService } from './group/group.service';
import { UserandgroupRoutingModule } from './userandgroup-routing.module';
// import { FormValidationComponent } from '../form-validation/form-validation.component';
import { ValidationService } from '../form-validation/validation.service';
import { UserlistComponent } from './user/userlist/userlist.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxPaginationModule } from 'ngx-pagination';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    UserandgroupRoutingModule,
    SharedModule,
    Ng2TelInputModule,
    NgxPaginationModule
  ],
  declarations: [
    UserComponent,
    GroupComponent,
    NewUserComponent,
    UserDetailComponent,
    NewGroupComponent,
    GroupDetailComponent,
    UserlistComponent,
 
    // FormValidationComponent
  ],
  providers: [UserService, GroupService, ValidationService]
})
export class UserandgroupModule { }
