import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleIconComponent } from './toggle-icon.component';
import { ToggleIconDirective } from './toggle-icon.directive';
 



@NgModule({
  declarations: [ToggleIconComponent,ToggleIconDirective],
  exports:[ToggleIconComponent],
  imports: [
    CommonModule
  ]
})
export class ToggleIconModule { }
