import { Component, OnInit, OnDestroy, Output, ElementRef, ViewChild, Directive  } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { BotService } from './bot.service';
import { ChatService } from './../components/chat-list/chat.service';


// @Directive()
@Component({
  selector: 'app-bot',
  templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.scss']
})
export class BotComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  @ViewChild('botChatArea', { static: true }) private botchatContainer: ElementRef;
  subscribe: any;
  toggleBotChat = false;
  newBotMessage: string;
  messageContent: any = {
    output: [],
    context: {}
  };

  constructor(private botService: BotService, private chatService: ChatService) {
    this.subscription = this.chatService.getBotViewToggle().subscribe(message => this.toggleQuickView());
  }

  ngOnInit() {
    this.postBotMessage('hi');
  };

  toggleQuickView() {
    this.toggleBotChat = !this.toggleBotChat;
  }

  postBotMessage(message) {

    if (message) {

      const payLoad = {
        text: message,
        context: this.messageContent.context ? this.messageContent.context : null
      };


      const newMessage = {
        message: [message],
        sender: 'me'
      };

      this.newBotMessage = null;

      if (this.messageContent.output.length > 0) {
        this.messageContent.output.push(newMessage)
      }

      this.botService.postBotMessage(payLoad).subscribe((response) => {
        const botNewMsg = {
          sender: 'bot',
          message: response['output']['text']
        }
        this.messageContent.output.push(botNewMsg);
        this.messageContent.context = response['context'];
        this.keepScrollBarPosition();
      },
        error => {
          console.log(error);
        });
    }
  };


  keepScrollBarPosition() {
    try {
      setTimeout(() => {
        this.botchatContainer.nativeElement.scrollTop = this.botchatContainer.nativeElement.scrollHeight;
      }, 200)
    } catch (err) {
      console.log(err);
    }
  };


  ngOnDestroy() {
    this.subscription.unsubscribe();
  };

}
