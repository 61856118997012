import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild, Directive  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertPopup, config, HttpLoader, path } from 'app/app.config';
import { SocketService } from 'app/socket/socket.service';
import { Subject } from 'rxjs';

// @Directive()
@Component({
  selector: 'app-purchase-course',
  templateUrl: './purchase-course.component.html',
  styleUrls: []
})
export class PurchaseCourseComponent implements OnInit {

  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('purchaseCourseList', {static: true})
  private purchaseCourseScrollContainer: ElementRef;


  viewPurchaseCourseList = false;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  OFFSET = config.DEFAULT_OFFSET;
  RECORD_LIMIT = config.DEFAULT_RECORD_LIMIT;
  profileUrl = path.PROFILE_URL;
  activeUrl: any;

  viewControl = {
    changedSearchText : false
  }

  sortBy = 'time';
  showSearchSec = true;
  totalRowCount: number;
  search: string;
  public searchSubject = new Subject<any>();
  searchInList = new Subject<any>();
  showPaginateSpinner = false;
  // purchaseCourseList: any[] = [];
  public subscribers: any = {};

  purchaseCourseList:any = [
    {
      TotalLessonCount: 5,
      TotalUserCount: 3,
      chat_id: 1,
      chat_type: "group",
      file_id: "canstring_ai.PNG",
      full_name: "Cost Analysis",
      group_active: true,
      group_icon: null,
      group_id: 1,
      is_online: null,
      is_private: false,
      last_seen: null,
      status_name: null,
      unread_msg_count: 0,
      updatedAt: "2020-12-01T14:37:31.138Z",
      user_icon: null,
      user_id: null,
      user_status: null,
    },
    {
      TotalLessonCount: 5,
      TotalUserCount: 3,
      chat_id: 2,
      chat_type: "group",
      file_id: "canstring_ai.PNG",
      full_name: "Python Programming Masterclass",
      group_active: true,
      group_icon: null,
      group_id: 2,
      is_online: null,
      is_private: false,
      last_seen: null,
      status_name: null,
      unread_msg_count: 0,
      updatedAt: "2020-12-01T14:37:31.138Z",
      user_icon: null,
      user_id: null,
      user_status: null,
    },
    {
      TotalLessonCount: 5,
      TotalUserCount: 3,
      chat_id: 3,
      chat_type: "group",
      file_id: "canstring_ai.PNG",
      full_name: "Python for Data Science and Machine Learning Bootcamp",
      group_active: true,
      group_icon: null,
      group_id: 3,
      is_online: null,
      is_private: false,
      last_seen: null,
      status_name: null,
      unread_msg_count: 0,
      updatedAt: "2020-12-01T14:37:31.138Z",
      user_icon: null,
      user_id: null,
      user_status: null,
    },
    {
      TotalLessonCount: 5,
      TotalUserCount: 3,
      chat_id: 4,
      chat_type: "group",
      file_id: "canstring_ai.PNG",
      full_name: "Data Science Course 2020",
      group_active: true,
      group_icon: null,
      group_id: 4,
      is_online: null,
      is_private: false,
      last_seen: null,
      status_name: null,
      unread_msg_count: 0,
      updatedAt: "2020-12-01T14:37:31.138Z",
      user_icon: null,
      user_id: null,
      user_status: null,
    }
  ];

  courseFileList = [
    {
      created_at: "Dec 01, 2020",
        download_url: "http://localhost:8080/canstring/V1/chats/securefileDownload/image/1606833450421_group_gallery_canstring_ai.PNG",
        file_ext: "png",
        file_id: "canstring_ai.PNG",
        file_path: "1606833450421_group_gallery_canstring_ai.PNG",
        file_size: 170656,
        file_type: "image",
        message: "Dynamic Programming Lesson Image 1",
        message_id: 258,
        message_type: "file",
        shared_by: "dultulitre ",
        thumb_Url: "http://localhost:8080/canstring/V1/chats/secureReadfile/thumb/1606833450421_group_gallery_canstring_ai.PNG",
        view_Url: "http://localhost:8080/canstring/V1/chats/secureReadfile/image/1606833450421_group_gallery_canstring_ai.PNG",
    }
  ];

  uploadFileIcons = [
    {name: "pdf", icons : '../../../../assets/images/file_types/pdf.png'},
    {name: "doc",icons : '../../../../assets/images/file_types/doc.png'},
    {name: "docx",icons : '../../../../assets/images/file_types/docx.png'},
    {name: "txt",icons : '../../../../assets/images/file_types/txt.png'},
    {name: "ppt",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: "pptx",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: "pptm",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: 'mp3',icons : '../../../../assets/images/file_types/mp3.png'},
    {name: 'mp4',icons : '../../../../assets/images/file_types/mp4.png'},
    {name: 'wav',icons : '../../../../assets/images/file_types/mp4.png'},
    {name: 'avi',icons : '../../../../assets/images/file_types/mp4.png'},
    {name: 'png',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'jpeg',icons : '../../../../assets/images/file_types/jpg.png'},
    {name: 'jpg',icons : '../../../../assets/images/file_types/jpg.png'},
    {name: 'flv',icons : '../../../../assets/images/file_types/mp4.png'},
    {name: 'html',icons : '../../../../assets/images/file_types/html.png'},
    {name: 'psd',icons : '../../../../assets/images/file_types/psd.png'},
    {name: 'json',icons : '../../../../assets/images/file_types/json.png'},
    {name: 'xlsx',icons : '../../../../assets/images/file_types/xlsx.png'},
    {name: 'xls',icons : '../../../../assets/images/file_types/xls.png'},
    {name: 'css',icons : '../../../../assets/images/file_types/css.png'},
    {name: 'zip',icons : '../../../../assets/images/file_types/zip.png'},
    {name: 'sql',icons : '../../../../assets/images/file_types/zip.png'}
  ];


  constructor(
    private alert: AlertPopup,
    private httpLoader: HttpLoader,
    private router: Router,
    private route: ActivatedRoute,
    private socketService: SocketService
  ) { }

  ngOnInit(): void {
    this.getPurchaseCourse(0);
  }

  getPurchaseCourse(pageNo){

  }

  showSearchBox(toggle){
    this.showSearchSec = !this.showSearchSec;
  }

  fetchPurchaseCourseList(){

  }

  onScrollDown(){

  }

  secureFileVideo(){

  }

  secureFileRead(){

  }

  secureFileDownLoad(){

  }

  deleteSecureFile(){

  }

}
