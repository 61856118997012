import { Component, OnInit, HostBinding, Input, OnDestroy, AfterViewInit, ViewChild, ElementRef, Directive  } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { config, AlertPopup, HttpLoader } from './../../app.config';
import { NewNotificationComponent } from './new-notification/new-notification.component';
import { NotificationService } from './notification.service';
import { SocketService } from './../../socket/socket.service';

import {Subject} from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';


// @Directive()
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: []
})
export class NotificationComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @HostBinding('style.flex-direction') direction='column';
  @ViewChild('noficationList', { static: true }) private notifyScrollContainer: ElementRef;

  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  // RECORD_LIMIT: number = 11;
  OFFSET: number = config.DEFAULT_OFFSET;
  activeUrl: any;

  showNotifyList = false;
  showEmtystate = true;
  showNotfication = false;
  showListofNotification = false;
  viewNotificatonList = false;

  postList: any[] = [];
  totalRowCount: number;
  showSearchSec = true;
  sortBy = 'name';
  searchNotification: string;
  showPaginateSpinner = false;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  isUserRole: any;
  showPaginateLoader = false;


  viewControl = {
    changedSearchText: false
  };
  searchInList = new Subject<any>();

  private subscribers: any = {};
  constructor(
    private notificationService: NotificationService,
    private alert: AlertPopup,
    private httpLoader: HttpLoader,
    private router: Router,
    private route: ActivatedRoute,
    private socketService: SocketService) {
      this.searchInList
      .debounceTime(500)
      .subscribe(event => {
        this.searchNotification = event.target.value;
        if (!this.searchNotification) {
          return;
        }
        this.viewControl.changedSearchText = true;
        this.getPosts(0);
      });
  }

  ngOnInit() {
    this.getPosts(0);
    this.route.url.subscribe(() => {
      console.log("this.route.snapshot",this.route.snapshot);
      this.activeUrl = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.url[0].path : false;
    });

    this.isUserRole = window.localStorage.getItem('user_role');
    this.isUserRole = parseInt(this.isUserRole.replace(/\"/g, ""));
  };

  ngAfterViewInit() {
    try {
      this.subscribers.userPost = this.socketService.receiveUserPost().subscribe(userPost => {
        if (userPost) {
          const exixtingPostLists = [...this.postList];
          if (userPost.is_new) {
            exixtingPostLists.unshift(userPost);
            this.totalRowCount += 1;
          } else if (!userPost.is_new) {
            exixtingPostLists.find(post => {
              if (post.post_id === userPost.post_id) {
                return post.post_name = userPost.post_name;
              }
            });
          }
          this.postList = exixtingPostLists;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };


  getPosts(pageNo) {
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
      }
      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: this.searchNotification || '',
        sort: this.sortBy
      };
      this.notificationService.getPosts(payLoad).subscribe(
        post => {
          if (offset === 0) {
            this.postList = post['postList'];
            this.scrollToTop();
          } else {
            this.postList = [...this.postList.concat(post['postList'])];
          }
          this.totalRowCount = post['TotalRowCount'];
          this.showPaginateSpinner = false;
          this.httpLoader.hideLoader();
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.showPaginateSpinner = false;
          this.httpLoader.hideLoader();
        }
      )
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  };


  scrollToTop() {
    setTimeout(() => {
      this.notifyScrollContainer.nativeElement.scrollTop = 0;
    }, 100);
  };

  fetchNextNotificationList() {
    const listLength = this.postList.length;
    console.log(listLength);

    if (this.totalRowCount > listLength && !this.showPaginateSpinner) {
      this.OFFSET += 1;
      this.showPaginateSpinner = true;
      this.getPosts(this.OFFSET);
    }
  };

  showSearchbox(toggle) {
    this.showSearchSec = !this.showSearchSec;
    // if (toggle === 'close' && this.searchNotification) {
    if (this.viewControl.changedSearchText) {
      try {
        this.searchNotification = '';
        this.viewControl.changedSearchText = false;
        const offset = 0;
        this.getPosts(offset);
      } catch (err) {
        this.httpLoader.hideLoader();
      }
    }
  }

  newNotification(){
    this.router.navigateByUrl('/main/channels/newChannels');
    this.notifyScrollContainer.nativeElement.hide;
  }
   

  ngOnDestroy() {
    this.subscribers.userPost.unsubscribe();
  };

}

