import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { config } from 'app/app.config';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  baseUrl = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/`;

  constructor(
    private http: HttpClient
  ) { }

  getAllChatSearchList(payLoad){
    return this.http.get(`${this.baseUrl}chats/messageAttachments?offset=${payLoad.offset}&limit=${payLoad.limit}&search=${payLoad.search}`);
  }

  getFilterElibrarySection(payLoad){
    return this.http.get(`${this.baseUrl}chats/messageAttachments?offset=${payLoad.offset}&limit=${payLoad.limit}&search=${payLoad.search}`);
  }
}
