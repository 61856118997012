import { AfterViewInit, Component, ElementRef, HostBinding, OnInit, Renderer2, ViewChild, Directive, inject, Inject  } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { AppComponent } from "app/app.component";
import { AlertPopup, config, HttpLoader, ResolveEmit } from "app/app.config";
import { ValidationService } from "app/components/form-validation/validation.service";
import { FileValidationService } from "app/shared/file-validation.service";
import { SocketService } from "app/socket/socket.service";
import { QuizService } from "../quiz.service";
import { VideoQuizComponent } from "../video-quiz.component";
import { GroupService } from "app/components/userandgroup/group/group.service";
import { Subject } from "rxjs";
import * as _ from "underscore";

import { threadId } from "worker_threads";
import { ConfirmationDialogService } from "app/confirmation-dialog/confirmation-dialog.service";
import { DOCUMENT } from "@angular/common";

// @Directive()
@Component({
  selector: "app-video-quiz-details",
  templateUrl: "./video-quiz-details.component.html",
  styleUrls: [],
})
export class VideoQuizDetailsComponent implements OnInit, AfterViewInit {
  @HostBinding("class.flex-grid") leftBarClass = true;
  @HostBinding("style.width") flexwidth = "100%";
  @ViewChild("videoQuizFileInput") videoQuizFileInput: ElementRef;
  @ViewChild("fileInput") fileInput: ElementRef;
  @ViewChild("media") media: ElementRef;
  @ViewChild("currentTime") currentTime: ElementRef;
  @ViewChild("progressBar", { static: true }) progressBar: ElementRef;

  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  totalLessonCount: number = 0;
  totalStudentCount: number = 0;

  activeUrl: string;
  searchText: string;
  selectedClassData = {};
  getLessonList: any[] = [];
  listOfUsers: any[] = [];
  lessonTitle: string;
  lessonCreateAt: any;
  selectQuizIds: any[] = [];
  videoQuizFound:boolean;

  getSearchItem: any = [];
  public search: string;
  showPaginateSpinner = false;
  showBtnspinner = false;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  classId: any;
  selectQuizId: any;

  editedLessonId: number;
  videoUrl: string;
  mediaContent: any = null;
  getVideoQuestion: any[] = [];
  toggleModalSection = false;
  selectedTab: string;
  selectedIdList: number[] = [];
  questionIdList: any[] = [];
  questList: any[] = [];
  questionLists: any[] = [];
  getQuestionList: any[] = [];
  getLessonRecords: any[] = [];
  showBtnSpinner = false;
  isRequiredOption = false;
  quiztime: any;
  interVideoQuiz: any[] = [];
  questionIndList: any[] = [];
  editQuestIndList: any[] = [];
  editQuestList: any[] = [];
  htmlElement = "";
  disabledSelectBtn: boolean = true;
  isEditQuestion = [];
  submitted = false;
  isAlreadySelect: any;
  selQtHighlight = "quIdHighlighted";
  markerSeekBar: any[] = [];
  isEditBtnDisabled = false;
  getLastUpdateDate: any;
  isAssessmentFound:boolean=false;
  isSurveyFound:boolean=false;
  isPollFound:boolean= false;

  viewControl = {
    changedSearchText: false,
    showHideLessonTileView: false,
  };

  videoQuizzForm: FormGroup;
  editQuizzForm: FormGroup;

  searchInList = new Subject<any>();
  width:number = window.innerWidth;
  height:number = window.innerHeight;
  mobileWidth:number  = 760;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private videoQuizService: QuizService,
    private httpLoader: HttpLoader,
    private alert: AlertPopup,
    private app: AppComponent,
    private fb: FormBuilder,
    private validationService: ValidationService,
    private videoQuizComponent: VideoQuizComponent,
    private socketService: SocketService,
    private fileValidationService: FileValidationService,
    private groupService: GroupService,
    
    private confirmDialogService:ConfirmationDialogService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document:Document
  ) {
    this.videoQuizzForm = fb.group({
      lesson_id: [""],
      quiz_time: [""],
      questions: fb.array([]),
      is_required: [""],
    });

    this.editQuizzForm = fb.group({
      lesson_id: [""],
      question_ids: fb.array([""]),
      quiz_time: [""],
      questions: fb.array([""]),
      is_required: [""],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((x) => {
      this.classId = x.get("id");

      const payload = {
        offset: this.OFFSET,
        limit: this.RECORD_LIMIT,
        classId: this.classId,
      };
      this.getClassLessonDetails(payload);
    });

    this.route.url.subscribe(() => {
      if(this.width <= this.mobileWidth){
          this.videoQuizComponent.viewClassRoomList = true;
        }else{
          this.videoQuizComponent.viewClassRoomList = false;
        }
  });
 
  }

  openTab(evt, tabName) {
    var i, x, tablinks;
    x = document.getElementsByClassName("content-tab");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tab");
    for (i = 0; i < x.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" is-active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " is-active";
  }

  ngAfterViewInit(): void {}

  hideShowClassRoom() {
    this.videoQuizComponent.viewClassRoomList = !this.videoQuizComponent
      .viewClassRoomList;
   }

  closeActiveUrl() {
    this.videoQuizComponent.viewClassRoomList = false;
    this.router.navigate(["/main/videoquiz"]);
  }

  fetchNextLessonDetails() {}

  onScrollDown() {}

  getClassLessonDetails(payload) {
    try {
      this.httpLoader.showLoader();
      this.viewControl.showHideLessonTileView = false;
      this.videoQuizComponent.viewClassRoomList = false;
      this.videoQuizService.getAllClassLessonList(payload).subscribe(
        (classList) => {
          this.httpLoader.hideLoader();
          this.getLessonRecords = classList["recordList"];
          this.totalLessonCount = classList["TotalRowCount"];
          this.totalStudentCount = classList["TotalUserCount"];
          this.selectedClassData = classList["groupDetail"];


        },
        (error) => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        }
      );
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  editLessonDetails(lesson_id) {
    try {
      this.httpLoader.showLoader();
      this.videoQuizService.getSelectLessonData(lesson_id).subscribe(
        (data: any) => {
          this.httpLoader.hideLoader();
          this.editedLessonId = lesson_id;
          this.viewControl.showHideLessonTileView = true;
          this.videoQuizComponent.viewClassRoomList = true;
          this.getLessonList = data.record;
          this.lessonTitle = data.record["message"];
          this.lessonCreateAt = data.record["createdAt"];
          this.videoQuizFound = data.record['is_video_quiz_found'];



          this.videoUrl = data.record["videos_url"];
          this.getVideoQuestion = data.questionAll;
          this.interVideoQuiz = data.record["inter_questions"];
          this.getLastUpdateDate = data.record["updatedAt"];

          var getSelectIds = this.interVideoQuiz.map((ex)=>{
            return ex.question_ids;
          });

          var ids = getSelectIds.reduce((r, e) => (r.push(...e), r), [])

          this.selectQuizIds = ids;

          var positions = this.interVideoQuiz.map((el) => {
            return el.time;
          });

          this.addVideoMarker(positions);
        },
        (error) => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        }
      );
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  getVideoPlayingOnTime(evt) {
    const millisec = evt.target.currentTime;

    var minutes = Math.floor(millisec / 60);
    var seconds = Math.floor(millisec % 60);
    var m = minutes < 10 ? "0" + minutes : minutes;
    var s = seconds < 10 ? "0" + seconds : seconds;

    this.currentTime.nativeElement.value = m + ":" + s;

    this.quiztime = Math.floor(millisec * 60);
  }

  stopVideoStream() {
    this.media.nativeElement.pause();
  }

  getChangedTime(evt) {
    var time = evt.target.value;

    var getSeconds = new Date("1970-01-01T" + time + "Z").getTime() / 1000;
    this.quiztime = getSeconds;
  }

  viewQuestionSection() {
    if(this.currentTime.nativeElement.value==''){
      this.alert.showAlertMessage({message: 'Please play the video and add questions.'});
      return false;
    }else{
      this.toggleModalSection = true;
      this.media.nativeElement.pause();

      var whereYouAt = this.media.nativeElement.currentTime;

      let button = document.querySelector(".disabledBtn");
      button.setAttribute("disabled", "disabled");

      // let btn = document.querySelector(".disSaveQuizBtn");
      // btn.setAttribute("disabled", "disabled");

      // var questionId = this.getVideoQuestion.map((el) => {
      //   return el.question_id;
      // });

      // var addedQuestIds = this.interVideoQuiz.map((xl) => {
      //   return xl.question_ids;
      // });

      // for (let i = 0; i < questionId.length; i++) {
      //   const ids = questionId[i];
      // }
    }

  }

  selectQuestionId(index, item) {
    let button = document.querySelector(".disabledBtn");
    button.removeAttribute("disabled");

    this.getVideoQuestion[index].is_selected = !this.getVideoQuestion[index]
      .is_selected;
    if (
      this.getVideoQuestion[index].is_already_selected &&
      this.getVideoQuestion[index].is_selected
    ) {
      this.getVideoQuestion[index].is_changed = false;
    } else {
      this.getVideoQuestion[index].is_changed = true;
    }

    if (
      this.selectedIdList.indexOf(item.question_id) > -1 &&
      !this.getVideoQuestion[index].is_selected
    ) {
      this.selectedIdList = _.without(this.selectedIdList, item.question_id);
      return;
    } else if (
      this.getVideoQuestion[index].is_selected &&
      this.getVideoQuestion[index].is_already_selected
    ) {
      return;
    }
  }

  addQuestionSection() {
    this.toggleModalSection = !this.toggleModalSection;

    var selectQuestionList = _.where(this.getVideoQuestion, {
      is_changed: true,
    });

    if (this.editQuestIndList.length > 0) {
      var questionIndex = selectQuestionList.map((k) => {
        if (k.is_selected === true) {
          return Object.assign(
            {},
            {
              question_id: k.question_id,
              question: k.question,
            }
          );
        }
      });

      questionIndex = questionIndex.filter(function (j) {
        return j !== undefined;
      });

      let payload1: any = [];

      for (let i = 0; i < this.getVideoQuestion.length; i++) {
        const qId = this.getVideoQuestion[i];
        var indLength = questionIndex.length;

        questionIndex.find((idx) => {
          if (qId.question_id === idx.question_id) {
            payload1[i] = {
              question_id: qId.question_id,
              question: qId.question,
              qIndex: i + 1,
            };
          }
        });
      }

      payload1 = payload1.filter(function (lq) {
        return lq;
      });

      this.editQuestIndList = this.editQuestIndList.concat(payload1);

      var questionIdList = selectQuestionList.map((q) => {
        if (q.is_selected === true) {
          return q.question_id;
        }
      });

      questionIdList = questionIdList.filter(function (x) {
        return x !== undefined;
      });

      this.editQuestList = this.editQuestList.concat(questionIdList);
    } else {
      const video = document.getElementsByClassName("video-stream")[0];

      // var btn = document.querySelector(".disSaveQuizBtn");
      // btn.removeAttribute("disabled");

      var questionIndex = selectQuestionList.map((k) => {
        if (k.is_selected === true) {
          return Object.assign(
            {},
            {
              question_id: k.question_id,
              question: k.question,
            }
          );
        }
      });

      questionIndex = questionIndex.filter(function (j) {
        return j !== undefined;
      });

      let payload1: any = [];

      for (let i = 0; i < this.getVideoQuestion.length; i++) {
        const qId = this.getVideoQuestion[i];
        var indLength = questionIndex.length;

        questionIndex.find((idx) => {
          if (qId.question_id === idx.question_id) {
            payload1[i] = {
              question_id: qId.question_id,
              question: qId.question,
              qIndex: i + 1,
            };
          }
        });
      }

      payload1 = payload1.filter(function (lq) {
        return lq;
      });

      this.questionIndList = payload1;

      var questionIdList = selectQuestionList.map((q) => {
        if (q.is_selected === true) {
          return q.question_id;
        }
      });

      questionIdList = questionIdList.filter(function (x) {
        return x !== undefined;
      });

      this.questList = questionIdList;
    }
  }

  closeAddQuestSection() {
    this.toggleModalSection = false;

    const questionList = _.findWhere(this.getVideoQuestion, {
      is_changed: true,
    });

    if (questionList) {
      this.selectedIdList = [];
      this.questList = [];
      this.questionIndList = [];
      this.editLessonDetails(this.editedLessonId);
    } else {
      this.selectedIdList = [];
      this.questList = [];
      this.questionIndList = [];
    }
  }

  closeInteractiveQuiz() {
    this.questList = [];
    // this.videoQuizzForm.reset();
    this.selectedIdList = [];
    this.questionIndList = [];
  }

  closeModalVideoQuiz() {
    console.log("width",this.width);
    if(this.width <=640){
      this.viewControl.showHideLessonTileView = !this.viewControl.showHideLessonTileView;
      //this.videoQuizComponent.viewClassRoomList = false;
    }else{
      this.viewControl.showHideLessonTileView = false;
      this.videoQuizComponent.viewClassRoomList = false;
      this.videoQuizzForm.reset();
      this.selectedIdList = [];
      this.questList = [];
      this.questionIndList = [];
      // this.editQuizzForm.reset();
      this.isEditQuestion[this.editedLessonId] = false;
    }
     
    
   
  }

  saveQuestionData(formData) {
    var timeQ = this.media.nativeElement.currentTime;

    var hms = this.currentTime.nativeElement.value;
    var a = hms.split(":");

    var getAtSeconds = 0;


    if (a[0] && a[1] && a[2]) {
      getAtSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    } else if (a[0] && a[1]) {
      getAtSeconds = (+a[0]) * 60 + (+a[1]);
    }

    const payload = {
      lesson_id: this.editedLessonId,
      quiz_time: getAtSeconds,
      question_ids: this.questList,
      is_required: formData.is_required
        ? formData.is_required
        : this.isRequiredOption,
      questions: this.questionIndList,
    };


    this.httpLoader.showLoader();

    this.videoQuizService.addNewInteractiveQuiz(payload).subscribe(
      (response: any) => {
        this.alert.showAlertMessage(response);
        this.httpLoader.hideLoader();
        this.media.nativeElement.play();
        this.videoQuizzForm.reset();
        this.questList = [];
        this.questionIndList = [];
        this.selectedIdList = [];
        const questionList = _.findWhere(this.getVideoQuestion, {
          is_changed: true,
        });

        if (questionList) {
          this.selectedIdList = [];
          this.editLessonDetails(this.editedLessonId);
        } else {
          this.selectedIdList = [];
        }
        var quizData = response.result;
        if (this.interVideoQuiz.length === 0) {
          this.interVideoQuiz.push(quizData);
        } else {
          this.interVideoQuiz.push(quizData);
        }
      },
      (error) => {
        this.httpLoader.hideLoader();
        this.alert.showAlertMessage(error.error);
      }
    );
  }

  deleteSelectQuiz(quizId) {
    try {
      this.confirmDialogService.openConfirmDialog('',{
        title:'Confirmation',
        message:'Are you sure want to delete this quiz?',
        btnOkText:'YES',
        btnCancelText:'NO',
        onClose: (data:any) => {
          if(data){
            this.httpLoader.showLoader();
            const index = _.findIndex(this.interVideoQuiz, { quiz_id: quizId });
            this.videoQuizService.deleteQuizData(quizId).subscribe(
              (response: any) => {
                this.httpLoader.hideLoader();
                this.interVideoQuiz.splice(index, 1);
                this.editLessonDetails(this.editedLessonId);
                this.alert.showAlertMessage(response);
              },
              (error) => {
                this.httpLoader.hideLoader();
                this.alert.showAlertMessage(error.error);
              }
            );
          }
        }	  
    });
     /* this._confirmation
        .create("Confirmation", "Are you sure want to delete this quiz?", {
          confirmText: "YES",
        })
        .subscribe((ans: ResolveEmit) => {
          if (ans.resolved) {
            this.httpLoader.showLoader();
            const index = _.findIndex(this.interVideoQuiz, { quiz_id: quizId });
            this.videoQuizService.deleteQuizData(quizId).subscribe(
              (response: any) => {
                this.httpLoader.hideLoader();
                this.interVideoQuiz.splice(index, 1);
                this.editLessonDetails(this.editedLessonId);
                this.alert.showAlertMessage(response);
              },
              (error) => {
                this.httpLoader.hideLoader();
                this.alert.showAlertMessage(error.error);
              }
            );
          }
        }); */
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  editedSelectQuiz(quizId) {
    this.httpLoader.showLoader();
    this.isEditQuestion[quizId - 1] = false;
    this.editQuizzForm.reset();
    this.videoQuizService.editSelectQuestion(quizId).subscribe((data: any) => {
      this.httpLoader.hideLoader();
      this.isEditQuestion[quizId] = true;
      this.selectQuizId = quizId;
      var qId = data.record.quiz_id;
      let getEditData = data.record.question_ids;

      this.selectQuizIds = getEditData;

      if (qId === quizId) {
        this.isEditBtnDisabled = false;
      } else {
        this.isEditBtnDisabled = true;
      }

      this.editQuizzForm.patchValue({
        quiz_time: data.record["time"],
        is_required: data.record["is_required"],
      });
      this.editQuestIndList = data.record["questions"];
      this.editQuestList = data.record["question_ids"];
    });
  }

  removeSelectIndex(index, item) {
    const indx: number = this.editQuestIndList.indexOf(item);
    const inx: number = this.editQuestList.indexOf(item.question_id);

    if (indx !== -1) {
      this.editQuestIndList.splice(index, 1);
    }

    if (inx !== -1) {
      this.editQuestList.splice(index, 1);
    }
  }

  //edit selected quiz details change and click on update button
  updateVideoQuizData(quizId, formData) {
    try {
      this.httpLoader.showLoader();

      var hms = formData.quiz_time;

      var a = hms.split(":");

      var getAtSeconds = 0;


      if (a[0] && a[1] && a[2]) {
        getAtSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
      } else if (a[0] && a[1]) {
        getAtSeconds = (+a[0]) * 60 + (+a[1]);
      }

      this.showBtnSpinner = true;

      const payload = {
        lesson_id: this.editedLessonId,
        quiz_time: getAtSeconds,
        question_ids: this.editQuestList,
        is_required: formData.is_required
          ? formData.is_required
          : this.isRequiredOption,
        questions: this.editQuestIndList,
      };

      this.videoQuizService.updateSelectQuestion(quizId, payload).subscribe(
        (response: any) => {
          this.httpLoader.hideLoader();
          this.showBtnSpinner = false;
          this.alert.showAlertMessage(response);
          this.editLessonDetails(this.editedLessonId);
          this.editQuizzForm.reset();
          this.isEditQuestion[quizId] = false;
          this.interVideoQuiz = response.record;
        },
        (error) => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        }
      );
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  updatedInteractiveQuiz() {
    try {
      this.httpLoader.showLoader();
      this.showBtnspinner = true;

      const payload = {
        lesson_id: this.editedLessonId,
        is_video_quiz_found: true,
        updatedAt: new Date()
      };
      this.videoQuizService.updateLessonQuiz(payload).subscribe(
        (response: any) => {
          this.httpLoader.hideLoader();
          this.showBtnspinner = false;
          this.alert.showAlertMessage(response);
          this.videoQuizzForm.reset();
          this.questList = [];
          this.selectedIdList = [];
          this.viewControl.showHideLessonTileView = false;
          this.videoQuizComponent.viewClassRoomList = false;
        },
        (error) => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        }
      );
    } catch (error) {
      console.log(error);
      this.httpLoader.hideLoader();
    }
  }

  clearEditQuizzData(quizId) {
    this.isEditQuestion[quizId] = false;
    this.editQuizzForm.reset();
    this.editQuestIndList = [];
    this.editQuestList = [];
  }

  addVideoMarker(positions) {
    var totalDuration = this.getLessonList["duration"];

    var seekbar = positions.map(function (position) {
      // Is position within range of the duration?
      if (position <= totalDuration) {
        const [hours, minutes] = position.split(":");
        const duration = +hours * 60 * 60 + +minutes * 60;

        const [h, m] = totalDuration.split(":");
        const totdur = +h * 60 * 60 + +m * 60;
        // Calculate position in percentage..
        const left = (duration / totdur) * 100 + "%";
        return left;
      }
    });

    this.markerSeekBar = seekbar;
  }

  secondsToTime(e) {
    var h = Math.floor(e / 3600)
        .toString()
        .padStart(2, "0"),
      m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0");

    return h + ":" + m;
  }

  async convertDurationtoSeconds(duration) {
    var times = duration.split(":");
    times.reverse();
    var x = times.length,
      y = 0,
      z;
    for (var i = 0; i < x; i++) {
      z = times[i] * Math.pow(60, i);
      y += z;
    }
    console.log(y);
  }

   
}
