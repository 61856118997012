import { Component, OnInit, Directive } from '@angular/core';
import * as _ from 'underscore';
import { AlertService, Alert, AlertType } from './alert.service';
import { config } from './../app.config';


// @Directive()
@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit {
    alerts: Alert[] = [];
    val: string;
    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.alertService.getAlert().subscribe((alert: Alert) => {
            if (!alert) {
                this.alerts = [];
                return;
            }
            alert.id = new Date().getTime();
            this.alerts.push(alert);
            this.alerts = _.sortBy(this.alerts, (alertObj) => alertObj.id);
            const timeoutId = setTimeout(() => {
                this.alerts = this.alerts.filter(x => x.id !== alert.id);
            }, config.NOTIFICATION_TIMEOUT);
        });
    };

    // clearTimeout(timeoutId);

    removeAlert(alert: Alert) {
        this.alerts = this.alerts.filter(x => x.id !== alert.id);
    };

    cssClass(alert: Alert) {
        if (!alert) {
            return;
        }
        switch (alert.type) {
            case AlertType.Success:
                return 'notification alert-success';
            case AlertType.Error:
                return 'notification alert-danger';
            case AlertType.Info:
                return 'notification alert-info';
            case AlertType.Warning:
                return 'notification alert-warning';
        }
    };
}
