import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AlertPopup } from 'app/app.config';
import { HeaderService } from 'app/components/header/header.service';
import { AlertService } from 'app/notification-alert/alert.service';
import { AssistBotService } from '../assist-bot.service';

@Component({
  selector: 'app-bot-suggestion',
  templateUrl: './bot-suggestion.component.html',
  styleUrls: ['./bot-suggestion.component.scss']
})
export class BotSuggestionComponent {

  chatbotData:any=[];

  showDisclaimer: boolean = true;
  suggestionList: any = [];
  autoSearchDafaultValue: string = "";
  language:string="eng";

  constructor(
    private alert: AlertPopup,
    private http: HttpClient,
    private header: HeaderService,
    private assistBotService: AssistBotService
  ) {
    
  }

  ngOnInit() {
    this.chatbotData=[];
  }

  setautoComplete(value: any) {
    this.autoSearchDafaultValue = value['data'];
    if (value['action'] != "Keypress") {
      this.save();
    }
  }

  save() {
    let questionIndex = 0;
    if (this.autoSearchDafaultValue.trim() == "") {
      this.alert.showAlertMessage({ "message": "Please enter your question" });
      return;
    }

    //  this.chatbotData.push(
    //   {
    //     "type":"question",
    //     "content":{
    //       "text":this.autoSearchDafaultValue
    //     }
    //   }
    // );

    let answerData = [{
      "questionId": '',
      "bot_type": "bot",
      "question": this.autoSearchDafaultValue.trim(),
      "answer": "",
      "search": true
    }];
    this.chatbotData.unshift(answerData[0]);
    setTimeout(() => {
      this.autoSearchDafaultValue = "";
    }, 1500);
    // this.http.post("http://54.201.38.98:8501/query",{"query":this.autoSearchDafaultValue}).subscribe((response:any)=>{
    //    console.log("res",response['result']);
    //    let answerData = [{
    //     "questionId": 1,
    //     "bot_type": "bot",
    //     "sender": "bot",
    //     "name": "You",
    //     "question": this.autoSearchDafaultValue.trim(),
    //     "answer": response['result'],
    //     "context": {}
    //   }];
    //   this.chatbotData.unshift(answerData[0]);
    // });
    // this.chatbotData.push(
    //   {
    //     "type":"question",
    //     "content":{
    //       "text":this.autoSearchDafaultValue
    //     }
    //   }
    // );
    //this.chatbotData.unshift(question);
    //questionIndex = this.chatbotData.length - 1;

    /*  setTimeout(()=>{
       let answerData:any;
       if(this.language=="hindi" || this.language=="spanish"){
          answerData = this.answerList.filter((ans:any)=> ans['question'].trim().toLowerCase().includes(this.autoSearchDafaultValue.trim().toLowerCase()));
       }else{
          answerData = this.answerList.filter((ans:any)=> ans['keywords'].trim().toLowerCase().includes(this.autoSearchDafaultValue.trim().toLowerCase()));
       }
 console.log("answerData",answerData);
       if(answerData.length > 0){
          answerData.forEach(data=>{
            this.chatbotData.unshift(data);
          });
 
       }else{
            let answerData = [{
             "questionId": 1,
             "bot_type": "bot",
             "sender": "bot",
             "name": "You",
             "question": this.autoSearchDafaultValue.trim(),
             "answer": "<div>Invalid question</div>",
             "context": {}
           }];
           this.chatbotData.unshift(answerData[0]);
       }
 
      },1000);
 
     setTimeout(()=>{
       //this.gotoHistory(this.chatbotData[questionIndex]['questionId']);
      this.autoSearchDafaultValue="";
     },5000);
   */
    // this.chatHistoryList = this.chatbotData;



  }

}
