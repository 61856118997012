import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class DataSharingService {

    private addNotifyCount$ = new Subject();
    private privateChatMessage$ = new Subject();
    private groupChatMessage$ = new Subject();
    private userStatus$ = new Subject();
    private typingStatusNotify$ = new Subject();
    private newChatsNotify$ = new Subject();
    private messageUpdateNotify$ = new Subject();
    private readMsgUpdateNotify$ = new Subject();
    private groupUpdateNotify$ = new Subject();
    private meetingCallingSession$ = new Subject();
    private meetingCallAccpetd$ = new Subject();
    private iceCandidate$ = new Subject();
    private sdp$ = new Subject();
    private dataAnswer$ = new Subject();
    private statusUpdate$ = new Subject();
    private userTaskNotify$ = new Subject();
    private staffRoomRecord$ = new Subject();
    private publishTopic$ = new Subject();
    private updateLessionCount$ = new Subject();
    private elibraryDashbaord$ = new Subject();
    private instantChangeGroupName = new Subject();
    private fogotMailId = new Subject();
    private lessonsTileView = new Subject();
    public controlSecondlevelmenu:BehaviorSubject<{}> = new BehaviorSubject({});

    constructor() { }

    getUserMessage(): Observable<any> {
        return this.privateChatMessage$.asObservable();
    };

    getGroupMessage(): Observable<any> {
        return this.groupChatMessage$.asObservable();
    };

    getNotifyCount(): Observable<any> {
        return this.addNotifyCount$.asObservable();
    };

    getUserStatusNotify(): Observable<any> {
        return this.userStatus$.asObservable();
    };

    getTypingStatusNotify(): Observable<any> {
        return this.typingStatusNotify$.asObservable();
    };

    getNewChatsNotify(): Observable<any> {
        return this.newChatsNotify$.asObservable();
    };

    getMessageUpdateNotify(): Observable<any> {
        return this.messageUpdateNotify$.asObservable();
    };

    getReadMsgUpdateNotify(): Observable<any> {
        return this.readMsgUpdateNotify$.asObservable();
    };

    getGroupUpdateNotify(): Observable<any> {
        return this.groupUpdateNotify$.asObservable();
    };

    getMeetingCallingSession(): Observable<any> {
        return this.meetingCallingSession$.asObservable();
    }

    getCallAccpetd(): Observable<any> {
        return this.meetingCallAccpetd$.asObservable();
    }
    getIceCandidate(): Observable<any> {
        return this.iceCandidate$.asObservable();
    }
    getSpd(): Observable<any> {
        return this.sdp$.asObservable();
    }
    getDataAnswer(): Observable<any> {
        return this.dataAnswer$.asObservable()
    }

    getUserstatusUpdate(): Observable<any> {
        return this.statusUpdate$.asObservable()
    }

    getPintaskNotify(): Observable<any> {
      return this.userTaskNotify$.asObservable()
    }

    getStaffRoomList(): Observable<any> {
      return this.staffRoomRecord$.asObservable();
    }

    getPublishTopic(): Observable<any> {
      return this.publishTopic$.asObservable();
    }

    getLessionCount(): Observable<any> {
      return this.updateLessionCount$.asObservable();
    }

    getElibraryDashboardCount(): Observable<any> {
      return this.elibraryDashbaord$.asObservable();
    }

    getChangeGroupName(): Observable<any> {
      return this.instantChangeGroupName.asObservable();
    }

    getForgotMailId(): Observable<any> {
      return this.fogotMailId.asObservable();
    }

    getLessonsRecords(): Observable<any> {
      return this.lessonsTileView.asObservable();
    }


    emitNewUserMessage(message): any {
        this.privateChatMessage$.next(message);
    };

    emitNewGroupMessage(message): any {
        this.groupChatMessage$.next(message);
    };

    emitNotifyCount(menuName): any {
        this.addNotifyCount$.next(menuName);
    }

    emituserStatusUpdate(status): any {
        this.statusUpdate$.next(status);
    }

    emitUserStatusNotify(status): any {
        this.userStatus$.next(status);
    }

    emitTypingStatusNotify(status): any {
        this.typingStatusNotify$.next(status);
    }

    emitNewChatsNotify(chat): any {
        this.newChatsNotify$.next(chat);
    };

    emitMessageUpdateNotify(chat): any {
        this.messageUpdateNotify$.next(chat);
    };

    emitBulkReadMsgUpdateNotify(chat): any {
        this.readMsgUpdateNotify$.next(chat);
    };

    emitGroupUpdateNotify(chat): any {
        this.groupUpdateNotify$.next(chat);
    };
    emitMeetingCallingSession(call): any {
        this.meetingCallingSession$.next(call)
    }
    emitCallAccepted(res): any {
        this.meetingCallAccpetd$.next(res)
    }
    emitIceCandidate(res): any {
        this.iceCandidate$.next(res)
    }
    emitSdp(res): any {
        this.sdp$.next(res)
    }
    emitDataAnswed(res):any {
        this.dataAnswer$.next(res)
    }

    emitPinTaskNotify(res):any {
      this.userTaskNotify$.next(res)
    }

    emitStaffRoomList(res): any{
      this.staffRoomRecord$.next(res);
    }

    emitPublishTopic(res): any {
      this.publishTopic$.next(res);
    }
    emitLessonCount(res): any {
      this.updateLessionCount$.next(res);
    }

    emitElibaryDashboardCount(res) : any {
      this.elibraryDashbaord$.next(res);
    }

    emitChangeGroupName(res) : any {
      this.instantChangeGroupName.next(res);
    }

    emitgetForgotMailId(res) :  any {
      this.fogotMailId.next(res);
    }

    emitLessonsRecords(res) : any {
      this.lessonsTileView.next(res);
    }

}
