import { HttpClient } from '@angular/common/http';
import { config } from './../app.config';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';



@Injectable()
export class BotService {

    baseUrl = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;

    constructor(private http: HttpClient) { }

    postBotMessage(payLoad) {
        return this.http.post(`${this.baseUrl}/bot-message`, payLoad);
    };


}
