import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild, Directive  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { AlertPopup, config, HttpLoader, path } from 'app/app.config';
import { ValidationService } from 'app/components/form-validation/validation.service';
import { PlayerService } from 'app/components/media-player/media-player-service';
import { SecureFileService } from 'app/secure-image/secure-image-service';
import { FileValidationService } from 'app/shared/file-validation.service';
import { SocketService } from 'app/socket/socket.service';
import { Subject } from 'rxjs';
import { StudyRoomService } from '../../study-room.service';
import { StudyroomDetailsComponent } from '../studyroom-details.component';
import { saveAs as importedSaveAs } from 'file-saver';
import { jsPDF } from "jspdf";
import { StaffRoomService } from 'app/components/staff-room/staff-room.service';
import { DatePipe } from '@angular/common';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { StudyRoomComponent } from '../../study-room.component';
import { ELibraryService } from 'app/components/elibraryandcourse/e-library/e-library.service';
import { merge } from 'rxjs-compat/operator/merge';

// @Directive()
@Component({
  selector: 'app-studyroom-list',
  templateUrl: './studyroom-list.component.html',
  // styleUrls: []
})
export class StudyroomListComponent implements OnInit, AfterViewInit, OnDestroy {

  // @HostBinding('class.flex-grid') leftBarClass = true;
  // @HostBinding('style.width') flexwidth = '100%';
  @Input() studyRoomDataList;
  @Input() uploadAssignData;
  @Input() maxLength: number = 400;
  @Input() maxHeight = '4rem';
  @ViewChild('scrollframe') scrollFrame: ElementRef;
  @ViewChild('fileInput') private fileInput: ElementRef;
  // @Output() mediaContent: EventEmitter<any> = new EventEmitter();

  baseUrl = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;

  playingMedia: string;

  totalRowCount: number;
  search: string;
  downContext: boolean = false;
  searchInList = new Subject<any>();

  OFFSET: number = config.DEFAULT_OFFSET;
  RECORD_LIMIT : number = config.DEFAULT_RECORD_LIMIT;
  showAssignmentModal = [];
  assignmentFeedback = [];
  feedback = {};
  isTopicId: number;
  fileDeleted : boolean  = false;

  viewControl = {
    changedSearchText: false
  }

  domElementControl = {
    showCreateNewRoom : false
  }

  files:any[]= [];
  fileUploadingProgress = false;
  listOfFiles: any[] = [];
  uploadAssignForm: FormGroup;
  preUploadedFile:any;
  preProfileUrl = path.PRE_PROFILE_URL;
  profileUrl = path.PROFILE_URL;
  attachedFileExt = config.ASSIGN_FILE_EXT;
  attachedFileSize = config.ASSIGN_FILE_SIZE;
  attachedFileLimit = config.ATTACHMENT_LIMIT;
  max_attachFileSize = config.MAX_ATTACH_FILE_SIZE;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  feedbackErrMsg:any;

  getCurrentTopic = {};
  isActiveTopic:any[]  = [];
  feedbackres = true;
  showBtnSpinner = false;


  private subscribers: any = {};

  _domParams = {
    hideFilePreview: false
  }

  //window resize
  isMobile: boolean = false;
  width:number = window.innerWidth;
  height:number = window.innerHeight;
  mobileWidth:number  = 768;
  selectedFiles: File[];

  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  uploadFileIcons = [
    {name: "pdf", icons : '../../../../assets/images/file_types/pdf.png'},
    {name: "doc",icons : '../../../../assets/images/file_types/doc.png'},
    {name: "docx",icons : '../../../../assets/images/file_types/docx.png'},
    {name: "txt",icons : '../../../../assets/images/file_types/txt.png'},
    {name: "ppt",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: "pptx",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: "pptm",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: 'mp3',icons : '../../../../assets/images/file_types/mp3.png'},
    {name: 'mp4',icons : '../../../../assets/images/file_types/mp4.png'},
    {name: 'wav',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'avi',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'png',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'jpg',icons : '../../../../assets/images/file_types/jpg.png'},
    {name: 'jpeg',icons : '../../../../assets/images/file_types/jpg.png'},
    {name: 'flv',icons : '../../../../assets/images/file_types/png.png'}
  ];

  mediaContent: any = null;
  inputSrc: string;
  thumbSrc: string;
  showFileLoadSpinner:boolean;
  selectedReadFile="";
  base64textString = [];


  constructor(
    private studyRoomService: StudyRoomService,
    private studyroomDetails : StudyroomDetailsComponent,
    private alert: AlertPopup,
    private httpLoader: HttpLoader,
    private validateService: ValidationService,
    private playerService: PlayerService,
    private secureFileService: SecureFileService,
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    private fb: FormBuilder,
    private fileValidationService: FileValidationService,
    private route: ActivatedRoute,
    private router: Router,
    private socketService: SocketService,
    private appComponent: AppComponent,
    private staffRoomService: StaffRoomService,
    private StudyroomDetailsComponent: StudyroomDetailsComponent,
    private studyroomComponent: StudyRoomComponent,
    private elibraryService: ELibraryService
  ) {
    this.uploadAssignForm = fb.group(
      {
        files: [null, Validators.compose([Validators.required])],
      }
    );

    this.uploadAssignForm.valueChanges.subscribe((item: any) => {
      if(this.uploadAssignForm.valid){
        this.fileDeleted = false;
      }else{
        this.fileDeleted = true;
      }
    })
   }

  ngOnInit(): void {

    if(this.width <= this.mobileWidth){
      this.studyroomComponent.viewStudyRoomList = true;
    }else{
      this.studyroomComponent.viewStudyRoomList = false;
    }

    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      let windowWith = window.innerWidth;
      if(windowWith <= this.mobileWidth){
        this.studyroomComponent.viewStudyRoomList = true;
      }else{
        this.studyroomComponent.viewStudyRoomList = false;
      }
    })

  }

  ngAfterViewInit(){
    this.subscribers.media = this.elibraryService.getMediaContent().subscribe(
      (response) => {
        console.log(response, 'response')
        if (this.mediaContent && response) {
          if (this.mediaContent.file_path !== response.file_path) {
            this.mediaContent = response;

          }
        } else {
          this.mediaContent = response;
        }
      },
      (error) => console.log(error)
    );
  }



  uploadAssignmentModal(data, index){
    this.uploadAssignData = data;
    this.isTopicId = index;
    this.showAssignmentModal[index] =! this.showAssignmentModal[index];
  }

  hideAssignModal(index){
    this.showAssignmentModal[index] = false;
    this.preUploadedFile = null;
    this.files = [];
    this.uploadAssignForm.reset();
  }

  async fileValidationHanlder(files) {
    try {

      if (files.length > this.attachedFileLimit) {
        this.alert.showAlertMessage({'message': `Error: At a time you can upload only ${this.attachedFileLimit} files`});
        let button = document.querySelector(".disabledBtn");
                button.setAttribute('disabled', 'disabled');
        // return false;
      }else{
        const isValidate = await this.fileValidationService.fileValidation(files, this.attachedFileExt, this.attachedFileSize);
        return files;
      }
    } catch (err) {
        err.map(li => {
            const errMsg = {
                message: li
            };
            this.alert.showAlertMessage(errMsg);
            let button = document.querySelector(".disabledBtn");
                button.setAttribute('disabled', 'disabled');
        });
        return false;
    }
  };


  async onSelectFileChange(event){

    const files = event.target.files;

    var file, imgData;



    if (files.length==1) {
      const fileValid = await this.fileValidationHanlder(files);
      if (fileValid) {
        this.concatFileIntoArray(files);
      }
    }else{

      if (event.target.files.length > 0) {
        for (let i = 0; i < event.target.files.length; i++) {
          file = event.target.files[i];
          var reader = new FileReader();
          reader.onload = this._handleReaderLoaded.bind(this);
          imgData = reader.readAsBinaryString(file);
          // this.files.push(file);
        }
        event.target.value = '';
      }


    }

  }

  async _handleReaderLoaded(e) {
    this.base64textString.push('data:image/png;base64,' + btoa(e.target.result));

    var doc = new jsPDF();

        let imgData = this.base64textString;

        for (let i = 0; i < imgData.length; i++) {
            let img = new Image();
              const imgWidth = doc.internal.pageSize.getWidth();;
                const imgHeight = doc.internal.pageSize.getHeight();
                const imgRatio = imgWidth / imgHeight;
                // if (i >= 0) {
                //     if (imgRatio > 0) {
                //       doc.addPage([imgWidth, imgHeight]);
                //     }
                //     else {
                //       doc.addPage([imgWidth, imgHeight], 'p');
                //     }
                // }
                // doc.setPage(i + 2);
                doc.addImage(imgData[i], 'JPEG', 15, 40, 180, 160);
                doc.addPage();
                var userId = window.localStorage.getItem('user_id');
                if (i == imgData.length - 1) {
                  doc.save(userId+'_'+'assign'+new Date().getTime()+'.pdf');
                  var blob = doc.output('blob');

                  console.log(blob);
                }
        }

    // var width = doc.internal.pageSize.getWidth();
    // var height = doc.internal.pageSize.getHeight();

    // var img=[];

    // var blobs = "";
    // let name = new Date().getTime();
    // let imgDatas = this.base64textString;
    // for (let i = 0; i < imgDatas.length; i++) {
    //   let img = new Image();
    //   doc.addImage(imgDatas[i], 'JPEG', 15, 40, 180, 160);
    //   doc.addPage();
    // }


    // const userId = window.localStorage.getItem('user_id');

    //   doc.save(userId+'_'+'assign'+new Date().getTime()+'.pdf');

    //   // const fileValid = await this.fileValidationHanlder(doc);
    //   // if (fileValid) {
    //   //   this.concatFileIntoArray(doc);
    //   // }
   }


  concatFileIntoArray(files){
    if (files && files.length > 0) {
      const formFields = {
          fieldName: 'staff_topic',
          activity: 'topic',
          storeType: 'temp'
      };
      let button = document.querySelector(".disabledBtn");
        button.setAttribute('disabled', 'disabled');

      this.files.push(files[0]);
      this.fileValidationService.fileValidation(files, this.attachedFileExt, this.attachedFileSize).then(validResponse => {
      this.appComponent.fileUploadWithProgess(formFields, this.files).then((fileUpLoadResponse: any) => {
              for (const key in fileUpLoadResponse) {
                  if (fileUpLoadResponse[key]) {
                      this.preUploadedFile = fileUpLoadResponse[key]
                  }
              }
              this.files = [];
              this.fileInput.nativeElement.value = '';
              let button = document.querySelector(".disabledBtn");
                  button.removeAttribute('disabled');
          }).catch(error => {
              this.files = [];
              this.fileInput.nativeElement.value = '';
              this.alert.showAlertMessage(error.error);
          })
      }).catch(err => {
          this.fileInput.nativeElement.value = '';
          this.files = [];
          err.map(li => this.alert.showAlertMessage(li));
      });
  }
  }

  onFileChange(event){
    if (event.target.files && event.target.files[0]) {
        const fileLength = event.target.files.length;
        for (let i = 0; i < fileLength; i++) {
          var reader = new FileReader();
          reader.onload = (event:any) => {
            const selectedFile = event.target.result;
            this.files.push(selectedFile);
            this.listOfFiles.push(selectedFile.name);
          }

          reader.readAsDataURL(event.target.files[i]);

        }
      }
  }

  removeUploadFile(){
    try {
      this.files = [];
      this.preUploadedFile= null;
      this.fileDeleted = true;

      // if(this.uploadAssignForm.valid){
      //   this.fileDeleted = false;
      // }else{
      //   this.fileDeleted = true;
      // }
    } catch (error) {
      console.log(error);

    }
  }

  removeSelectedFile(index){
    this.listOfFiles.splice(index, 1);
    this.files.splice(index, 1);
  }

  playMedia(fileData){

    this.playerService.clear();
    if (fileData.file_type ==='video' || fileData.file_type === 'audio') {
      if (fileData) {
        const payLoad = {
          file_path: fileData.download_url,
          file_type: fileData.file_type
        };
        this.playerService.emitMediaContent(payLoad);
        this.playingMedia = {...fileData}.download_url;
      }
    }
  }


  secureReadfile(fileData){

    const url = fileData.view_url;
    if(url){
      fileData.startFileDownLoad = true;
      this.secureFileService.secureReadfile(url).subscribe(blob => {



        fileData.startFileDownLoad = false;

        // const fileUrl = (blob, fileData.file_id);

        // this.isviewUploadDoc = true;
        // this.viewPdfORPPT = fileData.file_path;
        // // this.selectedViewer.viewerUrl = this.viewPdfORPPT;
        // // this.selectedDoc =`${this.baseUrl}/Media/Document/${fileData.download_url}`
        // this.selectedDoc = url;

      })
    }
  }

  secureFileDownLoad(fileData: any) {
    try {
        const url = fileData.download_url;
        // console.log(url);

        if (url) {
            fileData.startFileDownLoad = true;
            this.secureFileService.secureFileDownLoad(url).subscribe(blob => {
              console.log(fileData.file_id);

                fileData.startFileDownLoad = false;
                importedSaveAs(blob, fileData.file_id);
            }, error => {
                fileData.startFileDownLoad = false;
                console.log(error);
            })
        }
    } catch (err) {
        fileData.startFileDownLoad = false;
        console.log(err);
    }
};


  submitAssignment(formVal, uploadAssignData){
    if (formVal) {
      this.files[0];

      try {
        const data = uploadAssignData;
        const payLoad = {
          topic_id: data['topic_id'],
          room_id: data['room_id'],
          teacher_id: data['created_by'],
          unique_id: Date.now(),
          file_data: this.preUploadedFile
        }
        const topicId = data['topic_id'];
        this.showBtnSpinner = true;

        this.studyRoomService.createAssignment(payLoad).subscribe(response=> {
          if(response['success']==false){
            this.alert.showAlertMessage(response);
          }else{
            this.alert.showAlertMessage(response);
            this.httpLoader.hideLoader();
            this.showAssignmentModal[topicId] = false;
            this.uploadAssignForm.reset();
            this.preUploadedFile=null;
            this.showBtnSpinner = false;
          }
        }, (error) => {
          this.alert.showAlertMessage(error.error);
          this.httpLoader.hideLoader();
          this.showBtnSpinner = false;
          this.preUploadedFile=null;
          this.uploadAssignForm.reset();
          this.showAssignmentModal[topicId] = false;
        })
      } catch (error) {
        this.httpLoader.hideLoader();
        console.log(error);
        this.showBtnSpinner = false;
      }
    }
  }

  reviewFeedBack(topicId){
    this.isTopicId = topicId;
    this.assignmentFeedback[topicId] =! this.assignmentFeedback[topicId];
    this.studyRoomService.getReviewFeedback(topicId).subscribe(response => {
      console.log(response);

      if(response['success']===false){
        this.alert.showAlertMessage(response['message']);
        this.assignmentFeedback[topicId] = false;
      }else{
        this.feedbackErrMsg = response['success'];
        this.feedback = response['result'];
        this.feedbackres = false;
      }

    }, error => {
      console.log(error.error.message);

      this.feedbackres = true;
      this.feedbackErrMsg = error.error.success;
      this.alert.showAlertMessage(error.error['message']);
      // this.assignmentFeedback[topicId] = false;

    })
  }

  viewCurrentTopic(id){
    this.isTopicId = id;
    this.isActiveTopic[id] =! this.isActiveTopic[id];
    this.elibraryService.clear();
    this.staffRoomService.getCurrentTopic(id).subscribe(response=>{
      this.getCurrentTopic = response['result'];
      this.inputSrc = response['result']['view_url'];
      var url = response['result']['view_url'];
      this.showFileLoadSpinner = true;

      this.elibraryService.emitMediaContent(response['result']);
      if (url) {
        this.secureFileService.secureReadfile(url).subscribe((blob) => {
          this.showFileLoadSpinner = false;
          this.selectedReadFile = url;
        });
      }
    })
  }

  videoPlayerInit(event) {
    const fileSrc = this.mediaContent ? this.mediaContent.file_path : null;
    this.inputSrc = fileSrc.replace("securefileDownload", "secureReadfile");
  }

  hideFeedbackModal(index){
    this.assignmentFeedback[index] = false;
  }

  hideTopicModal(index){
    this.isActiveTopic[index] = false;
  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.

  }

  ngOnDestroy(){

  }

}
