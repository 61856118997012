import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostBinding, Inject, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StripeCardNumberComponent, StripeService } from 'ngx-stripe';
import { SubscriptionService } from './subscription.service';
import {
  PaymentIntent,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent {

  @HostBinding("class.flex-grid") leftBarClass = true;
  @HostBinding("style.width") flexwidth = "100%";
  @HostBinding('style.flex-direction') direction='column';
  @ViewChild("fileInput") fileInput: ElementRef;
  @ViewChild("logoFileInput") logoFileInput: ElementRef;
  @Output() onClose = new EventEmitter();
  @ViewChild("closebutton") closebutton;
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  tabName = 'subscription';
  submitted = false;
  logged_user_id: number;
  subscriptionDetails: any = {};
  _closeSubscriptionDetails: any = {};
  layoutShow = false;
  showIconImage = false;
  showBtnSpinner = false;
  loggedUserRole: number;
  isSubscripeMorePlan: boolean = false;
  isShowSubscription: boolean = false;
  isSubscriptPaymentOption: boolean = false;
  subscriptionPlanName: string = "";
  subscriptionAmount: number = 0;
  supportMailId: string = "sales@absencegpt.ai";
  paymentForm: FormGroup;

  public payPalConfig ? : IPayPalConfig;

  country_list:any = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antigua &amp; Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bosnia &amp; Herzegovina',
    'Botswana',
    'Brazil',
    'British Virgin Islands',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Cape Verde',
    'Cayman Islands',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Congo',
    'Cook Islands',
    'Costa Rica',
    'Cote D Ivoire',
    'Croatia',
    'Cruise Ship',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Estonia',
    'Ethiopia',
    'Falkland Islands',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Polynesia',
    'French West Indies',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kuwait',
    'Kyrgyz Republic',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macau',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Namibia',
    'Nepal',
    'Netherlands',
    'Netherlands Antilles',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Palestine',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Reunion',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Pierre &amp; Miquelon',
    'Samoa',
    'San Marino',
    'Satellite',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'South Africa',
    'South Korea',
    'Spain',
    'Sri Lanka',
    'St Kitts &amp; Nevis',
    'St Lucia',
    'St Vincent',
    'St. Lucia',
    'Sudan',
    'Suriname',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    "Timor L'Este",
    'Togo',
    'Tonga',
    'Trinidad &amp; Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks &amp; Caicos',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'Uruguay',
    'Uzbekistan',
    'Venezuela',
    'Vietnam',
    'Virgin Islands (US)',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ];


  paymentHandler: any = null;
  stripeAPIKey: any = 'pk_test_51PLHHNSDuguvJYJa9K8NaQ05NCohHREKIYV00wjG7xFfVYkbYAbajWRcHxmq5mkEJKCVcfBzRhm9RDiVInIm5W9D00psJWj24u';

  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        fontWeight: 400,
        //fontFamily: 'Circular',
        fontSize: '20px',
        iconColor: '#666EE8',
        color: '#002333',
        '::placeholder': {
          color: '#919191',
        },
      },
    },
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };


  
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private subscriptionService: SubscriptionService,
    private stripeService: StripeService,
    @Inject(DOCUMENT) private document:Document
  ) {
    this.paymentForm = fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      amount: ['', [Validators.required, Validators.pattern(/d+/)]],
      country:['',[Validators.required]]
    })
  }

  ngOnInit(): void {
    // this.invokeStripe();
    this.document.body.classList.add('scroll-stop');
    this.initConfig();
  }

  initConfig(){
    this.payPalConfig = {
      currency: "EUR",
      clientId: "Aaa6yLyEQh1fktciF-zoKaL4BZbgZK2ZSsKB6mtowA_grkCySWWp_EQmyjUyN5-LLawrLcdsqh-h7HJp",
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: '9.99',
              breakdown: {
                item_total: {
                  currency_code: 'EUR',
                  value: '9.99'
                }
              }
            },
            items: [
              {
                name: 'Enterprise Subscription',
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'EUR',
                  value: '9.99',
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
       // this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    }
  }

  /**
   * Navigate tab from settings menu
   * @param tabName
   */
  selectSettingsTab(tabName: string) {
    if (tabName === "payment_history") {
      this.subscriptionDetails = { ...this._closeSubscriptionDetails };
    }
    this.tabName = tabName;
  }

  changePlanOption() {
    this.isShowSubscription = true;
    this.isSubscripeMorePlan = true;
  }

  chooseCountry(event:any){

  }

  makePayment(amount: any, planName:any) {

    console.log(amount, 'Amount');
    this.isSubscriptPaymentOption = true;
    this.isSubscripeMorePlan = false;

    this.subscriptionAmount = amount;
    this.subscriptionPlanName = planName;

    // const paymentHandler = (<any>window).StripeCheckout.configure({
    //   key: this.stripeAPIKey,
    //   locale: 'auto',
    //   token: function (stripeToken: any) {
    //     console.log(stripeToken);
    //     alert('Stripe token generated!');
    //   },
    // });
    // paymentHandler.open({
    //   name: 'ItSolutionStuff.com',
    //   description: '3 widgets',
    //   amount: amount * 100,
    // });


  }

  pay(): void {
    if (this.paymentForm.valid) {

      var subscripAmount = this.subscriptionAmount * 12;
      
      this.createPaymentIntent(subscripAmount)
        .pipe(
          switchMap((pi) =>
            this.subscriptionService.confirmCardPayment(
              console.log(pi)

              //pi.client_secret, {
              // payment_method: {
              //   card: this.card.element,
              //   billing_details: {
              //     name: this.paymentForm.get('name').value,
              //   },
              // },
              // })
            ))
        )
        .subscribe((result: any) => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer
            }
          }
        });
    } else {
      console.log(this.paymentForm);
    }
  }

  createPaymentIntent(amount: number): Observable<PaymentIntent> {
    // return this.http.post<PaymentIntent>(
    //   `${env.apiUrl}/create-payment-intent`,
    //   { amount }
    // );

    console.log(amount);

    return;

  }

  changePaymentOptions() {
    this.paymentForm.reset();
    this.isSubscripeMorePlan = true;
    this.isSubscriptPaymentOption = false;
  }

  /*------------------------------------------
--------------------------------------------
invokeStripe() Function
--------------------------------------------
--------------------------------------------*/
  // invokeStripe() {
  //   if (!window.document.getElementById('stripe-script')) {
  //     const script = window.document.createElement('script');

  //     script.id = 'stripe-script';
  //     script.type = 'text/javascript';
  //     script.src = 'https://checkout.stripe.com/checkout.js';
  //     script.onload = () => {
  //       this.paymentHandler = (<any>window).StripeCheckout.configure({
  //         key: this.stripeAPIKey,
  //         locale: 'auto',
  //         token: function (stripeToken: any) {
  //           console.log(stripeToken);
  //           alert('Payment has been successfull!');
  //         },
  //       });
  //     };

  //     window.document.body.appendChild(script);
  //   }
  // }
}