import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { THEMES } from './theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  setmyTheme:boolean=false;
  constructor(@Inject(DOCUMENT) private document: Document) {}

  setTheme(name = "default") {
    //window.location.reload();
    if(this.setmyTheme){
     this.document.head.removeChild(this.document.getElementById("rootStyle"));
    }

    let stylecolor='';
   let styleEml = this.document.createElement('style');
   styleEml.setAttribute("id","rootStyle");
   localStorage.setItem("myTheme",name);
    const theme = THEMES[name];
   
    Object.keys(theme).forEach((key) => {
      stylecolor +=`--${key}`+":"+theme[key]+";\n";
     // this.document.documentElement.style.setProperty(`--${key}`, theme[key]);
    });

    styleEml.textContent=':root{\n'+stylecolor+'}';
    document.head.insertBefore(styleEml,document.head.lastElementChild);
    this.setmyTheme=true;
  }
}
