import { Component, OnInit, EventEmitter, Output, ElementRef, HostListener, Directive  } from '@angular/core';
// import { EmojiService } from './emoji.service';
import { Ng2EmojiService } from 'ng2-emoji';


// @Directive()
@Component({
    selector: 'app-emoji',
    templateUrl: './emoji.component.html',
    styleUrls: ['./emoji.component.scss']
})

export class EmojiComponent implements OnInit {
    @Output()
    public pickedEmoji: EventEmitter<any> = new EventEmitter();
    emojiToggle = false;
    emojisList: Array<string>;

    constructor(
        // private emojiService: EmojiService,
        private _elementRef: ElementRef) {
        this.emojisList = Ng2EmojiService.emojis;
    }

    ngOnInit() { };

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.emojiToggle = false;
        } else {
            this.emojiToggle = true;
        }
    };

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.emojiToggle = false;
        }

        if (event.keyCode === 13) {
            this.emojiToggle = false;
        }
    };

    appendEmojiText(event, emoji) {
        if (!emoji) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        const appendEmoji = `:${emoji}:`;
        this.pickedEmoji.emit(appendEmoji);
    };

    toggleEmoji() {
        this.emojiToggle = !this.emojiToggle;
    };

}
