import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { SharedModule } from 'app/shared/shared.module';

import { NotificationComponent } from './notification.component';
import { NotificationDetailsComponent } from './notification-details/notification-details.component';
import { SubPostComponent } from './notification-details/sub-post/sub-post.component';
import { CommentComponent } from './notification-details/comment/comment.component';
import { SubCommentComponent } from './notification-details/comment/sub-comment/sub-comment.component';
import { RealUpdateComponent } from './real-update/real-update.component';
import { NewNotificationComponent } from './new-notification/new-notification.component';
import { NotificationService } from './notification.service';
import { NotificationRoutingModule } from './notification-routing.module';
import { UserService } from '../userandgroup/user/user.service';





@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    NotificationRoutingModule
  ],
  declarations: [
    NotificationComponent,
    NotificationDetailsComponent,
    SubPostComponent,
    CommentComponent,
    SubCommentComponent,
    RealUpdateComponent,
    NewNotificationComponent
  ],
  providers: [NotificationService, UserService]
})
export class NotificationModule { }
