import { Component, OnInit, HostBinding, Input, ViewChild, ElementRef, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { HttpEventType, HttpResponse, HttpClient, HttpRequest } from '@angular/common/http';
import * as _ from 'underscore';
import { config, path, UPLOAD_URI, HttpLoader, AlertPopup } from './../../../../app.config';
import { GroupService } from '../group.service';
import { GroupComponent } from '../group.component';
import { AppComponent } from './../../../../app.component';
import { FileValidationService } from './../../../../shared/file-validation.service';
import { UserService } from '../../user/user.service';
import { ValidationService } from '../../../form-validation/validation.service';
import { UtilityService } from './../../../../utility.service';

import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';
import { ChatService } from 'app/components/chat-list/chat.service';
import { fromEvent, Subscription} from 'rxjs';


// @Directive()
@Component({
  selector: 'app-new-group',
  templateUrl: './new-group.component.html',
  styleUrls: []
})
export class NewGroupComponent implements OnInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  newGroupForm: FormGroup;
  search: string;
  listOfUsers: any[] = [];
  totalRowCount: number;
  searchText: string;
  privacyList = [
    { name: 'Public', value: false },
    { name: 'Private', value: true }
  ];

  uploadSpinner = false;
  toggleModalSection = false;
  userIdList: number[] = [];
  user_Ids: number[] = [];
  // selectedFileList: any[] = [];

  /** User Profile icon handler variables */
  selectedFileList: any[] = [];
  profileExtension = config.PROFILE_EXT;
  profileSize = config.PROFILE_SIZE;

  // selectedFile: any;
  // fileLoadedPercentage: number;
  preUploadedFile: any;
  showPaginateSpinner = false;

  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;

  viewControl = {
    changedSearchText: false
  };
  searchInList = new Subject<any>();

  isMobile: boolean = false;
  width:number = window.innerWidth;
  height:number = window.innerHeight;
  mobileWidth:number  = 768;


  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  /** specifying Base URL.*/
  public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/groups`;
  profileUrl = path.PROFILE_URL;
  preProfileUrl = path.PRE_PROFILE_URL;
  constructor(private fb: FormBuilder,
    private appComponent: AppComponent,
    private chatService: ChatService,
    private groupComponent: GroupComponent, private groupService: GroupService,
    private userService: UserService, private httpLoader: HttpLoader, private router: Router, private route: ActivatedRoute,
    private http: HttpClient,
    private utilityService: UtilityService,
    private fileValidationService: FileValidationService,
    private alert: AlertPopup) {
    this.newGroupForm = fb.group({
      chat_group_name: ['', Validators.compose([Validators.required, ValidationService.noWhitespaceValidator])],
      // is_private: [null, Validators.required],
      // is_private: [null],
      chat_description: [''],
      // group_id: ['']
    });

    this.searchInList
    .debounceTime(500)
    .subscribe(event => {
      this.searchText = event.target.value;
      this.viewControl.changedSearchText = true;
      this.userSearchAndPagination(0);
    });
  }



  ngOnInit() {
    const payLoad = {
      offset: this.OFFSET,
      limit: this.RECORD_LIMIT,
      search: this.search || ''
    };
    this.getuserList(payLoad);
    this.newGroupForm.patchValue({
      is_private: false
    });

    if(this.width <= this.mobileWidth){
      this.groupComponent.viewSecondPanel = true;
    }else{
      this.groupComponent.viewSecondPanel = false;
    }

    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      let windowWith = window.innerWidth;
      if(windowWith <= this.mobileWidth){
        this.groupComponent.viewSecondPanel = true;
      }else{
        this.groupComponent.viewSecondPanel = false;
      }
    })
  };

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.groupComponent.viewClassRoom = false;
  }

  /**
   * Call this function on every file choose
   * @param event
   */
  onFileChange(event) {
    const files = event.target.files;
    if (files && files.length > 0) {
      const formFields = {
        fieldName: 'user_profile',
        activity: 'user_profile_add',
        storeType: 'temp'
      };

      this.selectedFileList.push(files[0]);
      this.fileValidationService.fileValidation(files, this.profileExtension, this.profileSize).then(validResponse => {
        this.appComponent.fileUploadWithProgess(formFields, this.selectedFileList).then((fileUpLoadResponse: any) => {
          for (const key in fileUpLoadResponse) {
            if (fileUpLoadResponse[key]) {
              this.preUploadedFile = fileUpLoadResponse[key]
            }
          }
          this.selectedFileList = [];
          this.fileInput.nativeElement.value = '';
        }).catch(error => {
          this.selectedFileList = [];
          this.fileInput.nativeElement.value = '';
          this.alert.showAlertMessage(error.error);
        })
      }).catch(err => {
        this.fileInput.nativeElement.value = '';
        this.selectedFileList = [];
        err.map(li => this.alert.showAlertMessage(li));
      });
    }
  };

  keyDownHandler(event) {
    if (event.keyCode === 32 && event.target.selectionStart === 0) {
      return false;
    }
  }



  /**
   * Remove the Selected File based on the index position
   * @param index
   */

  removeSelectedFile() {
    this.preUploadedFile = null;
    this.selectedFileList = [];
  };

  /**
   * Add user selected user id in the userListId array
   * @param userId
   */
  selectUserId(userId) {
    if (this.userIdList.indexOf(userId) > -1) {
      this.userIdList = _.without(this.userIdList, userId);
      return;
    } else {
      this.userIdList.push(userId);
      return;
    }
  };

  /**
 * Select User id from User Section in modal window
 */
  openAddUserSection() {
    this.toggleModalSection = !this.toggleModalSection;
  };

  /**
   * Clear user Id list Selection
   */
  clearAddUserSection() {
    if (this.userIdList && this.userIdList.length > 0) {
      const confirmation = confirm('Do you want to cancel selected user?');
      if (confirmation === true) {
        this.userIdList = [];
        this.openAddUserSection();
      } else {
        return true;
      }
    } else {
      this.openAddUserSection();
    }
  };

  /**
   * Add New Class Room
   */

   addNewClassRoom(group){
    if(this.userIdList.length==0){
      this.alert.showAlertMessage({"message":"Please select atleast one user in this lesson"});


    }else{
        try {

            this.userIdList = _.uniq(this.userIdList);

            this.httpLoader.showLoader();
          group.user_Ids = this.userIdList;

          var group_name = group.chat_group_name;
          group_name = group_name.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
          group.chat_group_name = group_name.trim();

          this.chatService.createGroup(group).subscribe(
            (data)=>{
                this.httpLoader.hideLoader();
                this.alert.showAlertMessage(data);
                this.newGroupForm.reset();

                let userIdList = {...data['createdGroup']};
                userIdList.TotalUserCount = this.userIdList.length;

                var classCount = this.groupComponent.listOfGroups;

                if (classCount===undefined) {
                  this.groupComponent.listOfGroups = [userIdList];

                }else{
                  this.groupComponent.listOfGroups.unshift(userIdList);
                }

                this.userIdList = [];
                this.user_Ids = [];
                this.selectedFileList = [];

            },
            error =>{
              this.alert.showAlertMessage({message: error.error['message']} );
              this.selectedFileList = [];
              this.httpLoader.hideLoader();
            }
          )

        } catch (error) {
          this.selectedFileList = [];
          this.httpLoader.hideLoader();
          console.log(error);

        }
    }
   }

  /**
   * Add New group
   * @param group
   */
  // addNewGroup(group) {
  //   try {
  //     this.httpLoader.showLoader();
  //     group.userIdList = this.userIdList;
  //     group.fileData = this.preUploadedFile;
  //     this.groupService.addNewGroup(group).subscribe(
  //       (data) => {
  //         const groupId = data['groupDetail'] ? data['groupDetail'].group_id : 0;
  //         if (groupId) {
  //           const url = `${this.BASE_URL}/groupProfileUpload/${groupId}`
  //           this.httpLoader.hideLoader();
  //           this.groupComponent.listOfGroups.unshift(data['groupDetail']);
  //           this.newGroupForm.reset();
  //           this.userIdList = [];
  //           this.selectedFileList = [];
  //           this.preUploadedFile = null;
  //         }
  //         this.httpLoader.hideLoader();
  //       },
  //       error => {
  //         this.selectedFileList = [];
  //         this.preUploadedFile = null;
  //         this.httpLoader.hideLoader();
  //       })
  //   } catch (err) {
  //     this.httpLoader.hideLoader();
  //     this.selectedFileList = [];
  //     this.preUploadedFile = null;
  //     console.log(err);
  //   }
  // };

  /**
   * Get user list
   */
  getuserList(pageNo) {
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: this.search || ''
      };

      this.httpLoader.showLoader();
      this.userService.getActiveUserList(payLoad).subscribe(
        (lists) => {
          this.httpLoader.hideLoader();
          this.listOfUsers = lists['userList'];
          this.totalRowCount = lists['TotalRowCount'];
        },
        error => {
          this.httpLoader.hideLoader();
          console.log(error);
        })
    } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
    }
  };

  /**
 *
 * @param searchText
 */
  userSearchAndPagination(pageNo) {
    try {
      this.httpLoader.showLoader();
      if (pageNo === 0) {
        this.OFFSET = pageNo;
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        sort: '',
        search: this.searchText || ''
      };
      this.userService.getActiveUserPaginate(payLoad).subscribe(
        (userList) => {
          this.httpLoader.hideLoader();
          if (offset === 0) {
            this.listOfUsers = userList['userList'];
            this.totalRowCount = userList['TotalRowCount'];
          } else if (offset > 0) {
            this.listOfUsers = this.listOfUsers.concat(userList['userList']);
            this.showPaginateSpinner = false;
          }
        },
        error => {
          this.httpLoader.hideLoader();
          this.showPaginateSpinner = false;
          console.log(error);
        });
    } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
    }
  };

  fetchNextUserList(){

  }

  showGroupName(){
    this.groupComponent.viewClassRoom = true;
    this.router.navigate(['/main/userandgroup/group']);
  }


  /**
   * Pagination called while scroll down
   */
  onScrollDown() {
    if ((this.listOfUsers.length < this.totalRowCount) && !this.showPaginateSpinner) {
      this.OFFSET += 1;
      this.showPaginateSpinner = true;
      this.userSearchAndPagination(this.OFFSET);
    }
  };

}
