
import { Component, OnInit, Input, OnDestroy, HostListener, ViewChild, ElementRef, AfterViewInit, Renderer2, Directive } from '@angular/core';
import { path, config, AlertPopup, HttpLoader } from './../../../../app.config';

import { EmojiEvent } from 'lib/emoji/public_api';
import { DataSharingService } from 'app/shared/data-sharing.service';
import { HomeworkService } from 'app/components/homework/homework.service';
import { FormGroup, FormBuilder, Validators,  ReactiveFormsModule} from '@angular/forms';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { ValidationService } from 'app/components/form-validation/validation.service';
import { fromEvent, Observable, Subscription} from 'rxjs';
import { ChatListComponent } from '../../chat-list.component';

// @Directive()
@Component({
    selector: 'app-list-view',
    templateUrl: './msg-list-view.component.html',
    styleUrls: []
})
export class MessagListViewComponent implements OnInit , AfterViewInit{
    @Input() messageList;
    @Input() loggedUserId: number;
    @Input() loggedUserRole: number;
    @ViewChild('scrollframe') scrollFrame : ElementRef;
    @Input() maxLength: number = 400;
    @Input() maxHeight = '4rem';

    isCollapsed=[];

    profileUrl = path.PROFILE_URL;

    public scrollContainer: any;
    search:string;
    downContext: boolean = false;
    isActive=[];
    isShowing=[];
    activePinTask:any[] =[];
    model:any;

    isMobile: boolean = false;
    width:number = window.innerWidth;
    height:number = window.innerHeight;
    mobileWidth:number  = 760;


    resizeObservable$: Observable<Event>
    resizeSubscription$: Subscription

    constructor(
      private renderer: Renderer2,
      private homeworkService: HomeworkService,
      private alert: AlertPopup,
      private httpLoader: HttpLoader,
      private router: Router,
      private validateService: ValidationService,
      private chatComponent: ChatListComponent
    ) {
    }


    ngOnInit() {
      // this.scrollToBottom();
      this.loggedUserRole = parseInt(window.localStorage.getItem('user_role'));

      if(this.width <= this.mobileWidth){
        this.chatComponent.viewChatList = true;
      }else{
        this.chatComponent.viewChatList = false;
      }

      this.resizeObservable$ = fromEvent(window, 'resize')
      this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
        let windowWith = window.innerWidth;
        if(windowWith <= this.mobileWidth){
          this.chatComponent.viewChatList = true;
        }else{
          this.chatComponent.viewChatList = false;
        }
      })
    }

    ngAfterViewInit(){
      // this.scrollToBottom();
    }

      trackByFn(index, msg) {
        return msg ? msg.message_id : undefined;
    };

    aftershowMoreToggle(index) {

      document.getElementById("scrollFrame_"+index).scrollIntoView();

      // this.scrollFrame.nativeElement.scrollIntoView(true);


      // this.scrollContainer = el.offsetHeight;

     this.isCollapsed[index] = !this.isCollapsed[index]
   }

   moreOptionMenu(index){
    this.isActive[index] = !this.isActive[index];
   }

   messageFocus(index){
    this.isShowing[index] = !this.isShowing[index];
   }


   copytoclipboard(inputElement){
    // console.log(inputElement);
    const event = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', inputElement);
      e.preventDefault();
    }
      document.addEventListener('copy', event);

      try {
        let successful = document.execCommand('copy');
        let message = successful ? 'successful' : 'unsuccessful';
        if(successful){
          document.getElementsByClassName('contenteditable')[0].innerHTML=inputElement;
          return true;
        }
      } catch (error) {
        console.log('Oops, Unable to copy');

      }

   }

   deleteMessage(index){
    console.log('delete Message' +index);

   }

   handlePinTask(int){
    console.log(int, 'open');
    this.activePinTask[int] = true;
    this.isActive[int] = false;
  }

  submittedTask(msg){

   try {
    const payLoad = {
      'msg_id' : msg.message_id,
      'task' : msg.message,
      'from_user_id': msg.from_user_id,
      'to_user_id': (msg.to_user_id)?msg.to_user_id : null,
      'groupId' : msg.group_id,
      'task_due_date': this.model['due_date'],
      'status': '0'
    }


    const id = msg.message_id;

    this.homeworkService.createPinTask(payLoad).subscribe(response => {
        if(response){
          this.activePinTask[id] = false;
          this.alert.showAlertMessage(response);
        }
      }, error =>{
        console.log(id);

        this.activePinTask[id] = false;
        this.alert.showAlertMessage(error.error);
      })

    } catch (error) {
      this.alert.showAlertMessage(error);
    }

  }



  cancelTaskPin(index){
    this.activePinTask[index] = false;
  }


}

