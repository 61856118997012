import { Component, EventEmitter, Input, Output } from '@angular/core';
 

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  
  @Input() title: string="";
  @Input() message: string="";
  @Input() btnOkText: string="";
  @Input() btnCancelText: string="";
  @Output() onClose:EventEmitter<any> = new EventEmitter<any>();

  constructor( ) { }

  ngOnInit() {
    
  }
  close(data?: any): void {
    this.onClose.emit(data);
  }

  Confirm(){
    this.onClose.emit(true);
  }

  Cancel(){
    this.onClose.emit(false);
  }


}
 

