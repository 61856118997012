import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomeworkComponent } from './homework.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {path: 'homework', component: HomeworkComponent}
    ])
  ],
  declarations:[],
  exports: [RouterModule]
})

export class HomeworkRoutingModule{}
