import { HttpClient } from '@angular/common/http';
import { AfterContentChecked, AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild, Directive  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
 
import { AppComponent } from 'app/app.component';
import { AlertPopup, config, HttpLoader, path } from 'app/app.config';
import { SecureFileService } from 'app/secure-image/secure-image-service';
import { DataSharingService } from 'app/shared/data-sharing.service';
import { Subject } from 'rxjs';
import { QuizService } from './quiz.service';

// @Directive()
@Component({
  selector: 'app-video-quiz',
  templateUrl: './video-quiz.component.html',
  styleUrls: ['./video-quiz.component.scss']
})
export class VideoQuizComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {

  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('classRoomList', {static: true}) private classRoomScrollContainer: ElementRef;
  profileUrl = path.PROFILE_URL;
  sortBy = 'title';
  showSearchSec=true;
  search:string;
  activeUrl:any;
  showPaginateSpinner=false;
  OFFSET:number = config.DEFAULT_OFFSET;
  RECORD_LIMIT:number = config.DEFAULT_RECORD_LIMIT;
  getClassRoomList:any[]=[];
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  viewClassRoomList=false;
  totalClassListCount:number;

  viewControl = {
    changedSearchText:false
  }

  searchInList = new Subject<any>();

  private subscribers:any={};


  constructor(
    private videoQuizService: QuizService,
    private alert: AlertPopup,
    private httpLoader: HttpLoader,
    private route: ActivatedRoute,
    private router: Router,
    private app: AppComponent,
    private httpClient: HttpClient,
    private securedFileService: SecureFileService,
    private dataSharingService: DataSharingService,
 
  ) {
    this.searchInList.debounceTime(500).subscribe(event=>{
      this.viewControl.changedSearchText=true;
      const searchText = event.target.value;
      
      this.searchClassRoomList(searchText);
    })
  }

  ngOnInit(): void {
    this.getClassRoomDetails(this.search);
    this.route.url.subscribe(()=>{
      this.activeUrl = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.url[0].path: false;
    });
  }

  ngAfterViewInit(): void {

  }

  ngAfterContentChecked(): void {

  }

  searchClassRoomList(search){
    this.getClassRoomDetails(search);
  }

  showSearchBox(){
    this.showSearchSec =! this.showSearchSec;
    if (this.viewControl.changedSearchText) {
      try {
        this.search='';
        this.viewControl.changedSearchText=false;
        const offset=0;
        this.getClassRoomDetails(this.search, offset);
      } catch (error) {
        this.httpLoader.hideLoader();
        console.log(error);

      }
    }
  }

  fetchNextClassRoomList(){
    const listlength = this.getClassRoomList.length;
    if (this.totalClassListCount > listlength && !this.showPaginateSpinner) {
      this.OFFSET +=1;
      this.showPaginateSpinner=true;
      this.getClassRoomDetails(this.search, this.OFFSET);
    }
  }

  getClassRoomDetails(search='', pageNo=0){
    try {
      if (pageNo===0) {
        this.OFFSET=0;
        this.httpLoader.showLoader();
      }
      const offset = this.OFFSET;
      const payload = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: search || '',
        sort: this.sortBy
      };

      this.videoQuizService.getAllClassRoomList(payload).subscribe(data => {

        if (offset===0) {
          this.getClassRoomList = data['chatList']
        }else if (offset > 0) {
          this.getClassRoomList = [...this.getClassRoomList.concat(data['chatList'])];
        }

        this.totalClassListCount = data['TotalRowCount'];
        this.showPaginateSpinner=false;
        this.httpLoader.hideLoader();
      },error => {
        this.httpLoader.hideLoader();
        this.alert.showAlertMessage(error.error);
      })

    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);

    }
  }

  getStudyRoom(){

 

    const offset = this.OFFSET;
        const payload = {
          offset: offset,
          limit: this.RECORD_LIMIT,
          search: this.search || '',
          sort: this.sortBy
        };
  
        this.videoQuizService.getAllClassRoomList(payload).subscribe(data => {
  
          if (offset===0) {
            this.getClassRoomList = data['chatList']
          }else if (offset > 0) {
            this.getClassRoomList = [...this.getClassRoomList.concat(data['chatList'])];
          }
  
          this.totalClassListCount = data['TotalRowCount'];
          this.showPaginateSpinner=false;
          this.httpLoader.hideLoader();
        },error => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        })
  
  
      }
  



  ngOnDestroy(): void {

  }

}
