import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, HostListener, Directive } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertPopup, config, HttpLoader, ResolveEmit, path } from 'app/app.config';
import { ChatService } from 'app/components/chat-list/chat.service';
import { ValidationService } from 'app/components/form-validation/validation.service';
import { PlayerService } from 'app/components/media-player/media-player-service';
import { SecureFileService } from 'app/secure-image/secure-image-service';
import { BehaviorSubject } from 'rxjs';
import { StaffRoomService } from '../../staff-room.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { SocketService } from 'app/socket/socket.service';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import { viewerType } from 'ngx-doc-viewer/document-viewer.component';
import { FormBuilder, FormGroup, NumberValueAccessor, Validators } from '@angular/forms';
import { TopicDetailsComponent } from '../topic-details.component';
import {DomSanitizer,SafeResourceUrl} from '@angular/platform-browser';

import { FileValidationService } from 'app/shared/file-validation.service';
import { StudyRoomService } from 'app/components/study-room/study-room.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AppComponent } from 'app/app.component';
import { DataSharingService } from 'app/shared/data-sharing.service';
import { StaffRoomComponent } from '../../staff-room.component';
import { DatePipe } from '@angular/common';
import { fromEvent, Subscription } from "rxjs";
import { ELibraryService } from 'app/components/elibraryandcourse/e-library/e-library.service';
import { ConfirmationDialogService } from 'app/confirmation-dialog/confirmation-dialog.service';

// @Directive()
@Component({
  selector: 'app-topic-list-view',
  templateUrl: './topic-list-view.component.html',
  // styleUrls: ['./topic-list-view.component.css']
})
export class TopicListViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() RoomTopicList;
  @Input() public topicHighlight ;
  @Input() public classRoomId;
  // @Input() groupId;
  @Input() maxLength: number = 400;
  @Input() maxHeight = '4rem';
  @Input() fileData: any;
  @ViewChild('scrollframe') scrollFrame : ElementRef;
  @ViewChild('topicFileInput') public topicFileInput: ElementRef;
  // @Output()mediaContent: EventEmitter<any> = new EventEmitter();
  @HostListener("document:click", ["$event"]) onDocumentClick(event) {
    this.show = false;
  }
  clickit($event, ind) {
    $event.stopPropagation();
    this.dropdownIndex = ind;
    this.show = !this.show;
  }
  editTopicForm: FormGroup;
  // Date="";

  baseUrl = `${config.PROTOCOL}://${config.HOST}:${config.PORT}`;

  playingMedia: string;

  show = false;
   dropdownIndex: any;

  totalRowCount: number;
  search:string;
  downContext: boolean = false;
  searchInList = new Subject<any>();
  sortBy = 'topic';
  isAssignActive:any[] = [];
  showPaginateSpinner=false;
  OFFSET = config.DEFAULT_OFFSET;
  RECORD_LIMIT = config.DEFAULT_RECORD_LIMIT;
  getUserSearch: string;
  getUserList :any[] = [];
  isPusblished :string;
  viewPdfORPPT :string;
  showAssignTable:any[] = [];
  isviewUploadDoc = false;
  isactiveFeedback = [];
  isViewTopic= [];
  feebackData: any[] = [];
  selectedViewer: string;
  selectedDoc: void;
  feedbackForm: FormGroup;
  topicId: number;
  viewTopicId: number;
  showBtnSpinner = false;
  showFileLoadSpinner:boolean;
  viewFileType:any;
  mediaContent: any = null;
  inputSrc: string;
  thumbSrc: string;
  selectedReadFile="";

  getTopicDetails={};
  topicData = {};
  editTopicActive = [];
  /** File upload Variables */
  upLoadedFileList: any[] = [];
  fileUploadingInProgress = false;
  topicFileExtension = config.TOPIC_EXT;
  topicFileSize = config.TOPIC_FILE_SIZE;
  preUploadedFile:any;
  preProfileUrl = path.PRE_PROFILE_URL;
  profileUrl = path.PROFILE_URL;
  /** Chat File Size limit */
  chatFileSizeLimit: number = config.MAX_ATTACH_FILE_SIZE;
  chatFileExt = config.TOPIC_EXT;

  private subscribers: any = {};
  doc: string ="";
  viewAssignment = [];
  isDropHoverable = [];

  uploadAttachFile = true;
  uploadFileData ="";

  isMobile: boolean = false;
  width:number = window.innerWidth;
  height:number = window.innerHeight;
  mobileWidth:number  = 760;

  editTopicTime:any;

  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription



  viewControl = {
    changedSearchText: false
  }

  //staff type edit function
  staffType = [
    {name: 'Topic',value: 'T'},
    {name: 'Assignment',value: 'A'},
    // {name: 'Online Test',value: 'OT'}
  ];

  selectedDateLabel="Session Date";

  uploadFileIcons = [
    {name: "pdf", icons : '../../../../assets/images/file_types/pdf.png'},
    {name: "doc",icons : '../../../../assets/images/file_types/doc.png'},
    {name: "docx",icons : '../../../../assets/images/file_types/docx.png'},
    {name: "txt",icons : '../../../../assets/images/file_types/txt.png'},
    {name: "ppt",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: "pptx",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: "pptm",icons : '../../../../assets/images/file_types/ppt.png'},
    {name: 'mp3',icons : '../../../../assets/images/file_types/mp3.png'},
    {name: 'mp4',icons : '../../../../assets/images/file_types/mp4.png'},
    {name: 'wav',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'avi',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'png',icons : '../../../../assets/images/file_types/png.png'},
    {name: 'jpeg',icons : '../../../../assets/images/file_types/jpg.png'},
    {name: 'jpg',icons : '../../../../assets/images/file_types/jpg.png'},
    {name: 'html',icons : '../../../../assets/images/file_types/html.png'},
    {name: 'psd',icons : '../../../../assets/images/file_types/psd.png'},
    {name: 'json',icons : '../../../../assets/images/file_types/json.png'},
    {name: 'xlsx',icons : '../../../../assets/images/file_types/xlsx.png'},
    {name: 'xls',icons : '../../../../assets/images/file_types/xls.png'},
    {name: 'css',icons : '../../../../assets/images/file_types/css.png'},
    {name: 'zip',icons : '../../../../assets/images/file_types/zip.png'},
    {name: 'xml',icons : '../../../../assets/images/file_types/xml.png'}
];

  editorConfig: AngularEditorConfig = {
    editable : true,
  spellcheck: true,
  height: 'auto',
  minHeight: '0',
  //  maxHeight: '95px',
  width: 'auto',
  minWidth: '0',
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  placeholder: 'Enter Topic / Assignment here....',
  defaultParagraphSeparator: '',
  defaultFontName: '',
  defaultFontSize: '',
  fonts: [
    {class: 'arial', name: 'Arial'},
    {class: 'times-new-roman', name: 'Times New Roman'},
    {class: 'calibri', name: 'Calibri'},
    {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
  uploadWithCredentials: false,
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    [
      'undo',
      'redo',
      'strikeThrough',
      'subscript',
      'superscript',
      'heading',
    ],
    [
      'textColor',
      'backgroundColor',
      'customClasses',
      'link',
      'unlink',
      'insertImage',
      'insertVideo',
      'insertHorizontalRule',
      'removeFormat',
    ]
  ]
   }


  viewers: { name: viewerType, docs: string[], custom: boolean, acceptedUploadTypes: string, viewerUrl?: string }[] = [
    {
      name: 'google', docs: [
        'https://file-examples-com.github.io/uploads/2017/02/file-sample_100kB.docx',
        'https://file-examples-com.github.io/uploads/2017/02/file_example_XLSX_50.xlsx',
        'https://file-examples-com.github.io/uploads/2017/10/file_example_TIFF_1MB.tiff',
        'https://file-examples-com.github.io/uploads/2017/10/file-example_PDF_500_kB.pdf',
        `${this.baseUrl}/assets/file_example_PPT_250kB.ppt`,
        `${this.baseUrl}/assets/file_example_PPTX_250kB.pptx`,
      ], custom: true, acceptedUploadTypes: null
    }
  ];

  shedule_date: Date;

  constructor(
    private staffRoomService: StaffRoomService,
    private topicDetail : TopicDetailsComponent,
    private alert: AlertPopup,
    private httpLoader: HttpLoader,
    private router: Router,
    private validateService: ValidationService,
    private chatService: ChatService,
    private playerService: PlayerService,
    private secureFileService: SecureFileService,
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
  
    private confirmDialogService:ConfirmationDialogService,
    private fb: FormBuilder,
    private fileValidationService: FileValidationService,
    private studyroomService: StudyRoomService,
    private socketService: SocketService,
    private route: ActivatedRoute,
    private appComponent: AppComponent,
    private dataSharingService: DataSharingService,
    private topicDetailsComponent: TopicDetailsComponent,
    private staffroomComponent: StaffRoomComponent,
    private elibraryService: ELibraryService
  ) {
    this.shedule_date = new Date();

    this.feedbackForm = fb.group({
      feedback: ['', Validators.required],
      marks: ['', Validators.required],
      topicId: ['']
    })

    this.editTopicForm = fb.group({
      topic: ['', Validators.compose([Validators.required])],
      staff_type:[''],
      shedule_date:[''],
      shedule_time:[''],
      notes:['']
    })

    this.staffRoomService.emitNewTopicCreation().subscribe((topic) => {
      this.RoomTopicList.unshift(topic);
    });

    this.searchInList.debounceTime(500).subscribe(
      event => {
        this.search = event.target.value;
        if(!this.search){
          return;
        }
        this.viewControl.changedSearchText = true;
        this.getTopicSec(0);
      }
    )

   }

  ngOnInit(): void {
    this.getTopicSec(0);

    if(this.width <= this.mobileWidth){
      this.staffroomComponent.viewStaffRoomList = true;
    }else{
      this.staffroomComponent.viewStaffRoomList = false;
    }

    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      let windowWith = window.innerWidth;
      if(windowWith <= this.mobileWidth){
        this.staffroomComponent.viewStaffRoomList = true;
      }else{
        this.staffroomComponent.viewStaffRoomList = false;
      }
    })
  }

  ngAfterViewInit(){
    try {
      /** Subscribe upload assignment from socket emit */
      this.subscribers.assignment = this.socketService.studentassignmentSession().subscribe(studentPost => {
        if (studentPost && studentPost.topic_id === +this.route.snapshot.paramMap.get('id')) {
          const selectedTopic = {...this.RoomTopicList} ;
          selectedTopic['topic'] = studentPost.topic;
          this.RoomTopicList = selectedTopic;
        }
      })
    } catch (error) {
      console.log(error);

    }

    this.subscribers.media = this.elibraryService.getMediaContent().subscribe((response)=> {

      console.log(response, 'response')

      if (this.mediaContent && response) {
        if (this.mediaContent.file_path !== response.file_path) {
          this.mediaContent = response;
        }else{
          this.mediaContent = response;
        }
      }
    }, (error)=> console.log(error))
  }

  toggle(item, index){
    let selector = `.row-num-${index}`;
    document.querySelector(selector).classList.toggle('d-none');
    this.showAssignTable[index] = !this.showAssignTable[index];

    this.staffRoomService.getStudentList(index).subscribe(
      response=>{
        this.getUserList = response['result'];

      }
    )
  }

  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }

  getCurrentTime():string {
    return new Date().toLocaleTimeString('en-US', { hour12: false,
      hour: "numeric",
      minute: "numeric"})
  }



  deletedTopic(topicId){
    try {
        this.confirmDialogService.openConfirmDialog('',{
          title:'Confirmation',
          message:'Are you sure want to delete topic?',
          btnOkText:'DELETE',
          btnCancelText:'NO',
          onClose: (data:any) => {
            if(data){
              // this.httpLoader.showLoader();
            const payLoad = {
              topic_id : topicId,
            }
            this.staffRoomService.deletedTopic(payLoad).subscribe(
              response => {
                this.httpLoader.hideLoader();
                this.alert.showAlertMessage(response);
                let position = this.RoomTopicList.findIndex(x => x.topic_id ===topicId);

                if(~position){
                  this.RoomTopicList.splice(position, 1);
                }

              },
              error => {
                this.httpLoader.hideLoader();
                this.alert.showAlertMessage(error);
              })
            }
          }	  
        });		  

     /* this._confirmation.create('Confirmation', 'Are you sure want to delete topic?', { confirmText: 'DELETE'}).subscribe((ans: ResolveEmit) => {
        if(ans.resolved){
          // this.httpLoader.showLoader();
          const payLoad = {
            topic_id : topicId,
          }
          this.staffRoomService.deletedTopic(payLoad).subscribe(
            response => {
              this.httpLoader.hideLoader();
              this.alert.showAlertMessage(response);
              let position = this.RoomTopicList.findIndex(x => x.topic_id ===topicId);

              if(~position){
                this.RoomTopicList.splice(position, 1);
              }

            },
            error => {
              this.httpLoader.hideLoader();
              this.alert.showAlertMessage(error);
            }
          )
        }
      }); */


    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);

    }
  }

  getTopicSec(pageNo){
    try {
      if(pageNo === 0){
        this.OFFSET = 0;
      }
      const offset = this.OFFSET;

      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: this.search || ''
      };
    } catch (error) {

    }
  }

  playMedia(fileData){

    this.playerService.clear();
    if (fileData.file_type ==='video' || fileData.file_type === 'audio') {
      if (fileData) {
        const payLoad = {
          file_path: fileData.download_url,
          file_type: fileData.file_type
        };
        this.playerService.emitMediaContent(payLoad);
        this.playingMedia = {...fileData}.download_url;
      }
    }
  }


  secureReadfile(fileData){

    const url = fileData.view_url;
    if(url){
      fileData.startFileDownLoad = true;

      this.secureFileService.secureReadfile(url).subscribe(blob => {
        fileData.startFileDownLoad = false;
        let objectURL = URL.createObjectURL(blob);
        this.isviewUploadDoc = true;
        this.viewPdfORPPT = fileData.file_path;

        this.selectedDoc = url;

      })
    }
  }

  readSecureFileAssign(fileData, index){
    this.topicId = index;
    this.viewAssignment[index] =! this.viewAssignment[index];

    console.log(fileData);

    this.viewFileType = fileData.file_type;

    var url = fileData.view_url;
    this.showFileLoadSpinner = true;

    if (this.viewFileType==='application') {
      if(url){
        // this.doc = fileData.view_url;
        this.secureFileService.secureReadfile(url).subscribe((blob) => {
          this.showFileLoadSpinner = false;
          this.doc = url;
        });
      }
    }else{
      this.doc = url;
      this.showFileLoadSpinner = false;
    }


  }

  onClickModalOutSide(index){
    this.viewAssignment[index] = false;
  }

  secureFileDownLoad(fileData: any) {
    try {
        const url = fileData.download_url;
        // console.log(url);

        if (url) {
            fileData.startFileDownLoad = true;
            this.secureFileService.secureFileDownLoad(url).subscribe(blob => {
              console.log(fileData.file_id);

                fileData.startFileDownLoad = false;
                importedSaveAs(blob, fileData.file_id);
            }, error => {
                fileData.startFileDownLoad = false;
                console.log(error);
            })
        }
    } catch (err) {
        fileData.startFileDownLoad = false;
        console.log(err);
    }
}

viewCurrentTopic(index){
  this.isViewTopic[index] =! this.isViewTopic[index];
  this.viewTopicId = index;
  try {
    this.elibraryService.clear();
    this.staffRoomService.getCurrentTopic(index).subscribe(response=>{
      this.getTopicDetails = response['result'];
      this.inputSrc = response['result']['view_url'];
      var url = response['result']['view_url'];
      this.showFileLoadSpinner = true;

      this.elibraryService.emitMediaContent(response['result']);
      if (url) {
        this.secureFileService.secureReadfile(url).subscribe((blob) => {
          this.showFileLoadSpinner = false;
          this.selectedReadFile = url;
        });
      }
    })
  } catch (error) {

  }
}

hideAssignModal(index){
  this.viewAssignment[index] = false;
}

hideTopicModal(id){
  this.isViewTopic[id] = false;
}

hideEditModal(index){
  this.editTopicActive[index] = false;
  this.editTopicForm.reset();
  this.preUploadedFile=null;
  this.upLoadedFileList = [];
}


  IsPublishedTopic(classRoomId, topicId){

    try {
      // this.httpLoader.showLoader();
      const payLoad = {
        group_id: classRoomId,
        topic_id: topicId
      }

      this.staffRoomService.publishedTopic(payLoad).subscribe(
        response => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(response);
          let selector = `.row-num-${topicId}`;
          document.querySelector(selector).classList.toggle('d-none');
          this.dataSharingService.emitPublishTopic(response);

          for (const i in this.RoomTopicList) {
            if (this.RoomTopicList[i].topic_id === topicId) {
              this.RoomTopicList[i].is_published = true;
              break;
            }
          }
        },
        error => {
          this.httpLoader.hideLoader();
        }
      )
    } catch (error) {
      this.httpLoader.hideLoader();
    }
  }

  showPublishFeedback(topic_id){
    this.studyroomService.getReviewFeedback(topic_id).subscribe(response=>{
      console.log(response);

    })
  }

  removeExitingFile(data){
    try{
     this.removeFileFromEditList(data);
     this.upLoadedFileList = [];
     this.preUploadedFile = null;
     this.uploadAttachFile = false;
    } catch (error){
      console.log(error);
    }
  }

   removeUploadFile(data, duration, file_id) {
     try {
       this.removeFileFromList(data, duration);
       this.upLoadedFileList = [];
       this.preUploadedFile = null;
      //  this.topicFileInput.nativeElement.value = "";
       // this.durationInput.nativeElement.value = "";
       // this.fileDuration = null;
       this.uploadAttachFile = true;
     } catch (error) {
       console.log(error);
     }
   }

   removeFileFromList(val, videoDuration) {
     const data = val[0];
     let button = document.querySelector('.disabledBtn');
          button.setAttribute('disabled', 'disabled');
     if (this.upLoadedFileList.length > 0) {
       const newFileList = this.upLoadedFileList;
       const currentFiles = newFileList.filter(
         (fl) => fl.uniqId !== data.uniqId
       );
       const paramId = +this.route.snapshot.paramMap.get("id");
       let fileEl = null;
       const existFileList = this.appComponent.selectedFilesList;
       fileEl = existFileList.find((el) => el.group_id === paramId);
       if (fileEl) {
         fileEl.files = currentFiles;
       }
       this.upLoadedFileList = currentFiles;
       this.appComponent.selectedFilesList = existFileList;
     }
   }

   removeFileFromEditList(val){
     const data = val[0];

     if (this.upLoadedFileList.length > 0) {
       const newFileList = this.upLoadedFileList;
       const currentFiles = newFileList.filter(
         (fl) => fl.uniqId !== data.uniqId
       );
       const paramId = +this.route.snapshot.paramMap.get("id");
       let fileEl = null;
       const existFileList = this.appComponent.selectedFilesList;
       fileEl = existFileList.find((el) => el.group_id === paramId);

       if (fileEl) {
         fileEl.files = currentFiles;
       }
       this.upLoadedFileList = currentFiles;
       this.appComponent.selectedFilesList = existFileList;
     }
   }

  showHideAssignment(topicId){
    this.isAssignActive[topicId] =! this.isAssignActive[topicId];
  }

  hideModalWindow(){
    this.isviewUploadDoc= false;
  }

  isPublishFeedback(data, index){
    this.feebackData = data;
    this.topicId = index;
    this.isactiveFeedback[index] =! this.isactiveFeedback[index]
  }

  hideFeedbackModal(index){
    this.isactiveFeedback[index] = false;
  }

  public onClickFeedbackMark(event:any){
    var numberValue = event.target.value;
  if ((event.which != 46 || numberValue.indexOf('.') != -1)&&(event.which < 48 || event.which > 57)) {
      if((event.which != 46 || numberValue.indexOf('.') != -1)){
        this.alert.showAlertMessage({message: 'Please enter a numbers only'});
      }
      event.preventDefault();
  }
  }

  submitFeedback(formVal, feedbackData , topicId){

    try {
      this.showBtnSpinner = true;
      const data = feedbackData;
      let studentId = data['student_id'];

      const payLoad = {
        teacher_id : data['teacher_id'],
        student_id : studentId,
        topic_id : topicId,
        feedback : formVal['feedback'],
        marks : formVal['marks']
      };

      var item = this.getUserList.find(item => item.student_id === studentId);

        item.feedback = formVal['feedback'];
        item.marks = formVal['marks'];

      this.staffRoomService.createFeedBack(payLoad).subscribe(
        response=>{
          this.showBtnSpinner = false;
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(response);
          this.isactiveFeedback[topicId] = false;
          let selector = `.row-num-${studentId}`;
          document.querySelector(selector).classList.toggle('d-none');

          for (const i in this.getUserList) {
            if(this.getUserList[i].student_id===studentId){
              this.getUserList[i].is_feedback = true;
            }
          }
          this.feedbackForm.reset();
        },
        error => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error['message']);
        }
      )
    } catch (error) {
      console.log(error);
    }
  }

  async fileValidationHanlder(files){
    try {
      const isValidate = await this.fileValidationService.fileValidation(files, this.chatFileExt, this.chatFileSizeLimit);
      return files;
    } catch (err) {
      err.map(li => {
          const errMsg = {
              message: li
          };
          // this.alert.showAlertMessage(errMsg);
          this.alert.showAlertMessage({message: 'The file is too large and cannot be uploaded. Please reduce the size of the file and try again (max 1 GB)'});
          let button = document.querySelector(".disabledBtn");
          button.setAttribute('disabled', 'disabled');
      });
      return false;

    }
  }


  async onFileChange(event){
    const files = event.target.files;

    const fileValid = await this.fileValidationHanlder(files);
      if (fileValid) {
        this.concatFileIntoArray(files);
      }
  }


  concatFileIntoArray(files){
    if (files && files.length > 0) {
      let button = document.querySelector(".disabledBtn");
      button.setAttribute('disabled', 'disabled');
      if(this.upLoadedFileList.length < 1){
        const formFields = {
          fieldName: 'staff_topic',
          activity: 'topic',
          storeType: 'temp'
        };

        let fileEl = null;
        let existFileList = null;
        this.uploadAttachFile = true;
        this.fileUploadingInProgress = true;
        for (let i = 0; i < files.length; i++) {
          const splitArr = files[i].name.split('.');

          files[i].mimeType = splitArr[splitArr.length - 1].toLowerCase();
          files[i].uniqId = Date.now();
          existFileList = [...this.appComponent.selectedFilesList];

          if(fileEl){
            fileEl.files.unshift(files[i]);
            this.upLoadedFileList = fileEl.files;
            // if(files[i].mimeType==='pdf' || files[i].mimeType ==='docx' || files[i].mimeType==='doc' || files[i].mimeType==='ppt' || files[i].mimeType ==='pptx' || files[i].mimeType==='pptm' || files[i].mimeType==='txt' || files[i].mimeType==='xml'){
            //   this.editQuizForm.patchValue({
            //     duration: ''
            //   });
            // }
          }else{
            this.upLoadedFileList.push(files[0]);
            this.fileValidationService
          .fileValidation(files, this.chatFileExt, this.chatFileSizeLimit)
          .then((validResponse) => {
            this.appComponent
              .fileUploadWithProgess(formFields, this.upLoadedFileList)
              .then((fileUpLoadResponse: any) => {
                for (const key in fileUpLoadResponse) {
                  if (fileUpLoadResponse[key]) {
                    this.preUploadedFile = fileUpLoadResponse[key];
                  }
                }
                this.upLoadedFileList = [];
                let button = document.querySelector(".disabledBtn");
                    button.removeAttribute('disabled');
                this.fileUploadingInProgress = false;
              })
              .catch((error) => {
                this.upLoadedFileList = [];
                // this.topicFileInput.nativeElement.value = "";
                this.alert.showAlertMessage(error.error);
              });
          })
          .catch((err) => {
            this.topicFileInput.nativeElement.value = "";
            this.upLoadedFileList = [];
            err.map((li) => this.alert.showAlertMessage(li));
          });
          }
        }
      }else{
        this.alert.showAlertMessage({
          message: 'File seletion limit reached.'
        });
      }
    }
  }

  videoPlayerInit(event) {
    const fileSrc = this.mediaContent ? this.mediaContent.file_path : null;
    this.inputSrc = fileSrc.replace("securefileDownload", "secureReadfile");

    console.log(this.inputSrc)
  }



  editTopicData(topic_id){
    this.topicId = topic_id;
    this.editTopicActive[topic_id] =! this.editTopicActive[topic_id];
    try {
      this.staffRoomService.getCurrentTopic(topic_id).subscribe((response:any)=>{
        this.topicData = response.result;

        // this.editTopicTime = response.result['shedule_time'];

        this.editTopicTime = this.convert24To12HourFormat(response.result['shedule_time']);
      })
    } catch (error) {

    }
  }


  convert24To12HourFormat(time): string {
    let timeArray = time.split(":");
    let timeIn12HourFormat = "";
    if (parseInt(timeArray[0]) <= 12) {
      timeIn12HourFormat += time + " AM";
    }
    else {
      var newhh = (timeArray[0] - 12) + "";
      if (parseInt(newhh) < 10) {
        newhh = "0" + newhh;
      }
      timeIn12HourFormat = newhh + ":" + timeArray[1];
      timeIn12HourFormat += " PM";
    }
    return timeIn12HourFormat;
  }

  updateTopicDetails(data, id){
    try {
      this.showBtnSpinner = true;
      const topicid = id;
      data['file_data'] = this.uploadAttachFile ? this.preUploadedFile : null;

      this.staffRoomService.updateTopicData(topicid, data).subscribe(response => {
        const createdTopic = response['result'];
        const topicList = [...this.RoomTopicList];
        for (let i = 0; i < topicList.length; i++) {
          const element = topicList[i]['topic_id'];
          if(element===topicid){
            topicList[i] = createdTopic;
          }
        }

        topicList.unshift(topicList.splice(topicList.findIndex(topicList => topicList.topic_id === topicid), 1)[0]);

        console.log(topicList, 'topic');


        // topicList.slice(id, 1);
        // topicList.unshift(createdTopic);
        this.showBtnSpinner = false;
        this.editTopicActive[id] = false;
        this.RoomTopicList = topicList;
        this.httpLoader.hideLoader();
        this.alert.showAlertMessage(response);
        this.preUploadedFile = null;
        this.upLoadedFileList = [];
        this.editTopicForm.reset();
      }, error=>{
        this.httpLoader.hideLoader();
        this.alert.showAlertMessage(error.error);
      })
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);

    }
  }

  // moreButtonTopic(id){
  //   this.isActiveMoreBtn[id] != this.isActiveMoreBtn[id];
  // }

  showMoreBtnOption(topic_ids){
    this.isDropHoverable[topic_ids] =true;
  }

  hideMoreBtnOption(topic_ids){
    this.isDropHoverable[topic_ids] = false;
  }

  ngOnDestroy(){
    this.resizeSubscription$.unsubscribe()
  }

}
