import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';


@Injectable()
export class MenuService {

    // private _getMenuList = new Subject();
    // emitMenuList$ = this._getMenuList.asObservable();

    constructor() { }

    // emitUserMenuList(menuList) {
    //     this._getMenuList.next(menuList);
    // };
}
