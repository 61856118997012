import { Component, OnInit, HostBinding, Directive } from '@angular/core';

// @Directive()
@Component({
  selector: 'app-connection-detail',
  templateUrl: './connection-detail.component.html',
  styleUrls: ['./connection-detail.component.scss']
})
export class ConnectionDetailComponent implements OnInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = "100%";
  constructor() { }

  ngOnInit() {
  }

}
