// import 'rxjs/add/observable/of';
// import 'rxjs/add/observable/from';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/throw';
// import "rxjs/add/operator/delay";

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { config } from '../../../app.config';

@Injectable()

export class GroupService {
    private groupInsert = new Subject<any>();
    userId: number;
    userName: string;
    Offset = config.DEFAULT_OFFSET;
    Record_limit = config.DEFAULT_RECORD_LIMIT;

    /** specifying Base URL.*/
    public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;

    constructor(private http: HttpClient) { }

    addNewGroup(group) {
        return this.http.post(`${this.BASE_URL}/groups`, group);
    };

    emitNewGroupCreation() {
        return this.groupInsert.asObservable();
    };

    getGroupList() {
        return this.http.get(`${this.BASE_URL}/groups`);
    };

    getClassRoomList(payLoad){
      return this.http.get(`${this.BASE_URL}/classList?offset=${payLoad.offset}&limit=${payLoad.limit}&sort=${payLoad.sort}&search=${payLoad.search}`);
    }

    getSelectedGroupList(payLoad) {
        return this.http.get(`${this.BASE_URL}/selectGroup?userId=${payLoad.userId}&offset=${payLoad.offset}&limit=${payLoad.limit}&search=${payLoad.search}`);
    };

    getGroupDetail(group_id: number) {
        return this.http.get(`${this.BASE_URL}/groups/${group_id}`);
    };

    // getClassRoomDetail(group_id: number){
    //   return this.http.get(`${this.BASE_URL}/currentRoom?groupId=${group_id}`);
    // }

    getClassRoomDetail(group_id: number){

      const payLoad = {
        offset: this.Offset,
        limit: this.Record_limit
      };

      return this.http.get(`${this.BASE_URL}/chats/groupMemberList?offset=${payLoad.offset}&limit=${payLoad.limit}&groupId=${group_id}`);
    }

    getSortAndSearch(payLoad) {
        return this.http.get(`${this.BASE_URL}/groups?offset=${payLoad.offset}&limit=${payLoad.limit}&sort=${payLoad.sort}&search=${payLoad.search}`);
    };

    // Updated User Service

    // updateGroupDetail(payLoad) {
    //     return this.http.put(`${this.BASE_URL}/groups/${payLoad.id}`, payLoad.data);
    // };

    updateGroupDetail(payLoad) {
      return this.http.put(`${this.BASE_URL}/groupsUsers/${payLoad.id}`, payLoad.data);
  };



    deleteGroupDetail(group_id) {
        return this.http.delete(`${this.BASE_URL}/groups/${group_id}`);
    };

    deleteSelectedGroup(group_id){
      return this.http.delete(`${this.BASE_URL}/groupRoom/${group_id}`);
    }

    deleteGroupProfile(group_id) {
        // return this.http.delete(`${this.BASE_URL}/groups/deleteGroupProfile/${group_id}`);
        return this.http.delete(`${this.BASE_URL}/chats/deleteChatGroupIcon?chat_group_id=${group_id}`);
    };

    deleteGroupUsers(group_id, student_id){
      return this.http.delete(`${this.BASE_URL}/groupUser/${group_id}/${student_id}`);
    }

}
