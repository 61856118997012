import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpHeaders, HttpParams  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { config } from 'app/app.config';
import { LoaderService } from 'app/loader/loader.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

export class Media {
  file_path: string;
  file_type: number;
}

@Injectable({
  providedIn: 'root'
})
export class StudyRoomService {

  BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;

  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  private mediaContent = new Subject<Media>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private loaderService: LoaderService
  ) { }

  private showLoader(): void {
    this.loaderService.showLoader();
  }

  private hideLoader(): void {
    this.loaderService.hideLoader();
  }

  getStudyRoom(payLoad){
    this.showLoader();
    return this.http.get(`${this.BASE_URL}/studyroom/getRoom?offset=${payLoad.offset}&limit=${payLoad.limit}&search=${payLoad.search}&sort=${payLoad.sort}`);
  }

  getStudyRoomTopic(id){
    // this.showLoader();
    return this.http.get(`${this.BASE_URL}/studyroom/getTopicList/${id}`);
  }

  createAssignment(payLoad){

    return this.http.post(`${this.BASE_URL}/studyroom/submitAssignment`, payLoad);
  }

  getReviewFeedback(id){
    return this.http.get(`${this.BASE_URL}/studyroom/reviewFeedBack/${id}`);
  }

  // createFeedBack(payLoad){
  //   // const Params = this.preHttpParams(payLoad);
  //   return this.http.post(`${this.BASE_URL}/studyroom/feedback`, payLoad);
  // }

  getMediaContent(): Observable<any> {
    return this.mediaContent.asObservable();
  }

  emitMediaContent(mediaFields: any) {
    setTimeout(() => {
      this.mediaContent.next(<Media>mediaFields);
    }, 300);
  }

  clear(){
    this.mediaContent.next();
  }

  preHttpParams(payLoad) {
    let Params = new HttpParams();
    for (const key in payLoad) {
        if (key && (payLoad[key] || payLoad[key] === 0)) {
            Params = Params.append(key, payLoad[key]);
        }
    }
    return Params;
  };


}
