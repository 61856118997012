import { Component, OnDestroy, OnInit, AfterViewInit, Input, Output, ViewChild, ElementRef, Directive  } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { AlertPopup, HttpLoader } from 'app/app.config';
import { StaffRoomService } from '../../staff-room.service';


// @Directive()
@Component({
  selector: 'app-topic-tile-view',
  templateUrl: './topic-tile-view.component.html',
  styleUrls: ['./topic-tile-view.component.css']
})
export class TopicTileViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() RoomTopicList;
   @Input() maxLength: number = 400;
  @Input() maxHeight = '4rem';
  @ViewChild('scrollframe') scrollFrame : ElementRef;

  constructor(
    private staffRoomService: StaffRoomService,
    private httpLoader: HttpLoader,
    private alert: AlertPopup,
    private appcomponent: AppComponent,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }

  deletedTopic(topicId){
    try {
      // this.httpLoader.showLoader();
      const payLoad = {
        'topic_id' : topicId
      }
      this.staffRoomService.deletedTopic(payLoad).subscribe(
        response => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(response);
        }
      )
    } catch (error) {

    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

  }

}
