import { Component, OnInit, Input, HostBinding, OnDestroy, AfterViewInit, Directive } from '@angular/core';
import { config, AlertPopup, HttpLoader } from 'app/app.config';
import { Alert } from 'app/notification-alert/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeworkService } from './homework.service';
import { SocketService } from 'app/socket/socket.service';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';

// @Directive()
@Component({
  selector: 'app-homework',
  templateUrl: './homework.component.html',
  styleUrls: []
})
export class HomeworkComponent implements OnInit, OnDestroy {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @Input() loggedUserId: number;
  OFFSET:number = config.DEFAULT_OFFSET;
  sortBy = 'status';
  RECORD_LIMIT = config.DEFAULT_RECORD_LIMIT;
  searchTasks: string;
  taskFilter:string;
  totalRowCount: number;

  userRole = 1;

  sortOptions = false;
  showFilterList = false;
  activeFilter = false;
  taskLists:any[] = [];
  isAssignActive:any[] = [];
  isActiveUser:any[]= [];
  isUserRole :any;
  statusFilter:any;
  groupFilter:any;
  assignee:any;
  assigner:any;
  dateFilter:any;
  startdateFilter:any;
  enddateFilter:any;

  viewControl = {
    changedSearchText: false
  };
  searchInList = new Subject<any>();
  search:string;

  arrayOne(n: number): any[] {
    return Array(n);
  }

  statusList = ['assigned', 'in progress', 'completed', 'delayed', 'pending'];
  groupList = [{
    'name': 'Individual',
    'value': 'nonGroup'
  },{
    'name' : 'Group',
    'value': 'group'
  }]

  showPaginateSpinner = false;
  constructor(
    private alert : AlertPopup,
    private router: Router,
    private route: ActivatedRoute,
    private httpLoader: HttpLoader,
    private homeworkService: HomeworkService,
    private socketService: SocketService,
  ) {
    this.searchInList
      .debounceTime(500)
      .subscribe(event => {
        this.search = event.target.value;
        if (!this.search) {
          return;
        }
        this.viewControl.changedSearchText = true;
        this.getTaskList(0);
      });
  }

  ngOnInit(): void {
    this.getTaskList(0);

    this.isUserRole = window.localStorage.getItem('user_role');
    this.isUserRole = parseInt(this.isUserRole.replace(/\"/g, ""));

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    try {
      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: this.search || '',
      };
      this.homeworkService.getActiveUsers(payLoad).subscribe(response => {
        this.showPaginateSpinner = false;
        if(this.OFFSET === 0){
          this.isActiveUser = response['userList'];
        }else{
          this.isActiveUser = this.isActiveUser.concat(response['userList']);
        }

      })
    } catch (error) {
      this.alert.showAlertMessage(error.error);
    }

  }

  showHideAssignment(id){
    console.log(id);

    this.isAssignActive[id] =!this.isAssignActive[id];
  }

  updateTaskStatus(){

  }

  getTaskList(pageNo){
    try {
      if(pageNo === 0){
        this.OFFSET = 0;
        // this.httpLoader.showLoader();
      }
      const offset = this.OFFSET;
      // this.dateFilter = this.startdateFilter+ 'and' +this.enddateFilter;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: this.search || '',
        sort: this.sortBy || '',
        filters:this.taskFilter || '',
        status: this.statusFilter || '',
        assigned_to: this.assignee || '',
        assigned_by: this.assigner || '',
        from_date: this.startdateFilter || '',
        to_date: this.enddateFilter || ''
      };
      this.homeworkService.getOverallTaskLists(payLoad).subscribe(response => {
        this.showPaginateSpinner = false;
        if(this.OFFSET === 0){
          // this.httpLoader.showLoader();
        this.taskLists = response['pinDetails'];
        }else if(this.OFFSET > 0){
          this.showPaginateSpinner = false;
          this.taskLists = this.taskLists.concat(response['pinDetails']);
        }
        this.totalRowCount = response['TotalRowCount'];
      }, (error) => {
        this.alert.showAlertMessage(error.error);
        this.showPaginateSpinner = false;
        this.httpLoader.hideLoader();
      })

    } catch (error) {
      this.httpLoader.hideLoader();
      this.alert.showAlertMessage(error.error);
    }
  }

  homeworkSortOptions(){
    this.sortOptions = !this.sortOptions;
  }

  homeworkFilter(){
    this.activeFilter =!this.activeFilter;
    this.showFilterList=!this.showFilterList;

  }

  updatePinTaskStatus(id, event){

    if (window.confirm("Are you sure you want update task status?")){
      try {
        const payLoad = {
          'pin_id' : id,
          'status' : event.target.value
        }

        this.homeworkService.updateTaskStatus(payLoad).subscribe(response=> {
          // this.showTaskData[id] = false;
          this.alert.showAlertMessage(response);
        })
      } catch (error) {
        this.alert.showAlertMessage(error.error);
      }
    }else {
      return false;
    }
  }


  ngOnDestroy(){
    this.searchInList.unsubscribe();
  }

}
