import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { RouterModule } from '@angular/router';
import { StudyRoomComponent } from './study-room.component';
import { StudyroomDetailsComponent } from './studyroom-details/studyroom-details.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {path: '', component: StudyRoomComponent, children: [
        {path: ':id', component: StudyroomDetailsComponent},
      ] }
    ])
  ],
  declarations:[],
  exports: [RouterModule]
})

export class StudyRoomRoutingModule {}
