import { Component, OnInit, HostBinding, Input, ViewChild, ElementRef, Directive, Inject, Injector  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { HttpEventType, HttpResponse, HttpClient, HttpRequest } from '@angular/common/http';
import * as _ from 'underscore';
import { config, path, UPLOAD_URI, HttpLoader, AlertPopup } from './../../../../app.config';
import { AppComponent } from './../../../../app.component';
import { FileValidationService } from './../../../../shared/file-validation.service';
import { ValidationService } from '../../../form-validation/validation.service';
import { UtilityService } from './../../../../utility.service';

import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';
import { ChatService } from 'app/components/chat-list/chat.service';
import { fromEvent, Subscription} from 'rxjs';
import { GroupService } from 'app/components/userandgroup/group/group.service';
import { UserService } from 'app/components/userandgroup/user/user.service';
import { GroupComponent } from 'app/components/userandgroup/group/group.component';
import { ELibraryComponent } from '../e-library.component';
import { DOCUMENT } from '@angular/common';



// @Directive()
@Component({
  selector: 'app-new-course',
  templateUrl: './new-course.component.html',
  styleUrls: ["./new-course.component.scss"]
})
export class NewCourseComponent implements OnInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  newGroupForm: FormGroup;
  search: string;
  listOfUsers: any[] = [];
  totalRowCount: number;
  searchText: string;
  privacyList = [
    { name: 'Public', value: false },
    { name: 'Private', value: true }
  ];

  uploadSpinner = false;
  toggleModalSection = false;
  userIdList: number[] = [];
  user_Ids: number[] = [];
  // selectedFileList: any[] = [];

  /** User Profile icon handler variables */
  selectedFileList: any[] = [];
  profileExtension = config.PROFILE_EXT;
  profileSize = config.PROFILE_SIZE;

  // selectedFile: any;
  // fileLoadedPercentage: number;
  preUploadedFile: any;
  showPaginateSpinner = false;

  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;

  viewControl = {
    changedSearchText: false
  };
  searchInList = new Subject<any>();

  isMobile: boolean = false;
  width:number = window.innerWidth;
  height:number = window.innerHeight;
  mobileWidth:number  = 768;


  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  /** specifying Base URL.*/
  public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/groups`;
  profileUrl = path.PROFILE_URL;
  preProfileUrl = path.PRE_PROFILE_URL;
  showModal:boolean=false;

  constructor(
    private fb: FormBuilder,
    private appComponent: AppComponent,
    private chatService: ChatService,
    private groupService: GroupService,
    private userService: UserService,
    private httpLoader: HttpLoader,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private utilityService: UtilityService,
    private fileValidationService: FileValidationService,
    private alert: AlertPopup,
    private elibraryComponent: ELibraryComponent,
    @Inject(DOCUMENT)  private document:Document
  ) {
    this.newGroupForm = fb.group({
      chat_group_name: ['', Validators.compose([Validators.required, ValidationService.noWhitespaceValidator])],
      // is_private: [null, Validators.required],
      // is_private: [null],
      chat_description: [''],
    });

    this.searchInList
    .debounceTime(500)
    .subscribe(event => {
      this.searchText = event.target.value;
      this.viewControl.changedSearchText = true;
      this.userSearchAndPagination(0);
    });
  }

  ngOnInit(): void {
    const payLoad = {
      offset: this.OFFSET,
      limit: this.RECORD_LIMIT,
      search: this.search || ''
    };
    this.getuserList(payLoad);
    this.newGroupForm.patchValue({
      is_private: false
    });

    // if(this.width <= this.mobileWidth){
    //   this.elibraryComponent.viewSecondPanel = true;
    // }else{
    //   this.elibraryComponent.viewSecondPanel = false;
    // }

    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      let windowWith = window.innerWidth;
      // if(windowWith <= this.mobileWidth){
      //   this.elibraryComponent.viewSecondPanel = true;
      // }else{
      //   this.elibraryComponent.viewSecondPanel = false;
      // }
    });
    this.showModal = true;
  }

  onFileChange(event) {
    const files = event.target.files;
    if (files && files.length > 0) {
      const formFields = {
        fieldName: 'user_profile',
        activity: 'user_profile_add',
        storeType: 'temp'
      };

      this.selectedFileList.push(files[0]);
      this.fileValidationService.fileValidation(files, this.profileExtension, this.profileSize).then(validResponse => {
        this.appComponent.fileUploadWithProgess(formFields, this.selectedFileList).then((fileUpLoadResponse: any) => {
          for (const key in fileUpLoadResponse) {
            if (fileUpLoadResponse[key]) {
              this.preUploadedFile = fileUpLoadResponse[key]
            }
          }
          this.selectedFileList = [];
          this.fileInput.nativeElement.value = '';
        }).catch(error => {
          this.selectedFileList = [];
          this.fileInput.nativeElement.value = '';
          this.alert.showAlertMessage(error.error);
        })
      }).catch(err => {
        this.fileInput.nativeElement.value = '';
        this.selectedFileList = [];
        err.map(li => this.alert.showAlertMessage(li));
      });
    }
  };

  keyDownHandler(event) {
    if (event.keyCode === 32 && event.target.selectionStart === 0) {
      return false;
    }
  }

  /**
   * Remove the Selected File based on the index position
   * @param index
   */

   removeSelectedFile() {
    this.preUploadedFile = null;
    this.selectedFileList = [];
  };

  /**
   * Add user selected user id in the userListId array
   * @param userId
   */
   selectUserId(userId) {
    if (this.userIdList.indexOf(userId) > -1) {
      this.userIdList = _.without(this.userIdList, userId);
      return;
    } else {
      this.userIdList.push(userId);
      return;
    }
  };

  showGroupName(){
  //  this.document.getElementsByClassName('second-level-menu')[0].classList.remove('hide');
    // this.router.navigate(['/main/elibraryandcourse/e-library']);
    this.elibraryComponent.viewControl.closeEmptyMessages = false;
    this.showModal = !this.showModal;
    history.back();
  }

   /**
 * Select User id from User Section in modal window
 */
    openAddUserSection() {
      this.toggleModalSection = !this.toggleModalSection;
    };


    /**
   * Clear user Id list Selection
   */
  clearAddUserSection() {
    if (this.userIdList && this.userIdList.length > 0) {
      const confirmation = confirm('Do you want to cancel selected user?');
      if (confirmation === true) {
        this.userIdList = [];
        this.openAddUserSection();
      } else {
        return true;
      }
    } else {
      this.openAddUserSection();
    }
  };

  /**
   * Add New Class Room
   */

   addNewClassRoom(group){
    if(this.userIdList.length==0){
      this.alert.showAlertMessage({"message":"Please select atleast one user in this lesson"});

    }else{
      try {


        this.userIdList = _.uniq(this.userIdList);

        this.httpLoader.showLoader();
          group.user_Ids = this.userIdList;

        var group_name = group.chat_group_name;
        group_name = group_name.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
        group.chat_group_name = group_name.trim();



        // console.log(this.elibraryComponent.totalRowCount);

        this.chatService.createGroup(group).subscribe(
          (data)=>{
            this.alert.showAlertMessage(data);
            this.newGroupForm.reset();

            let userIdList = {...data['createdGroup']};
            userIdList.TotalUserCount = this.userIdList.length;

            this.elibraryComponent.elibraryList.unshift(userIdList);

            this.userIdList = [];
            this.user_Ids = [];
            this.selectedFileList = [];
            this.httpLoader.hideLoader();
          },
          error =>{
          this.alert.showAlertMessage({message: error.error['message']} );
          this.selectedFileList = [];
          this.httpLoader.hideLoader();
          }
        )
      //  }else{
      //    this.alert.showAlertMessage({message: 'Please select the student'});
      //  }
      } catch (error) {
      this.selectedFileList = [];
      this.httpLoader.hideLoader();
      console.log(error);
      }
    }
  }

  fetchNextUserList(){
    this.OFFSET += 1;
    this.userSearchAndPagination(this.OFFSET);
  }

  /**
   * Get user list
   */
   getuserList(pageNo) {
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: this.search || ''
      };

      this.httpLoader.showLoader();
      this.userService.getActiveUserList(payLoad).subscribe(
        (lists) => {
          this.httpLoader.hideLoader();
          this.listOfUsers = lists['userList'];
          this.totalRowCount = lists['TotalRowCount'];
        },
        error => {
          this.httpLoader.hideLoader();
          console.log(error);
        })
    } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
    }
  };

  /**
 *
 * @param searchText
 */
   userSearchAndPagination(pageNo) {
    try {
      this.httpLoader.showLoader();
      if (pageNo === 0) {
        this.OFFSET = pageNo;
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        sort: '',
        search: this.searchText || ''
      };
      this.userService.getActiveUserPaginate(payLoad).subscribe(
        (userList) => {
          this.httpLoader.hideLoader();
          if (offset === 0) {
            this.listOfUsers = userList['userList'];
            this.totalRowCount = userList['TotalRowCount'];
          } else if (offset > 0) {
            this.listOfUsers = this.listOfUsers.concat(userList['userList']);
            this.showPaginateSpinner = false;
          }
        },
        error => {
          this.httpLoader.hideLoader();
          this.showPaginateSpinner = false;
          console.log(error);
        });
    } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
    }
  };

  /**
   * Pagination called while scroll down
   */
   onScrollDown() {
    if ((this.listOfUsers.length < this.totalRowCount) && !this.showPaginateSpinner) {
      this.OFFSET += 1;
      this.showPaginateSpinner = true;
      this.userSearchAndPagination(this.OFFSET);
    }
  };

}
