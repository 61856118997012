import { HttpClient } from '@angular/common/http';
import { AfterContentChecked, AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { AlertPopup, config, HttpLoader, path } from 'app/app.config';
import { SecureFileService } from 'app/secure-image/secure-image-service';
import { PlayerService } from '../media-player/media-player-service';
import { StaffRoomService } from '../staff-room/staff-room.service';
import { StudyRoomService } from './study-room.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { Subject, Observable } from 'rxjs';
import { SocketService } from 'app/socket/socket.service';
import { ChatService } from '../chat-list/chat.service';
import { DataSharingService } from 'app/shared/data-sharing.service';
 
import * as _ from 'underscore';
// import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';

// @Directive()
@Component({
  selector: 'app-study-room',
  templateUrl: './study-room.component.html',
  styleUrls: ['./study-room.component.css']
})
export class StudyRoomComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {

@HostBinding('class.flex-grid') leftBarClass = true;
@HostBinding('style.width') flexwidth = '100%';
@ViewChild('studyRoomList', {static: true}) private studyRoomScrollContainer: ElementRef;


  sortBy = 'time';
  showSearchSec =true;
  search:string;
  activeUrl:any;

  showPaginateSpinner = false;
  OFFSET = config.DEFAULT_OFFSET;
  RECORD_LIMIT = config.DEFAULT_RECORD_LIMIT;
  getStudyRoomLists: any[]=[];
  isActiveModal: boolean;
  playingMedia: string;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  profileUrl = path.PROFILE_URL;
  viewStudyRoomList = false;
  totalRowCount: number;
  public viewSecondPanel = false;
  public topicSubscribers: any = {};

  welcomeWindowName: string;
  showWelcomeWindow = true;
  showMenuView = false;
  loggedUserDeatails: any;
  is_tour_completed = false;

  viewControl = {
    changedSearchText : false
  }

  // offset:any;

  searchInList = new Subject <any>();

  private subscribers: any = {};
  public userSubscribers: any = {};

  constructor(
    private studyRoomService: StudyRoomService,
    private alert: AlertPopup,
    private httpLoader: HttpLoader,
    private route: ActivatedRoute,
    private router: Router,
    private app: AppComponent,
    private staffRoomService: StaffRoomService,
    private playerService: PlayerService,
    private httpClient: HttpClient,
    private secureFileService: SecureFileService,
    private socketService: SocketService,
    private chatService: ChatService,
    private dataSharingService: DataSharingService,
  
    private appComponent: AppComponent
  ) {
    // this.searchInList.debounceTime(500).subscribe(
    //   event =>{
    //     this.search=event.target.value;
    //     if(!this.search){
    //       return;
    //     }
    //     this.viewControl.changedSearchText = true;
    //     this.getStudyRoom(0);
    //   }
    // )

    this.searchInList.debounceTime(500).subscribe(
      event => {
        this.viewControl.changedSearchText = true;
        const searchText = event.target.value;
        this. searchClassList(searchText);
      }
    )
  }

  ngOnInit(): void {
    this.welcomeWindow('welcome');
    this.getStudyRoom(this.search);
    this.route.url.subscribe(()=> {
      this.activeUrl = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.url[0].path : false;
    });
  }

  ngAfterViewInit(){

    try {

     this.topicSubscribers.topicListSub = this.dataSharingService.getPublishTopic().subscribe(newTopic => {
       console.log(newTopic);

     })

      this.subscribers.staff_topic = this.socketService.studyRoomSession().subscribe(publishTopic => {
        if(publishTopic){
          const existingTopicList = [...this.getStudyRoomLists];
          console.log(publishTopic);
          if (publishTopic.is_new) {
            existingTopicList.unshift(publishTopic);
            this.totalRowCount += 1;
          }else if (!publishTopic.is_new) {
            existingTopicList.find(topicList => {
              if (topicList.group_id === publishTopic.group_id) {
                return topicList.group_name = publishTopic.group_name;
              }
            });
          }
          this.getStudyRoomLists = existingTopicList;
        }
      })
    } catch (error) {
      console.log(error);

    }
  }

  ngAfterContentChecked() {

    if (_.isEqual(this.loggedUserDeatails, this.appComponent.userDetail)) {
      return;
    }

    if (_.isObject(this.appComponent.userDetail)) {
      this.loggedUserDeatails = this.appComponent.userDetail;
      this.is_tour_completed = !this.loggedUserDeatails.is_tour_completed
    }
  };

  searchClassList(search){
    this.getStudyRoom(search);
  }


  getStudyRoom(search = '', pageNo = 0){
    try {
      if(pageNo === 0){
        this.OFFSET = 0;
      }
      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: search || '',
        sort: this.sortBy
      };

      this.studyRoomService.getStudyRoom(payLoad).subscribe(
        data => {
          if(offset === 0){
            this.getStudyRoomLists = data['groupDetails'];
            // this.scrollToTop();
          }else {
            this.getStudyRoomLists = [...this.getStudyRoomLists.concat(data['groupDetails'])];
          }
          this.totalRowCount = data['TotalRowCount'];
          this.showPaginateSpinner = false;
          this.httpLoader.hideLoader();
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.showPaginateSpinner = false;
          this.httpLoader.hideLoader();
        }
      )
    } catch (error) {
      this.httpLoader.hideLoader();
    }
  }

  // scrollToTop(){
  //   setTimeout(()=> {
  //     this.studyRoomScrollContainer.nativeElement.scrollTop = 0;
  //   }, 100);
  // }

  /** Active Teacher help Desk */
  welcomeWindow(name) {

    if (name) {
      this.showWelcomeWindow = true;
      this.welcomeWindowName = name;
    } else {
      this.showWelcomeWindow = false;
    }
  }

  showOnboarding() {
    //this.hintService.initialize({ elementsDisabled: true });
    this.showWelcomeWindow = false;
  }


  fetchNextStudyRoomList(){
    const listlength = this.getStudyRoomLists.length;
    if(this.totalRowCount > listlength && !this.showPaginateSpinner){
      this.OFFSET +=1;
      this.showPaginateSpinner = true;
      this.getStudyRoom(this.search, this.OFFSET);
    }
  }

  playMedia(fileData){

    this.playerService.clear();
    if (fileData.file_type ==='video' || fileData.file_type === 'audio') {
      if (fileData) {
        const payLoad = {
          file_path: fileData.download_url,
          file_type: fileData.file_type
        };
        this.playerService.emitMediaContent(payLoad);
        this.playingMedia = {...fileData}.download_url;
      }
    }
  }

  secureFileDownLoad(fileData: any) {
    try {
        const url = fileData.download_url;
        if (url) {
            fileData.startFileDownLoad = true;
            this.secureFileService.secureFileDownLoad(url).subscribe(blob => {
                fileData.startFileDownLoad = false;
                importedSaveAs(blob, fileData.file_id);
            }, error => {
                fileData.startFileDownLoad = false;
                console.log(error);
            })
        }
    } catch (err) {
        fileData.startFileDownLoad = false;
        console.log(err);
    }
};

  showSearchBox(){
    this.showSearchSec =! this.showSearchSec;
    if (this.viewControl.changedSearchText) {
      try {
        this.search = '';
        this.viewControl.changedSearchText = false;
        const offset = 0;
        this.getStudyRoom(this.search, offset);
      } catch (error) {
        this.httpLoader.hideLoader();
      }
    }
  }

  uploadAssignment(){
    this.isActiveModal =! this.isActiveModal;
  }
  hideTopicModal(){
    this.isActiveModal = false;
  }

  incrementTopicCount(response){
    if(response){
      const copyTopicList = [...this.getStudyRoomLists];
      const findIndex = copyTopicList.findIndex(el => el.chat_group_id === response.group_id);
      const copyObj = copyTopicList[findIndex];
      if (findIndex >= 0) {
        copyObj.last_message = response.message;
        if (copyObj.unread_msg_count >= 0) {
          copyObj.unread_msg_count += 1;
        } else {
          copyObj.unread_msg_count = 0;
        }
        copyTopicList[findIndex] = copyObj;
      } else {
        copyTopicList[0] = copyObj;
      }

      this.getStudyRoomLists = copyTopicList;
    }
  }

  ngOnDestroy(){ //9443921678
    // this.subscribers.publishTopic.unsubscribe();
  }

}
