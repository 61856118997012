import { Component, OnInit, Input, OnChanges, HostBinding, Directive  } from '@angular/core';


// @Directive()
@Component({
    selector: 'empty-uname',
    templateUrl: './empty-profile.component.html'
})

export class EmptyUnameComponent implements OnInit, OnChanges{
    @HostBinding('class.display-ib') profileElmentClass = true;
    @Input() userName: string;
    @Input() liProfile: boolean;
    @Input() detailProfile: boolean;
    @Input() detailHeadProfile: boolean;
    firstChar: string;

    constructor() { }

    ngOnInit() {
    };


    ngOnChanges(){
        this.displayUserFirstLetter(this.userName)
    }


    displayUserFirstLetter(userName) {
        if (userName) {
            this.firstChar = this.userName.charAt(0).toUpperCase();
        } else {
            return;
        }
    };
}
