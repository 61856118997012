import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, AfterViewInit, Directive  } from '@angular/core';
import { path } from './../../../../app.config';
import { Router } from '@angular/router';

import { DataSharingService } from '../../../../shared/data-sharing.service';
import { ChatService } from '../../chat.service';
// @Directive()
@Component({
    selector: 'app-audio-receiver',
    templateUrl: './audio-receiver-view.component.html',
    styleUrls: [],
    providers: [ChatService]

  })

  export class AudioReceiverView implements OnInit {

   @Input() callingUser
   // @Output() callingResponseData = new EventEmitter();
    response = false
    callingView = false;
    time: number = 0;
    display ;
    interval;

    constructor(
      private chatservice: ChatService,
      private router: Router,
      private dataSharing : DataSharingService
    ){

    }
    ngOnInit(): void {
        //throw new Error("Method not implemented.");
        this.startTimer();
    }
    profileUrl = path.PROFILE_URL;

    startTimer() {
      this.interval = setInterval(() => {
        if (this.time === 0) {
          this.time++;
        } else {
          this.time++;
        }
        this.display=this.transform( this.time)
      }, 1000);
    }
    transform(value: number): string {
         const minutes: number = Math.floor(value / 60);
         return minutes + ':' + (value - minutes * 60);
    }
    pauseTimer() {
      clearInterval(this.interval);
    }

    callActivated() {
      this.callingUser.type = false
      this.callingUser['callResponse'] = true
      this.chatservice.acceptedCall(this.callingUser).subscribe(res => {
        this.dataSharing.emitDataAnswed({data:'ans'})
        let userID = this.callingUser.fromID
       // this.router.navigate([`/chats/singleuser/${userID}`]);



      })
    }

    callDeactivated(){
      this.callingUser.type = false
      this.chatservice.declinedCall(this.callingUser)
    }


  }
