import { Injectable } from '@angular/core';
import { config as _CONFIG } from './../app.config';

@Injectable()
export class FileValidationService {
    errors: any[] = [];
    constructor() { }


    fileValidation(files: File, fileExtension, size: number) {
        return new Promise((resolve, reject) => {
            this.errors = [];
            if (this.isValidFiles(files, fileExtension, size)) {
                resolve(files);
            } else {
                reject(this.errors);
            }
        });
    };


    private isValidFiles(files, fileExtension, size) {
        this.isValidFileExtension(files, fileExtension, size);
        return this.errors.length === 0;
    };


    private isValidFileExtension(files, fileExtension, size) {
        const extensions = (fileExtension.split(',')).map((ext) => ext.toUpperCase().trim());
        for (let i = 0; i < files.length; i++) {
            const ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;
            const exists = extensions.includes(ext);
            if (!exists) {
                this.errors.push(`Error (Extension): ${files[i].name} - Unsupported file format.`);
            }
            this.isValidFileSize(files[i], size);
        }
    };


    private isValidFileSize(file, size) {
        const fileSizeinMB = file.size / (1024 * 1000);
        const fileSize = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
        if (fileSize > size) {
            const errorMsg = {
                message : `Error (File Size): ${file.name} : exceed file size limit of ${size} MB ( " ${fileSize} "MB )`
            }
            this.errors.push(errorMsg);
        }
    };
}
