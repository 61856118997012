import { Component, OnInit, HostBinding, ViewChild, ElementRef, Injectable, Output, EventEmitter, Directive  } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import {
  HttpEventType,
  HttpResponse,
  HttpClient,
  HttpRequest,
} from "@angular/common/http";
import { Router } from "@angular/router";
import * as _ from "underscore";
 
import { AppComponent } from "./../../app.component";
import {
  BASE_URI,
  path,
  config,
  ResolveEmit,
  AlertPopup,
  HttpLoader,
} from "./../../app.config";
import { ValidationService } from "./../form-validation/validation.service";
import { UserService } from "./../userandgroup/user/user.service";
import { SettingsService } from "./settings.service";
import { UtilityService } from "./../../utility.service";
import { HeaderService } from "./../header/header.service";
import { FileValidationService } from "./../../shared/file-validation.service";
import { MustMatch } from "./must-match.validator";
import { ConfirmationDialogService } from "app/confirmation-dialog/confirmation-dialog.service";
import { ThemeService } from './theme.service';
// import { EventEmitter } from 'protractor';

// @Directive()
@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  @HostBinding("class.flex-grid") leftBarClass = true;
  @HostBinding("style.width") flexwidth = "100%";
  @HostBinding('style.flex-direction') direction='column';
  @ViewChild("fileInput") fileInput: ElementRef;
  @ViewChild("logoFileInput") logoFileInput: ElementRef;
  @Output() onClose = new EventEmitter();
  @ViewChild("closebutton") closebutton;

  newUserForm: FormGroup;
  accountFrom: FormGroup;
  settingsForm: FormGroup;
  instituteForm: FormGroup;
  submitted = false;
  // selectedFileList: any[] = [];
  settingsDetail: any = {};
  _cloneSettingsDetail: any = {};
  chat_layout: any = {};
  settingsList: any[] = [];
  otherSettings: any = {};
  fileLoadedPercentage: number;
  logged_user_id: number;
  user_icon: string;
  company_icon: string;
  tabName = "profile";
  layoutShow = false;
  showIconImage = false;
  showBtnSpinner = false;
  loggedUserRole: number;

  cuntryCode: number;

  genderArrayList = [
    { name: "Male", value: "M" },
    { name: "Female", value: "F" },
    // {name: 'Other',value: 'O'}
  ];

  defaultGender = "M";

  /** User Profile icon handler variables */
  selectedFileList: any[] = [];
  selectedLogoFileList: any[] = [];
  profileExtension = config.PROFILE_EXT;
  profileSize = config.PROFILE_SIZE;

  profileUrl = path.PROFILE_URL;
  companyUrl = path.COMPANY_URL;

  preUploadedFile: any;
  selectedTheme:any="steelblue";
  constructor(
    private appComponent: AppComponent,
    private fb: FormBuilder,
    private alert: AlertPopup,
    private httpLoader: HttpLoader,
    private userService: UserService,
    private settingsService: SettingsService,
    private http: HttpClient,
    private router: Router,
    private utilityService: UtilityService,
    private headerService: HeaderService,
    private themeService: ThemeService,
    private confirmDialogService:ConfirmationDialogService,
    private fileValidationService: FileValidationService,
  ) {
    this.newUserForm = fb.group({
      first_name: [
        null,
        Validators.compose([
          Validators.required,
          ValidationService.namePattern,
        ]),
      ],
      last_name: [""],
      email_id: [
        "",
        Validators.compose([
          Validators.required,
          ValidationService.emailValidator,
        ]),
      ],
      phone_no: [
        null,
        Validators.compose([
          Validators.required,
          ValidationService.phoneNoValidate,
        ]),
      ],
      user_address: [""],
      user_city: [""],
      user_state: [""],
      user_zip: [null],
      gender: [""],
      user_notes: [""],
    });

    //   this.accountFrom = fb.group({
    //     old_password: ['', Validators.compose([Validators.required, ValidationService.passwordValidator])],
    //     new_password: ['', Validators.compose([Validators.required, ValidationService.passwordValidator])],
    //     confirm_password: ['', Validators.compose([Validators.required, ValidationService.passwordValidator])],
    //   }, {
    //     validator: MustMatch('new_password', 'confirm_password')
    // })

    this.accountFrom = fb.group(
      {
        old_password: ["", Validators.compose([Validators.required])],
        new_password: [
          "",
          Validators.compose([Validators.required, , Validators.minLength(6)]),
        ],
        confirm_password: ["", Validators.compose([Validators.required])],
      },
      {
        validator: MustMatch("new_password", "confirm_password"),
      }
    );

    this.instituteForm = fb.group({
      title: ['', Validators.compose([Validators.required, ValidationService.noWhiteSpaceTitleValidator])],
      caption: ['', Validators.compose([Validators.required, ValidationService.noWhiteSpaceTitleValidator])]
    })
  }

  ngOnInit() {
    try {
      this.httpLoader.showLoader();
      this.settingsService.getUserSettings().subscribe(
        (settings) => {
          this.httpLoader.hideLoader();
          this.layoutShow = true;

          this.logged_user_id = settings["loggedId"];
          this.chat_layout = settings["chat_layout"];
          this.user_icon = settings["userDetail"].user_icon;
          this.otherSettings = settings["otherSettings"];
          // this.settingsDetail = settings['settingsDetail'];
          this._cloneSettingsDetail = settings["settingsDetail"];
          this.settingsList = settings["settingsList"];
          const formField = this.newUserForm.value;
          for (const key in formField) {
            if (key) {
              const newObject = {};
              newObject[key] = settings["userDetail"][key];
              this.newUserForm.patchValue(newObject);
            }
          }

          if (settings['company_details']!==null) {

            this.preUploadedFile = settings['company_details'];

            const fieldData = this.instituteForm.value;
            for (const key in fieldData) {
              const newObject = {};
                newObject[key] = settings["company_details"][key];
                this.instituteForm.patchValue(newObject);
            }
          }else{
            this.preUploadedFile = null;
          }

        },
        (error) => {
          this.httpLoader.hideLoader();
          console.log(error);
        }
      );
    } catch (err) {
      this.httpLoader.hideLoader();
      console.log(err);
    }

    this.loggedUserRole = JSON.parse(window.localStorage.getItem('user_role'));

    this.onClose.emit();
  }

  hasError($event) {}

  getPhoneNumber($event) {}

  telInputObject(obj) {
    console.log(obj.s.dialCode);
    this.cuntryCode = obj.s.dialCode;

    obj.setCountry("in");
  }

  onCountryChange($event) {
    console.log($event);
  }

  public isValidChar(evt: any) {
    evt = evt ? evt : event;
    var charCode = evt.charCode
      ? evt.charCode
      : evt.keyCode
      ? evt.keyCode
      : evt.which
      ? evt.which
      : 0;
    if (
      charCode > 31 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122)
    ) {
      this.alert.showAlertMessage({
        message: "Please enter last Name in characters only.",
      });
      return false;
    }
    return true;
  }

  public isLettersOnly(evt: any) {
    evt = evt ? evt : event;
    var charCode = evt.charCode
      ? evt.charCode
      : evt.keyCode
      ? evt.keyCode
      : evt.which
      ? evt.which
      : 0;
    if (
      charCode > 31 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122)
    ) {
      this.alert.showAlertMessage({
        message: "Please enter state in characters only.",
      });
      return false;
    }
    return true;
  }

  public isNumberKey(evt: any) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      // this.discountValidate = true;
      this.alert.showAlertMessage({
        message: "Please enter zip in numbers only",
      });
      // this.priceErrMsg = 'Please enter discount number only. For example 535';
      return false;
    } else {
      // this.discountValidate = false;
      return;
    }
  }

  /**
   * Update user details
   * @param userVal
   */
  updateUserDetail(userVal) {
    try {
      this.httpLoader.showLoader();
      this.userService.updateUserDetail(0, userVal).subscribe(
        (user) => {
          this.httpLoader.hideLoader();
          this.newUserForm.markAsPristine();
          this.alert.showAlertMessage(user);
        },
        (error) => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        }
      );
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  }

  /**
   * Update user own password
   * @param password
   */

  get f() {
    return this.accountFrom.controls;
  }
  updateAccountPassword(password) {
    try {
      this.submitted = true;
      if (this.accountFrom.invalid) {
        return;
      }
      this.httpLoader.showLoader();
      this.settingsService.updateOwnPassword(password).subscribe(
        (response) => {
          this.httpLoader.hideLoader();
          this.submitted = false;
          this.accountFrom.reset();
          this.alert.showAlertMessage(response);
        },
        (error) => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        }
      );
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  }

  /**
   * De activate the user account (only soft delete)
   */
  deactivateUserAccount(value) {
    try {
      this.confirmDialogService.openConfirmDialog('',{
        title:'Confirmation',
        message:'Are you sure want to deactivate your account?',
        btnOkText:'YES',
        btnCancelText:'NO',
        onClose: (data:any) => {
          if(data){
            this.settingsService.deleteAccount().subscribe(
              (response) => {
                this.alert.showAlertMessage(response);
                window.localStorage.clear();
                this.router.navigate(["/login"]);
              },
              (error) => {
                this.httpLoader.hideLoader();
                this.alert.showAlertMessage(error.error);
              }
            );
          } else {
            return true;
          }
        }	  
    });	
     /* this._confirmation
        .create("Confirmation", "Are you sure want to deactivate your account?")
        .subscribe((ans: ResolveEmit) => {
          if (ans.resolved) {
            this.settingsService.deleteAccount().subscribe(
              (response) => {
                this.alert.showAlertMessage(response);
                window.localStorage.clear();
                this.router.navigate(["/login"]);
              },
              (error) => {
                this.httpLoader.hideLoader();
                this.alert.showAlertMessage(error.error);
              }
            );
          } else {
            return true;
          }
        }); */
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  }

  /**
   * Get file data and call file upload API
   * @param event
   */

  onFileChange(event) {
    const files = event.target.files;
    const selectedUserId = this.logged_user_id;
    if (files && files.length > 0) {
      const formFields = {
        fieldName: "user_profile",
        activity: "user_profile_update",
        user_id: selectedUserId,
      };

      this.selectedFileList.push(files[0]);
      this.fileValidationService
        .fileValidation(files, this.profileExtension, this.profileSize)
        .then((validResponse) => {
          this.appComponent
            .fileUploadWithProgess(formFields, this.selectedFileList)
            .then((fileUpLoadResponse: any) => {
              for (const key in fileUpLoadResponse) {
                if (fileUpLoadResponse[key]) {
                  this.user_icon = fileUpLoadResponse[key]["user_icon"];
                  this.headerService.userProfileChanged(
                    fileUpLoadResponse[key]["user_icon"]
                  );
                }
              }
              this.selectedFileList = [];
              this.fileInput.nativeElement.value = "";
            })
            .catch((error) => {
              this.selectedFileList = [];
              this.fileInput.nativeElement.value = "";
              this.alert.showAlertMessage(error.error);
            });
        })
        .catch((err) => {
          this.fileInput.nativeElement.value = "";
          this.selectedFileList = [];
          err.map((li) => this.alert.showAlertMessage(li));
        });
    }
  }

  onLogoImgChange(event) {
    const files = event.target.files;
    const selectedUserId = this.logged_user_id;
    if (files && files.length > 0) {
      const formFields = {
        fieldName: "company_profile",
        activity: "company_logo",
        // user_id: selectedUserId
      };

      this.selectedLogoFileList.push(files[0]);
      this.fileValidationService
        .fileValidation(files, this.profileExtension, this.profileSize)
        .then((validResponse) => {
          this.appComponent
            .fileUploadWithProgess(formFields, this.selectedLogoFileList)
            .then((fileUpLoadResponse: any) => {
              for (const key in fileUpLoadResponse) {
                if (fileUpLoadResponse[key]) {
                  this.preUploadedFile =
                    fileUpLoadResponse[key]["company_logo"];
                  // this.headerService.instituteLogoChanged(
                  //   fileUpLoadResponse[key]["company_logo"]
                  // );
                  const button = document.querySelector('.disabledBtn');
                  button.removeAttribute('disabled');
                }
              }
              this.alert.showAlertMessage(fileUpLoadResponse[0].message);
              this.selectedLogoFileList = [];
              this.logoFileInput.nativeElement.value = "";
            })
            .catch((error) => {
              this.selectedLogoFileList = [];
              this.logoFileInput.nativeElement.value = "";
              this.alert.showAlertMessage(error.error);
            });
        })
        .catch((err) => {
          this.alert.showAlertMessage({message: 'File not supported. Only support .png, .jpg and .jpeg file'})
          this.logoFileInput.nativeElement.value = "";
          this.selectedLogoFileList = [];
          err.map((li) => this.alert.showAlertMessage(li));
        });
    }
  }

  updateInstituteData(formData){
    try {
      this.showBtnSpinner = true;
      this.httpLoader.showLoader();

      var payLoad;
      if (this.preUploadedFile!==null) {
        payLoad = {
          company_logo: this.preUploadedFile['company_logo'],
          title: formData['title'].trim(),
          caption: formData['caption'].trim()
        }
      }else{
        payLoad = {
          company_logo: null,
          title: formData['title'].trim(),
          caption: formData['caption'].trim()
        }
      }
      this.settingsService.createInstituteDetails(payLoad).subscribe((response:any) => {
        this.showBtnSpinner = false;
        this.httpLoader.hideLoader();
        this.alert.showAlertMessage(response)
        this.headerService.instituteLogoChanged(response.data);
      }, error => {
        this.httpLoader.hideLoader();
        this.showBtnSpinner = false;
        this.alert.showAlertMessage(error.error);
      })
    } catch (error) {
      this.httpLoader.hideLoader();
      this.showBtnSpinner = false;
      console.log(error)
    }
  }

  deleteInstituteLogoIcon() {
    try {
      this.selectedLogoFileList = [];
      const selectedUserId = this.logged_user_id;
      const company_logo = null;
      this.userService.deleteCompanyLogo().subscribe(
        (response) => {
          this.alert.showAlertMessage(response);
          this.preUploadedFile = null;
          //this.preUploadedFile.company_logo = null;
          const title = this.instituteForm.value['title'];
          const caption = this.instituteForm.value['caption'];
          this.headerService.instituteLogoChanged({ company_logo: null, title: title, caption: caption });
        },
        (error) => {
          this.alert.showAlertMessage(error.error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Chang the chat layout style
   * @param value
   * @param layout
   */
  updateUserSettings(value, layout, formVal) {
    try {
      this.httpLoader.showLoader();
      this.settingsService.updateUserSettings(value).subscribe(
        (response) => {
          const appuserSetting = { ...this.appComponent.userSetting, ...value };
          this.appComponent.userSetting = appuserSetting;

          // if (layout === 'chat_layout') {
          //   appuserSetting.default_layout = value.default_layout;
          //   this.appComponent.userSetting = appuserSetting;
          // }

          if (!layout) {
            this._cloneSettingsDetail = { ...this.settingsDetail };
            formVal.form.markAsPristine();
          }

          this.alert.showAlertMessage(response);
          this.httpLoader.hideLoader();
        },
        (error) => {
          this.alert.showAlertMessage(error.error);
          this.httpLoader.hideLoader();
        }
      );
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  }



  deleteUserProfileIcon() {
    try {
      this.selectedFileList = [];
      this.fileInput.nativeElement.value = "";
      const selectedUserId = this.logged_user_id;
      this.userService.deleteUserProfile(selectedUserId).subscribe(
        (response) => {
          this.user_icon = null;
          this.headerService.userProfileChanged(null);
        },
        (error) => {
          this.alert.showAlertMessage(error.error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Navigate tab from settings menu
   * @param tabName
   */
  selectSettingsTab(tabName: string) {
    if (tabName === "notification") {
      this.settingsDetail = { ...this._cloneSettingsDetail };
    }
    this.tabName = tabName;
  }

  settingChange(field_name, bool) {
    if (field_name === "do_not_disturb" && bool) {
      for (const key in this.settingsDetail) {
        if (key) {
          this.settingsDetail[key] = false;
        }
      }
    }

    if (field_name === "do_not_disturb" && !bool) {
      this.settingsDetail = { ...this._cloneSettingsDetail };
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   * NgFor track by function
   * @param index
   * @param item
   */
  trackByFn(index, item) {
    return index;
  }

  showProfileImage() {
    this.showIconImage = true;
  }

  hideIconImage() {
    this.showIconImage = false;
    this.onClose.emit(false);
  }

  outsideClickIcon() {
    this.showIconImage = false;
  }
  onRadioChange(event: any) {
    this.selectedTheme=event.target.value;
    this.themeService.setTheme(this.selectedTheme);
    this.alert.showAlertMessage({
      message: "Theme Changed.",
    });
   }
  
}
