import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './forgot.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '', component: ForgotPasswordComponent
      }
    ])
  ],
  declarations: [],
  exports: [RouterModule]
})
export class ForgotRoutingModule { }
