import { Component, OnInit, AfterViewInit, Directive } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { SocketService } from './../../../socket/socket.service';
import { NotificationService } from './../notification.service';
import { HttpLoader, path, AlertPopup } from './../../../app.config';

// @Directive()
@Component({
  selector: 'app-real-update',
  templateUrl: './real-update.component.html',
  styleUrls: ['./real-update.component.scss']
})
export class RealUpdateComponent implements OnInit, AfterViewInit {

  showMoreInfo = true;
  private subscribers: any = {};
  postUserList: {};
  postUserCount: number;
  profileUrl = path.PROFILE_URL;


  public barChartOptions: any = {
    'scaleShowVerticalLines': false,
    'responsive': true,
    'scales': {
      'xAxes': [{
        ticks: {
          beginAtZero: true
        }
      }],
      'yAxes': [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  public barChartLabels: string[] = ['MAIL', 'SMS'];
  public barChartType = 'horizontalBar';
  public barChartLegend = true;

  public barChartData: any[] = [
    { data: [], label: '' },
    { data: [], label: '' },
    { data: [], label: '' }
  ];

  constructor(
    private route: ActivatedRoute,
    private socketService: SocketService,
    private notificationService: NotificationService,
    private httpLoader: HttpLoader,
    private alert: AlertPopup,
  ) { }


  ngOnInit() {
    this.route.paramMap
      .switchMap((params: ParamMap) => this.notificationService.getPostSentStatus(+params.get('id'))).subscribe(
      response => {
        this.barChartData = [...response['postStatusList']];
        this.postUserList = response['userList'].rows;
        this.postUserCount = response['userList'].rows;
        this.httpLoader.hideLoader();
      },
      error => {
        this.alert.showAlertMessage(error.error);
        this.httpLoader.hideLoader();
      }
      )
  }

  ngAfterViewInit() {
    try {
      this.subscribers.userPostSentStatus = this.socketService.receiveUserPostSentStatus().subscribe(postStatus => {
        if (postStatus && +postStatus.post_id === +this.route.snapshot.paramMap.get('id')) {
          const sentStatusList = [...this.barChartData];

          if (postStatus.mail_sent === 'Y') {
            sentStatusList.map(li => {
              if (li.label === 'SENT') {
                li.data[0] += 1;
              }
            })
          }

          if (postStatus.mail_delivered === 'Y') {
            sentStatusList.map(li => {
              if (li.label === 'DELIVERED') {
                li.data[0] += 1;
              }
            })
          }


          if (postStatus.msg_sent === 'Y') {
            sentStatusList.map(li => {
              if (li.label === 'SENT') {
                li.data[1] += 1;
              }
            })
          }

          if (postStatus.msg_delivered === 'Y') {
            sentStatusList.map(li => {
              if (li.label === 'DELIVERED') {
                li.data[1] += 1;
              }
            })
          }

          this.barChartData = sentStatusList;

        }
      });
    } catch (err) {
      console.log(err);
      this.httpLoader.hideLoader();
    }
  }

  // getPostSentStatus() {
  //   try {
  //     const postId =
  //     this.notificationService.getPostSentStatus().subscribe(
  //       response => {

  //       },
  //       error => {

  //       }
  //     )
  //   } catch (err) {

  //   }
  // };


  moreInfo() {
    this.showMoreInfo = !this.showMoreInfo;
  };

  // events
  public chartClicked(e: any): void {
    // console.log(e);
  }

  public chartHovered(e: any): void {
    // console.log(e);
  }

}
