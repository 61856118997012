import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './../../auth-guard.service';

import { HomeComponent } from '../../components/home/home.component';
import { NotificationComponent } from './notification.component';
import { NotificationDetailsComponent } from './notification-details/notification-details.component';
import { RealUpdateComponent } from './real-update/real-update.component';
import { NewNotificationComponent } from './new-notification/new-notification.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
         path: '',  component: NotificationComponent  ,
         children: [
          { path: 'newChannels', component: NewNotificationComponent },
          { path: ':id', component: NotificationDetailsComponent }
         ]

    //   {
    //     path: 'bulletineBoard', component: NotificationComponent, canActivate: [AuthGuard], children: [
    //       { path: 'newbulletineboard', component: NewNotificationComponent },
    //     { path: ':id', component: NotificationDetailsComponent }
    //   ]
      }
    ])
  ],
  declarations: [],
  exports: [RouterModule]
})
export class NotificationRoutingModule { }
