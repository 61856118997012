
import { Component, Input, ViewChild, AfterViewChecked, ElementRef, Injectable, OnInit, HostBinding, Directive } from '@angular/core';
import { FormGroup, FormBuilder, Validators,  ReactiveFormsModule} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { path, AlertPopup, HttpLoader, config } from './../../../../app.config';
import { DataSharingService } from 'app/shared/data-sharing.service';
import { HomeworkService } from 'app/components/homework/homework.service';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { SocketService } from 'app/socket/socket.service';
import { ValidationService } from 'app/components/form-validation/validation.service';
// import { SharedModule } from 'app/shared/shared.module';
// import { HttpEventType, HttpResponse, HttpClient, HttpRequest } from '@angular/common/http';
import * as _ from 'underscore';
import { ChatListComponent } from '../../chat-list.component';
import { fromEvent, Observable, Subscription} from 'rxjs';




// @Directive()
@Component({
    selector: 'app-normal-view',
    templateUrl: './msg-normal-view.component.html',
    styleUrls: []
})


export class MessageNormalViewComponent implements OnInit {

    @Input() messageList;
    @Input() loggedUserId: number;
    @Input() pinId: number;
    // @Input() loggedUserRole: number;
    @Input() maxLength: number = 400;
    @ViewChild('scrollframe') scrollFrame : ElementRef;
    public scrollContainer: any;
    search:string;



    profileUrl = path.PROFILE_URL;

    isCollapsed= [];
    isActive=[];
    activePinTask: any[] = [];
    assignmentData: any[] = [];
    newTaskForm: FormGroup;
    showBtnSpinner = false;
    model: any = {};
    getSelectedGroupName: any;
    isUserRole:any;

    isMobile: boolean = false;
    width:number = window.innerWidth;
    height:number = window.innerHeight;
    mobileWidth:number  = 760;


    resizeObservable$: Observable<Event>
    resizeSubscription$: Subscription


    constructor(
      private dataSharingService: DataSharingService,
      private homeworkService: HomeworkService,
      private alert: AlertPopup,
      private httpLoader: HttpLoader,
      private fb: FormBuilder,
      // private httpError: HttpErrorResponse,
      // private http: HttpClient,
      // private route: ActivatedRoute,
      private app: AppComponent,
      private router: Router,
      private socketService: SocketService,
      private validateService: ValidationService,
      private chatComponent: ChatListComponent
    ) {
      this.newTaskForm = fb.group({
        due_date: ['', Validators.required]
      });


    }

    ngOnInit(){
      this.isUserRole = window.localStorage.getItem('user_role');
      this.isUserRole = parseInt(this.isUserRole.replace(/\"/g, ""));

      if(this.width <= this.mobileWidth){
        this.chatComponent.viewChatList = true;
      }else{
        this.chatComponent.viewChatList = false;
      }

      this.resizeObservable$ = fromEvent(window, 'resize')
      this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
        let windowWith = window.innerWidth;
        if(windowWith <= this.mobileWidth){
          this.chatComponent.viewChatList = true;
        }else{
          this.chatComponent.viewChatList = false;
        }
      })
    }

    trackByFn(index, msg) {
        return msg ? msg.message_id : undefined;
    };


    aftershowMoreToggle(index){

      document.getElementById("scrollFrame_"+index).scrollIntoView();

      this.isCollapsed[index] = !this.isCollapsed[index]
    }

    showMessageActions($event){
      console.log($event);

    }

    hoverInMessageHandler(int){
      this.isActive[int] = !this.isActive[int];
    }

    // hoverOutMessageHandler(index){
    //   // this.isActive[index] = false;
    // }

    editChatMessage(index){

    }

    deleteChatMessage(index){
      console.log('This message has been deleted ' + index);
    }

    handlePinTask(int){
      console.log(int, 'open');
      this.activePinTask[int] = true;
      this.isActive[int] = false;
    }

    submittedTask(msg){

     try {
      const payLoad = {
        'msg_id' : msg.message_id,
        'task' : msg.message,
        'from_user_id': msg.from_user_id,
        'to_user_id': (msg.to_user_id)?msg.to_user_id : null,
        'groupId' : msg.group_id,
        'task_due_date': this.model['due_date'],
        'status': '0'
      }


      const id = msg.message_id;

      this.homeworkService.createPinTask(payLoad).subscribe(response => {
          if(response){
            this.activePinTask[id] = false;
            this.alert.showAlertMessage(response);
          }
        }, error =>{
          console.log(id);

          this.activePinTask[id] = false;
          this.alert.showAlertMessage(error.error);
        })

      } catch (error) {
        this.alert.showAlertMessage(error);
      }

    }



    cancelTaskPin(index){
      console.log(index);

      this.activePinTask[index] = false;
    }



     copytoclipboard(inputElement){
      // console.log(inputElement);
      const event = (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', inputElement);
        e.preventDefault();
        // ...('copy', e), as event is outside scope
        //document.removeEventListener('copy', e);
      }
        document.addEventListener('copy', event);
        document.execCommand('copy');
     }
}
