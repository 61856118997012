import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim'
})

export class TrimPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    return value.replace(/&nbsp;/g, '');
  }
}
