import { Component, OnInit, OnDestroy, Directive  } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { Subscription } from 'rxjs/Subscription';
// import { LoaderState } from './loader.state';
import { LoaderService, LoaderState } from './loader.service';


// @Directive()
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  showLoader = false;
  defaultProgress = 50;
  timeInterval: any;
  private subscription: Subscription;
  subscribe: any;
  apiCount = 0;

  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        if (state.showLoader) {
          this.apiCount += 1;
          if (!this.showLoader) {
            this.showLoader = state.showLoader;
            const source = Observable.interval(30);
            this.subscribe = source.subscribe((val: any) => {
              this.defaultProgress += 1;
              if (this.defaultProgress >= 90) {
                this.subscribe.unsubscribe();
              }
            });
          }
        } else if (!state.showLoader) {
          this.apiCount = this.apiCount > 0 ? this.apiCount - 1 : 0;
          if (this.showLoader && this.apiCount === 0) {
            setTimeout(() => {
              this.subscribe.unsubscribe();
              this.defaultProgress = 100;
              this.showLoader = false;
            }, 150);
          }
        }
      });
  };

  ngOnDestroy() {
    this.subscription.unsubscribe();
  };

}
