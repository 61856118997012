import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'app/app.config';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssistBotService {

  searchListBheaviour:BehaviorSubject<any> = new BehaviorSubject([]);
  baseUrl = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;
  chatHistoryList:any=[];
  chatPinList:any=[];
  chatbotData:any=[];
  chatbotDocFiles:any=[];
  chatbotAudioFiles:any=[];
  historyListBheaviour:BehaviorSubject<any> = new BehaviorSubject({});
  GptConfiguration:any;
  tabPanelBheaviour:BehaviorSubject<any> = new BehaviorSubject({});
  constructor(
    private http: HttpClient
  ) { }

  createHistory(payload){
    return this.http.post(`${this.baseUrl}/createHistory`, payload);
  }

  getChatBotHistory(){
    let user_id = window.localStorage.getItem('user_id');
    return this.http.get(`${this.baseUrl}/getChatBotHistory?user_id=${user_id}`);
  }

  deleteHistory(historyNo){
    let user_id = window.localStorage.getItem('user_id');
    return this.http.delete(`${this.baseUrl}/deleteHistory/${historyNo}/${user_id}`);
  }

  deletePin(pinNo){
    let user_id = window.localStorage.getItem('user_id');
    return this.http.delete(`${this.baseUrl}/deletePin/${pinNo}/${user_id}`);
  }

  deleteAllHistoryLists(){
    let user_id = window.localStorage.getItem('user_id');
    let payload={
      "user_id":user_id,
      "question_type":"history"
    }
    return this.http.delete(`${this.baseUrl}/deleteHistory/deleteAllHistoryLists/${payload}`);
  }
  
  deleteAllPinList(){
    let user_id = window.localStorage.getItem('user_id');
    let payload={
      "user_id":user_id,
      "question_type":"pin"
    }
    return this.http.delete(`${this.baseUrl}/deleteHistory/deleteAllHistoryLists/${payload}`);
  }

 
 getClientConfig(){
    return this.http.get('../assets/json/client_config.json');
 }

  BotFilegenerate(payload:any){
    return this.http.post(`${this.baseUrl}/addBotLessonandAudio`,payload)
  }

  RefreshBotData(payload:any,params:any){
    return this.http.put(`${this.baseUrl}/updateChatBotHistory/${params['history_id']}/${params['user_id']}`,payload);
  }


}
