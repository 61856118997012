import { Component, OnInit, AfterViewInit, OnDestroy, HostBinding, ElementRef, ViewChild, Directive  } from '@angular/core';
import { DatePipe } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/of';
import { Observable } from 'rxjs/Observable';
import * as _ from 'underscore';
import { config, path, HttpLoader, AlertPopup } from './../../../app.config';
import { NotificationService } from '../notification.service';
import { NotificationComponent } from '../notification.component';
import { SubPostComponent } from './sub-post/sub-post.component';
import { SocketService } from './../../../socket/socket.service';
import { FileValidationService } from './../../../shared/file-validation.service';
import { AppComponent } from './../../../app.component';
import {fromEvent, Subscription} from 'rxjs';



// @Directive()
@Component({
    selector: 'app-notification-details',
    templateUrl: './notification-details.component.html',
    styleUrls: []
})
export class NotificationDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('class.flex-grid') leftBarClass = true;
    @HostBinding('style.width') flexwidth = '100%';
    @ViewChild('fileInput') fileInput: ElementRef;

    RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
    OFFSET: number = config.DEFAULT_OFFSET;

    /** View Variables */
    domElementControl = {
        showCreateNewSubPost: false
    };
    showNotifyRealTimeUpdate = false;
    selectedNotification = {};
    public search: string;
    subPostDetailList: any[] = [];
    totalRowCount: number;
    subPostName: string;
    editedPostDetails: any = null;
    postId: number;

    invalidCommentsError = false;

    /** User Profile icon handler variables */
    selectedFileList: any[] = [];
    profileExtension = config.POST_FILE_EXT;
    profileSize = config.PROFILE_SIZE;
    preUploadedFile: any;
    preProfileUrl = path.PRE_PROFILE_URL;

    profileUrl = path.PROFILE_URL;
    showPaginateSpinner = false;
    modalScrollDistance = config.DEFAULT_SCROLL_DIST;
    modalScrollThrottle = config.SCROLL_THROTTLE;
    private subscribers: any = {};

    isMobile: boolean = false;
    width:number = window.innerWidth;
    height:number = window.innerHeight;
    mobileWidth:number  = 760;


    resizeObservable$: Observable<Event>
    resizeSubscription$: Subscription

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationService,
        private notificationComponent: NotificationComponent,
        private httpLoader: HttpLoader,
        private socketService: SocketService,
        private alert: AlertPopup,
        private fileValidationService: FileValidationService,
        private appComponent: AppComponent) {
    }


    ngOnInit() {
        this.getOnePostDetails();

        this.route.url.subscribe(() => {
            if(this.width <= this.mobileWidth){
                this.notificationComponent.viewNotificatonList = true;
              }else{
                this.notificationComponent.viewNotificatonList = false;
              }
        });

       

        this.resizeObservable$ = fromEvent(window, 'resize')
        this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
          let windowWith = window.innerWidth;
          if(windowWith <= this.mobileWidth){
            this.notificationComponent.viewNotificatonList = true;
          }else{
            this.notificationComponent.viewNotificatonList = false;
          }
        })

    };

    ngAfterViewInit() {
        try {
            /*** Subscribe new post from socket emit */
            this.subscribers.userPost = this.socketService.receiveUserPost().subscribe(userPost => {
                if (userPost && userPost.post_id === +this.route.snapshot.paramMap.get('id')) {
                    const selectedPost = { ...this.selectedNotification };
                    selectedPost['post_name'] = userPost.post_name;
                    this.selectedNotification = selectedPost;
                }
            });

            this.subscribers.userSubPost = this.socketService.receiveUserSubPost().subscribe(userSubPost => {
                if (userSubPost) {
                    const subPostList = [...this.subPostDetailList];
                    if (userSubPost.is_new && userSubPost.post_id === +this.route.snapshot.paramMap.get('id')) {
                        subPostList.unshift(userSubPost);
                        this.subPostDetailList = subPostList
                    } else if (!userSubPost.is_new) {
                        subPostList.find(sub => {
                            if (sub.sub_post_id === userSubPost.sub_post_id) {
                                sub.sub_post_name = userSubPost.sub_post_name;
                                return sub;
                            }
                        });
                    } else if (userSubPost.is_new && userSubPost.post_id !== +this.route.snapshot.paramMap.get('id')) {
                        const postList = [...this.notificationComponent.postList];
                        postList.find(post => {
                            if (post.post_id === userSubPost.post_id) {
                                post.unread_post_count += 1;
                                return post;
                            }
                        });
                        this.notificationComponent.postList = postList;
                    }
                }
            });

            this.subscribers.userPostComment = this.socketService.receiveUserPostComment().subscribe(userPostComment => {
                if (userPostComment) {
                    const subPostList = [...this.subPostDetailList];
                    if (userPostComment.post_id === +this.route.snapshot.paramMap.get('id')) {
                        subPostList.map(subPost => {
                            if (subPost.sub_post_id === userPostComment.sub_post_id) {
                                subPost.commentsList.unshift(userPostComment);
                                subPost.commentsCount += 1;
                            }
                        });
                        this.subPostDetailList = subPostList
                    }
                }
            });

            this.subscribers.userPostSubComment = this.socketService.receiveUserPostSubComment().subscribe(userPostSubComment => {
                if (userPostSubComment) {
                    const subPostList = [...this.subPostDetailList];
                    if (userPostSubComment.post_id === +this.route.snapshot.paramMap.get('id')) {
                        subPostList.map(subPost => {
                            if (subPost.sub_post_id === userPostSubComment.sub_post_id) {
                                subPost.commentsList.map(comment => {
                                    if (comment.comment_id === userPostSubComment.comment_id) {
                                        comment.subCommentList.push(userPostSubComment);
                                        comment.subCommentsCount += 1;
                                    }
                                });
                            }
                        });
                        this.subPostDetailList = subPostList
                    }
                }
            });

        } catch (err) {
            console.log(err);
        }
    };


    closeActiveMobileView(){
      this.notificationComponent.viewNotificatonList = false;
      this.router.navigate(['/main/channels']);
    }


    getOnePostDetails() {
        try {
            this.subscribers.userNotification = this.route.paramMap
                .switchMap((params: ParamMap) =>
                    this.notificationService.getPostDetail(+params.get('id'))).subscribe(
                post => {
                    this.OFFSET = config.DEFAULT_OFFSET;
                    this.selectedNotification = post['post_detail'];
                    this.postId = post['post_id'];
                    this.subPostDetailList = post['sub_post_detail'];
                    this.totalRowCount = post['TotalRowCount'];
                    this.httpLoader.hideLoader();
                    this.updateNewSubPostReadStatus(this.subPostDetailList);
                },
                error => {
                    this.router.navigate(['/main/bulletineBoard']);
                    this.httpLoader.hideLoader();
                }
                )

        } catch (err) {
            this.httpLoader.hideLoader();
        }
    };


    updateNewSubPostReadStatus(subPostList) {
        try {
            if (subPostList && subPostList.length > 0) {
                const unReadSubPost = _.findWhere(subPostList, { is_viewed: false });
                const recentSubpost = subPostList[0];
                if (unReadSubPost) {
                    const payLoad = {
                        post_id: recentSubpost.post_id,
                        sub_post_id: recentSubpost.sub_post_id
                    };
                    this.notificationService.updateSubPostReadStatus(payLoad).subscribe(
                        response => {
                            this.httpLoader.hideLoader();
                            const postList = [...this.notificationComponent.postList];
                            postList.find(post => {
                                if (post.post_id === recentSubpost.post_id) {
                                    post.unread_post_count = 0;
                                    return post;
                                }
                            });
                            this.notificationComponent.postList = postList;
                        },
                        error => {
                            this.httpLoader.hideLoader();
                        });
                }
            }
        } catch (err) {
            console.log(err)
        }
    };

    /** Post Edit, Update , cancel functionlaities */

    editPostName() {
        this.editedPostDetails = { ...this.selectedNotification };
    };

    updatePostName(post) {
        if (post && post.post_name) {
            try {
                const payload = {
                    post_name: post.post_name,
                    post_id: post.post_id
                }
                this.notificationService.updatePosts(payload).subscribe(
                    posts => {
                        this.selectedNotification = { ...this.editedPostDetails };
                        const postList = [...this.notificationComponent.postList];
                        postList.map((el) => {
                            if (el.post_id === this.editedPostDetails.post_id) {
                                el.post_name = this.editedPostDetails.post_name;
                            }
                        });
                        this.notificationComponent.postList = postList;
                        this.editedPostDetails = null;
                        this.httpLoader.hideLoader();
                        this.alert.showAlertMessage(posts);
                    },
                    error => {
                        this.httpLoader.hideLoader();
                        this.alert.showAlertMessage(error);
                    }
                )
            } catch (err) {
                console.log(err)
            }
        }
    }

    cancelEditedPostName() {
        this.editedPostDetails = null;
    };

    /** New Subpost Create and cancel */

    openCreateNewSubPostPanel() {
        this.domElementControl.showCreateNewSubPost = !this.domElementControl.showCreateNewSubPost;
    };

    async fileValidationHanlder(files){
      try {
        const isValidate = await this.fileValidationService.fileValidation(files, this.profileExtension, this.profileSize);
        return files;
      } catch (err) {
          err.map(li => {
              const errMsg = {
                  message: li
              };
              // this.alert.showAlertMessage(errMsg);
              this.alert.showAlertMessage({message: 'The file is too large and cannot be uploaded. Please reduce the size of the file and try again (min 15 MB)'});
          });
          return false;
      }
    }

    async onFileChange(event) {
      const files = event.target.files;

      let fileExt = files[0].type.split('/');

      let ext = fileExt[1].toUpperCase();

      const inArray = this.profileExtension.split(',');

      let data = inArray.find(ob => ob === ext);

      if(data){
        const fileValid = await this.fileValidationHanlder(files);
        if (fileValid) {
          this.concatFileIntoArray(files);
        }
      }else{
        this.alert.showAlertMessage({message: 'File format not supported. Please accepted '+ this.profileExtension + ' format only' })
      }
    };

    concatFileIntoArray(files){
      if (files && files.length > 0) {
        const formFields = {
            fieldName: 'gallery_post',
            activity: 'user_post_add',
            storeType: 'temp'
        };

        this.selectedFileList.push(files[0]);
        this.fileValidationService.fileValidation(files, this.profileExtension, this.profileSize).then(validResponse => {
            this.appComponent.fileUploadWithProgess(formFields, this.selectedFileList).then((fileUpLoadResponse: any) => {
                for (const key in fileUpLoadResponse) {
                    if (fileUpLoadResponse[key]) {
                        this.preUploadedFile = fileUpLoadResponse[key]
                    }
                }
                this.selectedFileList = [];
                this.fileInput.nativeElement.value = '';
            }).catch(error => {
                this.selectedFileList = [];
                this.fileInput.nativeElement.value = '';
                this.alert.showAlertMessage(error.error);
            })
        }).catch(err => {
            this.fileInput.nativeElement.value = '';
            this.selectedFileList = [];
            err.map(li => this.alert.showAlertMessage(li));
        });
      }
    }

    removeSelectedFile() {
        this.selectedFileList = [];
        this.preUploadedFile = null;
    };

    createSubPost(sub_post_name) {
      this.invalidCommentsError = true;
        if (sub_post_name && this.subPostName.trim() !== '') {
            try {
                const payload = {
                    sub_post_name,
                    post_id: this.postId,
                    file_data: this.preUploadedFile
                }
                this.notificationService.createSubPost(payload).subscribe(
                    response => {
                      this.invalidCommentsError = false;
                        this.removeSelectedFile();
                        const createdSubPost = response['createdSubPost'];
                        const subpostList = [...this.subPostDetailList];
                        subpostList.unshift(createdSubPost);
                        this.totalRowCount += 1;
                        this.subPostDetailList = subpostList;
                        this.subPostName = null;
                        this.domElementControl.showCreateNewSubPost = false;
                        this.httpLoader.hideLoader();
                        this.alert.showAlertMessage(response);
                    },
                    error => {
                        this.httpLoader.hideLoader();
                        this.alert.showAlertMessage(error);
                    }
                )
            } catch (err) {
                this.httpLoader.hideLoader();
                console.log(err);
            }
        }
    };


    createCommentValid(event){
      if(event.target.value===""){
        this.invalidCommentsError = true;
      }else{
        this.invalidCommentsError = false;
      }
    }



    calcTextLength(text: string) {
        if (text) {
            return text.length > 0 ? text.length + 10 : text.length;
        }
        return 50;
    };

    fetchNextSubPostList() {
        const listLength = this.subPostDetailList.length;
        if (this.totalRowCount > listLength && !this.showPaginateSpinner) {
            this.OFFSET += 1;
            this.showPaginateSpinner = true;
            const offset = this.OFFSET;
            const payLoad = {
                post_id: this.postId,
                offset: offset,
                limit: this.RECORD_LIMIT,
                search: this.search || ''
            };
            this.notificationService.getSubPostList(payLoad).subscribe(
                response => {
                    this.subPostDetailList = [...this.subPostDetailList.concat(response['sub_post_detail'])];
                    this.showPaginateSpinner = false;
                },
                error => {
                    this.showPaginateSpinner = false;
                    this.alert.showAlertMessage(error.error);
                });
        }
    };

    //Keyword restrict first space
    keyHandleComment(event){
      if(event.which === 32 && event.target.selectionStart === 0 ){
        event.preventDefault();
        return false;
      }
    }

    showRealTimeUpdate() {
        this.showNotifyRealTimeUpdate = !this.showNotifyRealTimeUpdate;
    };

    hideNotificationList() {
        this.notificationComponent.viewNotificatonList = !this.notificationComponent.viewNotificatonList;
    };

    ngOnDestroy() {
        this.subscribers.userPost.unsubscribe();
        this.subscribers.userSubPost.unsubscribe();
        this.subscribers.userPostComment.unsubscribe();
        this.subscribers.userPostSubComment.unsubscribe();
    };

}
