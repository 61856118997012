import { Component, OnInit, Input, OnDestroy, AfterViewInit, AfterContentChecked, Directive, inject, Inject  } from '@angular/core';
import { Router } from '@angular/router';
import { path, AlertPopup, HttpLoader } from './../../app.config';
import { HeaderService } from './header.service';
import { SocketService } from './../../socket/socket.service';
import { AppComponent } from './../../app.component';
import * as _ from 'underscore';
import { Observable, Observer } from 'rxjs';
import { merge } from 'rxjs/observable/merge';
import { fromEvent }from 'rxjs/observable/fromEvent';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
 



// @Directive()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  @Input() isUserLoggedIn = false;
  @Input() currentUser: any;
  @Input() statusDetails: any;

  private subscribers: any = {};
  profileUrl = path.PROFILE_URL;

  companyUrl = path.COMPANY_URL;


  authStatus: any ={ title: 'Available' , useClass: 'circle-green'};
  connectionMonitor: boolean;
  timer:any="";
  isConnection: boolean = false;
  // customStatus = ["Be right way", "Off Work", "Do not disturb" ];
  noChangeYet:boolean = true;
  minutes: number = 0;
  hours: number = 0;
  seconds: number = 0;
  tick = 0;
  isToken: any;
  activeUserChannelList : any[] = [];
  singleSelect: any;
  isUserRole:any;


  constructor(
    private router: Router,
    private headerService: HeaderService,
    private socketService: SocketService,
    private alert: AlertPopup,
    private loader: HttpLoader,
    private app: AppComponent,
   @Inject(DOCUMENT) private document:Document,private titleService: Title
    ) {

    }

  ngOnInit() {

    this.isUserLoggedIn = true;
    if(window.localStorage.getItem('token')){
      this.isUserRole = window.localStorage.getItem('user_role');
      this.isUserRole = parseInt(this.isUserRole.replace(/\"/g, ""));
    }


    // const company_id = window.localStorage.getItem('company_id');

    // this.singleSelect = parseInt(company_id);

     this.createOnline$().subscribe(isOnline => {
        this.connectionMonitor = isOnline;
        if(isOnline==false){
          let seconds = 1000;
          let mString ="";
          Observable.interval(seconds * 1).subscribe(x => {

            if(seconds == 360000){
              this.timer = seconds;
            }else{
              this.tick = x;

          this.seconds = this.getSeconds(this.tick);
          this.minutes = this.getMinutes(this.tick);
          this.hours = this.getHours(this.tick);
          this.timer = this.minutes + ':' + this.seconds + ' ' + mString;
            }
          })
        }
      });

      // this.headerService.getCurrentUserDetail().subscribe(
      //   response => {

      //     const company_id = response['userDetail']['company_id'];
      //     this.singleSelect = parseInt(company_id);

      //   }
      // )

      // this.isToken = localStorage.getItem('token');

      // if(this.isToken){
      //   const currentData = {...this.currentUser};
      //     currentData.user_status = 1
      //     currentData.status_name = 'Available';
      //     this.currentUser = currentData;
      //     this.authStatus = {title: 'Available', useClass: 'circle-green'};
      // }else{
      //   const currentData = {...this.currentUser};
      //     currentData.user_status = 0
      //     currentData.status_name = 'Out of Office';
      //     this.currentUser = currentData;
      //     this.authStatus = {title: 'Out of Office', useClass: 'circle-gray'};
      // }




  };

  ngAfterContentChecked() {
  }

  ngAfterViewInit() {
    try {
      this.subscribers.userIconImage = this.headerService.profileIconChange$.subscribe(fileId => {

        if (this.currentUser) {
          this.currentUser.user_icon = fileId;
          
        }
      });
    } catch (err) {
      console.log(err);
    }

    //company logo
    try {
      this.subscribers.companyLogoId = this.headerService.companyLogoChange$.subscribe((response:any) => {
        if (this.currentUser) {
          // this.currentUser.company_logo = logoId.company_logo;
          this.currentUser.caption = response.caption;
          this.currentUser.title = response.title;
          this.currentUser.company_logo = response.company_logo;
          this.titleService.setTitle(this.currentUser.title);
        }
      })
    } catch (error) {
      console.log(error);
    }



    /* Active user company name */
    if(window.localStorage.getItem('token')){
      this.headerService.getChannelList().subscribe(channelList => {

        if(channelList){
          this.activeUserChannelList = channelList['result'];
        }
      });
    }
  };

  displayUserFirstLetter(userName) {
    if (userName) {
      return userName.charAt(0).toUpperCase();
    } else {
      return;
    }
  }

  logout() {
    const payload = {
      user_id: this.currentUser.user_id,
      status_id: 0
    }

    this.headerService.updateStatus(payload).subscribe(res => {
      window.localStorage.clear();
      this.router.navigate(['/login']);
    });
  };

  changeCustomStatus(e){
    this.noChangeYet = false;

    let inpValue = e.target.value;
    let classname = e.target.previousElementSibling.classList[1];

    let customStatus = [inpValue, classname];


     this.onSelectOption(customStatus);
  }

  onSelectOption(event){

    if(event.sm_id !== this.currentUser.user_status) {

      const payload = {
        user_id: this.currentUser.user_id,
        status_id: event.sm_id
      }

      this.headerService.updateStatus(payload).subscribe(res => {
        if(event.sm_name == 'Available'){
          const currentData = {...this.currentUser};
          currentData.user_status = 1
          currentData.status_name = 'Available';
          this.currentUser = currentData;
          this.authStatus = {title: 'Available', useClass: 'circle-green'};
        }else if(event.sm_name == 'Away'){
          const currentData = {...this.currentUser};
          currentData.user_status = 3
          currentData.status_name = 'Away';
          this.currentUser = currentData;
          this.authStatus = {title: 'Away', useClass: 'circle-yellow'};
        }else if(event.sm_name == 'Busy'){
          const currentData = {...this.currentUser};
          currentData.user_status = 2
          currentData.status_name = 'Busy';
          this.currentUser = currentData;
          this.authStatus = {title: 'Busy', useClass: 'circle-red'};
        }else if(event.sm_name == 'Out Of Office'){
          const currentData = {...this.currentUser};
          currentData.user_status = 0
          currentData.status_name = 'Out Of Office';
          this.currentUser = currentData;
          this.authStatus = {title: 'Out of Office', useClass: 'circle-gray'};
        }
        // else if(event){
        //   this.authStatus = { title: event[0], useClass: event[1]}
        // }
        this.alert.showAlertMessage(res);
      },
      error => {
        // this.alert.showAlertMessage(error.error);
      });
    }

  }

  //Internet connection check with offline or online
  createOnline$(){
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  onlineCheck() {
    if(navigator.onLine==false){
      // let msg = 'Still no connection';
      // this.alert.showAlertMessage(msg);
      alert('Still no connection');
    }

  }

  ngOnDestroy() {
    this.socketService.disconnectSocket();
    this.subscribers.userIconImage.unsubscribe();
    this.subscribers.companyLogoId.unsubscribe();
    if (_.isObject(this.app.subscribers)) {
      this.app.subscribers.isSubscribed = false;
      if (this.app.subscribers.userMessage) {
        this.app.subscribers.userMessage.unsubscribe();
        this.app.subscribers.groupMessage.unsubscribe();
        this.app.subscribers.userStatusNotify.unsubscribe();
        this.app.subscribers.userTypingUpdate.unsubscribe();
        this.app.subscribers.initiateNewChat.unsubscribe();
        this.app.subscribers.updateDelivery.unsubscribe();
        this.app.subscribers.readBulkMessage.unsubscribe();
        this.app.subscribers.groupUpdate.unsubscribe();
      }
    }
  }

  private getSeconds(ticks: number) {
    return this.pad(ticks % 60);
  }

  private getMinutes(ticks: number) {
    return this.pad((Math.floor(ticks / 60)) % 60);
  }

  private getHours(ticks: number) {
    return this.pad(Math.floor((ticks / 60) / 60));
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

  switchCompanyId(event){
    try {
      const payLoad = {
        "company_id" : event
      }

      this.loader.showLoader();
      this.headerService.changeChannel(payLoad).subscribe((response) => {
        if(response){
          console.log(response);
          // this.selectedCompany = response['company_id'];
            window.localStorage.user_id = JSON.stringify(response['user_id']);
            window.localStorage.user_role = JSON.stringify(response['user_role']);
            window.localStorage.token = JSON.stringify(response['token']);
            window.localStorage.company_id = JSON.stringify(response['company_id']);
            window.location.href ="/#/chats";
            // this.router.navigate(['/chats']);
            window.location.reload();

          this.loader.hideLoader();
        }
      },
      (error) => {
        this.loader.hideLoader();
        this.alert.showAlertMessage(error.error);
      })

      } catch (error) {
        this.loader.hideLoader();
        this.alert.showAlertMessage(error.error);
      }


  }


  toggleSideMenu(){
   let sidebar = this.document.querySelector('.sidebar');
      sidebar.classList.toggle('show');
  }

}
