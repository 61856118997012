import { DOCUMENT } from '@angular/common';
import { ApplicationRef, EmbeddedViewRef, Inject, Injectable, Type, createComponent } from '@angular/core';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(private appRef: ApplicationRef,@Inject(DOCUMENT) private doc: Document) { }

  public openConfirmDialog(comp: any,options?: {
        title: string,
        message: string,
        btnOkText:string,
        btnCancelText:string,
        data?:any;
        onClose?: (data?: any) => void;
        }
  ) {
    const componentRef = createComponent(ConfirmationDialogComponent, {
      environmentInjector: this.appRef.injector
    });
    this.doc.body.append(
      (<EmbeddedViewRef<any>>componentRef.hostView).rootNodes[0]
    );
  
    // attach view
    this.appRef.attachView(componentRef.hostView);

    // assign title
    componentRef.instance.title = options?.title || '';
    componentRef.instance.message = options?.message || '';
    componentRef.instance.btnOkText = options?.btnOkText || '';
    componentRef.instance.btnCancelText = options?.btnCancelText || '';

    const s = componentRef.instance.onClose.subscribe((data: any) => {
      
      if (options?.onClose) {
          options.onClose(data);
      }

      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      s.unsubscribe();
    });
  }
}
