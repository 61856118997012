import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignupComponent } from './signup.component';
import { SignupRoutingModule } from './signup-routing';
import { SharedModule } from '../../shared/shared.module';

import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import {Ng2TelInputModule} from 'ng2-tel-input';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SignupRoutingModule,
    SharedModule,
    PasswordStrengthBarModule,
    Ng2TelInputModule
  ],
  declarations: [
    SignupComponent
  ],
  providers: []
})
export class SignupModule { }
