import { Component, OnInit, HostBinding, Directive } from '@angular/core';

// @Directive()
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html'
})
export class HelpComponent implements OnInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = "100%";

  isToken : any;

  constructor() { }

  ngOnInit() {
    this.isToken = localStorage.getItem('token');
  }

}



