import { Directive, Output, HostListener, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';

// @Directive()
@Directive({
    selector: '[appDragDrop]',
})
export class DragDropDirective implements OnInit, OnDestroy {



    @Output() dropHandler: EventEmitter<any> = new EventEmitter<any>();

    @HostListener('dragover', ['$event']) onDragOver(e) {
        e.preventDefault();
    };
    @HostListener('dragenter', ['$event']) handleDragEnter(e) {
        e.preventDefault();
    };
    @HostListener('dragleave', ['$event']) handleDragLeave(e) {
        e.preventDefault();
    };
    @HostListener('drop', ['$event']) handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        this.dropHandler.emit(files);
    };

    ngOnInit() {
    };

    ngOnDestroy() {
        this.dropHandler.unsubscribe();
    };

}
