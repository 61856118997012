import { ApplicationRef, EmbeddedViewRef, Inject, Injectable, Injector, StaticProvider, Type, createComponent } from '@angular/core'; 
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor( private appRef: ApplicationRef,
    @Inject(DOCUMENT) private doc: Document ) { }

    public openConfirmDialog(
      c: Type<any>,
      options?: {
        title?: string,
        message?:string,
        data?: any;
        onclose?: (data: any) => void;
        // optionally add more events mapped to peach
        // onPeach?: (data: any) => void;
        // injected services at will
        providers?: StaticProvider[];
      }
    ){
          // first create a Rose component
        const componentRef = createComponent(c, {
          environmentInjector: this.appRef.injector,
        });

        // append to body, we will use platform document for this
        this.doc.body.append(
          (<EmbeddedViewRef<any>>componentRef.hostView).rootNodes[0]
        );

            // attach view
        this.appRef.attachView(componentRef.hostView);

        // assign title
        componentRef.instance.title = options?.title || '';
        componentRef.instance.message = options?.message || '';

         // when closed destroy
    const s = componentRef.instance.onClose.subscribe((data: any) => {
      // call onclose if exists

      if (options?.onclose) {
        options.onclose(data);
      }

      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      s.unsubscribe();
    });

  }

  public openListDialog(
    comp: Type<any>,
    options?: {
      title?: string,
      message?:string,
      data?: any;
      onclose?: (data: any) => void;
      // optionally add more events mapped to peach
      // onPeach?: (data: any) => void;
      // injected services at will
      providers?: StaticProvider[];
    }
  ){
        // first create a Rose component
      const componentRef = createComponent(comp, {
        environmentInjector: this.appRef.injector,
      });

      // append to body, we will use platform document for this
      this.doc.body.append(
        (<EmbeddedViewRef<any>>componentRef.hostView).rootNodes[0]
      );

          // attach view
      this.appRef.attachView(componentRef.hostView);

      // assign title
      componentRef.instance.title = options?.title || '';
      componentRef.instance.message = options?.message || '';
      componentRef.instance.listData = options?.data || [];

       // when closed destroy
  const s = componentRef.instance.onClose.subscribe((data: any) => {
    // call onclose if exists

    if (options?.onclose) {
      options.onclose(data);
    }

    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
    s.unsubscribe();
  });

}
  
}
