import { Component, OnInit, AfterViewInit, Input, OnDestroy, Directive } from '@angular/core';
import { DatePipe } from '@angular/common';
import { config, path, HttpLoader, AlertPopup } from './../../../../app.config';
import { NotificationService } from './../../notification.service';


// @Directive()
@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: []
})
export class CommentComponent implements OnInit {
    @Input() postComment: any;
    RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
    OFFSET: number = config.DEFAULT_OFFSET;
    totalRowCount: number;
    subPost: any;
    private subscribers: any = {};
    profileUrl = path.PROFILE_URL;
    showPaginateSpinner = false;
    constructor(
        private notificationService: NotificationService,
        private httpLoader: HttpLoader,
        private alert: AlertPopup) {
    }

    ngOnInit() {
    };

    /** Comments functinality Section */
    openNewSubComment(comment) {
        if (comment) {
            comment.showReply = !comment.showReply;
        }
    };

    createNewSubComment(comment) {

        if (comment.newSubComment.trim() !== '') {
            try {

                const payLoad = {
                    comment_id: comment.comment_id,
                    sub_comment: comment.newSubComment
                };

                this.notificationService.createNewSubComment(payLoad).subscribe(
                    response => {
                        delete comment.newSubComment;
                        comment.subCommentsCount += 1;
                        const subCommentList = [...comment.subCommentList];
                        // subCommentList.push(response['createdSubComment']);
                        subCommentList.unshift(response['createdSubComment']);
                        comment.subCommentList = subCommentList;
                        this.httpLoader.hideLoader();
                    },
                    error => {
                        this.httpLoader.hideLoader();
                        this.alert.showAlertMessage(error.error);
                    });
            } catch (err) {
                this.httpLoader.hideLoader();
            }
        }
    };

    loadMoreComments(post) {
        if (post) {
            try {
                this.totalRowCount = post.commentsCount;
                const listLength = post.commentsList.length;
                if (this.totalRowCount > listLength && !this.showPaginateSpinner) {
                    this.showPaginateSpinner = true;
                    const payLoad = {
                        sub_post_id: post.sub_post_id,
                        offset: this.OFFSET,
                        limit: this.RECORD_LIMIT
                    };
                    this.notificationService.getComment(payLoad).subscribe(
                        response => {
                            this.showPaginateSpinner = false;
                            if (this.OFFSET === 0) {
                                post.commentsList = response['commentsList'];
                            } else if (this.OFFSET > 0) {
                                post.commentsList = [...post.commentsList.concat(response['commentsList'])];
                            }
                            this.OFFSET += 1;
                        },
                        error => {
                            this.showPaginateSpinner = false;
                            this.alert.showAlertMessage(error.error);
                        }
                    )
                }
            } catch (err) {
                console.log(err);
            }
        }
    };


    calcTextLength(text: string) {
        if (text) {
            return text.length > 0 ? text.length + 10 : text.length;
        }
        return 50;
    };

    //Write reply comment space without character restrict

    keyHandleRely(event){
      if(event.which === 32 && event.target.selectionStart === 0 ){
        // event.preventDefault();
        return false;
      }
    }

}
