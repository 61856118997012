import { Component, OnInit, Directive } from '@angular/core';

// @Directive()
@Component({
  selector: 'app-purchase-course-details',
  templateUrl: './purchase-course-details.component.html',
  styleUrls: ['./purchase-course-details.component.css']
})
export class PurchaseCourseDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
