import { AlertPopup } from './../../app.config';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, Directive } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { config } from '../../app.config';
import { ValidationService } from './../form-validation/validation.service';
import { PasswordResetService } from './password-reset.service';
import { DataSharingService } from 'app/shared/data-sharing.service';


// @Directive()
@Component({
  selector: 'app-forgot',
  templateUrl: './password-reset.component.html',
  styleUrls: []
})

export class PasswordResetComponent implements OnInit, AfterViewInit, OnDestroy {
  passwordResetForm: FormGroup;
  showMailConfirmation = false;
  @Input() emailIds: any;
  token: string;
  errorMessage: string;
  copyRights:number = config.copyrights;
  email_id;

  private subscribers: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private resetService: PasswordResetService,
    private dataSharingService: DataSharingService,
    private alert: AlertPopup) {
    this.passwordResetForm = formBuilder.group({
      password: [null, Validators.compose([Validators.required, ValidationService.passwordValidator])],
      confirm_password: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.clearLocalStorage();
    this.token = this.route.snapshot.paramMap.get('token');
  };

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    try {
      this.resetService.authenticateEmailId$.subscribe(data => {
        this.emailIds = data;
        console.log(this.emailIds)
      })
    } catch (error) {
      console.log(error)
    }
  }


  clearLocalStorage() {
    window.localStorage.clear();
  };

  returnToLogin() {
    this.router.navigate(['/login']);
  };

  confirmResetPassword(value) {

    if (!value) { return }
    if (value.password === value.confirm_password) {

      const email_id = window.sessionStorage.getItem('email_id');

      const payLoad = {
        // token: this.token,
        email_id: email_id,
        password: value.password
      };

      console.log(payLoad)
      this.resetService.requestResetPassword(payLoad).subscribe(
        response => {
     
          this.showMailConfirmation = true;
        },
        error => {
          this.alert.showAlertMessage(error.error);
        }
      )
    } else {
      this.errorMessage = `The Password doesn't match`;
    }
  };

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    // this.subscribers.userEmailId.unsubscribe();
  }

}

