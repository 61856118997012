import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FeedbackComponent } from "./feedback.component";

@NgModule({
    imports: [
      CommonModule,
      RouterModule.forChild([
        {
          path: '', component: FeedbackComponent
        }
      ])
    ],
    declarations: [],
    exports: [RouterModule]
  })
export class FeedbackRoutingModule{}