import { HttpClient } from '@angular/common/http';
import { Component} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertPopup } from 'app/app.config';
import { ChatService } from 'app/components/chat-list/chat.service';
import { jsPDF } from "jspdf";
import { AssistBotService } from '../assist-bot.service';
import { ListdialogComponent } from 'app/core/listdialog/listdialog.component';
import { DialogService } from 'app/services/dialog.service';
 declare const $:any;

@Component({
  selector: 'app-botleave-planner',
  templateUrl: './botleave-planner.component.html',
  styleUrls: ['./botleave-planner.component.scss']
})
export class BotleavePlannerComponent {

  
  stateList:any=[];
  leaveReasons:any=[];
  resultData:any=[];
  leaveForm:FormGroup=new FormGroup({});
  showPaginateSpinner:boolean=false;
  volumeicons:any=["fa fa-volume-off","fa fa-volume-up"];
  showDisclaimer:boolean=true;
  public isActiveFeedback: boolean = false;
  commentForm:FormGroup=new FormGroup({});


  //base_url:any = `http://115.246.210.90:8501`;
  llm_url:any;
  constructor(private http:HttpClient,private fb:FormBuilder,private alert: AlertPopup,private chatService: ChatService,private assistBotService:AssistBotService,private dialogService:DialogService){
    this.leaveForm = this.fb.group({
        'state_name':new FormControl('Alabama'),
        'leave_reason':new FormControl("Maternity")
    });
    this.commentForm = this.fb.group({
      'comment':new FormControl('',Validators.required)
    });
  }

  ngOnInit(){
    this.llm_url = this.assistBotService.GptConfiguration['LLm_url'];
    this.http.get('../assets/json/us_state_list.json').subscribe((data:any)=>{
        this.stateList = data;
    });
   
    this.http.get('../assets/json/absence_leave_reason_list.json').subscribe((data:any)=>{
        this.leaveReasons = data;
    });
    this.assistBotService.historyListBheaviour.subscribe((data:any)=>{
      this.resultData = this.assistBotService.chatHistoryList.filter((hList:any)=>hList.question_type=="history" && hList.bot_type==data['templateName']);
    });
   
  }


  submitForm(){
    console.log("leaveForm",this.leaveForm.value);
    this.showPaginateSpinner= true;
    //let query="what qualifies for "+this.leaveForm.value['leave_reason']+" in "+this.leaveForm.value['state_name'];
    let query ="I am located in "+this.leaveForm.value['state_name'] +" and my leave reason is "+this.leaveForm.value['leave_reason'] +", can you explain the eligibility.";
    let headers:any = new Headers();
    headers.append('Content-Type', 'application/json');
    const payload = {
      "query":query,
      "clientid":localStorage.getItem('company_id')
    };

    this.http.post(this.llm_url+"query",payload, {headers: headers}).subscribe((response:any)=>{
       let resultdata={
        "question":this.leaveForm.value['state_name']+", "+this.leaveForm.value['leave_reason'],
        "bot_answer": response['result'].replace(/\n\r?/g, '<br />'),
        "bot_type":"normal",
        "question_type":"history",
        "sequence_id":new Date().valueOf()
      }
      let user_id = window.localStorage.getItem('user_id');
      let botPayload ={
      "user_id":user_id,
      "question":this.leaveForm.value['state_name']+", "+this.leaveForm.value['leave_reason'],
      "answer":response['result'],
      "question_type":"history",
      "bot_type":"normal",
      "sequence_id":new Date().valueOf()
      };
      this.assistBotService.createHistory(botPayload).subscribe((result:any)=>{
       
         if(result['success']){
          resultdata["question_type"]="history";
          resultdata["sequence_id"]=result['createdMessage']["sequence_id"];
          resultdata["history_id"] = result['createdMessage']["history_id"];
          resultdata['created_at'] = new Date();
          this.assistBotService.chatHistoryList.unshift(resultdata);
          this.assistBotService.historyListBheaviour.next({"templateName":"normal"});
          this.showPaginateSpinner= false;
         }
     
      });
    
     
     
    },(error:any)=>{
      this.showPaginateSpinner= false;
    });
 

  }


  


  downloadPDF(items:any){
    this.alert.showAlertMessage({'message':'Answer downloaded'});
    var doc = new jsPDF();
    var img = new Image()
    img.src = '../assets/images/absence_gpt.png';
    doc.addImage(img,'png',0,0,100,25);
    doc.setFont("sans-serif");
    doc.setTextColor('#000');
    doc.setFontSize(14);
    doc.text(items.question,15,30);
    doc.setTextColor('#000');
    doc.setFontSize(11);
    
    items.bot_answer = items.bot_answer.replace(/<br\s*[\/]?>/gi, "\n");
    var splitTitle = doc.splitTextToSize(items.bot_answer,185,{textIndent: 30});
    doc.text(splitTitle,15,38);
    
    
    var userId = window.localStorage.getItem('user_id');
     
      doc.save(userId+'_'+'assign'+new Date().getTime()+'.pdf');
      var blob = doc.output('blob');
    
      console.log(blob);
  }

  copiedText(data){

    if (!navigator.clipboard){
      // use old commandExec() way
    } else{
      this.alert.showAlertMessage({"message":"Copy this text to clipboard.."});
        navigator.clipboard.writeText(data.bot_answer).then(
            function(){  console.log("yeah!"); // success
        }).catch(function() { console.log("err"); // error
      });
    }

  }

  speakText(state:boolean,textdata:string){

    let text = textdata.replace(/(<([^>]+)>)/gi, "");
    if ('speechSynthesis' in window) {
       var to_speak = new SpeechSynthesisUtterance(text);
       if(state){
        speechSynthesis.cancel();
        window.speechSynthesis.speak(to_speak);
       }else{
        speechSynthesis.cancel();
       }

     } else {
         console.log('not support ');
     }
}

PingedChat(data){
 
 let pinnedData = data;
 let pingfilter = this.assistBotService.chatPinList.filter(ping=>ping['sequence_id']==pinnedData["sequence_id"]);
 if(pingfilter.length > 0){
   this.alert.showAlertMessage({"message":"Chat bot history pin already exist"});
     return;
 }

  let user_id = window.localStorage.getItem('user_id');
  let botPayload ={
  "user_id":user_id,
  "question":pinnedData["question"],
  "answer":pinnedData['bot_answer'],
  "question_type":"pin",
  "sequence_id":pinnedData["sequence_id"],
  "history_id":pinnedData["history_id"]
  }
  this.assistBotService.createHistory(botPayload).subscribe((result:any)=>{
  
    if(result['success']){
      this.alert.showAlertMessage(result);
  
      botPayload["bot_type"]="normal";
      this.assistBotService.chatPinList.unshift(botPayload);
      
      this.assistBotService.historyListBheaviour.next({"templateName":"normal"});
    }
  
  });
 
}
 
toggleDisclaimer(){
  this.showDisclaimer = !this.showDisclaimer;
}

submitFeedForm(){
  
    this.alert.showAlertMessage({"message":"Thanks for your feedback."});
       $('#inputField').focus();
   
}

showFeedbackform(){
  this.isActiveFeedback =!this.isActiveFeedback;
  console.log( this.isActiveFeedback, 'isActive');
  
}

onKeypressEvent(event: any) {
  let comment = event.target.value;
  
   if ((event.code == "Enter") || (event.code == "NumpadEnter")) {
      console.log("comment",comment);
   }
 }

 saveComment(items:any){
  let message = "<b>Question: </b>"+items.question+"? <br /><b>Comments: </b> "+this.commentForm.value['comment'];
  const refId = new Date().valueOf();
   
 
const payLoad = {"group_id":345,"message":message,"unique_ref_id":refId};
this.chatService.sendGroupMessage(payLoad).subscribe(
    (messageRes) => {
      if (messageRes['createdMessage']) {
        this.alert.showAlertMessage({'message':'Your comments saved'});
      }
    });
//   Array.from(document.querySelectorAll('.comment-area-box')).map((el)=> {
//     el.classList.remove('is-active');
//  });

}

refresh(index){
  this.resultData[index].created_at = new Date();
}

openDialog(data){

  this.dialogService.openListDialog(ListdialogComponent,{
    title:'Create Document',
    data:[
      {"label":"Save as a word document","value":"word"},{"label":"Save as a pdf file ","value":"pdf"}],
    onclose: (selectedList) => {
       
       let user_id = window.localStorage.getItem('user_id');
       let payload = {
        "user_id":parseInt(user_id),
        "question":data.question,
        "answer":data.bot_answer,
        "question_type":selectedList['value'],
        "bot_type": "normal",
        "sequence_id":new Date().valueOf()
       }
       console.log('file  generate payload',payload);
       this.assistBotService.BotFilegenerate(payload).subscribe((res:any)=>{
        console.log("file generate",res);
        if(res['success']){
          this.alert.showAlertMessage({'message':'File generated successfully'});
          this.assistBotService.chatHistoryList.unshift(res['result']);
          this.assistBotService.historyListBheaviour.next({"templateName":"normal"});
        }else{
          this.alert.showAlertMessage(res);
        }
       },(error:any)=>{
        console.log("file generate error",error);
        this.alert.showAlertMessage(error['error']);
        this.assistBotService.historyListBheaviour.next({"templateName":"normal"});
     });
    }
   });
}


generateAudio(data){
  let user_id = window.localStorage.getItem('user_id');
  let payload = {
   "user_id":parseInt(user_id),
   "question":data.question,
   "answer":data.bot_answer,
   "question_type":"audio",
   "bot_type": "normal",
   "sequence_id":new Date().valueOf()
  }

  this.assistBotService.BotFilegenerate(payload).subscribe((res:any)=>{
    console.log("file generate",res);
    if(res['success']){
      this.alert.showAlertMessage({'message':'File generated successfully'});
      this.assistBotService.chatHistoryList.unshift(res['result']);
      this.assistBotService.historyListBheaviour.next({"templateName":"normal"});
    }else{
      this.alert.showAlertMessage(res);
    }
  },(error:any)=>{
    console.log("file generate error",error);
    this.alert.showAlertMessage(error['error']);
    this.assistBotService.historyListBheaviour.next({"templateName":"normal"});
 });
}


}
