import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'app/app.config';

@Injectable()
export class SecureFileService {
    errors: any[] = [];

    baseUrl= `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;

    constructor(private http: HttpClient) { }

    secureFileDownLoad(url: string) {
        return this.http.get(url, { responseType: 'blob' });
    };

    secureReadfile(url: string){
      return this.http.get(url, { responseType: 'blob' })
    }

}
