import { Component, OnInit, HostBinding, ElementRef, ViewChild, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { NotificationComponent } from '../notification.component';
import { NotificationService } from '../notification.service';
import { UserService } from '../../userandgroup/user/user.service';
import * as _ from 'underscore';
import { config, path, HttpLoader, AlertPopup } from './../../../app.config';
import { FileValidationService } from './../../../shared/file-validation.service';
import { AppComponent } from './../../../app.component';

import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';
import { fromEvent, Subscription } from 'rxjs';

// @Directive()
@Component({
  selector: 'app-new-notification',
  templateUrl: './new-notification.component.html',
  styleUrls: []
})
export class NewNotificationComponent implements OnInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('fileInput') fileInput: ElementRef;
  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;

  showNotfication = false;
  notificationId: number;
  notificationForm: FormGroup;
  userId: number;
  userName: string;
  tabName: string;
  search: string;
  listOfUsers: any[] = [];
  totalRowCount: number;
  userIdList: number[] = [];
  // listOfGroups: any[] = [];
  // groupIdList: number[] = [];

  /** User Profile icon handler variables */
  selectedFileList: any[] = [];
  profileExtension = config.POST_FILE_EXT;
  profileSize = config.PROFILE_SIZE;
  preUploadedFile: any;
  preProfileUrl = path.PRE_PROFILE_URL;

  profileUrl = path.PROFILE_URL;

  showPaginateSpinner = false;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;

  viewControl = {
    changedSearchText: false
  };
  searchInList = new Subject<any>();

  isMobile: boolean = false;
  width:number = window.innerWidth;
  height:number = window.innerHeight;
  mobileWidth:number  = 768;


  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private alert: AlertPopup,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private appComponent: AppComponent,
    private notificationComponent: NotificationComponent,
    private httpLoader: HttpLoader,
    private fileValidationService: FileValidationService) {
    this.notificationForm = fb.group({
      post_name: [null, Validators.required],
      sub_post_name: [null],
      post_priority: [null, Validators.required]
    });


    this.searchInList
      .debounceTime(500)
      .subscribe(event => {
        this.search = event.target.value;
        if (!this.search) {
          return;
        }
        this.viewControl.changedSearchText = true;
        this.getUserList(0);
      });
  }

  ngOnInit() {
    this.tabName = 'users';
    this.notificationId = +this.route.snapshot.paramMap.get('id');
    this.getUserList(0);
    // this.getGroupList();

    if(this.width <= this.mobileWidth){
      this.notificationComponent.viewNotificatonList = true;
    }else{
      this.notificationComponent.viewNotificatonList = false;
    }

    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      let windowWith = window.innerWidth;
      if(windowWith <= this.mobileWidth){
        this.notificationComponent.viewNotificatonList = true;
      }else{
        this.notificationComponent.viewNotificatonList = false;
      }
    })
  }

  addUpdatedNotificationList(notification) { }

  addNewNotification() {
    this.showNotfication = !this.showNotfication;
  }

  closeNotifyResView(){
    this.notificationComponent.viewNotificatonList = false;
  }

  createPost(post) {
    try {
      if (this.userIdList && this.userIdList.length > 0) {
        this.userIdList = _.uniq(this.userIdList);


        const postName = post.post_name;
        if (postName && postName.length > 255) {
          return this.alert.showAlertMessage({ message: 'Title length must be less than 255 characters' });
        }


        const subPostName = post.sub_post_name;

        if (subPostName && subPostName.length > 255) {
          return this.alert.showAlertMessage({ message: 'Message length must be less than 255 characters' });
        }


        const payLoad = {
          post_name: post.post_name,
          sub_post_name: post.sub_post_name,
          post_priority: post.post_priority,
          file_data: this.preUploadedFile,
          user_ids: this.userIdList,
          group_ids: []
        }

        this.notificationService.createPost(payLoad).subscribe(
          posts => {
            this.notificationComponent.postList.unshift(posts['createdPost']);
            this.notificationComponent.totalRowCount += 1;
            const postId = posts['createdPost']['post_id'];
            this.router.navigate([`/main/channels/${postId}`]);
            this.httpLoader.hideLoader();
          },
          error => {
            this.alert.showAlertMessage(error.error);
            this.httpLoader.hideLoader();
          }
        )
      } else {
        this.alert.showAlertMessage({ message: 'Please select the user.' });
      }
    } catch (err) {
      console.log(err);
      this.httpLoader.hideLoader();
    }
  };

  getUserList(pageNo) {
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: this.search || ''
      };


      this.notificationService.getPostUserList(payLoad).subscribe(
        lists => {
          if (offset === 0) {
            this.listOfUsers = lists['userList'];
          } else {
            this.listOfUsers = [...this.listOfUsers.concat(lists['userList'])];
          }
          this.totalRowCount = lists['TotalRowCount'];
          this.httpLoader.hideLoader();
          this.showPaginateSpinner = false;
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.showPaginateSpinner = false;
          this.httpLoader.hideLoader();
        }
      )
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  };

  fetchNextUserList() {
    const pageCount = Math.ceil(this.totalRowCount / this.RECORD_LIMIT) - 1;
    if (pageCount > this.OFFSET && !this.showPaginateSpinner) {
      this.OFFSET += 1;
      this.showPaginateSpinner = true;
      this.getUserList(this.OFFSET);
    }
  };

  selectedUser(userId) {
    try {
      if (userId) {
        if (this.userIdList.indexOf(userId) > -1) {
          this.userIdList = _.without(this.userIdList, userId);
        } else {
          this.userIdList.push(userId);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  async fileValidationHanlder(files) {
    try {
        const isValidate = await this.fileValidationService.fileValidation(files, this.profileExtension, this.profileSize);
        return files;
    } catch (err) {
        err.map(li => {

            const errMsg = {
                message: li
            };
            // this.alert.showAlertMessage(errMsg);
            this.alert.showAlertMessage({message: 'The file is too large and cannot be uploaded. Please reduce the size of the file and try again (min 5 MB)'});
        });
        return false;
    }
  };


  async onFileChange(event) {

    const files = event.target.files;

    let fileExt = files[0].type.split('/');

    let ext = fileExt[1].toUpperCase();

    const inArray = this.profileExtension.split(',');

    let data = inArray.find(ob => ob === ext);

    if(data){
      const fileValid = await this.fileValidationHanlder(files);
      if (fileValid) {
        this.concatFileIntoArray(files);
      }
    }else{
      this.alert.showAlertMessage({message: 'File format not supported. Please accepted '+ this.profileExtension + ' format only' })
    }
  };

  concatFileIntoArray(files){

    if (files && files.length > 0) {

      if (this.selectedFileList.length < 1) {
        const formFields = {
          fieldName: 'gallery_post',
          activity: 'user_post_add',
          storeType: 'temp'
        };


        this.selectedFileList.push(files[0]);
        this.fileValidationService.fileValidation(files, this.profileExtension, this.profileSize).then(validResponse => {
          this.appComponent.fileUploadWithProgess(formFields, this.selectedFileList).then((fileUpLoadResponse: any) => {
            for (const key in fileUpLoadResponse) {
              if (fileUpLoadResponse[key]) {
                this.preUploadedFile = fileUpLoadResponse[key]
              }
            }
            this.selectedFileList = [];
            this.fileInput.nativeElement.value = '';
          }).catch(error => {
            console.log(error)
            this.selectedFileList = [];
            this.fileInput.nativeElement.value = '';
            this.alert.showAlertMessage(error.error);
          })
        }).catch(err => {
          alert('File Not supported');
          this.fileInput.nativeElement.value = '';
          this.selectedFileList = [];
          err.map(li => this.alert.showAlertMessage(li));
        });

      }else{
        this.alert.showAlertMessage({
          message: 'File seletion limit reached.'
      });
      }

    }
  }

  removeSelectedFile() {
    this.selectedFileList = [];
    this.preUploadedFile = null;
  };

  // getGroupList() {
  //   try {
  //     this.notificationService.getGroupList().subscribe(
  //       lists => {
  //         this.listOfGroups = lists['chatlist'];
  //         // this.totalRowCount = lists['TotalRowCount'];
  //         this.httpLoader.hideLoader();
  //       },
  //       error => {
  //         this.alertService.success(error['message']);
  //         console.log(error);
  //         this.httpLoader.hideLoader();
  //       }
  //     )
  //   } catch (err) {
  //     console.log(err);
  //     this.httpLoader.hideLoader();
  //   }
  // }

  // selectedGroup(groupId) {
  //   try {
  //     if (groupId) {
  //       if (this.groupIdList.indexOf(groupId) > -1) {
  //         this.groupIdList = _.without(this.groupIdList, groupId);
  //       } else {
  //         this.groupIdList.push(groupId);
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  cancelUserSearch() {
    if (this.viewControl.changedSearchText) {
      this.viewControl.changedSearchText = false;
      this.search = null;
      this.getUserList(0);
    }
  };

}
