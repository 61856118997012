import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCustomScroll]'
})
export class CustomScrollDirective {

  constructor(private renderer:Renderer2,private elem: ElementRef) { }

  ngAfterViewInit(){

    this.scrollcallculate();
  }

scrollcallculate(){
  setTimeout(() => {
    const marginTop =  this.elem.nativeElement.offsetTop;
    const marginLeft =  this.elem.nativeElement.offsetLeft;
   const widthParent = this.elem.nativeElement.offsetWidth;
    const heigthParent = this.elem.nativeElement.offsetHeight;
    const calculateHeight = (window.innerHeight -  (marginTop + 150));
    const calculatewidth = (window.innerWidth -  (marginLeft));
    console.log("calculateHeight",calculateHeight);
    this.renderer.setStyle(this.elem.nativeElement,'width',calculatewidth+'px');
    this.renderer.setStyle(this.elem.nativeElement,'overflow','auto');
    this.renderer.setStyle(this.elem.nativeElement,'height', calculateHeight+'px');
  },1000);
}

@HostListener('window:resize', ['$event'])
onResize(event) {
  this.scrollcallculate();
}

}
