import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByDate',
  pure: false
})
export class SortByDate implements PipeTransform {

  transform(array: Array<string>, args: string, bool: boolean): Array<string> {
    array.sort((a: any, b: any) => {
      let firstField = null;
      let compareField = null;
      
      if (args === 'updatedAt') {
        firstField = new Date(a[args]).valueOf();
        compareField = new Date(b[args]).valueOf();
      } else {
        firstField = a[args];
        compareField = b[args];
        bool = false
      }

      if (firstField < compareField) {
        return bool ? 1 : -1;
      } else if (firstField > compareField) {
        return bool ? -1 : 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
