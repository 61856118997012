import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, Directive  } from '@angular/core';
import { path } from './../../../../app.config';
import { Subscription, Observable } from 'rxjs';
import { ChatMessageComponent } from '../chat-message.component';
import adapter from 'webrtc-adapter';

declare let RTCPeerConnection: any;





// @Directive()
@Component({
    selector: 'app-audio-view',
    templateUrl: './audio-call-view.component.html',
    styleUrls: []
})
export class AudioCallViewComponent implements OnInit {
    @Input() audioInput;
    @Output() audioHandler = new EventEmitter();

    profileUrl = path.PROFILE_URL;

    audioTimer = false;
    audioRinging = true;
    conference= false;

    tick = 0;
    minutes: number = 0;
    hours: number = 0;
    seconds: number=0;
  sub: Subscription;
  countDown: any;
  constructor(private chatMessageComponent: ChatMessageComponent) {
  }
  ngOnInit() {
    this.timerDisplay();
    // console.log(adapter.browserDetails.browser);
    // console.log(adapter.browserDetails.version);
  }

    audioCallEnd() {
        this.audioHandler.emit(false);
    }
  addMoreMembers() {
    this.chatMessageComponent.showAudioUserList();
  }

    private timerDisplay(){

      setTimeout(() => {
       this.audioRinging = false;
       this.audioTimer = true;
       this.conference = true;
    setTimeout(() => {
      this.audioRinging = false;

       let timer = Observable.timer(1, 1000);

       this.sub = timer.subscribe(
         t => {
           this.tick =t;

           this.seconds = this.getSeconds(this.tick);
           this.minutes = this.getMinutes(this.tick);
           this.hours = this.getHours(this.tick);
          this.countDown = this.minutes + ':' + this.seconds;
        }
      )
    }, 1000);
    return false;
})
    }

    private getSeconds(ticks: number) {
      return this.pad(ticks % 60);
  }

  private getMinutes(ticks: number) {
       return this.pad((Math.floor(ticks / 60)) % 60);
  }

  private getHours(ticks: number) {
      return this.pad(Math.floor((ticks / 60) / 60));
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
}
}
