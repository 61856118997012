import { Component, OnInit, HostBinding, ViewChild, ElementRef, Directive } from '@angular/core';
import { HttpEventType, HttpResponse, HttpClient, HttpRequest } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import 'rxjs/add/operator/switchMap';
import * as _ from 'underscore';
import { Observable } from 'rxjs/Observable';
 
import { UserService } from '../user.service';
import { config, path, ResolveEmit, AlertPopup, HttpLoader } from './../../../../app.config';
import { UserComponent } from '../user.component';
import { ValidationService } from '../../../form-validation/validation.service';
import { AppComponent } from './../../../../app.component';
import { NewUserComponent } from './../new-user/new-user.component';
import { GroupService } from './../../group/group.service';
import { UtilityService } from './../../../../utility.service';
import { FileValidationService } from './../../../../shared/file-validation.service';
import { fromEvent, Subscription } from 'rxjs';
import { ConfirmationDialogService } from 'app/confirmation-dialog/confirmation-dialog.service';
 

// @Directive()
@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: []
})
export class UserDetailComponent implements OnInit {
    @HostBinding('class.flex-grid') leftBarClass = true;
    @HostBinding('style.width') flexwidth = '100%';
    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild('input1') inputEl: ElementRef;
    RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
    OFFSET: number = config.DEFAULT_OFFSET;
    newUserForm: FormGroup;
    accountForm: FormGroup;
    searchText: string;
    roleList: any[] = [];
    groupList: any[] = [];
    totalRowCount: number;
    selectedIdList: number[] = [];
    groupIdList: any[] = [];

    /** User Profile icon handler variables */
    selectedFileList: any[] = [];
    profileExtension = config.PROFILE_EXT;
    profileSize = config.PROFILE_SIZE;

    editedUserId: number;
    updatedUserDetail: any = {};
    acc_active: boolean;
    editedFormValue: any = {};
    uploadSpinner = false;
    hideUserPwdPanel = true;
    viewSecondPanel = true;
    userDetailView = true;

    toggleModalSection = false;
    showPaginateSpinner = false;
    modalScrollDistance = config.DEFAULT_SCROLL_DIST;
    modalScrollThrottle = config.SCROLL_THROTTLE;

    /** specifying Base URL.*/
    public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/users`;
    profileUrl = path.PROFILE_URL;


    genderArrayList = [{
      name: 'Male',
      value : 'M'
    },
    {
      name: 'Female',
      value: 'F'
    },
    // {
    //   name: 'Other',
    //   value: 'O'
    // }
  ];

  isMobile: boolean = false;
    width:number = window.innerWidth;
    height:number = window.innerHeight;
    mobileWidth:number  = 768;


    resizeObservable$: Observable<Event>
    resizeSubscription$: Subscription

   isEmailVerfied:boolean = false;
   user_role:any;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private appComponent: AppComponent,
        public userComponent: UserComponent,
        private userService: UserService,
        private http: HttpClient,
        private fb: FormBuilder,
        private groupService: GroupService,
        private utilityService: UtilityService,
       
        private confirmDialogService:ConfirmationDialogService,
        private loader: HttpLoader,
        private alert: AlertPopup,
        private fileValidationService: FileValidationService) {
        this.newUserForm = fb.group({
            first_name: ['', Validators.compose([Validators.required, ValidationService.nameValidator])],
            last_name: ['',Validators.compose([Validators.required, ValidationService.noWhitespaceValidator, ValidationService.namePattern])],
            user_role: ['', Validators.required],
            phone_no: ['', Validators.compose([Validators.required, ValidationService.phoneNoValidate, ValidationService.noWhitespaceValidator])],
            email_id: ['', Validators.compose([Validators.required, ValidationService.emailValidator])],
            // gender: [''],
            user_address: [''],
            user_city: [''],
            user_state: [''],
            user_zip: [''],
            user_notes: [''],
            roll_number:[''],
            reg_number:[''],
            aadhar_number:[''],
            pan_number:[''],
            emis_number:[''],
            other_ids:['']
        });

        this.accountForm = fb.group({
            new_password: ['', Validators.compose([Validators.required, ValidationService.passwordValidator])],
            confirm_password: ['', Validators.compose([Validators.required, ValidationService.passwordValidator])],
        });
    }

    ngOnInit() {
        try {
            this.loader.showLoader();
            this.route.paramMap
                .switchMap((params: ParamMap) => this.userService.getUserDetail(+params.get('id')))
                .subscribe(user => {
                   console.log("user",user)
                 
                    this.loader.hideLoader();
                    this.roleList = user['roleList'];
                    this.user_role = parseInt(user['userDetail']['user_role']);
                    this.editedUserId = user['userDetail'] ? user['userDetail'].user_id : 0;
                    if (user['userDetail']) {
                        this.updatedUserDetail = {
                            first_name: user['userDetail'].first_name,
                            user_icon: user['userDetail'].user_icon
                        };

                        this.acc_active = user['userDetail'].acc_active;
                        this.isEmailVerfied = user['userDetail'].is_email_verified;
                       // const formField = this.newUserForm.value;
                        // const newObject = {};
                        // for (const key in formField) {
                        //     if (key) {
                              
                        //         newObject[key] = user['userDetail'][key];
                               
                                
                        //     }
                        // }
                          this.newUserForm.patchValue(user['userDetail']);
                    }
                    this.selectedIdList = [];
                    this.groupSearchAndPagination(0);
                },
                error => {
                    this.loader.hideLoader();
                    console.log(error);
                })
        } catch (err) {
            this.loader.hideLoader();
            console.log(err);
        }

        if(this.width <= this.mobileWidth){
          this.userComponent.viewUserList = true;
        }else{
          this.userComponent.viewUserList = false;
        }

        this.resizeObservable$ = fromEvent(window, 'resize')
        this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
          let windowWith = window.innerWidth;
          if(windowWith <= this.mobileWidth){
            this.userComponent.viewUserList = true;
          }else{
            this.userComponent.viewUserList = false;
          }
        })
    };

    ngAfterViewInit(): void {
      //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
      //Add 'implements AfterViewInit' to the class.
      this.userComponent.viewActionBtn = false;
      // this.inputEl.nativeElement.focus();
    }

    keyDownHandler(event) {
      if (event.keyCode === 32 && event.target.selectionStart === 0) {
        return false;
      }
    }

    /**
      *
      * @param searchText
      */
    groupSearchAndPagination(pageNo, userId = this.editedUserId) {
        try {
            this.loader.showLoader();
            if (pageNo === 0) {
                this.OFFSET = pageNo;
            }

            const offset = this.OFFSET;
            const payLoad = {
                userId: userId,
                offset: offset,
                limit: this.RECORD_LIMIT,
                sort: '',
                search: this.searchText || ''
            };
            this.groupService.getSelectedGroupList(payLoad).subscribe(
                (response) => {
                    this.loader.hideLoader();
                    if (offset === 0) {
                        this.groupList = response['groupList'];
                        this.totalRowCount = response['TotalRowCount'];
                    } else if (offset > 0) {
                        this.groupList = [...this.groupList.concat(response['groupList'])];
                    }
                    this.showPaginateSpinner = false;
                },
                error => {
                    this.loader.hideLoader();
                    this.alert.showAlertMessage(error.error);
                });
        } catch (err) {
            this.loader.hideLoader();
            console.log(err);
        }
    };

    /**
     * Add group Ids in groupIdList Array
     * @param groupId
     */

    selectGroup(index, groupId) {
        this.groupList[index].is_selected = !this.groupList[index].is_selected;
        if (this.groupList[index].is_aleady_selected && this.groupList[index].is_selected) {
            this.groupList[index].is_changed = false;
        } else {
            this.groupList[index].is_changed = true;
        }

        if (this.selectedIdList.indexOf(groupId) > -1 && !this.groupList[index].is_selected) {
            this.selectedIdList = _.without(this.selectedIdList, groupId);
            return;
        } else if (this.groupList[index].is_selected && !this.groupList[index].is_aleady_selected) {
            this.selectedIdList.push(groupId);
            return;
        }
    };

    /**
     * Pagination called while scroll down
     */
    onScrollDown() {
        if (this.groupList.length < this.totalRowCount && !this.showPaginateSpinner) {
            this.OFFSET += 1;
            this.showPaginateSpinner = true;
            this.groupSearchAndPagination(this.OFFSET);

            // setTimeout(() => {
            //     this.groupSearchAndPagination(this.OFFSET);
            // }, 3000);
        }
    };

    /**
     * Update user details
     * @param userVal
     */
    updateUserDetail(userVal) {
        try {

            const groupList = _.where(this.groupList, { is_changed: true });
            const groupIdList = groupList.map(group => {
                return Object.assign({},
                    {
                        group_id: group.group_id,
                        is_selected: group.is_selected
                    });
            });
            userVal.groupIdList = groupIdList;
            userVal.role_name = this.roleList.find(el=>el.role_id==userVal.user_role).role_name;
            const id = this.editedUserId;
            this.userService.updateUserDetail(id, userVal).subscribe(
                (user) => {
                    this.newUserForm.setValue(user['updatedUser']);
                    this.loader.hideLoader();
                    this.selectedIdList = [];
                    this.groupList.map(group => {
                        if (group.is_changed === true) {
                            if (group.is_selected) {
                                group.is_aleady_selected = true;
                            } else {
                                group.is_aleady_selected = false;
                            }
                            delete group.is_changed;
                        }
                    });
                    this.newUserForm.markAsPristine();
                    this.userComponent.getUserListSortAndSearch(0);
                    this.alert.showAlertMessage(user);
                },
                error => {
                    this.loader.hideLoader();
                    this.alert.showAlertMessage(error.error);
                }
            )
        } catch (err) {
            this.loader.hideLoader();
            console.log(err);
        }
    };

    /**
     * Add Group Section in modal window
    */
    addGroupSection() {
        this.toggleModalSection = !this.toggleModalSection;
        const groupList = _.findWhere(this.groupList, { is_changed: true });
        if (groupList) {
            this.newUserForm.markAsDirty();
        }
    };

    cancelGroupSection() {
        this.toggleModalSection = false;
        const groupList = _.findWhere(this.groupList, { is_changed: true });
        if (groupList) {
            this.selectedIdList = [];
            this.groupSearchAndPagination(0);
        } else {
            this.selectedIdList = [];
        }
    };

    /**
     * Clear Group Id list Selection
     */
    clearGroupSection() {
        const groupList = _.findWhere(this.groupList, { is_changed: true });
        if (groupList) {
            this.confirmDialogService.openConfirmDialog('',{
                title:'Confirmation',
                message:'Do you want to clear the modification?',
                btnOkText:'YES',
                btnCancelText:'NO',
                onClose: (data:any) => {
                    if(data){
                        this.selectedIdList = [];
                        this.groupSearchAndPagination(0);
                    } else {
                        return true;
                    }
                }	  
            });
            /* this._confirmation.create('Confirmation', 'Do you want to clear the modification?')
                .subscribe((ans: ResolveEmit) => {
                    if (ans.resolved) {
                        this.selectedIdList = [];
                        this.groupSearchAndPagination(0);
                    } else {
                        return true;
                    }
                }); */
        }
    };

    /**
     * Get file data and call file upload API
     * @param event
     */

    onFileChange(event) {
        const files = event.target.files;
        const selectedUserId = this.editedUserId;
        if (files && files.length > 0) {
            const formFields = {
                fieldName: 'user_profile',
                activity: 'user_profile_update',
                user_id: selectedUserId
            };

            this.selectedFileList.push(files[0]);
            this.fileValidationService.fileValidation(files, this.profileExtension, this.profileSize).then(validResponse => {
                this.appComponent.fileUploadWithProgess(formFields, this.selectedFileList).then((fileUpLoadResponse: any) => {
                    for (const key in fileUpLoadResponse) {
                        if (fileUpLoadResponse[key]) {
                            this.updatedUserDetail.user_icon = fileUpLoadResponse[key]['user_icon'];
                            this.userComponent.listOfUsers.find(user => {
                                if (user.user_id === selectedUserId) {

                                    return user.user_icon = fileUpLoadResponse[key]['user_icon'];
                                }
                            });
                        }
                        const successMsg = fileUpLoadResponse[key]['message'];
                        this.alert.showAlertMessage(successMsg);
                    }
                    this.selectedFileList = [];
                    this.fileInput.nativeElement.value = '';
                }).catch(error => {
                    this.selectedFileList = [];
                    this.fileInput.nativeElement.value = '';
                    this.alert.showAlertMessage(error.error);
                })
            }).catch(err => {
                this.fileInput.nativeElement.value = '';
                this.selectedFileList = [];
                err.map(li => this.alert.showAlertMessage(li));
            });
        }
    };



    /** Deactivate user profile */

    activateAndDeactiveUser = function () {
        try {

            const confirmMessage = this.acc_active ? 'deactivate' : 'activate'
            this.confirmDialogService.openConfirmDialog('',{
                title:'Confirmation',
                message:`Are you sure want to ${confirmMessage} user?`,
                btnOkText:'YES',
                btnCancelText:'NO',
                onClose: (data:any) => {
                  if(data){
                    const id = this.editedUserId;
                    const index = _.findIndex(this.userComponent.listOfUsers, { user_id: id });
                    this.userService.activateAndDeactiveUser(id).subscribe(
                        response => {
                          if(response['success']==true){
                            this.loader.hideLoader();
                            this.alert.showAlertMessage(response);
                            this.acc_active = response['acc_active'];
                            const userList = [...this.userComponent.listOfUsers];
                            this.userComponent.getUserListSortAndSearch(0);
                            const userObj = userList.find(el => el.user_id === id);
                            // userObj.acc_active = response['acc_active'];
                            userList[index] = userObj;
                            this.userComponent.listOfUsers = userList;
                            // window.location.reload();
                          }else{
                            this.loader.hideLoader();
                            this.alert.showAlertMessage(response);
                            this.acc_active = response['acc_active'];
                            const userList = [...this.userComponent.listOfUsers];
                            const userObj = userList.find(el => el.user_id === id);
                            // userObj.acc_active = response['acc_active'];
                            userList[index] = userObj;
                            this.userComponent.listOfUsers = userList;
                          }
                        },
                        error => {
                          console.log(error);

                            this.loader.hideLoader();
                            this.alert.showAlertMessage(error.error);
                        })
                    }else{
                    this.loader.hideLoader();
                    }
                }	  
            });
          /*  this._confirmation.create('Confirmation', `Are you sure want to ${confirmMessage} student?`)
                .subscribe((ans: ResolveEmit) => {
                    this.loader.showLoader();
                    if (ans.resolved) {
                        const id = this.editedUserId;
                        const index = _.findIndex(this.userComponent.listOfUsers, { user_id: id });
                        this.userService.activateAndDeactiveUser(id).subscribe(
                            response => {
                              if(response['success']==true){
                                this.loader.hideLoader();
                                this.alert.showAlertMessage(response);
                                this.acc_active = response['acc_active'];
                                const userList = [...this.userComponent.listOfUsers];
                                this.userComponent.getUserListSortAndSearch(0);
                                const userObj = userList.find(el => el.user_id === id);
                                // userObj.acc_active = response['acc_active'];
                                userList[index] = userObj;
                                this.userComponent.listOfUsers = userList;
                                // window.location.reload();
                              }else{
                                this.loader.hideLoader();
                                this.alert.showAlertMessage(response);
                                this.acc_active = response['acc_active'];
                                const userList = [...this.userComponent.listOfUsers];
                                const userObj = userList.find(el => el.user_id === id);
                                // userObj.acc_active = response['acc_active'];
                                userList[index] = userObj;
                                this.userComponent.listOfUsers = userList;
                              }
                            },
                            error => {
                              console.log(error);

                                this.loader.hideLoader();
                                this.alert.showAlertMessage(error.error);
                            }
                        )
                    }else{
                      this.loader.hideLoader();
                    }
                }); */
        } catch (err) {
            this.loader.hideLoader();
        }
    };


    removeUserDetail = function () {
         
        try {
            this.confirmDialogService.openConfirmDialog('',{
                title:'Confirmation',
                message:'Are you sure want to delete user?',
                btnOkText:'Yes',
                btnCancelText:'No',
                data: {},
                onClose: (data:any) => {
                  if(data){
                    this.loader.showLoader();
                        const id = this.editedUserId;
                        const index = _.findIndex(this.userComponent.listOfUsers, { user_id: id });
                        this.userService.deleteUserDetail(id).subscribe(
                            response => {
                                this.loader.hideLoader();
                                this.userComponent.viewSecondPanel = false;
                                this.userComponent.viewActionBtn = true;
                                this.userDetailView = false;
                                this.router.navigate(['/main/userandgroup/user']);
                                this.userComponent.getUserListSortAndSearch(0);
                                this.alert.showAlertMessage(response);
                            },
                            error => {
                                this.loader.hideLoader();
                                this.alert.showAlertMessage(error.error);
                            }
                        )
                  }else{
                    this.userComponent.viewSecondPanel = false;
                    this.userComponent.viewActionBtn = false;
                    // this.userDetailView = true;
                    this.loader.hideLoader();
                  }
                } 
              });
           /* this._confirmation.create('Confirmation', 'Are you sure want to delete student?', { confirmText: 'YES' })
                .subscribe((ans: ResolveEmit) => {
                    if (ans.resolved) {
                        this.loader.showLoader();
                        const id = this.editedUserId;
                        const index = _.findIndex(this.userComponent.listOfUsers, { user_id: id });
                        this.userService.deleteUserDetail(id).subscribe(
                            response => {
                                this.loader.hideLoader();
                                this.userComponent.viewSecondPanel = false;
                                this.userComponent.viewActionBtn = true;
                                this.userDetailView = false;
                                this.router.navigate(['/userandgroup/user']);
                                this.userComponent.getUserListSortAndSearch(0);
                                this.alert.showAlertMessage(response);
                            },
                            error => {
                                this.loader.hideLoader();
                                this.alert.showAlertMessage(error.error);
                            }
                        )
                    }else{
                      this.userComponent.viewSecondPanel = false;
                      this.userComponent.viewActionBtn = false;
                      // this.userDetailView = true;
                      this.loader.hideLoader();
                    }
                }); */
        } catch (err) {
            this.loader.hideLoader();
        }
    };

    /** Delete user profile Icon picture */
    deleteUserProfile() {
        try {
            this.loader.showLoader();
            const id = this.editedUserId;
            if (id) {
                this.userService.deleteUserProfile(id).subscribe(
                    response => {
                        this.loader.hideLoader();
                        this.alert.showAlertMessage(response);
                        this.updatedUserDetail.user_icon = response['user_icon'];
                        this.userComponent.listOfUsers.find(user => {
                            if (user.user_id === id) {
                                return user.user_icon = response['user_icon'];
                            }
                        });
                    },
                    error => {
                        this.loader.hideLoader();
                        this.alert.showAlertMessage(error.error);
                    }
                )
            }
        } catch (err) {
            this.loader.hideLoader();
            console.log(err);
        }
    };

    hideSecondPanel() {
        this.userComponent.viewSecondPanel = !this.userComponent.viewSecondPanel;
    };

    showUserHeadPanel(){
      this.userComponent.viewActionBtn = true;
    }

    closeUserAccountPanel() {
        this.hideUserPwdPanel = true;
        this.accountForm.reset();
    };

    openUserAccountPanel() {
        this.hideUserPwdPanel = false;
    };

    /**
     * Update user password
     * @param password
     */
    updateAccountPassword(password) {
        try {
            const user_id = this.editedUserId;
            if (password.new_password === password.confirm_password) {
                this.loader.showLoader();
                password.user_id = user_id;
                this.userService.userPassword(password).subscribe(
                    response => {
                        this.loader.hideLoader();
                        this.accountForm.reset();
                        this.alert.showAlertMessage(response);
                    },
                    error => {
                        this.loader.hideLoader();
                        this.alert.showAlertMessage(error.error);
                    }
                )
            } else {
                this.alert.showAlertMessage({
                    message: `The Password doesn't match`
                });
            }
        } catch (err) {
            this.loader.hideLoader();
            console.log(err);
        }

    };

}
