import { Directive, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[appInfiniteScroll]'
})
export class InfiniteScrollDirective {
    @Output() reachedBottom: EventEmitter<any> = new EventEmitter();
    @Output() reachedTop: EventEmitter<any> = new EventEmitter();

    constructor(public el: ElementRef) { }

    @HostListener('scroll', ['$event'])
    onScroll(event) {
        try {

            const top = event.target.scrollTop;
            const height = this.el.nativeElement.scrollHeight;
            const offset = this.el.nativeElement.offsetHeight;
            const clientHeight = this.el.nativeElement.clientHeight;


            // emit bottom event
            if (top > height - offset - 1) {
                this.reachedBottom.emit(event);
            }

            // if(top < height = clientHeight){
            //   this.reachedBottom.emit(event);
            // }

            // emit top event
            if (top <= 2) {
                this.reachedTop.emit(event);
            }

        } catch (err) { }
    }

}

