import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './auth-service.service';
import { AuthGuardService } from './auth-guard.service';
import { AuthInterceptor } from './auth-interceptor.service';

import { AppComponent } from './app.component';
import { LoginModule } from './components/login/login.module';
import { ForgotModule } from './components/forgot-password/forgot.password.module';
import { SignupModule } from './components/signup/signup.module';
import { PasswordResetModule } from './components/password-reset/passwod-reset.module';
import { HeaderService } from './components/header/header.service';
import { MenuService } from './components/menu-options/menu.serive';
import { config, AlertPopup, HttpLoader } from './app.config';
// import { HintModule } from 'angular-custom-tour'
// import {TooltipModule} from 'ng2-tooltip-directive';
// import {TooltipModule} from 'ngx-tooltip';

import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { AudioReceiverView } from './components/chat-list/chat-message/audio-receiver-view/audio-receiver.component'
import { MenuOptionsComponent } from './components/menu-options/menu-options.component';
import { ChatListModule } from './components/chat-list/chat-list.module';
import { UserandgroupModule } from './components/userandgroup/userandgroup.module';
import { NotificationModule } from './components/notification/notification.module';
import { SettingsModule } from './components/settings/settings.module';
import { HelpModule } from './components/help/help.module';
import { PrivacyModule } from './components/privacy/privacy.module';
import { VerificationModule } from './components/verification/verification.module';

// Connection Menu
import { ConnectionsComponent } from './components/connections/connections.component';
import { ConnectionDetailComponent } from './components/connections/connection-detail/connection-detail.component';


import { SocketService } from './socket/socket.service';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { UtilityService } from './utility.service';
import { AlertService } from './notification-alert/alert.service';
import { AlertComponent } from './notification-alert/alert.component';
 

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { PlayerComponent } from './components/media-player/media-player-component';
import { PlayerService } from './components/media-player/media-player-service';

import { BotComponent } from './ibm-bot/bot.component';
import { BotService } from './ibm-bot/bot.service';

import { PushNotificationsService } from './push-notification/push-notification-service';
import { TooltipDirective } from './shared/tooltip.directive';
import { SearchComponent } from './components/search/search.component';
import { SearchService } from './components/search/search.service';
import { AttendanceService } from './components/attendance/attendance.service';
// import { AttendanceComponent } from './components/attendance/attendance.component';

// import { ChatBotModule } from './components/chatbot/chatbot.module';

import { NgSelectModule } from '@ng-select/ng-select';
import { AttendanceModule } from './components/attendance/attendance.module';
import { HomeworkModule } from './components/homework/homework.module';
import { HomeworkService } from './components/homework/homework.service';
import { StaffRoomModule } from './components/staff-room/staff-room.module';
import { StudyRoomModule } from './components/study-room/study-room.module';
import { StaffRoomService } from './components/staff-room/staff-room.service';
// import { ELibraryModule } from './components/e-library/e-library.module';
// import { ELibraryService } from './components/e-library/e-library.service';
import { ElibraryandcourseModule } from './components/elibraryandcourse/elibraryandcourse.module';
import { environment } from 'environments/environment';
import { AiAssistModule } from './components/ai-assist/ai-assist.module';
import { VideoQuizModule } from './components/video-quiz/video-quiz.module';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { TabsModule } from './core/tabs/tabs.module';
import { ThemeService } from './components/settings/theme.service';
import { SubscriptionModule } from './components/subscription/subscription.module';
import { NgxStripeModule } from 'ngx-stripe';
import { ListdialogComponent } from './core/listdialog/listdialog.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { FeedbackModule } from './components/feedback/feedback.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LayoutComponent } from './views/layout/layout.component';
import { AppRoutingModule } from './app.routing.module';
import { LayoutModule } from './views/layout/layout.module';
 


// export const routes: Routes = [
//   { path: '', redirectTo: 'login', pathMatch: 'full' },
//   // {
//   //     path: 'connections', component: ConnectionsComponent, children: [
//   //         { path: ':id', component: ConnectionDetailComponent }]
//   // }
// ];



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SearchComponent,
    HomeComponent,
    MenuOptionsComponent,
    ConnectionsComponent,
    ConnectionDetailComponent,
    LoaderComponent,
    AlertComponent,
    PlayerComponent,
    BotComponent,
    TooltipDirective,
    AudioReceiverView,
    ListdialogComponent,
   ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
  //  RouterModule.forRoot(routes, { useHash: true }),
  AppRoutingModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    LoginModule,
    PrivacyModule,
    SignupModule,
    AttendanceModule,
    HomeworkModule,
    StaffRoomModule,
    StudyRoomModule,
    // ELibraryModule,
   // ElibraryandcourseModule,
    // BotModule,
    VerificationModule,
    ForgotModule,
    PasswordResetModule,
    UserandgroupModule,
   // ChatListModule,
    SettingsModule,
   // NotificationModule,
    HelpModule,
    // HintModule,
  //   AiAssistModule,
    VideoQuizModule,
    LayoutModule,
    // ChatBotModule
    DragDropModule,
    GuidedTourModule,
    SubscriptionModule,
    FeedbackModule,
    NgxStripeModule.forRoot()
  ],
  providers: [SocketService, HeaderService, SearchService, MenuService, AuthService, AuthGuardService, LoaderService, AlertPopup, HttpLoader,
    UtilityService, AlertService,  BotService, AttendanceService, HomeworkService, StaffRoomService,GuidedTourService,
    [{
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }],
    PlayerService,
    PushNotificationsService,
    ThemeService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
