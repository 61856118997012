import { RouterModule, Routes } from "@angular/router";
import { ELibraryComponent } from './e-library/e-library.component';
import { ELibraryDetailsComponent } from './e-library/e-library-details/e-library-details.component';
import { PurchaseCourseComponent } from './purchase-course/purchase-course.component';
import { path } from 'app/app.config';
import { PurchaseCourseDetailsComponent } from './purchase-course/purchase-course-details/purchase-course-details.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewCourseComponent } from "./e-library/new-course/new-course.component";

const routes: Routes = [
  {path: '', redirectTo: 'e-library', pathMatch: 'full'},
  {
    path: 'e-library', component: ELibraryComponent, children:[
      { path: 'newclass', component: NewCourseComponent },
      {path: ':id', component:  ELibraryDetailsComponent}
      ]
    },
    {
      path: 'elibraryandcourse/purchase-course', component: PurchaseCourseComponent, children: [
        {
          path: ':id', component: PurchaseCourseDetailsComponent
        }
      ]
    }
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  declarations: [],
  exports: [RouterModule]
})

export class ElibraryandcourseRoutingModule { }
