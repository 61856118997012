import { Component, OnInit, Input, AfterViewInit, OnDestroy, OnChanges, DoCheck, EventEmitter, Output, Directive, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef  } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { ChatService } from '../chat.service';
import { SocketService } from './../../../socket/socket.service';
import { config, path, AlertPopup } from './../../../app.config';
import { SecureFileService } from './../../../secure-image/secure-image-service';
import { DataSharingService } from './../../../shared/data-sharing.service';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import { ChatMessageComponent } from '../chat-message/chat-message.component';
import { HomeworkService } from 'app/components/homework/homework.service';
import { error } from 'protractor';
import { BehaviorSubject, Subscription } from 'rxjs';


// @Directive()
@Component({
  selector: 'app-group-userlist',
  templateUrl: './group-userlist.component.html',
  styleUrls: []
})
export class GroupUserlistComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {

 
  @Input() groupDetail: any;
  @Input() userDetail: any;
  @Input() events: Observable<void>;
  @Input() isGroupChat: boolean;
  @Input() audioUser: boolean;
  @Input() pinId : any;
  @Output() onClose = new EventEmitter;
  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  FILEOFFSET: number = config.DEFAULT_OFFSET;
  LINKOFFSET: number = config.DEFAULT_OFFSET;
  TASKOFFSET: number = config.DEFAULT_OFFSET;
  search: string;
  private eventsSubscription: Subscription;

  showMemberList:boolean=true;
  showMemberListBS:BehaviorSubject<any>=new BehaviorSubject(false);
  // _domControl = {

  // };
  searchInList = new Subject<any>();
  profileUrl = path.PROFILE_URL;
  groupMemberList: any[] = [];
  nonGroupUserList: any[] = [];
  filesList: any[] = [];
  linksList: any[] = [];
  tasksList: any[] = [];
  tasksDetails : any[] = [];
  selectedUserIdList: number[] = [];
  isActiveClass = false;
  fileList = false;
  totalRowCount = 0;
  totalGroupRowCount = 0;
  totalFileRowCount = 0;
  totalLinkRowCount = 0;
  totalTaskRowCount = 0;
  tab_name = null;
  userRole = 1;
  isActiveSideBar:boolean = false;

  showPaginateSpinner = false;
  selectedStatus ="";

  public userSubscribers: any = {};
  showTaskData=[];
  isUserRole:any;

  taskStatus = ['assigned', 'in progress', 'completed'];


  taskStatusColor = {
    "0" : "is-info",
    "1" : "is-primary",
    "2" : "is-success",
    "3" : "is-danger"
  };


  constructor(
    private route: ActivatedRoute,
    private chatService: ChatService,
    private homeworkService: HomeworkService,
    private socketService: SocketService,
    private alert: AlertPopup,
    private chatMessage: ChatMessageComponent,
    private secureFileService: SecureFileService,
    private dataSharingService: DataSharingService) {
      this.searchInList
      .debounceTime(500)
      .subscribe(event => {
        this.search = event.target.value;
        // if (!this.search) {
        //   return;
        // }
        // this.viewControl.changedSearchText = true;
        this.getNonGroupMembers(this.groupDetail.chat_group_id,0);
      });

     }

  ngOnInit() {

    this.eventsSubscription = this.events.subscribe();

    if (this.isGroupChat) {
      this.tab_name = 'users';
      this.route.paramMap.switchMap((params: ParamMap) =>
        this.chatService.getGroupMemberList({
          offset: this.OFFSET,
          limit: this.RECORD_LIMIT,
          groupId: +params.get('id')
        })).subscribe(
        memberList => {
          this.groupMemberList = memberList['groupMemberList'];
          this.totalRowCount = memberList['TotalRowCount'];
          this.isActiveClass = true;
        },
        error => {
          this.alert.showAlertMessage(error.error);
        });
    } else if(this.audioUser) {
      this.tab_name = 'users';
      this.groupDetail = {
        chat_group_id: 1,
        can_edit: true
      }
      this.chatService.getGroupMemberList({
        offset: this.OFFSET,
        limit: this.RECORD_LIMIT,
        groupId: 1
      }).subscribe(
      memberList => {
        this.groupMemberList = memberList['groupMemberList'];
        this.totalRowCount = memberList['TotalRowCount'];
        this.isActiveClass = true;
      },
      error => {
        this.alert.showAlertMessage(error.error);
      });
    } else {

      const tabName = this.chatMessage.isActiveTabName;

      if(this.tab_name === 'files'){
        // this.tab_name = 'files';
        this.getMessageAttachmentList(this.tab_name, 0);
      }else if(this.tab_name === 'links'){
        // this.tab_name = 'links';
      this.getMessageAttachmentLink(this.tab_name, 0);
      }else if(this.tab_name === 'tasks'){
        // this.tab_name = 'tasks';
      this.getMessageAttachmentTask(this.tab_name, 0);
      }
    }

    this.isUserRole = window.localStorage.getItem('user_role');
    this.isUserRole = parseInt(this.isUserRole.replace(/\"/g, ""));

    this.onClose.emit();
    this.showMemberListBS.next(true);
    this.showMemberListBS.subscribe((value:any)=>{
      this.showMemberList = value;
      console.log("do init",this.showMemberList);
    });
    

  }




  // ngAfterViewInit() {
  //   this.socketService.receiveUserStatus().subscribe(response => {
  //     const userConnection = this.groupMemberList.find(user => {
  //       if (user.chat_type === 'user' && user.user_id === response.id) {
  //         return user.is_online = !response.userDisconnected;
  //       }
  //     });
  //     if (userConnection) {
  //       userConnection.last_seen = new Date();
  //     }
  //   });
  // };


  // this.groupMemberList
  ngAfterViewInit(): void {
    this.userSubscribers.userStatusSub = this.dataSharingService.getUserStatusNotify().subscribe(userStatus => {
      const copyUserList = [...this.groupMemberList];
      const findIndex = copyUserList.findIndex(el => el.user_id === userStatus.id);
      if (findIndex >= 0) {
        const copyObj = copyUserList[findIndex];
        copyObj.is_online = !userStatus.userDisconnected;
        copyObj.last_seen = userStatus.t;
        copyObj.status_name = userStatus.status_name;
        copyObj.user_status = userStatus.user_status;
        copyUserList[findIndex] = copyObj;
      }
      this.groupMemberList = copyUserList;

      const copyNonGroupUserList = [...this.nonGroupUserList];
      const index = copyNonGroupUserList.findIndex(el => el.user_id === userStatus.id);
      if (index >= 0) {
        const copyObj = copyNonGroupUserList[index];
        copyObj.is_online = !userStatus.userDisconnected;
        copyObj.last_seen = userStatus.t;
        copyNonGroupUserList[index] = copyObj;
      }
      this.nonGroupUserList = copyNonGroupUserList;
    });

    this.userSubscribers.statusUpdate = this.dataSharingService.getUserstatusUpdate().subscribe(userStatus => {
      const copyUserList = [...this.groupMemberList];
      const findIndex = copyUserList.findIndex(el => el.user_id === userStatus.user_id);
      if (findIndex >= 0) {
        const copyObj = copyUserList[findIndex];
        copyObj.status_name = userStatus.status_name;
        copyObj.user_status = userStatus.status_id;
        copyUserList[findIndex] = copyObj;
      }
      this.groupMemberList = copyUserList;

    });


    this.userSubscribers.taskNotify = this.dataSharingService.getPintaskNotify().subscribe(pintask => {
      // const pinTaskList = [...this.groupMemberList];
      // const index = pinTaskList.findIndex(el => el.user_id === pintask.id);
      // if (index >= 0) {
      //   pinTaskList[index] = pintask;
      // }
      this.tasksList = pintask;
    });

  }


  ngDoCheck(): void {

    const activeTabName = this.chatMessage.isActiveTabName;

    if(activeTabName){
      this.tab_name === activeTabName;
      this.selectPanelMenu(activeTabName);
    }

    console.log("do check",this.showMemberList);
  }



  addNewMember(chat_group_id,offset) { 
    this.showMemberListBS.next(false);
    this.getNonGroupMembers(chat_group_id,offset);
  };

  cancelNewGroupMemebers() {
    this.showMemberListBS.next(true);
    this.search = null;
  }

  selectPanelMenu(tab_name) {

   // this.showMemberListBS.next(true);
    this.tab_name = tab_name;

    if (tab_name === 'files' && this.filesList.length === 0) {
      this.getMessageAttachmentList(tab_name, 0);
    }

    if (tab_name === 'links' && this.linksList.length === 0) {
      this.getMessageAttachmentLink(tab_name, 0);
    }
    if (tab_name === 'tasks' && this.tasksList.length === 0) {
      this.getMessageAttachmentTask(tab_name, 0);
    }
  }

  closeSideBarTab(tab_name){
    this.chatMessage._domParams.showUserList = false;
    this.chatMessage.hidechatContent = false; 
  }


  getAllGroupMemebersList(groupId, pageNo) {
    if (pageNo === 0) {
      this.OFFSET = 0;
    }

    const offset = this.OFFSET;
    const payLoad = {
      offset: offset,
      limit: this.RECORD_LIMIT,
      search: this.search || '',
      groupId: groupId
    };

    this.chatService.getGroupMemberList(payLoad).subscribe(
      response => {
        this.showPaginateSpinner = false;
        if (this.OFFSET === 0) {
          this.groupMemberList = response['groupMemberList'];
        } else {
          this.groupMemberList = this.groupMemberList.concat(response['groupMemberList'])
        }
      },
      error => {
        this.showPaginateSpinner = false;
        this.alert.showAlertMessage(error.error);
      }
    )

  };


  /** Get Non group memebers list */
  getNonGroupMembers(groupId, pageNo) {

    try {

      if (pageNo === 0) {
        this.OFFSET = 0;
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: this.search || '',
        groupId: groupId
      };

      this.chatService.getNonGroupMemberList(payLoad).subscribe(
        response => {
          this.showPaginateSpinner = false;
          if (this.OFFSET === 0) {
            this.nonGroupUserList = response['memberList'];
            this.totalGroupRowCount = response['TotalRowCount'];
          } else {
            this.showPaginateSpinner = false;
            this.nonGroupUserList = this.nonGroupUserList.concat(response['memberList']);

            console.log(this.nonGroupUserList);

          }
        },
        error => {
          this.alert.showAlertMessage(error.error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  /** Get Message Attachment Files and Links List */

  getMessageAttachmentList(tab_name, offset) {

    const payLoad: any = {
      offset: offset,
      limit: this.RECORD_LIMIT,
      search: this.search || '',
      userId: this.userDetail.user_id,
      groupId: this.groupDetail.chat_group_id
    }

    if (tab_name === 'files') {
      this.chatService.getMessageAttachments(payLoad).subscribe(
        response => {
          this.showPaginateSpinner = false;
          if (offset === 0) {
            this.filesList = response['recordList'];
          } else {
            this.filesList = this.filesList.concat(response['recordList']);
          }
          this.totalFileRowCount = response['TotalRowCount'];
        },
        error => {
          this.showPaginateSpinner = false;
          this.alert.showAlertMessage(error.error)
        });
    }



  };

  getMessageAttachmentLink(tab_name, offset){
    const payLoad: any = {
      offset: offset,
      limit: this.RECORD_LIMIT,
      search: this.search || '',
      userId: this.userDetail.user_id,
      groupId: this.groupDetail.chat_group_id
    }

    if (tab_name === 'links') {
      this.chatService.getMessageLinks(payLoad).subscribe(
        response => {
          this.showPaginateSpinner = false;
          if (offset === 0) {
            this.linksList = response['linkList'];
          } else {
            this.linksList = this.linksList.concat(response['linkList']);
          }
          this.totalLinkRowCount = response['TotalRowCount'];
        },
        error => {
          this.showPaginateSpinner = false;
          this.alert.showAlertMessage(error.error)
        });
    }
  }

  getMessageAttachmentTask(tab_name, offset){

    const payLoad: any = {
      offset: offset,
      limit: this.RECORD_LIMIT,
      search: this.search || '',
      userId: this.userDetail.user_id,
      groupId: this.groupDetail.chat_group_id
    }

    if (tab_name === 'tasks') {

      console.log(this.isGroupChat);


      if (this.isGroupChat) {
        this.homeworkService.getGroupTaskLists(payLoad).subscribe(
          response => {
            this.showPaginateSpinner = false;
            if (offset === 0) {
              this.tasksList = response['pinDetails'];
            }else{
              this.tasksList = this.tasksList.concat(response['pinDetails']);
            }
          },
          error => {
            console.log(error.error);
            this.showPaginateSpinner = false;
          }
        )

      }else{
        this.homeworkService.getPinTaskLists(payLoad).subscribe(
          response => {
            this.showPaginateSpinner = false;
            if(offset === 0){
              this.tasksList = response['pinDetails'];
              this.alert.showAlertMessage(response['message']);
            }else{
              this.tasksList = this.tasksList.concat(response['pinDetails']);
            }
            this.totalTaskRowCount = response['TotalRowCount'];
          },
          error => {
            this.showPaginateSpinner = false;
            this.alert.showAlertMessage(error.error);
          })
      }
    }
  }


  /** Select new group memebers */

  selectNewGroupMembers(userId) {
    if (this.selectedUserIdList.indexOf(userId) === -1) {
      this.selectedUserIdList.push(userId);
    } else {
      const fiteredUserId = this.selectedUserIdList.filter(id => id !== userId);
      this.selectedUserIdList = [...fiteredUserId];
    }
  };


  /** Save selected user in Group */
  saveAddedGroupMembers(groupId) {
    console.log("groupid",groupId);
    try {
      if (this.selectedUserIdList && this.selectedUserIdList.length > 0) {
        const payLaod = {
          groupId: groupId,
          userIds: this.selectedUserIdList
        };

        this.chatService.addGroupMembers(payLaod).subscribe(
          response => {
            this.nonGroupUserList = this.nonGroupUserList.filter(el => {
              return this.selectedUserIdList.indexOf(el.user_id) === -1;
            });

            this.selectedUserIdList = [];
            const existingGroupList = [...this.groupMemberList];
            this.groupMemberList = existingGroupList.concat(response['membersList']);
            this.showMemberListBS.next(true);
            this.alert.showAlertMessage(response);
           // this.getAllGroupMemebersList(groupId,0);
          },
          error => {
            this.alert.showAlertMessage(error.error);
            this.showMemberListBS.next(true);
           // this.getAllGroupMemebersList(groupId,0);
          }
        )
      }
    } catch (err) {
      console.log(err);
    }
  };


  /** Remove group memebers */
  removeGroupMemebers(groupId, userId) {
    try {

      if (this.groupMemberList.length > 1) {
        const payLaod = {
          groupId: groupId,
          userIds: [userId]
        };

        this.chatService.removeGroupMembers(payLaod).subscribe(
          response => {
            const findedUserDetail = this.groupMemberList.find(user => user.user_id === userId);
            const remainingGroupMembers = this.groupMemberList.filter(el => {
              return el.user_id !== userId;
            });

            this.groupMemberList = remainingGroupMembers;
            this.alert.showAlertMessage(response);
          },
          error => {
            this.alert.showAlertMessage(error.error);
          }
        )
      } else {
        this.alert.showAlertMessage({
          message: 'Cannot delete all the Users, At least one User needed in the Group/Lesson'
        });
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };


  onScrollDown(groupId, userId, fn_name) {

    if (fn_name === 'allMemebers') {
      if ((this.groupMemberList.length < this.totalRowCount) && !this.showPaginateSpinner) {
        this.showPaginateSpinner = true;
        this.OFFSET += 1;
        this.getAllGroupMemebersList(groupId, this.OFFSET);
      }
    } else if (fn_name === 'newMembers') {
      if ((this.nonGroupUserList.length < this.totalGroupRowCount) && !this.showPaginateSpinner) {
        this.showPaginateSpinner = true;
        this.OFFSET += 1;
        this.getNonGroupMembers(groupId, this.OFFSET);
      }
    } else if (fn_name === 'files') {
      if ((this.filesList.length < this.totalFileRowCount) && !this.showPaginateSpinner) {
        this.showPaginateSpinner = true;
        this.FILEOFFSET += 1;
        this.getMessageAttachmentList(fn_name, this.FILEOFFSET);
      }
    } else if (fn_name === 'links') {
      if ((this.linksList.length < this.totalLinkRowCount) && !this.showPaginateSpinner) {
        this.showPaginateSpinner = true;
        this.LINKOFFSET += 1;
        this.getMessageAttachmentLink(fn_name, this.LINKOFFSET);
      }
    }else if(fn_name === 'tasks') {
      if ((this.tasksList.length < this.totalTaskRowCount) && !this.showPaginateSpinner) {
        this.showPaginateSpinner = true;
        this.TASKOFFSET += 1;
        this.getMessageAttachmentTask(fn_name, this.TASKOFFSET);
      }
    }

  };


  // secureFileDownLoad(filepath: string, fileId: string) {
  //   try {
  //     const url = filepath;
  //     if (url) {
  //       this.secureFileService.secureFileDownLoad(url).subscribe(blob => {
  //         importedSaveAs(blob, fileId);
  //       }, error => this.alert.showAlertMessage(error.error))
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };


  secureFileDownLoad(fileData: any) {
    try {
        const url = fileData.download_url;
        if (url) {
            fileData.startFileDownLoad = true;
            this.secureFileService.secureFileDownLoad(url).subscribe(blob => {
                fileData.startFileDownLoad = false;
                importedSaveAs(blob, fileData.file_id);
            }, error => {
                fileData.startFileDownLoad = false;
                this.alert.showAlertMessage(error.error)
            })
        }
    } catch (err) {
        fileData.startFileDownLoad = false;
        console.log(err);
    }
};

getTaskDetails(id, groupId){
  this.onClose.emit(false);
  if(this.isGroupChat){
    const payLoad = {
      'pinId': id,
      'groupId': groupId
    }
    this.homeworkService.getGroupTaskView(payLoad).subscribe(response =>{
      this.pinId = id;
      this.showTaskData[id] = true;
      this.tasksDetails = response['pinDetails'];
    })
  }else{
    this.homeworkService.getPinTask(id).subscribe(response => {
      console.log(response);

      this.pinId = id;
      this.showTaskData[id] = true;
      this.tasksDetails = response['pinDetails'];
    })
  }


}


updatePinTaskStatus(id, event){

  if (window.confirm("Are you sure you want update task status?")){
    try {
      const payLoad = {
        'pin_id' : id,
        'status' : event.target.value
      }

      this.homeworkService.updateTaskStatus(payLoad).subscribe(response=> {
        this.showTaskData[id] = false;
        this.alert.showAlertMessage(response);
      })
    } catch (error) {
      this.alert.showAlertMessage(error.error);
    }
  }else {
    return false;
  }
}

closeModalTask(id){
  this.showTaskData[id] = false;
  this.onClose.emit(false);
}

ngOnDestroy() {
  this.searchInList.unsubscribe();
  this.eventsSubscription.unsubscribe();
}

}
