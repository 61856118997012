import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SubscriptionComponent } from './subscription.component';
import { AuthGuardService as AuthGuard } from './../../auth-guard.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { SubscriptionRoutingModule } from './subscription-routing.module';
import { ValidationService } from '../form-validation/validation.service';
import { SubscriptionService } from './subscription.service';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'environments/environment';
import { NgxPayPalModule } from 'ngx-paypal';
 


@NgModule({
  declarations: [
    SubscriptionComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SubscriptionRoutingModule,
    NgxStripeModule.forRoot(environment.stripe.publicKey),
    NgxPayPalModule
  ],
  providers: [
    ValidationService,
    SubscriptionService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class SubscriptionModule { }
