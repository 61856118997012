import { Component, OnInit, EventEmitter, Output, ElementRef, HostListener, Directive  } from '@angular/core';

// import { emojis, EmojiEvent, EmojiService, CompressedEmojiData } from 'lib/picker/ngx-emoji';

import { EmojiEvent } from 'lib/emoji/public_api';
import { EmojiService } from 'lib/picker/ngx-emoji';


// @Directive()
@Component({
  selector: 'app-emojimart',
  templateUrl: './emojimart.component.html',
  styleUrls: ['./emojimart.component.scss']
})
export class EmojimartComponent implements OnInit {

  @Output()
  public pickedEmoji: EventEmitter<any> = new EventEmitter();
  emojimartToggle = false;
  emojisList = [];
  set = 'google';
  native = false;

  constructor(
    private _elementRef: ElementRef,
    private emojiService: EmojiService
  ) {

  }

  ngOnInit(): void {

  }


  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement){

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);

    if(!clickedInside){
      this.emojimartToggle = false;
    }else{
      this.emojimartToggle = true;
    }
  };

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent){

    if (event.keyCode === 27) {
      this.emojimartToggle = false;
    }

    if (event.keyCode === 13) {
      this.emojimartToggle = false;
    }
  };


  toggleEmojiMart(){
    this.emojimartToggle = !this.emojimartToggle;
  }


  appendEmojiText($event: EmojiEvent) {

    const emojiMart = $event.emoji.native;

    if (!emojiMart) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    const appendEmoji = `${emojiMart}`;
    this.pickedEmoji.emit(appendEmoji);

  }

  emojiFilter(e: string): boolean {
    // Can use this to test [emojisToShowFilter]
    if (e && e.indexOf && e.indexOf('1F4') >= 0) {
      return true;
    }
    return false;
  }


}
