import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { AiAssistModule } from 'app/components/ai-assist/ai-assist.module';
import { HeaderService } from 'app/components/header/header.service';
import { SocketService } from 'app/socket/socket.service';

@Component({
  selector: 'app-layout',
   templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  userDetail:any;
  menuListItem:any;
  userSetting:any;
  constructor(  private socketService: SocketService,
    private headerService: HeaderService,@Inject(DOCUMENT) private document:Document,){
   
  }

  ngOnInit(){
   
  this.getCurrentUserData();
  }

  getCurrentUserData() {
 
    try {
      this.headerService.getCurrentUserDetail().subscribe(
        user => {
         // this.companyid = user['userDetail']['company_id'];
          window.localStorage.setItem('username', user['userDetail']['first_name']);

          const companyDetail = user['company_details'];

          if (companyDetail!==null) {
            const company = {
              company_logo: companyDetail['company_logo'],
              title: companyDetail['title'],
              caption: companyDetail['caption']
            }

            this.userDetail = {...company, ...user['userDetail']};
          } else {
            this.userDetail = user['userDetail']
          }

         // this.is_tour_completed = !this.userDetail.is_tour_completed;
         
          this.userSetting = user['settingsDetail'];
         let modified_menu = user['menuList'].map((list:any)=>{
          let menuchange:any={}
            for (const key in list) {
              if (Object.prototype.hasOwnProperty.call(list, key)) {
                //const element = object[key];
                menuchange[key]=list[key];
                if(key=='menu_name'){
                  menuchange['path']='main/'+list[key];
                }
                
              }
            }
            return menuchange;
        });
         console.log("menulist modified_menu",modified_menu)
          this.menuListItem = modified_menu;
         // this.socketService.initiateSocket();
         // this.observableRefresh();

          const newtoken = window.localStorage.token
          // if(this.currentToken !== newtoken) {
          //  this.loginstatus();
          // }
          // this.router.navigate(['/chats']);
          // if(this.is_tour_completed){
          //   setTimeout(()=>{
          //     this.startTour();
          //   },1500);
        
          // }
        },
        error => {
          const isLogout = error.error['logout'];
          if(isLogout===true){
          //  this.router.navigate(['/login']);
          }
        })
    } catch (err) {
      const isLogout = err.error['logout'];
      if(isLogout===true){
       // this.router.navigate(['/login']);
      }
    }
  }

  toggleSideMenu(){
    let sidebar = this.document.querySelector('.sidebar');
     sidebar.classList.toggle('show');
  }

  

}
