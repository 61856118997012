import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { Observable, fromEvent, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { AssistBotService } from '../assist-bot.service';

declare const google: any;

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent {
  @ViewChild('autoCompleteInput', { static: false }) autoCompleteInput: ElementRef;
  @Input() suggestionList: any = [];
  @Output() setautoComplete = new EventEmitter<{ data: string, action: string }>();
  @Input() autoSearchDafaultValue;
  @Input() defaultSelectLanguage;
  @Input() botType;
  @Input() suggestionEnable;

  showSearches: boolean = false;
  isSearching: boolean = false;
  searchedList: any = [];
  placeholderText = "Ask Question";
  constructor(private renderer: Renderer2, private assistBotService: AssistBotService) {
    this.searchedList = this.suggestionList;

    this.renderer.listen('window', 'click', (e: any) => {
      /**
       * only run when togglebutton is not clicked
       * if we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * and the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (e.target !== this.autoCompleteInput.nativeElement) {
        this.showSearches = false;
      }
    });
  }



  ngOnInit() {

    this.showSearches = false;
    setTimeout(() => {
      this.Search();
    }, 2000);

    this.assistBotService.searchListBheaviour.subscribe((result: any) => {
      // console.log("result",result);

      if (result.length > 0) {
        this.searchedList = result;
        this.showSearches = true;
      }
      else {
        this.showSearches = true;
        this.isSearching = false;
      }
    });

     

  }

 ngAfterViewInit(){
  setTimeout(()=>{
    this.autoCompleteInput.nativeElement.focus();},2000);
 }
  ngAfterViewChecked() {
    if (this.defaultSelectLanguage === "hindi") {
      this.placeholderText = "प्रश्न पूछो";
    } else if (this.defaultSelectLanguage === "spanish") {
      this.placeholderText = "pregunta";
    } else {
      this.placeholderText = "Ask Question";
    }
  }

  getList(name): Observable<any> {
    return of(this.filterList(name))
  }

  filterList(name) {

    return this.suggestionList.filter((val: any) => val['question'].trim().toLowerCase().includes(name.trim().toLowerCase())).map((data: any) => data['question']);
  }

  Search() {

    const search$ = fromEvent(this.autoCompleteInput.nativeElement, 'keyup').pipe(
      map((event: any) => this.autoSearchDafaultValue = event.target.value),
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => this.isSearching = true),
      switchMap((term) => term ? this.getList(term) : of<any>(this.suggestionList)),
      tap(() => {
        this.isSearching = false;
        this.showSearches = true;
      }));

    search$.subscribe(data => {
      this.isSearching = false;
      if (this.autoSearchDafaultValue) {
        this.searchedList = data;
      } else {
        this.searchedList = data.map((data: any) => data['question']);
      }
    });
  }

  setSelected(data: string) {
    this.searchedList = this.filterList(data);
    this.setautoComplete.emit({ 'data': data, 'action': 'select' });
    this.autoCompleteInput.nativeElement.value = data;
    this.showSearches = false;
  }

  trackById(index: any, item: any): void {
    return item._id;
  }

  closeDropDown(): void {
    this.showSearches = false;
  }

  onKeypressEvent(event: any) {
    this.autoSearchDafaultValue = event.target.value;
    this.setautoComplete.emit({ 'data': this.autoSearchDafaultValue, 'action': 'Keypress' });
    if ((event.code == "Enter") || (event.code == "NumpadEnter")) {
      this.showSearches = false;
      this.setautoComplete.emit({ 'data': this.autoSearchDafaultValue, 'action': 'Enter' });
    }
  }

  clear() {
    this.autoSearchDafaultValue = "";
    this.setautoComplete.emit({ 'data': this.autoSearchDafaultValue, 'action': 'Keypress' });
  }

}
