import { LoaderService } from './../../loader/loader.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { config, BASE_URI } from '../../app.config';


@Injectable()

export class NotificationService {

    BASE_URL = BASE_URI.URL;

    constructor(private http: HttpClient, private loaderService: LoaderService) { }

    private showLoader(): void {
        this.loaderService.showLoader();
    };
    private hideLoader(): void {
        this.loaderService.hideLoader();
    };

    getPostUserList(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.BASE_URL}/postsUserList`, { params: Params });
    };

    createPost(notification) {
        this.showLoader();
        return this.http.post(`${this.BASE_URL}/posts`, notification);
    };

    getPosts(payLoad) {
        this.showLoader();
        return this.http.get(`${this.BASE_URL}/posts?offset=${payLoad.offset}&limit=${payLoad.limit}&search=${payLoad.search}&sort=${payLoad.sort}`);
    };

    updatePosts(post) {
        this.showLoader();
        return this.http.put(`${this.BASE_URL}/posts`, post);
    };

    getPostDetail(postId: number) {
        this.showLoader();
        return this.http.get(`${this.BASE_URL}/posts/${postId}`);
    };

    createSubPost(subPost) {
        this.showLoader();
        return this.http.post(`${this.BASE_URL}/subposts`, subPost)
    };

    getSubPostList(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.BASE_URL}/subposts`, { params: Params });
    };


    updateSubPosts(subPost) {
        this.showLoader();
        return this.http.put(`${this.BASE_URL}/subposts`, subPost);
    };

    createComment(comment) {
        this.showLoader();
        return this.http.post(`${this.BASE_URL}/comments`, comment);
    };

    getComment(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.BASE_URL}/comments`, { params: Params });
    };

    createNewSubComment(subComment) {
        return this.http.post(`${this.BASE_URL}/subcomments`, subComment);
    };

    getAllSubComment(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.BASE_URL}/subcomments`, { params: Params });
    };

    getGroupList() {
        this.showLoader();
        return this.http.get(`${this.BASE_URL}/chats`);
    };

    updateSubPostReadStatus(payLoad) {
        this.showLoader();
        return this.http.put(`${this.BASE_URL}/subpoststatus`, payLoad);
    };

    getPostSentStatus(post_id) {
        this.showLoader();
        return this.http.get(`${this.BASE_URL}/postSentStatus?post_id=${post_id}`);
    };




    preHttpParams(payLoad) {
        let Params = new HttpParams();
        for (const key in payLoad) {
            if (key && (payLoad[key] || payLoad[key] === 0)) {
                Params = Params.append(key, payLoad[key]);
            }
        }
        return Params;
    };

}
