import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';

import { AttendanceRoutingModule } from './attendance.routing';
import { ValidationService } from '../form-validation/validation.service';
import { AttendanceComponent } from './attendance.component';
import { AttendanceService } from './attendance.service';

@NgModule({
  imports:[
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AttendanceRoutingModule
  ],
  declarations:[
    AttendanceComponent
  ],
  providers:[ValidationService, AttendanceService]
})

export class AttendanceModule {}
