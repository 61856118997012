import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appToggle]'
})
export class ToggleDirective {

  constructor(private elemRef:ElementRef,private renderer: Renderer2) { }
  @HostListener('click', ['$event.target']) toggleClass(target){
 
    if(this.elemRef.nativeElement.nextElementSibling.className.includes("is-active")){
      this.renderer.removeClass(this.elemRef.nativeElement.nextElementSibling,"is-active");
    }else{
      this.renderer.addClass(this.elemRef.nativeElement.nextElementSibling,"is-active");
    }
   
 
  }
 

}
