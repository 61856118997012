import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { config } from './../app.config';


@Injectable()
export class SocketService {

    /** specifying Base URL. */
    private BASE_URL = `${config.PROTOCOL}://${config.SOCKET_HOST}:${config.PORT}`;
    private socket;

    constructor() { }

    /** Initiate socket client */
    initiateSocket() {
        // io.set('transports', ['websocket']);
        return new Promise((resolve, reject) => {
            try {
                // this.socket = io(this.BASE_URL, { query: `token=${JSON.parse(window.localStorage.token)}`, transports: ['websocket'] });
                this.socket = io(this.BASE_URL, { reconnect: true, rejectUnauthorized: false, secure: true,
                    query: `token=${JSON.parse(window.localStorage.token)}` }

                    );
              //  this.socket=io.connect(this.BASE_URL,{ query: `token=${JSON.parse(window.localStorage.token)})

                if (this.socket) {
                    resolve(true);
                } else {
                    reject(false);
                }

            } catch (err) {
                reject(err);
            }
        });
    };

    disconnectSocket() {
        if (this.socket) {
            this.socket.disconnect();
        }
    };

    /** Method to receive add-message-response event. */
    receiveUserStatus(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('user-connected', (data) => {
                    observer.next(data);
                });
            }

            return () => {
                // this.socket.disconnect();
            };
        });
        return observable;
    }

    /** Method to receive new chat user ..*/
    receiveNewChat(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('new-chat', (data) => {
                    observer.next(data);
                });
            }
            return () => {
                // this.socket.disconnect();
            };
        });
        return observable;
    };

    /** Method to receive add-message-response event. */
    receiveNewUserMessage(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('chat-user-message', (data) => {
                    observer.next(data);
                });
            }
            return () => {
                // this.socket.disconnect();
            };
        });
        return observable;
    };

    /** Method to receive add-message-response event. */
    receiveNewGroupMessage(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('chat-group-message', (data) => {
                    observer.next(data);
                });
            }
            return () => {
                // this.socket.disconnect();
            };
        });
        return observable;
    };


    /** Method to receive updated-group-response event. */
    receiveUpdateChatGroup(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('chat-update-group', (data) => {
                    observer.next(data);
                });
            }
            return () => {
                // this.socket.disconnect();
            };
        });
        return observable;
    };

    /** Method to receive user-status-update-response event. */
    receiveUserStatusUpdate(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('user-status', (data) => {
                    observer.next(data);
                });
            }
            return () => {
                // this.socket.disconnect();
            };
        });
        return observable;
    };


    /**
     * Method to emit message-delivered event
     * @param msg_data
     */
    updateDeliveredMessage(msg_data): any {
        this.socket.emit('message-delivered', msg_data);
    };

    /**
     * Method to emit message-Read event
     * @param msg_data
     */
    updateReadMessage(msg_data): any {
        this.socket.emit('message-read', msg_data);
    };

    updateDeliveredResponse(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('chat-message-update', (data) => {
                    observer.next(data);
                });
            }
            return () => {
            };
        });
        return observable;
    };

    readMessageOnTab(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('chat-bulk-read', (data) => {
                    observer.next(data);
                });
            }
            return () => {
            };
        });
        return observable;
    };


    /** Method to receive new post user and update ..*/
    receiveUserPost(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('user-post', (data) => {
                    observer.next(data);
                });
            }
            return () => {
                // console.log('called socket return in post....');
            };
        });
        return observable;
    };


    /** Method to receive new user sub post ..*/
    receiveUserSubPost(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('user-sub-post', (data) => {
                    observer.next(data);
                });
            }
            return () => {
                // console.log('called socket return in sub post....');
            };
        });
        return observable;
    };

    /** Method to receive new user sub post comment..*/
    receiveUserPostComment(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('user-post-comment', (data) => {
                    observer.next(data);
                });
            }
            return () => {
                // console.log('called socket return in comm-post....');
            };
        });
        return observable;
    };


    /** Method to receive new user sub post sub comment ..*/
    receiveUserPostSubComment(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('user-post-sub-comment', (data) => {
                    observer.next(data);
                });
            }
            return () => {
                // console.log('called socket return in sub -com post....');
            };
        });
        return observable;
    };

    /** Method to receive email and sms sent status ..*/
    receiveUserPostSentStatus(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('post-sent-status', (data) => {
                    observer.next(data);
                });
            }
            return () => {
                // console.log('called socket return in sub -com post....');
            };
        });
        return observable;
    };


    /** Update user typing notifications */
    emitUserTypingStatus(typing_data): any {
        if (this.socket) {
            this.socket.emit('typing', typing_data);
        }
    };

    /** Method to receive user typing and sms sent status ..*/
    receiveUserTypingStatus(): any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('updateTyping', (data) => {
                    observer.next(data);
                });
            }
            return () => {
                // console.log('called socket return in sub -com post....');
            };
        });
        return observable;
    };
    meetingCallingSession():any{
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('calling', (data) => {
                    observer.next(data);
                });
            }
            return () => {
            };
        });
        return observable;
    }
    callAccepted():any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('accepted', (data) => {
                    observer.next(data);
                });
            }
            return () => {
            };
        });

        return observable;
    }
    onicecandidate(data) {
        this.socket.emit('ice-candidate', data);
    }

    sdp (data){
        this.socket.emit('sdp', data)
    }

    icecandidate():any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('ice-candidate', (data) => {
                    observer.next(data);
                });
            }
            return () => {
            };
        });
        return observable;
    }
    sdpSession():any {
        const observable = new Observable(observer => {
            if (this.socket) {
                this.socket.on('sdp', (data) => {
                    observer.next(data);
                });
            }
            return () => {
            };
        });
        return observable;
    }
    // answerSdp(data){
    //     this.socket.emit('answerSdp', data)
    // }

    studyRoomSession():any {
      const observable = new Observable(observer => {
          if (this.socket) {
              this.socket.on('staff-topic', (data) => {
                  observer.next(data);
              });
          }
          return () => {
          };
      });
      return observable;
  }

  studentassignmentSession(): any {
    const observable = new Observable(observer => {
      if(this.socket){
        this.socket.on('submit-assignment', (data) => {
          observer.next(data);
        });
      }
      return () => {
      };
    });
    return observable;
  }

  staffFeedbackSession():any {
    const observable = new Observable(observer => {
      if (this.socket) {
          this.socket.on('submit-feedback', (data) => {
              observer.next(data);
          });
      }
      return () => {
      };
  });
  return observable;
  }

  updateChangeGroupName():any {
    const observable = new Observable(observer => {
      if(this.socket){
        this.socket.on('changeGroupName', (data) => {
          observer.next(data);
        })
      }
      return () => {

      };
    });
    return observable;
  }



  /** Method to receive new post user and update ..*/
  // receiveStaffTopic(): any {
  //   const observable = new Observable(observer => {
  //       if (this.socket) {
  //           this.socket.on('user-post', (data) => {
  //               observer.next(data);
  //           });
  //       }
  //       return () => {
  //           // console.log('called socket return in post....');
  //       };
  //   });
  //   return observable;
  // };
}
