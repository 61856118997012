import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AiAssistComponent } from './ai-assist.component';
import { AiAssistRoutingModule } from './ai-assist-routing.module';
import { DropdownDirective } from './dropdown.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToggleIconModule } from 'app/core/toggle-icon/toggle-icon.module';
import { SharedModule } from 'app/shared/shared.module';
import { TabsModule } from 'app/core/tabs/tabs.module';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { HttpClientModule } from '@angular/common/http';
import { TooltipDirective } from 'app/shared/tooltip.directive';
import { SearchHighlightPipe } from './search-highlight.pipe';
import { ToggleDirective } from './toggle.directive';
import { BotComponent } from './bot/bot/bot.component';
import { AssistBotService } from './assist-bot.service';
import { BotleavePlannerComponent } from './botleave-planner/botleave-planner.component';
import { BottemplateComponent } from './bottemplate/bottemplate.component';
import { BotSuggestionComponent } from './bot-suggestion/bot-suggestion.component';
import { DialogService } from 'app/services/dialog.service';
import { SecureFileService } from 'app/secure-image/secure-image-service';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
 


@NgModule({
  declarations: [
    AiAssistComponent,
    DropdownDirective,
   AutocompleteComponent,
   SearchHighlightPipe,
   ToggleDirective,
   BotComponent,
   BotleavePlannerComponent,
   BottemplateComponent,
   BotSuggestionComponent,
 
  ],
  imports: [
    CommonModule,
    AiAssistRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToggleIconModule,
    TabsModule,
    SharedModule,
    HttpClientModule,
    NgxDocViewerModule
  ],
  providers:[AssistBotService,DialogService,SecureFileService],
  exports:[SearchHighlightPipe],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AiAssistModule { }
