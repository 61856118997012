import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../../app.config';
import { Subject } from 'rxjs';

@Injectable()
export class PasswordResetService {

  /** specifying Base URL.*/
  // public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/resetForgotPassword/`

  public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/newPassword/`;

  private _getEmailId = new Subject();
  authenticateEmailId$ = this._getEmailId.asObservable();

  constructor(private http: HttpClient) { }

  requestResetPassword(data) {
    return this.http.post(`${this.BASE_URL}`, data);
  };

  getUserEmailId(email_id){
    this._getEmailId.next(email_id);
  }

};
