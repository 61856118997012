import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({ name: 'codify' })
export class CodifyPipe implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) {

    }
    transform(value): any {
        value = value + '';
        if (value && value.startsWith('/code')) {
             // make sure it's a string
            value = value.replace('/code', '');
            return this._sanitizer.bypassSecurityTrustHtml(`<pre class="prettyprint">${value}></pre>`);
        } else {
            return value;
        }
    }
}
