


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from '@angular/common/http';
import { config } from './../../app.config';

@Injectable()

export class SettingsService {

    /** specifying Base URL.*/
    public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;

    constructor(private http: HttpClient) {
    }

    updateOwnPassword(user) {
        return this.http.patch(`${this.BASE_URL}/users/updateOwnPassword`, user);
    };

    getUserSettings() {
        return this.http.get(`${this.BASE_URL}/userSettings`);
    };

    updateUserSettings(payload) {
        return this.http.put(`${this.BASE_URL}/userSettings`, payload);
    };

    deleteAccount() {
        return this.http.delete(`${this.BASE_URL}/userSettings`);
    };

    // defaultUserSettings() {
    //     return this.http.get(`${this.BASE_URL}/defaultUserSettings`);
    // }

    createInstituteDetails(payLoad){
      return this.http.post(`${this.BASE_URL}/create/companyDetails`, payLoad)
    }

}
