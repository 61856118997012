import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

export class Media {
    file_path: string;
    file_type: number;
};


@Injectable()
export class PlayerService {
    private mediaContent = new Subject<Media>();

    constructor() {

    }

    getMediaContent(): Observable<any> {
        return this.mediaContent.asObservable();
    }

    emitMediaContent(mediaFields: any) {
        setTimeout(() => {
            this.mediaContent.next(<Media>mediaFields);
        }, 300);
    };

    clear() {
        this.mediaContent.next();
    }
}
