import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-icon',
  templateUrl: './toggle-icon.component.html',
  styleUrls: ['./toggle-icon.component.scss']
})
export class ToggleIconComponent implements OnInit {

  @Input() icons:any;
  @Output() iconState:EventEmitter<any> = new EventEmitter<any>();
  @Input() defaultState:string='off';
  
  constructor() { }

  ngOnInit(): void {
  }

  changedicon(event:boolean){
    this.iconState.emit(event);
  }

}
