import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys', pure: false })
export class KeysPipe implements PipeTransform {
    transform(value) {
        const keys: any = [];
        for (const key in value) {
            if (key) {
                keys.push({ key: key, value: value[key] });
            }
        }
        return keys;
    }
}
