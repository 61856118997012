import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpHeaders} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HttpModule } from '@angular/http';

import { PrivacyComponent } from './privacy.component';
import { PrivacyService } from './privacy.service';
import { PrivacyRoutingModule } from './privacy-routing.module';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PrivacyRoutingModule
    // HttpModule
  ],
  declarations: [
    PrivacyComponent
  ],
  providers: [PrivacyService]
})
export class PrivacyModule { }
