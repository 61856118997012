// import { LoaderState } from './loader.state';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

export interface LoaderState {
    showLoader: boolean;
}

@Injectable()
export class LoaderService {
    private loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();

    constructor() {}

    showLoader() {
        this.loaderSubject.next(<LoaderState>{showLoader: true});
    };

    hideLoader() {
        this.loaderSubject.next(<LoaderState>{showLoader: false});
    };
}
