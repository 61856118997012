import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, field: string, input: string) {
    if (!!input) {
      input = input.toLowerCase();
      return value.filter(el => {
        return el[field].toLowerCase().indexOf(input) > -1;
      })
    }
    return value;
  }

}
