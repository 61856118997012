import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private http: HttpClient
  ) { }

  confirmCardPayment(data:any): any{
    console.log(data, 'data');
    
  }
}
