import { SharedModule } from 'app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordResetService } from './password-reset.service';
import { PasswordResetRoutingModule } from './password-reset.routing';
import { PasswordResetComponent } from './password-reset.component'


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordResetRoutingModule,
    SharedModule
  ],
  declarations: [
    PasswordResetComponent
  ],
  providers: [PasswordResetService]
})
export class PasswordResetModule { }
