import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { config } from 'app/app.config';
import { LoaderService } from 'app/loader/loader.service';

export class Media {
  file_path:string;
  file_type: number;
}

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  public base_url = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;

  RECORD_LIMIT:number = config.DEFAULT_RECORD_LIMIT;
  OFFSET:number = config.DEFAULT_OFFSET;

  constructor(
    private http: HttpClient,
    private router: Router,
    private loaderService: LoaderService
  ) { }

  getAllClassRoomList(payload){
    return this.http.get(`${this.base_url}/classList?offset=${payload.offset}&limit=${payload.limit}&sort=${payload.sort}&search=${payload.search}`);
  }

  getAllClassLessonList(payload){
    return this.http.get(`${this.base_url}/interactiveVideoList?offset=${payload.offset}&limit=${payload.limit}&classId=${payload.classId}`);
  }

  getSelectLessonData(lesson_id){
    return this.http.get(`${this.base_url}/interactionQuestions/${lesson_id}`);
  }

  addNewInteractiveQuiz(payload){
    return this.http.post(`${this.base_url}/createVideoQuiz`, payload);
  }

  updateLessonQuiz(payload){
    return this.http.put(`${this.base_url}/updateInteractiveVideo`, payload);
  }

  deleteQuizData(quizId){
    return this.http.delete(`${this.base_url}/deleteVideoQuiz/${quizId}`)
  }

  editSelectQuestion(quizId){
    return this.http.get(`${this.base_url}/editVideoQuiz/${quizId}`);
  }

  updateSelectQuestion(quizId, payload){
    return this.http.put(`${this.base_url}/updateVideoQuiz/${quizId}`, payload);
  }

  preHttpParams(payLoad) {
    let Params = new HttpParams();
    for (const key in payLoad) {
        if (key && (payLoad[key] || payLoad[key] === 0)) {
            Params = Params.append(key, payLoad[key]);
        }
    }
    return Params;
  };
}
