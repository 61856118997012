import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {

    static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        const config = {
            'required': 'This field is required',
            'invalidEmailAddress': 'Invalid email address',
            'invalidSignUpPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
            'invalidPassword': 'Invalid password.',
            'invalidNumber': 'Please enter 10 Digit Contact No.',
            'invalidZip': 'Zipcode should be numbers only',
            'whiteSpace' : 'Please enter 5 characters on lesson name',
            'titleWhiteSapce': 'Please enter valid title',
            'invalidEaadhar' : 'Please enter 12 Digit numbers only',
            'invalidEmis': 'Please enter 10 Digit number only',
            'invalidMark': 'Please enter mark number only!',
            'minlength': `Minimum length ${validatorValue.requiredLength}`,
            'invalidName' : 'Please enter characters only (For Example: John)'
        };

        return config[validatorName];
    }

    static emailValidator(control) {
        // if (control.value && control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
        //     return null;
        // } else {
        //     return { 'invalidEmailAddress': true };
        // }

        if (control.value && control.value.match(/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/)) {
          return null;
      } else {
          return { 'invalidEmailAddress': true };
      }
    }

    static passwordValidator(control) {
        // {6,100}           - Assert password is between 6 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        if (control.value && control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
            return null;
        } else {
            return { 'invalidPassword': true };
        }

      //   if (control.value) {
      //     return null;
      // } else {
      //     return { 'invalidPassword': true };
      // }
    }

    static passwordValidate(control){
       // {6,100}           - Assert password is between 6 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        if (control.value && control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
          return null;
      } else {
          return { 'invalidSignUpPassword': true };
      }

    }

    static namePattern(control){
      if(control.value && control.value.match(/^[A-Za-z]+$/)){
        return null;
      }else {
        return { 'invalidName' : true }
      }
    }

    static phoneNoValidate(control){
       if(control.value && control.value.match(/^[1-9]{1}[0-9]{9}$/)){
         return null;
       }else {
         return { 'invalidNumber': true };
       }
    }

    static zipValidator(control){
      if(control.value && control.value.match(/^[0-9]+$/)){
        return null;
      }else{
        return { 'invalidZip': true };
      }
    }

    static aadharNoValidate(control){
      if(control.value && control.value.match(/^[1-9]{1}[0-9]{11}$/)){
        return null;
      }else{
        return { 'invalidEaadhar' : true };
      }
    }

    static emisNoValidate(control){
      if(control.value && control.value.match(/^[1-9]{1}[0-9]{9}$/)){
        return null;
      }else{
        return { 'invalidEmis' : true };
      }
    }

    static noWhitespaceValidator(control){
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
    }

    static nameValidator(control){
      if(control.value.trim() !="" && control.value.match(/^[^\s][a-zA-Z\s]+[^\s]$/)){
        return null;
      }else{
        return { 'invalidName' : true }
      }
    }

    static noWhiteSpaceTitleValidator(control){
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'titleWhiteSapce': true };
    }

    static markNoValidate(control){
      if(control.value && control.value.match(/^[1-9]{1}[0-9]{9}$/)){
        return null;
      }else{
        return { 'invalidMark' : true };
      }
    }

    constructor() { }

}
