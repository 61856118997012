import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../../app.config';

@Injectable()
export class ForgotPasswordService {

  /** specifying Base URL.*/
  public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/forgotPassword/`

  constructor(private http: HttpClient) { }

  requestResetPassword(data) {
    return this.http.post(`${this.BASE_URL}`, data);
  };
};
