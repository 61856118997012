import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { SharedModule } from 'app/shared/shared.module';

import { ChatListComponent } from './chat-list.component';
import { NewChatComponent } from './new-chat/new-chat.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
// import { MessageNormalViewComponent } from './chat-message/msg-normal-view/msg-normal-view.component';

//import { AudioCallViewComponent } from './chat-message/audio-call-view/audio-call-view.component';
import { MessagListViewComponent } from './chat-message/msg-list-view/msg-list-view.component';
import { GroupUserlistComponent } from './group-userlist/group-userlist.component';
import { ChatListRoutingModule } from './chat-list-routing.module';
import { ChatService } from './chat.service';
import { AudioCallViewComponent } from "./chat-message/audio-call-view/audio-call-view.component";





@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ChatListRoutingModule,
    SharedModule,
  ],
  declarations: [
    ChatListComponent,
    NewChatComponent,
    ChatMessageComponent,
    // MessageNormalViewComponent,
    AudioCallViewComponent,
    MessagListViewComponent,
    GroupUserlistComponent
  ],
  providers: [ChatService]
})
export class ChatListModule { }
