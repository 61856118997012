import { AfterContentChecked, AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild, Directive  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
 
 
import { AppComponent } from 'app/app.component';
import { AlertPopup, config, HttpLoader, path, ResolveEmit } from 'app/app.config';
import { DataSharingService } from 'app/shared/data-sharing.service';
import { SocketService } from 'app/socket/socket.service';
import { Subject, Observable } from 'rxjs';
import { ValidationService } from '../form-validation/validation.service';
import { UserService } from '../userandgroup/user/user.service';
import { StaffRoomService } from './staff-room.service';
import * as _ from 'underscore';
import 'rxjs/add/operator/debounceTime';
import { ConfirmationDialogService } from 'app/confirmation-dialog/confirmation-dialog.service';

// @Directive()
@Component({
  selector: 'app-staff-room',
  templateUrl: './staff-room.component.html',
  styleUrls: []
})
export class StaffRoomComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {

  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  @ViewChild('staffRoomList', {static: true}) private staffRoomScrollContainer: ElementRef;
  profileUrl = path.PROFILE_URL;
  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;
  activeUrl: any;

  newRoomForm: FormGroup;

  showRoomList = false;
  showEmptyState = true;
  showRoomData = false;
  showListofRooms = false;
  viewStaffRoomList = false;
  is_tour_completed = false;
  loggedUserDeatails: any;
  welcomeWindowName: string;
  showWelcomeWindow = true;

  roomsList: any[] = [];

  totalRowCount: number;
  showSearchSec = true;
  sortBy = 'time';
  searchRooms:string;
  showPaginateSpinner = false;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  isActiveNewRoom = false;
  showBtnSpinner : boolean = true;
  public viewSecondPanel = false;


  viewControl = {
    changedSearchText : false
  }

  searchInList = new Subject<any>();

  private subscribers: any = {};

  constructor(
    private staffroomService: StaffRoomService,
    private alert: AlertPopup,
    private httpLoader: HttpLoader,
    private router: Router,
    private route: ActivatedRoute,
    private socketService: SocketService,
    private formBuilder: FormBuilder,
    private validateService: ValidationService,
    private dataSharingService: DataSharingService,
    private userService: UserService,
    
    private confirmDialogService:ConfirmationDialogService,
    private appComponent: AppComponent,
  
  ) {
    this.newRoomForm = formBuilder.group({
      title: [null, Validators.compose([Validators.required])],
      description:[null]
    });

    // this.searchInList.debounceTime(500).subscribe(event => {
    //   this.searchRooms = event.target.value;
    //   if (!this.searchRooms) {
    //     return;
    //   }
    //   this.viewControl.changedSearchText = true;
    //   this.getRooms(0);
    // })

    this.searchInList.debounceTime(500).subscribe(
      event => {
        this.viewControl.changedSearchText = true;
        const searchText = event.target.value;
        this.searchStaffRoomList(searchText);
      }
    )
  }

  ngOnInit(): void {
    this.welcomeWindow('welcome');
    this.getRooms(this.searchRooms);
    this.activeUrl = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.url[0].path : false;
  }


  ngAfterViewInit(): void {

  }

  ngAfterContentChecked(): void {
    if (_.isEqual(this.loggedUserDeatails, this.appComponent.userDetail)) {
      return;
    }

    if (_.isObject(this.appComponent.userDetail)) {
      this.loggedUserDeatails = this.appComponent.userDetail;
      this.is_tour_completed = !this.loggedUserDeatails.is_tour_completed;
    }
  }

  /** Active Teacher help Desk */
  welcomeWindow(name) {

    if (name) {
      this.showWelcomeWindow = true;
      this.welcomeWindowName = name;
    } else {
      this.showWelcomeWindow = false;
    }
  }

  showOnboarding() {
    //this.hintService.initialize({ elementsDisabled: true });
    this.showWelcomeWindow = false;
  }

  searchStaffRoomList(searchRooms){
    this.getRooms(searchRooms);
  }

  getRooms(searchRooms = '', pageNo = 0){
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
        this.httpLoader.showLoader();
      }
      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: searchRooms || '',
        sort: this.sortBy
      };
      this.staffroomService.getClassList(payLoad).subscribe(response=>{
        if(offset === 0){
          this.httpLoader.hideLoader();
          this.roomsList = response['chatList'];
          this.scrollToTop();
        }else if(offset > 0){
          this.showPaginateSpinner = false;
          this.roomsList = {...this.roomsList.concat(response['chatList'])};
        }

        this.totalRowCount = response['TotalRowCount'];
      })
    } catch (error) {
      this.alert.showAlertMessage(error.error);
      this.showPaginateSpinner = false;
      this.httpLoader.hideLoader();
    }
  }


  scrollToTop(){
    // setTimeout(()=>{
    //   this.staffRoomScrollContainer.nativeElement.scrollTop = 0;
    // }, 100);
  }

  fetchStaffRoomList(){
    const listLength = this.roomsList.length;
    if(this.totalRowCount > listLength && !this.showPaginateSpinner){
      this.OFFSET += 1;
      this.showPaginateSpinner = true;
      this.getRooms(this.searchRooms, this.OFFSET);
    }
  }

  showSearchBox(toggle){
    this.showSearchSec = !this.showSearchSec;
    if (this.viewControl.changedSearchText) {
      try {
        this.searchRooms = '';
        this.viewControl.changedSearchText = false;
        const offset = 0;
        this.getRooms(this.searchRooms, offset);
      } catch (error) {
        this.httpLoader.hideLoader();
      }
    }
  }



  createNewRoomPopup(){
    this.isActiveNewRoom = true;
  }

  hideRoomPopup(){
    this.isActiveNewRoom = false;
  }

  createNewRoom(formVal){
    try {
      this.showBtnSpinner = true;
      // this.httpLoader.showLoader();
      const payLoad = {
        'room_title': formVal['title'],
        'description': formVal['description']
      }

      this.staffroomService.createNewRoom(payLoad).subscribe(
        response =>{
          this.roomsList.push(response['result']);
          this.showBtnSpinner = false;
          this.isActiveNewRoom = false;
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(response);
        },
        error => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error.message);
        }
      )
    } catch (error) {
      this.httpLoader.hideLoader();
    }
  }

  deleteRoom(id){

    try {
      this.confirmDialogService.openConfirmDialog('',{
        title:'Confirmation',
        message:'Are you sure want to delete lesson room?',
        btnOkText:'DELETE',
        btnCancelText:'NO',
        onClose: (data:any) => {
          if(data){
            const payLoad = {
              'room_id': id
            }
            this.staffroomService.deleteRoom(payLoad).subscribe(
              response=>{
                this.alert.showAlertMessage(response);
                this.httpLoader.hideLoader();
                let position = this.roomsList.findIndex(x => x.room_id ===id);
  
                if(~position){
                  this.roomsList.splice(position, 1);
                }
              },
              error => {
                this.alert.showAlertMessage(error.error);
                this.httpLoader.hideLoader();
              }
            )
          }
          }
        });
    
     /* this._confirmation.create('Confirmation', 'Are you sure want to delete class room?', { confirmText: 'DELETE'}).subscribe((ans: ResolveEmit) => {
        if(ans.resolved){
          const payLoad = {
            'room_id': id
          }
          this.staffroomService.deleteRoom(payLoad).subscribe(
            response=>{
              this.alert.showAlertMessage(response);
              this.httpLoader.hideLoader();
              let position = this.roomsList.findIndex(x => x.room_id ===id);

              if(~position){
                this.roomsList.splice(position, 1);
              }
            },
            error => {
              this.alert.showAlertMessage(error.error);
              this.httpLoader.hideLoader();
            }
          )
        }
      }); */
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);

    }
  }


  ngOnDestroy(): void {

  }

}
