import { config } from './app.config';
import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {
  errors: Array<string> = [];
  constructor() { }

  fileValidation(files: File, fileExtension, size: number) {
    return new Promise((resolve, reject) => {
      this.errors = [];
      if (this.errors.length > 0) {
        reject(this.errors);
      } else {
        if (this.isValidFiles(files, fileExtension, size)) {
          resolve(files);
        }
      }
    });
  };

  private isValidFiles(files, fileExtension, size) {
    if (files.length > config.ATTACHMENT_LIMIT) {
      this.errors.push(`Error: At a time you can upload only ${config.ATTACHMENT_LIMIT} files`);
      return;
    }
    this.isValidFileExtension(files, fileExtension, size);
    return this.errors.length === 0;
  };

  private isValidFileExtension(files, fileExtension, size) {
    const extensions = (fileExtension.split(',')).map((ext) => ext.toUpperCase().trim());
    const ext = files.name.toUpperCase().split('.').pop() || files.name;
    const exists = extensions.includes(ext);
    if (!exists) {
      this.errors.push(`Error (Extension):  ${files.name}`);
      return;
    }
    this.isValidFileSize(files, size);
  };

  private isValidFileSize(file, size) {
    const fileSizeinMB = file.size / (1024 * 1000);
    const fileSize = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
    if (fileSize > size) {
      this.errors.push(`Error (File Size): ${file.name} : exceed file size limit of  ${size} MB ( " ${size} "MB )`);
    }
  };
}

