import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'angular2-moment';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
// import { ChartsModule } from 'ng2-charts/ng2-charts';
// import { Ng2EmojiModule } from '../ng2-emoji';
// import { Ng2EmojiModule } from 'ng2-emoji';
import { ChartsModule } from 'ng2-charts';


// import { HintModule } from 'angular-custom-tour';


import { DataSharingService } from './data-sharing.service';
import { DragDropDirective } from './file-drag-drop';
import { TimeagoPipe } from './timeago.pipe';
import { FocusDirective } from 'app/shared/focus.directive';
import { SearchPipe } from 'app/shared/search.pipe';
import { SortByPipe } from 'app/shared/sort-by.pipe';
import { SortByDate } from './sort-date';
import { PaginationLoaderComponent } from '../pagination-loader/pagination.component';
import { FormValidationComponent } from './../components/form-validation/form-validation.component';
import { LoaderService } from './../loader/loader.service';
import { KeysPipe } from './key-val.pipe';
import { InfiniteScrollDirective } from './infinite-scroll';
import { EmptyUnameComponent } from './../empty-profile/empty-profile.component';
import { EmojiComponent } from './../emoji/emoji.component';
import { EmojiService } from './../emoji/emoji.service';
import { GroupByPipe } from './groupby.pipe';
import { FileValidationService } from './file-validation.service';
import { SecuredImageComponent } from './../secure-image/secure-image.component';
import { SecureFileService } from './../secure-image/secure-image-service';
import { InfiniteScrollerDirective } from './infinite-scroller-directive';
import { SanitizePipe } from './sanitize.pipe';
import { FileSizePipe } from './file-size-pipe';
import { LinkifyPipe } from './link-pipe';
import { CodifyPipe } from './codify.pipe';

import { MessageNormalViewComponent } from './../components/chat-list/chat-message/msg-normal-view/msg-normal-view.component';
import { TruncatePipe } from './truncatePipe';
import { TrimPipe } from './trimPipe';
import { Highlight } from './highlight-pipe';
import { DecimalPipe } from './decimal-pipe';
import { EmojiModule } from 'lib/picker/ngx-emoji/emoji.module';
import { PickerModule } from 'lib/picker/picker.module';
import { EmojimartComponent } from './../emojimart/emojimart.component';
import { EmojiTranslatePipe } from './emoji.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { Time24to12Format } from './time24to12.pipe';
import { ConfirmdialogModule } from 'app/confirmation-dialog/confirmdialog.module';
import { CustomScrollDirective } from './custom-scroll.directive';
import { PageHeaderComponent } from 'app/components/page-header/page-header.component';


@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MomentModule, InfiniteScrollModule,
        ChartsModule,
        PickerModule,
        EmojiModule,
        // Ng2EmojiModule.forRoot()
      ],
    declarations: [
        TimeagoPipe,
        FocusDirective,
        SearchPipe,
        SortByPipe,
        SortByDate,
        InfiniteScrollDirective,
        KeysPipe,
        SanitizePipe,
        FileSizePipe,
        LinkifyPipe,
        TruncatePipe,
        TrimPipe,
        EmojiTranslatePipe,
        Highlight,
        DecimalPipe,
        Time24to12Format,
        CodifyPipe,
        FormValidationComponent,
        SecuredImageComponent,
        PaginationLoaderComponent,
        EmptyUnameComponent,
        EmojiComponent,
        EmojimartComponent,
        PageHeaderComponent,
        DragDropDirective,
        GroupByPipe, InfiniteScrollerDirective, MessageNormalViewComponent, CustomScrollDirective],
    exports: [
        CommonModule,
        FormsModule,
        MomentModule,
        // HintModule,
        TimeagoPipe,
        FocusDirective,
        SearchPipe,
        SortByPipe,
        SortByDate,
        InfiniteScrollDirective,
        KeysPipe,
        SanitizePipe,
        FileSizePipe,
        LinkifyPipe,
        TruncatePipe,
        TrimPipe,
        EmojiTranslatePipe,
        Highlight,
        DecimalPipe,
        Time24to12Format,
        CodifyPipe,
        InfiniteScrollModule,
        ChartsModule,
        NgSelectModule,
        // Ng2EmojiModule,
        PickerModule,
        EmojiModule,
        FormValidationComponent,
        SecuredImageComponent,
        PaginationLoaderComponent,
        EmptyUnameComponent,
        EmojiComponent,
        EmojimartComponent,
        PageHeaderComponent,
        DragDropDirective,
        CustomScrollDirective,
        GroupByPipe,
        InfiniteScrollerDirective, MessageNormalViewComponent,ConfirmdialogModule],
    providers: [LoaderService, DataSharingService, EmojiService, FileValidationService, SecureFileService]
})
export class SharedModule { }
