import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './../../auth-guard.service';
// import { HomeComponent } from '../../components/home/home.component';
import { UserComponent } from './user/user.component';
import { GroupComponent } from './group/group.component';
import { NewUserComponent } from './user/new-user/new-user.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { NewGroupComponent } from './group/new-group/new-group.component';
import { GroupDetailComponent } from './group/group-detail/group-detail.component';
import { UserlistComponent } from './user/userlist/userlist.component';




const routes: Routes = [
  { path: '', redirectTo: 'user', pathMatch: 'full' },
  {
    path: 'user',
     component: UserComponent, 
    canActivate: [AuthGuard], 
    children: [
      { path: 'newuser', component: NewUserComponent },
      { path: ':id', component: UserDetailComponent }
    ]
  },
  {
    path: 'userandgroup/user/:id',
    canActivate: [AuthGuard], 
    component: UserDetailComponent
  },
  {
    path: 'userandgroup/group', component: GroupComponent, canActivate: [AuthGuard], children: [
      { path: 'newgroup', component: NewGroupComponent },
      { path: ':id', component: GroupDetailComponent }
    ]
  }
]


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  declarations: [],
  exports: [RouterModule]
})
export class UserandgroupRoutingModule { }
