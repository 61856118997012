import { PushNotificationsService } from './push-notification/push-notification-service';
import { Component, OnInit, Inject, AfterViewInit, OnDestroy, HostListener, Directive  } from '@angular/core';
import { HttpRequest, HttpEventType, HttpClient } from '@angular/common/http';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as _ from 'underscore';
import { HeaderService } from './components/header/header.service';
import { LoaderComponent } from './loader/loader.component';
import { SocketService } from './socket/socket.service';
import { SettingsService } from './components/settings/settings.service';
import { AlertPopup, BASE_URI } from './app.config';
import { DataSharingService } from './shared/data-sharing.service';

import { UserService } from './components/userandgroup/user/user.service';
import {   GuidedTour, GuidedTourService, Orientation, ProgressIndicatorLocation } from 'ngx-guided-tour';
import { DOCUMENT } from '@angular/common';
import { ThemeService } from './components/settings/theme.service';
// @Directive()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class AppComponent implements OnInit, OnDestroy {
  // @HostListener('window:focus', ['$event'])
  // @HostListener('window:blur', ['$event'])
  title = 'app';
  isWindowFocused = true;
  public userLoggin = false;
  public userSetting: any = {};
  public callingUserDetails:any = {}
  public menuListItem: any;
  public userDetail: any;
  public userStatus = [];
  public selectedFilesList: any[] = [];
  is_tour_completed = false;
  public activated: {
    loginPage: boolean;
  };
  activeUserRole:any = "";
  public currentToken;

  subscribers: any = {
    isSubscribed: false
  };
  actualUrl: any;
  activeUrl: any;
  companyid :string;
  progressIndicatorLocation:any;

  TOUR: GuidedTour;
  constructor(
    private http: HttpClient,
    private socketService: SocketService,
    private headerService: HeaderService,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
    private router: Router,
    private dataSharingService: DataSharingService,
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService,
    private _notificationService: PushNotificationsService,
    private alert: AlertPopup,
    private guidedTourService: GuidedTourService,
    private themeService:ThemeService
  ) {

    this._notificationService.requestPermission();
    this.progressIndicatorLocation = ProgressIndicatorLocation.TopOfTourBlock;
    this.activated = {
      loginPage: false
    };


    this.router.events.subscribe(
      (event: Event): void => {
        if (event instanceof NavigationEnd) {
          this.activeUrl = this.router.url.split('/')[1];
          this.actualUrl = this.router.url.split('/')[2];

          if (['login', 'reset_password', 'sign_up', 'password_reset', 'verify_otp'].includes(this.activeUrl)) {
            this.activated.loginPage = true;
          } else {
            this.activated.loginPage = false;
          }
        }
      },
      error => {
        console.log(error);
      });


    // this.hintService.overlay$.subscribe(data => {
    //   if (!data) {
    //     this.updateUserOnboarding();
    //   }
    // });


  }

  onResize(event){
    event.target.innerWidth; // window width
  }


  dragOverHandler = (e) => {
    e.preventDefault();
  }

  dropHandler = (e) => {
    e.preventDefault();
  }

  focusHandler = (e) => {
    e.preventDefault();
    this.isWindowFocused = true;
  };

  focusBlurHandler = (e) => {
    this.isWindowFocused = false;
  };



  ngOnInit() {
    this.themeService.setTheme('steelblue');
    let myTheme = localStorage.getItem("myTheme");
    // if(myTheme){
    //   this.themeService.setTheme(myTheme);
    // }else{
    //   this.themeService.setTheme('default');
    // }
    
    this.document.body.classList.add('scroll-stop');
    
    // window.addEventListener('dragover', function (e) {
    //   e.preventDefault();
    // }, false);

    // window.addEventListener('drop', function (e) {
    //   e.preventDefault();
    // }, false);

    // window.addEventListener('focus', (e) => {
    //   e.preventDefault();
    //   this.isWindowFocused = true;
    // });

    // window.addEventListener('blur', (e) => {
    //   e.preventDefault();
    //   this.isWindowFocused = false;
    // });

    window.addEventListener('dragover', this.dragOverHandler, false);
    window.addEventListener('drop', this.dropHandler, false);
    window.addEventListener('focus', this.focusHandler, false);
    window.addEventListener('blur', this.focusBlurHandler, false);

    this.currentToken = window.localStorage.token;

    var activeRoleId = JSON.parse(window.localStorage.getItem('user_role'));
    // var roleId = activeRoleId.replace(/\"/g, "");
    this.activeUserRole = parseInt(activeRoleId);

    if (window.localStorage.token) {
      this.getUserStatusData();
      this.getCurrentUserData();
    } else {
      window.localStorage.clear();
    }
  }

  emitReadUpdate(response) {
    let updateDelivery = {};
    updateDelivery = _.pick(response, 'message_id', 'chat_type', 'from_user_id', 'to_user_id');
    this.socketService.updateReadMessage(updateDelivery);
  };

  observableRefresh() {

    try {

      if (window.localStorage.token) {
        if (this.subscribers.isSubscribed) {
          return;
        } else {
          this.subscribers.isSubscribed = true;
        }

        this.subscribers.userMessage = this.socketService.receiveNewUserMessage().subscribe(newMessage => {
          const response = {
            message: newMessage,
            settings: this.userSetting
          };
          this.dataSharingService.emitNewUserMessage(response);
          if (this.activeUrl === 'chats' && this.actualUrl === 'singleuser') {
            const paramId = +this.router.url.split('/')[3];
            if ((newMessage && newMessage.from_user_id) === paramId && this.isWindowFocused) {
              this.emitReadUpdate(newMessage);
            } else {
              this.emitUpdateDelivery(newMessage);
              if (this.userSetting.enable_desktop_alert) {
                if (this.userSetting.user_msg_alert) {
                  this._notificationService.generateNotification(newMessage);
                }
              }
            }
          } else {
            this.emitUpdateDelivery(newMessage);
            if (this.userSetting.enable_desktop_alert) {
              if (this.userSetting.user_msg_alert) {
                this._notificationService.generateNotification(newMessage);
              }
            }
          }

          if (this.activeUrl !== 'chats') {
            this.dataSharingService.emitNotifyCount({ menu: 'chats', count: 0 });
          }

        });

        this.subscribers.groupMessage = this.socketService.receiveNewGroupMessage().subscribe(newMessage => {
          const response = {
            message: newMessage,
            settings: this.userSetting
          };
          this.dataSharingService.emitNewGroupMessage(response);
          this.emitUpdateDelivery(newMessage);

          if (this.activeUrl === 'chats' && this.actualUrl === 'groupuser') {
            const paramId = +this.router.url.split('/')[3];
            if ((newMessage && newMessage.group_id) !== paramId) {
              if (this.userSetting.enable_desktop_alert) {
                if (this.userSetting.group_msg_alert) {
                  this._notificationService.generateNotification(newMessage);
                }
              }
            }
          }

          if (this.activeUrl !== 'chats') {
            // this.dataSharingService.emitNotifyCount('chats');
            this.dataSharingService.emitNotifyCount({ menu: 'chats', count: 0 });
            if (this.userSetting.enable_desktop_alert) {
              if (this.userSetting.group_msg_alert && newMessage.group_id) {
                this._notificationService.generateNotification(newMessage);
              }
            }
          }

        });

        this.subscribers.meetingSessions = this.socketService.meetingCallingSession().subscribe(session => {
          if(session!== null && session !== undefined){
            this.callingUserDetails = session
            this.callingUserDetails['type'] = true
          }
        })

        this.subscribers.callAccepted = this.socketService.callAccepted().subscribe(response => {
          this.dataSharingService.emitCallAccepted(response)
        })
        // ice candidate
        this.subscribers.iceCandidate = this.socketService.icecandidate().subscribe(ice => {
          this.dataSharingService.emitIceCandidate(ice)
        })

        this.subscribers.sdp = this.socketService.sdpSession().subscribe(sdp => {
          this.dataSharingService.emitSdp(sdp)
        })


        // this.subscribers.staff_topic = this.socketService.studyRoomSession().subscribe(public_topic => {
        //   // this.dataSharingService.emitSdp(staff_topic)
        //   this.dataSharingService.emitPublishTopic(public_topic);
        //   console.log(public_topic);

        // })


        this.subscribers.userStatusNotify = this.socketService.receiveUserStatus().subscribe(response => {
          this.dataSharingService.emitUserStatusNotify(response);
        });


        this.subscribers.userTypingUpdate = this.socketService.receiveUserTypingStatus().subscribe(typingStatusData => {
          this.dataSharingService.emitTypingStatusNotify(typingStatusData);
        });


        this.subscribers.initiateNewChat = this.socketService.receiveNewChat().subscribe(userChat => {
          this.dataSharingService.emitNewChatsNotify(userChat);
        });

        this.subscribers.receiveUserStatusUpdate = this.socketService.receiveUserStatusUpdate().subscribe(userStatus => {
          // console.log('receiveUserStatusUpdate', userStatus);
          this.dataSharingService.emituserStatusUpdate(userStatus);
        });


        this.subscribers.updateDelivery = this.socketService.updateDeliveredResponse().subscribe(deliveredMessage => {
          this.dataSharingService.emitMessageUpdateNotify(deliveredMessage);
        });

        this.subscribers.readBulkMessage = this.socketService.readMessageOnTab().subscribe(readedMessage => {
          this.dataSharingService.emitBulkReadMsgUpdateNotify(readedMessage);
        });


        this.subscribers.groupUpdate = this.socketService.receiveUpdateChatGroup().subscribe(updatedGroup => {
          this.dataSharingService.emitGroupUpdateNotify(updatedGroup);
        });

      }
    } catch (e) {
      console.log(e);
    }
  }



  emitUpdateDelivery(response) {
    let updateDelivery = {};
    updateDelivery = _.pick(response, 'message_id', 'chat_type', 'from_user_id', 'to_user_id');
    this.socketService.updateDeliveredMessage(updateDelivery);
  };


  getCurrentUserData() {
 
    try {
      this.headerService.getCurrentUserDetail().subscribe(
        user => {
          this.companyid = user['userDetail']['company_id'];
          window.localStorage.setItem('username', user['userDetail']['first_name']);

          const companyDetail = user['company_details'];

          if (companyDetail!==null) {
            const company = {
              company_logo: companyDetail['company_logo'],
              title: companyDetail['title'],
              caption: companyDetail['caption']
            }

            this.userDetail = {...company, ...user['userDetail']};
          } else {
            this.userDetail = user['userDetail']
          }

          this.is_tour_completed = !this.userDetail.is_tour_completed;
         
          this.userSetting = user['settingsDetail'];
         let modified_menu = user['menuList'].map((list:any)=>{
          let menuchange:any={}
            for (const key in list) {
              if (Object.prototype.hasOwnProperty.call(list, key)) {
                //const element = object[key];
                menuchange[key]=list[key];
                if(key=='menu_name'){
                  menuchange['path']='main/'+list[key];
                }
                
              }
            }
            return menuchange;
        });
          
          this.menuListItem = user['menuList'];
          this.socketService.initiateSocket();
          this.observableRefresh();

          const newtoken = window.localStorage.token
          // if(this.currentToken !== newtoken) {
            this.loginstatus();
          // }
          // this.router.navigate(['/chats']);
          if(this.is_tour_completed){
            setTimeout(()=>{
              this.startTour();
            },1500);
        
          }
        },
        error => {
          const isLogout = error.error['logout'];
          if(isLogout===true){
            this.router.navigate(['/login']);
          }
        })
    } catch (err) {
      const isLogout = err.error['logout'];
      if(isLogout===true){
        this.router.navigate(['/login']);
      }
    }
  };

  getUserStatusData() {
    
    this.headerService.getUserStatus().subscribe((res: any) => {
      this.userStatus = res;
      // this.loginstatus();
    })
  }

  doBeforeUnload() {
    // Alert the user window is closing
      return false;
  }

  loginstatus() {
    // const payload = {
    //   user_id: this.userDetail.user_id,
    //   status_id: 1
    // }

    // this.headerService.updateStatus(payload).subscribe((res: any) => {

    //   const copyUserDetail = {...this.userDetail}
    //   copyUserDetail.status_name = res.response.status_name;
    //   copyUserDetail.user_status = res.response.status_id;
    //   this.userDetail = copyUserDetail;
    // });
  }

  onFocus(event: FocusEvent): void {

    // Do something
    const payload = {
      user_id: this.userDetail.user_id,
      status_id: 0
    }

    this.headerService.updateStatus(payload).subscribe(res => {
      window.localStorage.clear();
    });

}
onBlur(event: FocusEvent): void {
  // Do something
  const payload = {
    user_id: this.userDetail.user_id,
    status_id: 0
  }

  this.headerService.updateStatus(payload).subscribe(res => {
    window.localStorage.clear();
  });

}

  doUnload() {
    const payload = {
      user_id: this.userDetail.user_id,
      status_id: 0
    }

    this.headerService.updateStatus(payload).subscribe(res => {
      window.localStorage.clear();
    });
  }

  socketTyping(payLoad) {
    try {
      if (this.socketService) {
        this.socketService.emitUserTypingStatus(payLoad);
      }
    } catch (err) {
      console.log(err);
    }
  };



  updateUserOnboarding() {
    this.userService.updateUserOnboarding().subscribe(response => {
      this.userDetail.is_tour_completed = true;
    }, error => {

    })
  };

  fileUploadWithProgess(formFields, filesList) {
    return new Promise((resolve, reject) => {
      const URL = `${BASE_URI.URL}/uploadFiles`;
      if (filesList && filesList.length > 0) {
        const promise: any[] = [];
        filesList.map(el => {
          promise.push(this.sendFileToServer(el, filesList, formFields).then(fileResp => {
            return fileResp;
          },
            error => {
              reject(error)
            }))
        });

        Promise.all(promise).then(allFileResponse => resolve(allFileResponse)).catch(err => reject(err));
      }
    })
  };


  sendFileToServer(file, filesList, formFields) {
    return new Promise((resolve, reject) => {
      const URL = `${BASE_URI.URL}/uploadFiles`;
      const formData: FormData = new FormData();
      formData.append(formFields.fieldName, file);
      formData.append('uniq', `${Date.now()}`);
      for (const key in formFields) {
        if (key) {
          formData.append(key, formFields[key]);
        }
      }

      const req = new HttpRequest('POST', URL, formData, { reportProgress: true });
      this.http.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          file.progressVal = Math.round(100 * event.loaded / event.total);
        }

        if (event.type === HttpEventType.Response) {
          const index = filesList.indexOf(file);
          filesList.splice(index, 1);
          resolve(event['body']);
        }
      },
        error => {
          reject(error);
        });
    });
  };

  //number validation
  commonValidateNumber(evt:any, labelName){
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.alert.showAlertMessage({message: 'Please enter number value only'})
      return false;
    }

    return true;
  }


   startTour(): void {
    
     if(this.activeUserRole==1){
      this.TOUR = {
        tourId: "Tour",
        useOrb: false,
        skipCallback: () => console.log("skip clicked"),
        completeCallback: () => {this.updateUserOnboarding(); console.log("complete clicked")},
        steps: [
          {
            title: "Absence GPT",
            selector: "#menu_item_1",
            content: "The information provided from this tool is not for legal advice, and is intended to provide references in regards to state laws on absences.",
            orientation: Orientation.Right
          },
          {
            title: "Policy Library",
            selector:"#menu_item_2",
            content: "Admin can upload e-books & share to Users. Users to study at e-books, notes.",
            orientation:Orientation.Right
          },
          {
            title: "Notification",
            selector:"#menu_item_7",
            content: "You can able to start threaded conversations on the project you are working on, share knowledge and connect with your team or it could be anything you would like to discuss or notify your team. ",
            orientation: Orientation.Right
          },
          {
            title: "Conversation",
            selector: "#menu_item_6",
            content: "You can able to chat with your team/invited people here either in 1-to-1 or 1-to-many, and access all images, files, and links shared in the conversation from here.",
            orientation: Orientation.Right
          },
          {
            title: "Manage User",
            selector: "#menu_item_4",
            content: "You can able to create user or registered user in this lessons and organize active user configured in this lesson.",
            orientation: Orientation.Right
          },
          {
            title: "Create New User",
            selector: "#createStudent",
            content: "Use this "+" button to add a new user. You can select any one users from the list if user are selected it will be treated as a lesson.",
            orientation: Orientation.Bottom
          }
        ]
      };
     }else{
     this.TOUR = {
      tourId: "purchases-tour",
      useOrb: false,
      skipCallback: () => console.log("skip clicked"),
      completeCallback: () => {this.updateUserOnboarding(); console.log("complete clicked")},
      steps: [
        {
          title: "Absence GPT",
          selector: "#menu_item_1",
          content: "The information provided from this tool is not for legal advice, and is intended to provide references in regards to state laws on absences.",
          orientation: Orientation.Right
        },
        {
          title: "Policy Library",
          selector:"#menu_item_2",
          content: "Admin can upload e-books & share to users. Users to study at e-books, notes.",
          orientation:Orientation.Right
        },
        {
          title: "Notification",
          selector:"#menu_item_7",
          content: "You can able to start threaded conversations on the project you are working on, share knowledge and connect with your team or it could be anything you would like to discuss or notify your team.",
           orientation: Orientation.Right
        },
        {
          title: "Conversation",
          selector: "#menu_item_6",
          content: "You can able to chat with your team/invited people here either in 1-to-1 or 1-to-many, and access all images, files, and links shared in the conversation from here.",
          orientation: Orientation.Right
        }
      ]
    };
   }
    this.guidedTourService.startTour(this.TOUR);
  }

  toggleSideMenu(){
    // let sidebar = this.document.querySelector('.sidebar');
    //    sidebar.classList.toggle('collapse-menu');
    this.userSetting.visible_sidebar = !this.userSetting.visible_sidebar;
   }


  ngOnDestroy() {
    this.subscribers.isSubscribed = false;
    this.subscribers.userMessage.unsubscribe();
    this.subscribers.groupMessage.unsubscribe();
    this.subscribers.userStatusNotify.unsubscribe();
    this.subscribers.userTypingUpdate.unsubscribe();
    this.subscribers.initiateNewChat.unsubscribe();
    this.subscribers.updateDelivery.unsubscribe();
    this.subscribers.readBulkMessage.unsubscribe();
    this.subscribers.groupUpdate.unsubscribe();
    this.subscribers.receiveUserStatusUpdate.unsubscribe();

    window.removeEventListener('dragover', this.dragOverHandler, false);
    window.removeEventListener('drop', this.dropHandler, false);
    window.removeEventListener('focus', this.focusHandler, false);
    window.removeEventListener('blur', this.focusBlurHandler, false);
  };

}
