import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpParams } from '@angular/common/http';
import { config } from '../../../app.config';

@Injectable({providedIn: 'root'})

export class UserService {

    /** specifying Base URL.*/
    public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}`;

    constructor(private http: HttpClient) {
    }

    // companySignup(data){
    //   return this.http.post(`${this.BASE_URL}/users/companySignup`, data);
    // }

    signupNewUser(data) {
        return this.http.post(`${this.BASE_URL}/user-signup`, data);
    }

    addNewUser(data) {
        return this.http.post(`${this.BASE_URL}/users`, data);
    };

    getUserList(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.BASE_URL}/users`, { params: Params });
    };

    getActiveUserList(payLoad) {
        const Params = this.preHttpParams(payLoad);
        return this.http.get(`${this.BASE_URL}/activeUsers`, { params: Params });
    };


    getActiveUserPaginate(payLoad) {
        return this.http.get(`${this.BASE_URL}/activeUsers?offset=${payLoad.offset}&limit=${payLoad.limit}&sort=${payLoad.sort}&search=${payLoad.search}`);
    };



    // getSelectedUserList(payLoad) {
    //     const Params = this.preHttpParams(payLoad);
    //     return this.http.get(`${this.BASE_URL}/selectUser`, { params: Params })
    // };

    getSelectedUserList(payLoad) {
      const Params = this.preHttpParams(payLoad);
      return this.http.get(`${this.BASE_URL}/chats/getNonGroupMemberList?`, { params: Params })
    }

    getDefaultLookup() {
        return this.http.get(`${this.BASE_URL}/users/defaultLookup`);
    };

    getUserDetail(user_id?) {
        return this.http.get(`${this.BASE_URL}/users/${user_id}`);
    };

    getSortAndSearch(payLoad) {
        return this.http.get(`${this.BASE_URL}/users?offset=${payLoad.offset}&limit=${payLoad.limit}&sort=${payLoad.sort}&search=${payLoad.search}`);
    };

    updateUserDetail(user_id, user) {
        return this.http.put(`${this.BASE_URL}/users/${user_id}`, user);
    };

    userPassword(password) {
        return this.http.patch(`${this.BASE_URL}/users/userPassword`, password);
    };

    activateAndDeactiveUser(user_id) {
        return this.http.patch(`${this.BASE_URL}/users/${user_id}`, null);
    };

    deleteUserDetail(user_id) {
        return this.http.delete(`${this.BASE_URL}/users/${user_id}`);
    };

    deleteUserProfile(user_id) {
        return this.http.delete(`${this.BASE_URL}/users/deleteUserProfile/${user_id}`);
    };

    deleteCompanyLogo(){
      return this.http.delete(`${this.BASE_URL}/remove/logo`)
    }

    verify_otp(otp) {
        return this.http.put(`${this.BASE_URL}/users/verify_otp`, otp);
    };

    updateUserOnboarding() {
        return this.http.put(`${this.BASE_URL}/complete-user-onboard`, '');
    };

    emitEmailToAllUsers(payLoad) {
        return this.http.put(`${this.BASE_URL}/sendEmailToAllUser`, payLoad);
    };


    bulkUserCreate(payLoad) {
        return this.http.post(`${this.BASE_URL}/users/bulk-user-create`, payLoad);
    };

    getBulkUserTemplate(): any {
        return this.http.get(`${this.BASE_URL}/users/bulk-user-template`, { responseType: 'blob' });
    };

    addNewClass(data){
      return this.http.post(`${this.BASE_URL}/createClass`, data);
    }

    getClassList(){
      return this.http.get(`${this.BASE_URL}/classList`);
    }


    preHttpParams(payLoad) {
        let Params = new HttpParams();
        for (const key in payLoad) {
            if (key && (payLoad[key] || payLoad[key] === 0)) {
                Params = Params.append(key, payLoad[key]);
            }
        }
        return Params;
    };

}
