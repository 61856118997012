import { Component, OnInit, HostBinding, Directive } from '@angular/core';

// @Directive()
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
})
export class PrivacyComponent implements OnInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = "100%";

  constructor() { }

  ngOnInit() {
  }

}
