import { Component, OnInit, Input, Directive } from '@angular/core';
import { DatePipe } from '@angular/common';
import { path, AlertPopup, HttpLoader } from './../../../../app.config';
import { NotificationService } from './../../notification.service';

// @Directive()
@Component({
    selector: 'app-sub-post',
    templateUrl: './sub-post.component.html',
    styleUrls: []
})
export class SubPostComponent implements OnInit {
    @Input() subPostList: any;
    subPostName: string;
    editedSubPostDetails: any = null;

    private subscribers: any = {};
    profileUrl = path.PROFILE_URL;
    constructor(
        private notificationService: NotificationService,
        private httpLoader: HttpLoader,
        private alert: AlertPopup) {
    }

    ngOnInit() {
    };

    editSubPostName(subPost) {
        if (subPost) {
            const subPostList = [...this.subPostList];
            this.subPostList = this.changeDisableParamsInSubPost(subPostList, subPost);
            this.editedSubPostDetails = { ...subPost };
        }
    };

    cancelEditedSubPostName(subPost) {
        const subPostList = [...this.subPostList];
        subPostList.map(post => {
            post.editSubPostName = false;
            post.editDisabled = false;
        });
        this.subPostList = subPostList;
        this.editedSubPostDetails = null;
    };

    updateSubPostName(subPost) {
        if (subPost.sub_post_name) {
            try {
                const payload = {
                    sub_post_name: subPost.sub_post_name,
                    sub_post_id: subPost.sub_post_id
                }
                this.notificationService.updateSubPosts(payload).subscribe(
                    posts => {
                        const subPostList = [...this.subPostList];
                        subPostList.map((el) => {
                            el.editSubPostName = false;
                            el.editDisabled = false;
                            if (el.sub_post_id === subPost.sub_post_id) {
                                el.sub_post_name = subPost.sub_post_name;
                            }
                        });
                        this.subPostList = subPostList;
                        this.editedSubPostDetails = null;
                        this.httpLoader.hideLoader();
                    },
                    error => {
                        this.httpLoader.hideLoader();
                        console.log(error);
                    }
                )
            } catch (err) {
                this.httpLoader.hideLoader();
                console.log(err)
            }
        }
    };

    /**
   * Internal Use
   * @param subPostList
   * @param subPost
   */

    changeDisableParamsInSubPost(subPostList, subPost) {
        subPostList.map(post => {
            if (subPost.sub_post_id === post.sub_post_id) {
                post.editSubPostName = true;
                post.editDisabled = false;
            } else {
                post.editSubPostName = false;
                post.editDisabled = true;
            }
        });

        return subPostList;
    };

    calcTextLength(text: string) {
        if (text) {
            return text.length > 0 ? text.length + 10 : text.length;
        }
        return 50;
    };

    /*** Comments functionalities */

    openNewComment(subPost) {
        subPost.showReply = !subPost.showReply;
    };

    createNewComment(subPost) {
        if (subPost.newComment.trim() !== '') {
            try {
                const payLoad = {
                    sub_post_id: subPost.sub_post_id,
                    comment: subPost.newComment
                };
                this.notificationService.createComment(payLoad).subscribe(
                    response => {
                        const subPostList = [...this.subPostList];
                        subPostList.map(el => {
                            if (el.sub_post_id === subPost.sub_post_id) {
                                delete el.newComment;
                                el.commentsCount += 1;
                                el.commentsList.unshift(response['createdComment']);
                            }
                        });
                        this.subPostList = subPostList;
                        // this.alert.showAlertMessage(response);
                        this.httpLoader.hideLoader();
                    },
                    error => {
                        this.httpLoader.hideLoader();
                        this.alert.showAlertMessage(error);
                        console.log(error);
                    });
            } catch (err) {
                this.httpLoader.hideLoader();
                console.log(err);
            }
        }
    };


    //Key handle white space break without characters
    keyHandleComment(event){

    if(event.which === 32 && event.target.selectionStart === 0 ){
        event.preventDefault();
        return false;
      }
    }
}
