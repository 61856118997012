import { Component, OnInit, HostBinding, AfterViewInit, Directive } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import * as _ from 'underscore';
import { ChatService } from '../chat.service';
import { ChatListComponent } from '../chat-list.component';
import { config, path, AlertPopup, HttpLoader } from './../../../app.config';
import { DataSharingService } from './../../../shared/data-sharing.service';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';
import { fromEvent, Subscription} from 'rxjs';




// @Directive()
@Component({
  selector: 'app-new-chat',
  templateUrl: './new-chat.component.html',
  styleUrls: []
})
export class NewChatComponent implements OnInit, AfterViewInit {
  @HostBinding('class.flex-grid') leftBarClass = true;
  @HostBinding('style.width') flexwidth = '100%';
  RECORD_LIMIT: number = config.DEFAULT_RECORD_LIMIT;
  OFFSET: number = config.DEFAULT_OFFSET;

  userList: any[] = [];
  frequentUserList: any[] = [];
  messages: any;
  selectedUsers: boolean;
  tabName: string;
  // search: string;
  public search: string = null;
  groupName: string;
  profileUrl = path.PROFILE_URL;
  allUserTotalRowCount: number;
  freqUserTotalRowCount: number;
  userIdList: number[] = [];


  showPaginateSpinner = false;
  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;

  viewControl: any = {
    changedSearchText: false
  };

  public userSubscribers: any = {};

  searchSubject = new Subject <any>();

  isMobile: boolean = false;
  width:number = window.innerWidth;
  height:number = window.innerHeight;
  mobileWidth:number  = 768;

  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  constructor(
    private chatService: ChatService,
    private chatListComponent: ChatListComponent,
    private router: Router,
    private httpLoader: HttpLoader,
    private alert: AlertPopup,
    private dataSharingService: DataSharingService) {
    this.searchSubject
      .debounceTime(500)
      .subscribe(event => {
        this.viewControl.changedSearchText = true;
        const searchText = event.target.value;
        this.searchUserList(searchText, this.tabName);
      });
  }

  ngOnInit() {
    this.tabName = 'all_user';
    this.getAllChatList(this.search);
    this.getFrequentChatList(this.search);


    if(this.width <= this.mobileWidth){
      this.chatListComponent.viewChatList = true;
    }else{
      this.chatListComponent.viewChatList = false;
    }

    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      let windowWith = window.innerWidth;
      if(windowWith <= this.mobileWidth){
        this.chatListComponent.viewChatList = true;
      }else{
        this.chatListComponent.viewChatList = false;
      }
    })
  }


  ngAfterViewInit(): void {

    this.userSubscribers.userStatusSub = this.dataSharingService.getUserStatusNotify().subscribe(userStatus => {
      const copyUserList = [...this.userList];
      const findIndex = copyUserList.findIndex(el => el.user_id === userStatus.id);
      if (findIndex >= 0) {
        const copyObj = copyUserList[findIndex];
        copyObj.is_online = !userStatus.userDisconnected;
        copyObj.last_seen = userStatus.t;
        copyObj.status_name = userStatus.status_name;
        copyObj.user_status = userStatus.user_status;
        copyUserList[findIndex] = copyObj;
      }
      this.userList = copyUserList;


      const copyFrequentUserList = [...this.frequentUserList];
      const index = copyFrequentUserList.findIndex(el => el.user_id === userStatus.id);
      if (index >= 0) {
        const copyObj = copyFrequentUserList[index];
        copyObj.is_online = !userStatus.userDisconnected;
        copyObj.last_seen = userStatus.t;
        copyObj.status_name = userStatus.status_name;
        copyObj.user_status = userStatus.user_status;
        copyFrequentUserList[index] = copyObj;
      }
      this.frequentUserList = copyFrequentUserList;
    });

    this.userSubscribers.statusUpdate = this.dataSharingService.getUserstatusUpdate().subscribe(userStatus => {
      const copyUserList = [...this.userList];
      const findIndex = copyUserList.findIndex(el => el.user_id === userStatus.user_id);
      if (findIndex >= 0) {
        const copyObj = copyUserList[findIndex];
        copyObj.status_name = userStatus.status_name;
        copyObj.user_status = userStatus.status_id;
        copyUserList[findIndex] = copyObj;
      }
      this.userList = copyUserList;


      const copyFrequentUserList = [...this.frequentUserList];
      const index = copyFrequentUserList.findIndex(el => el.user_id === userStatus.user_id);
      if (index >= 0) {
        const copyObj = copyFrequentUserList[index];
        copyObj.status_name = userStatus.status_name;
        copyObj.user_status = userStatus.status_id;
        copyFrequentUserList[index] = copyObj;
      }
      this.frequentUserList = copyFrequentUserList;
    });

  }


  switchNewChatTab(name) {
    this.tabName = name;
    this.OFFSET = 0;
  };

  searchUserList(search, tabName) {
    this.getAllChatList(search);
    this.getFrequentChatList(search);

    // switch (tabName) {
    //   case `all_user`: {
    //     this.getAllChatList(search);
    //     break;
    //   }
    //   case `frequent_user`: {
    //     this.getFrequentChatList(search);
    //     break;
    //   }
    //   default: {
    //     this.getAllChatList(search);
    //     break;
    //   }
    // }
  }

  getAllChatList(search = '', pageNo = 0) {
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
        this.httpLoader.hideLoader();
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: search || ''
      };

      this.chatService.getAllChatList(payLoad).subscribe(
        list => {
          if (offset === 0) {
            this.httpLoader.hideLoader();
            this.userList = list['userList'];
          } else {
            this.userList = [...this.userList.concat(list['userList'])];
          }
          this.allUserTotalRowCount = list['TotalRowCount'];
          this.showPaginateSpinner = false;
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.showPaginateSpinner = false;
          this.httpLoader.hideLoader();
        }
      )
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  };

  getFrequentChatList(search = '', pageNo = 0) {
    try {
      if (pageNo === 0) {
        this.OFFSET = 0;
        this.httpLoader.hideLoader();
      }

      const offset = this.OFFSET;
      const payLoad = {
        offset: offset,
        limit: this.RECORD_LIMIT,
        search: search || ''
      };


      this.chatService.frequentChatList(payLoad).subscribe(
        list => {
          if (offset === 0) {
            this.httpLoader.hideLoader();
            this.frequentUserList = list['frequentChatList'];
          } else {
            this.frequentUserList = [...this.frequentUserList.concat(list['frequentChatList'])];
          }
          this.freqUserTotalRowCount = list['TotalRowCount'];
          this.showPaginateSpinner = false;
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.showPaginateSpinner = false;
          this.httpLoader.hideLoader();
        }
      )
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  };


  clearSearchUserList() {
    this.search = null;
    if (!this.viewControl.changedSearchText) {
      return;
    }

    this.viewControl.changedSearchText = false;
    this.getAllChatList(this.search);
    this.getFrequentChatList(this.search);
  }


  fetchNextUserList() {
    if (this.tabName === 'all_user') {
      if (this.userList.length < this.allUserTotalRowCount && !this.showPaginateSpinner) {
        this.OFFSET += 1;
        this.showPaginateSpinner = true;
        this.getAllChatList(this.search, this.OFFSET);
      }
    } else if (this.tabName === 'frequent_user') {
      if (this.frequentUserList.length < this.freqUserTotalRowCount && !this.showPaginateSpinner) {
        this.OFFSET += 1;
        this.showPaginateSpinner = true;
        this.getFrequentChatList(this.search, this.OFFSET);
      }
    }
  };


  createChat(chat_type) {
    try {
      if (chat_type && chat_type === 'user') {
        this.httpLoader.showLoader();
        const to_user_id = this.userIdList.length > 0 ? this.userIdList[0] : 0;
        if (to_user_id > 0) {
          const payLoad = {
            to_user_id,
            chat_type
          };
          this.chatService.createChat(payLoad).subscribe(
            chat => {
              this.httpLoader.hideLoader();
              if (chat && chat['createdChat']) {
                const newChat = chat['createdChat'];
                this.chatListComponent.chatList.unshift(newChat);
                this.chatListComponent.totalRowCount += 1;
                this.router.navigate([`/main/chats/singleuser/${newChat.user_id}`]);
              } else if (chat && chat['user_id']) {
                this.router.navigate([`/main/chats/singleuser/${chat['user_id']}`]);
              }
              this.userIdList = [];
            },
            error => {
              this.alert.showAlertMessage(error.error);
              this.httpLoader.hideLoader();
            }
          )
        }
      }
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  };


  selectedUser(userId) {
    try {
      if (userId) {
        if (this.userIdList.indexOf(userId) > -1) {
          this.userIdList = _.without(this.userIdList, userId);
        } else {
          this.userIdList.push(userId);
        }
      }

    } catch (err) {
      console.log(err);
    }
  };


  createGroupChat(chat_type) {
    try {
      this.httpLoader.showLoader();
      const chat_group_name = this.groupName;
      const payLoad = {
        chat_group_name,
        user_Ids: _.union(this.userIdList),
        chat_type
      }
      this.chatService.createGroup(payLoad).subscribe(
        (chat) => {
          this.groupName = '';
          this.userIdList = [];
          this.selectedUsers = false;
          const newGroup = chat['createdGroup'];
          this.chatListComponent.chatList.unshift(newGroup);
          this.chatListComponent.totalRowCount += 1;
          this.router.navigate([`/main/chats/groupuser/${newGroup.group_id}`]);
          this.httpLoader.hideLoader();
        },
        error => {
          this.alert.showAlertMessage(error.error);
          this.httpLoader.hideLoader();
        }
      )
    } catch (err) {
      this.httpLoader.hideLoader();
    }
  }


  cancelUser() {
    try {
      this.selectedUsers = false;
      const list = _.findWhere(this.userList, { selected: true });
      if (list) {
        this.userList.map(user => user.selected = false);
      }
      if (this.search) {
        this.search = '';
        this.getAllChatList('');
      }
      this.router.navigate(['/main/chats']);
    } catch (err) {
      console.log(err);
    }
  }

}
