import { AlertPopup, HttpLoader } from './../../app.config';
import { Component, OnInit, Directive, ElementRef, EventEmitter, HostListener, inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { config } from '../../app.config';
import { ValidationService } from '../form-validation/validation.service';
import { UserService } from './../userandgroup/user/user.service';
import { isPlatformBrowser } from '@angular/common';


declare const windown : any;

const defaultUtilScript = '';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: [],
})

export class SignupComponent implements OnInit {


  signupForm: FormGroup;

  ngTelInput: any;
  showBtnSpinner: boolean = false;
  cuntryCode:number;
  copyRights:number = config.copyrights;

  public account = {
    password: <string>null
  }

  public baseColor = '#FFF';
  public passwordLabel = 'Password strength:';
  // public strengthLabels = ['', '(Weak)', '(Normal)', '(Strong)', '(Great!)'];
  public passwordStrength = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  public strength = 0;

  strengthChanged(strength: number){
    this.strength = strength;
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alert: AlertPopup,
    private userService: UserService,
    private httpLoader: HttpLoader) {
    this.signupForm = formBuilder.group({
      full_name: [null, Validators.compose([Validators.required])],
      company_name:  [null, Validators.compose([Validators.required])],
      email_id: [null, Validators.compose([Validators.required, ValidationService.emailValidator])],
      password: [''],
      phone_no: [null, Validators.compose([Validators.required, ValidationService.phoneNoValidate])],
      location: [null, Validators.compose([Validators.required])],
      product_type: [null]
    });
  }

  hasError($event){

  }

  getPhoneNumber($event){}

  telInputObject(obj){
    console.log(obj.s.dialCode);
    this.cuntryCode = obj.s.dialCode;

    obj.setCountry('us');
  }

  onCountryChange($event){
    console.log($event);

  }

  ngOnInit() { };

  returnToLogin() {
    this.router.navigate(['/login']);
  };


  ceateNewAcc(formVal) {

    try {
      this.showBtnSpinner = true;
      this.httpLoader.showLoader();

      const payLoad = {
        'company_name': formVal['company_name'],
        'email_id': formVal['email_id'],
        'password': formVal['password'],
        'full_name' : formVal['full_name'],
        'phone_no' : '+'+ this.cuntryCode + formVal['phone_no'],
        'location' : formVal['location'],
        'product_type': formVal['product_type']
      }

      this.userService.signupNewUser(payLoad).subscribe(response => {
        window.localStorage.user_id = JSON.stringify(response['user_id']);
        window.localStorage.user_role = JSON.stringify(response['user_role']);
        window.localStorage.token = JSON.stringify(response['token']);
        this.httpLoader.hideLoader();
        this.alert.showAlertMessage(response);
        this.router.navigate(['/verify_otp']);
      },
      error =>{
        this.httpLoader.hideLoader();
        this.showBtnSpinner = false;
        this.alert.showAlertMessage(error.error);
      })

    } catch (err) {
      this.httpLoader.hideLoader();
    }
  };

}

