import { Component } from '@angular/core';

@Component({
  selector: 'app-bottemplate',
  templateUrl: './bottemplate.component.html',
  styleUrls: ['./bottemplate.component.scss']
})
export class BottemplateComponent {

}
