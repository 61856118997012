import { Component, OnChanges, Input, Output, EventEmitter, OnDestroy, Directive  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpRequest, HttpResponse, HttpEventType } from '@angular/common/http';
import { saveAs as importedSaveAs } from 'file-saver';
import { Observable } from 'rxjs/Rx';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { path } from './../app.config';
import { SecureFileService } from './secure-image-service';
import { PlayerService } from './../components/media-player/media-player-service';
// import { ELibraryService } from 'app/components/e-library/e-library.service';


// @Directive()
@Component({
    selector: 'app-secured-image',
    templateUrl: './secure-image.component.html',
    styleUrls: ['./secure-image.component.scss']
})

export class SecuredImageComponent implements OnChanges, OnDestroy {

    @Input() src: string="";
    @Input() classStyle: string;
    @Input() fileData: any;

    @Output() mediaContent: EventEmitter<any> = new EventEmitter();
    playingMedia: string;
    downLoadedPercentage = 0;

    private src$ = new BehaviorSubject(this.src);

    dataUrl$ = this.src$.switchMap(url => this.loadImage(url));
    ngOnChanges(): void {
        this.src$.next(this.src);
    }

    constructor(
        private httpClient: HttpClient,
        private domSanitizer: DomSanitizer,
        private secureFileService: SecureFileService,
        private playerService: PlayerService,
        // private elibraryService: ELibraryService
        ) {
    }

    private loadImage(url: string): Observable<any> {
        try {
            if (url) {
                return this.httpClient
                    .get(url, { responseType: 'blob' })
                    .map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e)))
            }
        } catch (err) {
            console.log(err);
        }
    };

    playMedia(fileData: any) {
        this.playerService.clear();
        if (fileData.file_type === 'video' || fileData.file_type === 'audio') {
            if (fileData) {
                const payLoad = {
                    file_path: fileData.download_file_path,
                    file_type: fileData.file_type
                };
                this.playerService.emitMediaContent(payLoad);
                // this.elibraryService.emitMediaContent(payLoad);
                this.playingMedia = { ...fileData }.download_file_path;
            }
        }
    };

    secureFileDownLoad(fileData: any) {
        try {
            const url = fileData.download_file_path;
            if (url) {
                fileData.startFileDownLoad = true;
                this.secureFileService.secureFileDownLoad(url).subscribe(blob => {
                    fileData.startFileDownLoad = false;
                    importedSaveAs(blob, fileData.file_id);
                }, error => {
                    fileData.startFileDownLoad = false;
                    console.log(error);
                })
            }
        } catch (err) {
            fileData.startFileDownLoad = false;
            console.log(err);
        }
    };

    // secureFileDownLoad(fileData: any) {
    //     try {
    //         const URL = fileData.download_file_path;
    //         fileData.startFileDownLoad = true;
    //         if (URL) {
    //             const req = new HttpRequest('GET', URL, { responseType: 'blob', reportProgress: true });
    //             this.httpClient.request(req).subscribe(event => {
    //                 if (event.type === HttpEventType.DownloadProgress) {
    //                     this.downLoadedPercentage = Math.round(100 * event.loaded / event.total);
    //                 }

    //                 if (event.type === HttpEventType.Response) {
    //                     const blob: any = event['body'];
    //                     this.downLoadedPercentage = 0;
    //                     fileData.startFileDownLoad = false;
    //                     importedSaveAs(blob, fileData.file_id);
    //                 }
    //             });
    //         }
    //     } catch (err) {
    //         fileData.startFileDownLoad = false;
    //         console.log(err);
    //     }
    // };


    ngOnDestroy(): void {
        this.playingMedia = null;
    }

}
