import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { RouterModule } from '@angular/router';
import { StaffRoomComponent } from './staff-room.component';
import { TopicDetailsComponent } from './topic-details/topic-details.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {path: '', component: StaffRoomComponent, children: [
        {path: ':id', component: TopicDetailsComponent },
      ]
    }
    ])
  ],
  declarations:[],
  exports: [RouterModule]
})

export class StaffRoomRoutingModule {

}
