import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AiAssistComponent } from './ai-assist.component';
import { BottemplateComponent } from './bottemplate/bottemplate.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '', component: AiAssistComponent,
        children:[
          {
            path:'choosetemplate',
            component:BottemplateComponent
          }
        ]
      }
    ])
  ],
  exports:[RouterModule]
})
export class AiAssistRoutingModule { }
