import { Pipe, PipeTransform, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { emojis, EmojiService, CompressedEmojiData } from 'lib/picker/ngx-emoji';

/**
 * Angular pipe to transform HTML containing unicode emojis to
 * HTML containing emoji image elements
 *
 */
/** @dynamic */
@Pipe({
  name: 'emojismart'
})

export class EmojiTranslatePipe implements PipeTransform {

  private static cachedEmojiRegex: RegExp;


  /**
   * Utility method to get all text node descendants of a DOM node
   * @param node the DOM node to get text nodes for
   */
  public static getAllTextNodes(node: Node): CharacterData[] {
    const all = [];
    for (node = node.firstChild; node; node = node.nextSibling) {
      if (node.nodeType === Node.TEXT_NODE) {
        all.push(node);
      } else {
        all.push(...EmojiTranslatePipe.getAllTextNodes(node));
      }
    }
    return all;
  }


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer,
    private emojiService: EmojiService
    ) {
  }

  transform(
    html: string,
    setOrElementFn: 'google'
      | ((unicodeEmoji: string, element: CharacterData, position: number) => HTMLElement)= 'google',
    size?: number,
    sheetSize?: 16 | 20 | 32 | 64,
    backgroundImageFn?: (set: string, sheetSize: number) => string,
    sheetRows?: number,
    sheetColumns?: number,
    args?: any): SafeHtml  {

    let subs = html.split(":");

    subs.forEach(s => {
      let emoji = this.findEmoji(s);
      if (emoji) {
        let emojiCode = `:${s}:`;

        html = html.replace(emojiCode, emoji);
      }
    });



    return this.sanitizer.bypassSecurityTrustHtml(
      this.emojisToImages(
        html,
        setOrElementFn,
        size,
        sheetSize,
        backgroundImageFn,
        sheetRows,
        sheetColumns
      )
    );

  }

  findEmoji(id: string): string | undefined {


    let emoji = this.emojiService.emojis.find(emoji => emoji.id == id);

    if (emoji) {

      return emoji.native;
    }
    return undefined;
  }

  /**
     * Replaces all unicode emojis available through emoji-mart with a span displaying
     * the image representation of that emoji
     * @param html The original html
     */
    public emojisToImages(
      html: string,
      setOrElementFn?: 'google'
      | ((unicodeEmoji: string, element: CharacterData, position: number) => HTMLElement),
      size?: number,
      sheetSize?: 16 | 20 | 32 | 64,
      backgroundImageFn?: (set: string, sheetSize: number) => string,
      sheetRows?: number,
      sheetColumns?: number
      ): string {

      // Ensure most html entities are parsed to unicode:
      const div = <Element> this.document.createElement('div');
      div.innerHTML = html;
      html = div.innerHTML;


      const textNodes = EmojiTranslatePipe.getAllTextNodes(div);
      for(let currentItem of textNodes){
        let match: RegExpExecArray;

        while((match = this.emojiRegex.exec(currentItem.textContent))!== null){

          const unicodeEmoji = currentItem.textContent.substr(match.index, match[0].length);

          const hexCodeSegments = [];
          let j = 0;

          while(j < unicodeEmoji.length){
            const segment = unicodeEmoji.codePointAt(j).toString(16).toUpperCase();
            hexCodeSegments.push(segment);

            j += Math.ceil(segment.length / 4);
          }

          const hexCode = hexCodeSegments.join('-');
          const matchingData = this.findEmojiData(hexCode);

          if (matchingData) {

            let emojiElement: HTMLElement;

            if (typeof setOrElementFn === 'string') {

              emojiElement = this.createEmojiImageSpan(
                unicodeEmoji,
                matchingData.sheet,
                setOrElementFn,
                size,
                sheetSize,
                backgroundImageFn,
                sheetRows,
                sheetColumns
              );
            }else{
                emojiElement = setOrElementFn(unicodeEmoji, currentItem, match.index);
            }

            const text = currentItem.textContent;
            currentItem.textContent = text.substr(0, match.index);
            currentItem.parentNode.insertBefore(emojiElement, currentItem.nextSibling);
            currentItem = this.document.createTextNode(text.substr(match.index + match[0].length));
            emojiElement.parentNode.insertBefore(currentItem, emojiElement.nextSibling);

            this.emojiRegex.lastIndex = 0;

          }
        }
      }


      return div.innerHTML;
  }


  private createEmojiImageSpan(
    unicodeEmoji: string,
    sheet: [number, number],
    set: 'google',
    size?: number,
    sheetSize?: 16 | 20 | 32 | 64,
    backgroundImageFn?: (set: string, sheetSize: number) => string,
    sheetRows?: number,
    sheetColumns?: number
  ): HTMLElement {
    const span = document.createElement('span');
    span.setAttribute('contenteditable', 'false');
    span.className = 'emoji-pipe-image';
    span.innerText = unicodeEmoji;

    const styles: Partial<CSSStyleDeclaration> = this.emojiService.emojiSpriteStyles(
      sheet,
      set,
      size,
      sheetSize,
      sheetRows,
      backgroundImageFn,
      sheetColumns
    );
    styles.overflow = 'hidden';
    styles.textIndent = '-1000px';
    Object.assign(span.style, styles);

    return span;
  }

  /**
   * Regex matching all unicode emojis contained in emoji-mart
   */
  private get emojiRegex(): RegExp {
    if (EmojiTranslatePipe.cachedEmojiRegex) {
      return EmojiTranslatePipe.cachedEmojiRegex;
    }

    let characterRegexStrings: string[] = [];
    for (const emoji of emojis) {
      characterRegexStrings.push(this.emojiService.unifiedToNative(emoji.unified).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

      if (emoji.skinVariations) {
        for (const skinVariation of emoji.skinVariations) {
          characterRegexStrings.push(this.emojiService.unifiedToNative(skinVariation.unified).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
        }
      }
    }

    characterRegexStrings = characterRegexStrings.sort((a, b) => {
      if (a.length > b.length) {
        return -1;
      }

      if (b.length > a.length) {
        return 1;
      }

      return 0;
    });

    const strings = characterRegexStrings;
    const reString = '(' + strings.join('|') + ')';
    EmojiTranslatePipe.cachedEmojiRegex = new RegExp(reString, 'gu');

    return EmojiTranslatePipe.cachedEmojiRegex;
  }

  /**
   * Find raw emoji-mart data for a specific emoji hex code
   * @param hexCode String representation of the emoji hex code
   */
  private findEmojiData(hexCode: string): CompressedEmojiData {
      for (const emojiData of emojis) {
          if (emojiData.unified === hexCode) {
              return emojiData;
          }

          if (emojiData.skinVariations) {
              for (const skinVariation of emojiData.skinVariations) {
                  if (skinVariation.unified === hexCode) {
                      const skinData = Object.assign({}, emojiData);
                      skinData.sheet = skinVariation.sheet;
                      return skinData;
                  }
              }
          }
      }

      return null;
  }

}
