import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs.component';
import { TabComponent } from './tab/tab.component';
import { AssistBotService } from 'app/components/ai-assist/assist-bot.service';
 

@NgModule({
  declarations: [
    TabsComponent,
    TabComponent,
   
  ],
  exports:[TabsComponent,TabComponent,AssistBotService],
  imports: [
    CommonModule
  ]
})
export class TabsModule { }
