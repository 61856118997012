import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ELibraryComponent } from './e-library/e-library.component';
import { PurchaseCourseComponent } from './purchase-course/purchase-course.component';
import { ELibraryDetailsComponent } from './e-library/e-library-details/e-library-details.component';
import { ELibraryTileComponent } from './e-library/e-library-details/e-library-tile/e-library-tile.component';
import { ELibraryListComponent } from './e-library/e-library-details/e-library-list/e-library-list.component';
import { PurchaseCourseDetailsComponent } from './purchase-course/purchase-course-details/purchase-course-details.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ElibraryandcourseRoutingModule } from './elibraryandcourse-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { ELibraryService } from './e-library/e-library.service';
import { PurchaseCourseService } from './purchase-course/purchase-course.service';
import { ValidationService } from '../form-validation/validation.service';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import { ELibraryDashboardComponent } from './e-library/e-library-details/e-library-dashboard/e-library-dashboard.component';
import { ELibraryUploadComponent } from './e-library/e-library-details/e-library-upload/e-library-upload.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ChartsModule } from 'ng2-charts';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxWatermarkModule } from 'ngx-watermark';
import { NewCourseComponent } from './e-library/new-course/new-course.component';
import { ClipboardModule } from 'ngx-clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PageHeaderComponent } from '../page-header/page-header.component';




@NgModule({
  declarations: [ELibraryComponent, PurchaseCourseComponent, ELibraryDetailsComponent, ELibraryTileComponent, ELibraryListComponent, PurchaseCourseDetailsComponent, ELibraryDashboardComponent, ELibraryUploadComponent, NewCourseComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ElibraryandcourseRoutingModule,
    SharedModule,
    NgxDocViewerModule,
    VgControlsModule,
    VgCoreModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    RoundProgressModule,
    ChartsModule,
    AngularEditorModule,
    NgxWatermarkModule,
    ClipboardModule,
    DragDropModule
  ],
  providers: [
    ELibraryService, PurchaseCourseService, ValidationService
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ElibraryandcourseModule { }
