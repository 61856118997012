import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Rx';
import { config } from '../../app.config';

@Injectable()
export class LoginService {

  /** specifying Base URL.*/
  public BASE_URL = `${config.PROTOCOL}://${config.HOST}:${config.PORT}/canstring/${config.VERSION}/users/`

  constructor(private http: HttpClient) { }

  authenticateEmail(data){
    return this.http.post(`${this.BASE_URL}verifyEmail`, data);
  }

  authenticateUser(data) {
    return this.http.post(`${this.BASE_URL}authenticate`, data);
  };

};
