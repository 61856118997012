import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthGuardService as AuthGuard } from './../../auth-guard.service';

import { HomeComponent } from '../../components/home/home.component';
import { ChatListComponent } from './chat-list.component';
import { NewChatComponent } from './new-chat/new-chat.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '', component: ChatListComponent, canActivate: [AuthGuard], children: [
          { path: 'newchat', component: NewChatComponent, canActivate: [AuthGuard] },
          { path: 'singleuser/:id', component: ChatMessageComponent, canActivate: [AuthGuard] },
          { path: 'groupuser/:id', component: ChatMessageComponent, canActivate: [AuthGuard] }
        ]
      }
    ])
  ],
  declarations: [],
  exports: [RouterModule]
})
export class ChatListRoutingModule { }
