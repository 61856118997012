import { Component, OnInit, Directive } from '@angular/core';

// @Directive()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: []
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('home compo');
  }

}
